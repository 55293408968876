import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';

import moment from 'moment';

import { BlobProvider } from '@react-pdf/renderer';

import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Switch from '@mui/material/Switch';

import MenuBookIcon from '@mui/icons-material/MenuBook';
import LightbulbOutlinedIcon from '@mui/icons-material/LightbulbOutlined';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';

import PDFList from './PDFList';
import PDFViewer from './PDFViewer';
import EditNotes from './EditNotes';
import PDFRenderer from './PDFRenderer';

import { getUserEmail } from '../../../utils/auth';

import useFetch from '../../../hooks/useFetch';
import useS3 from '../../../hooks/useS3';

import './index.scss';

export default function PDFReport({
  userData,
  setUserData,
  generateNewReport,
  setGenerateNewReport,
  selectedGridValue,
}) {
  const [switchPosition, setSwitchPosition] = useState('view-report');

  const [reportList, setReportList] = useState(null);

  const [showTooltip, setShowTooltip] = useState(true);

  const [reportData, setReportData] = useState(null);

  const [parsingPDF, setParsingPDF] = useState(false);
  const [pdfLoading, setPdfLoading] = useState(false);
  const [loadingPDFs, setLoadingPDFs] = useState(false);

  const [pdfUrl, setPdfUrl] = useState(null);

  const [reportToView, setReportToView] = useState({});

  const [primaryInvestorEmail, setPrimaryInvestorEmail] = useState('');
  const [primaryInvestorName, setPrimaryInvestorName] = useState('');

  const s3Path = `820PortfolioReports/${userData.metaData.portfolioCompanyId}/${userData.metaData.transactionId}`;

  const fetchData = {
    ...selectedGridValue.term && selectedGridValue.volatility ? {
      term: new Intl.NumberFormat('en-US', {
        minimumIntegerDigits: 1,
        minimumFractionDigits: 1,
      }).format(selectedGridValue.term),
      volatility: selectedGridValue.volatility,
    } : {
      term: new Intl.NumberFormat('en-US', {
        minimumIntegerDigits: 1,
        minimumFractionDigits: 1,
      }).format(userData.gridData?.defaultScenarios?.term),
      volatility: userData.gridData?.defaultScenarios?.volatility.toFixed(1).toString(),
    },
    scenarios: userData.gridData?.defaultScenarios,
  };

  const [, generateFootnotesRequest] = useFetch();
  const [, generatePdfRequest] = useFetch();
  const [, getPdfRequest] = useFetch();

  const [, investorUsersRequest] = useFetch();

  const [, s3ListRequest] = useS3();
  const [, s3GetRequest] = useS3();
  const [, s3PutRequest] = useS3();

  function parsePdfData(pdfData, footnoteData) {
    setReportData({
      ...pdfData,
      EquityValueConclusionSummary: {
        ...pdfData.EquityValueConclusionSummary,
        marketAdjustment: userData.calcData.methods.backsolve.marketAdjustment ?
          parseFloat(userData.calcData.methods.backsolve.marketAdjustment.replaceAll('%', '')) : '',
      },
      CapitalizationTable: {
        ...JSON.parse(pdfData.CapitalizationTable.capTable),
        ...JSON.parse(pdfData['Liquidation Preferences']),
        remainingOptionPool: parseInt(userData.capData.remainingOptionsPool, 10),
      },
      BreakpointAnalyses: {
        breakpoints: { ...JSON.parse(pdfData.BreakpointAnalyses.breakpoints) },
        incrementalValuePerShare: { ...JSON.parse(pdfData.BreakpointAnalyses.incrementalValuePerShare) },
        totalValuePerShare: { ...JSON.parse(pdfData.BreakpointAnalyses.totalValuePerShare) },
      },
      OptionPricingAllocation: {
        ...pdfData.OptionPricingAllocation.backsolve && {
          backsolve: {
            ...pdfData.OptionPricingAllocation.backsolve,
            aggregateValuePerSharePerEquityClass: JSON.parse(pdfData.OptionPricingAllocation.backsolve.aggregateValuePerSharePerEquityClass),
            incrementalOptionValue: JSON.parse(pdfData.OptionPricingAllocation.backsolve?.incrementalOptionValue),
            incrementalOptionValueAllocation: JSON.parse(pdfData.OptionPricingAllocation.backsolve.incrementalOptionValueAllocation),
          },
        },
        // goal seek will always be present if the report utilizes the backsolve method
        ...pdfData.OptionPricingAllocation.goalSeek && {
          goalSeek: {
            ...pdfData.OptionPricingAllocation.goalSeek,
            aggregateValuePerSharePerEquityClass: JSON.parse(pdfData.OptionPricingAllocation.goalSeek.aggregateValuePerSharePerEquityClass),
            incrementalOptionValue: JSON.parse(pdfData.OptionPricingAllocation.goalSeek?.incrementalOptionValue),
            incrementalOptionValueAllocation: JSON.parse(pdfData.OptionPricingAllocation.goalSeek.incrementalOptionValueAllocation),
          },
        },
        ...pdfData.OptionPricingAllocation.pubco && {
          pubco: {
            ...pdfData.OptionPricingAllocation.pubco,
            aggregateValuePerSharePerEquityClass: JSON.parse(pdfData.OptionPricingAllocation.pubco.aggregateValuePerSharePerEquityClass),
            incrementalOptionValue: JSON.parse(pdfData.OptionPricingAllocation.pubco?.incrementalOptionValue),
            incrementalOptionValueAllocation: JSON.parse(pdfData.OptionPricingAllocation.pubco.incrementalOptionValueAllocation),
          },
        },
      },
      FairValuePerShare: {
        ...pdfData.FairValuePerShare,
        backsolve: { ...JSON.parse(pdfData.FairValuePerShare.backsolve) },
        ...pdfData.FairValuePerShare.pubco && { pubco: { ...JSON.parse(pdfData.FairValuePerShare.pubco) } },
        ...pdfData.FairValuePerShare.mna && { mna: { ...JSON.parse(pdfData.FairValuePerShare.mna) } },
      },
      FinnertyProtectivePut: {
        ...JSON.parse(pdfData.FinnertyProtectivePut.backsolve),
      },
      companyInfo: {
        companyName: userData.metaData.portfolioCompanyName,
        companyDescription: userData.companyData.customDescription,
        valuationDate: userData.transactionData.basicInfo.valuationDate,
        transactionDate: userData.transactionData.basicInfo.transactionDate,
        financialStatementDate: userData.transactionData.basicInfo.financialStatementDate,
        investorFirmName: userData.metaData.investorFirmName,
        headquarters: userData.companyData.headquarters,
        yearFounded: userData.companyData.yearFounded,
        primaryIndustry: userData.companyData.primaryIndustry,
        primarySector: userData.companyData.primarySector,
        website: userData.companyData.website,
      },
      weights: {
        backsolve: userData.calcData.methods.backsolve.weight,
        pubco: userData.calcData.methods.pubCo.weight,
        mna: userData.calcData.methods.backsolve.mna,
      },
      ValueComparison: { ...pdfData.ValueComparison },
      isDraft: generateNewReport.type === 'draft',
      footnoteData: footnoteData ? { ...userData.footnoteData, ...footnoteData } : userData.footnoteData,
      isVenBioUser: getUserEmail().includes('@venbio.com'),
    });
    setParsingPDF(false);
  }

  // only for development in the dev environment
  useEffect(() => {
    // eslint-disable-next-line
    if (reportData && process.env.REACT_APP_ENV_LABEL === 'dev') console.log(reportData, 'reportData');
  }, [reportData]);

  function generateNewPDF() {
    setParsingPDF(true);
    if (generateNewReport.version) {
      s3ListRequest({
        customPrefix: s3Path,
        requestType: 'list',
        onSuccess: (s3Response) => {
          const pdfToFinalize = s3Response.results.filter((s3Report) => s3Report.key.includes('.json'))
            .find((result) => result.key.includes(`-v${generateNewReport.version}.json`));
          s3GetRequest({
            key: pdfToFinalize.key,
            customPrefix: s3Path,
            requestType: 'get',
            onSuccess: (pdfS3Location) => {
              getPdfRequest({
                url: pdfS3Location,
                s3Call: true,
                onSuccess: (pdfData) => { parsePdfData(pdfData); },
              });
            },
          });
        },
      });
    } else {
      generatePdfRequest({
        url: '/reports/asc820/generate-portfolio-company-report',
        method: 'post',
        body: fetchData,
        bodyIds: ['enterpriseCompanyId', 'investorCompanyId', 'portfolioCompanyId', 'transactionId', 'requestUserId'],
        onSuccess: (responseData) => {
          setGenerateNewReport({ ...generateNewReport, version: responseData.split('-v')[1].split('.json')[0] });
          s3GetRequest({
            key: responseData.replace(`s3://${process.env.REACT_APP_CLIENT_FILES_BUCKET}-${process.env.REACT_APP_ENV_LABEL}/820PortfolioReports`, ''),
            customPrefix: '820PortfolioReports',
            requestType: 'get',
            onSuccess: (pdfS3Location) => {
              getPdfRequest({
                url: pdfS3Location,
                s3Call: true,
                onSuccess: (pdfData) => {
                  if (generateNewReport.withFootnotes) {
                    generateFootnotesRequest({
                      url: '/footnotes/dynamic-footnotes',
                      method: 'post',
                      body: fetchData,
                      bodyIds: ['enterpriseCompanyId', 'investorCompanyId', 'portfolioCompanyId', 'transactionId', 'requestUserId'],
                      onSuccess: (footnoteData) => {
                        setUserData({ ...userData, footnoteData: { ...userData.footnoteData, ...footnoteData } });
                        parsePdfData(pdfData, footnoteData);
                        setReportToView((report) => ({
                          ...report,
                          displayName: `${moment().format('MM-DD-YYYY HH-mm-ss').replace(' ', '_')}` +
                            `_${generateNewReport.type === 'final' ? 'Final' : 'Draft'}` +
                            '_820Val' +
                            `_${userData.metaData.investorFirmName.replaceAll(' ', '-')}` +
                            `_${userData.metaData.portfolioCompanyName.replaceAll(' ', '-')}`,
                        }));
                      },
                    });
                  } else {
                    parsePdfData(pdfData);
                  }
                },
              });
            },
          });
        },
      });
    }
  }

  function retrieveS3PDF() {
    setPdfLoading(true);
    s3GetRequest({
      key: reportToView.key,
      customPrefix: s3Path,
      requestType: 'get',
      onSuccess: setPdfUrl,
    });
  }

  function listAllPDFs(reportType) {
    setLoadingPDFs(true);
    s3ListRequest({
      customPrefix: s3Path,
      requestType: 'list',
      onSuccess: (listResults) => {
        let pdfList = listResults.results.filter((s3Report) => s3Report.key.includes('.pdf'));
        let [finalPDFs, draftPDFs] =
          pdfList.reduce(([p, f], e) => (e.key.split('.pdf')[0].endsWith('_final') ? [[...p, e], f] : [p, [...f, e]]), [[], []]);
        finalPDFs = finalPDFs.sort((a, b) => parseFloat(a.key.split('_v')[1].split('.pdf')[0]) - parseFloat(b.key.split('_v')[1].split('.pdf')[0]))
          .map((report, index) => ({
            ...report,
            displayVersion: index + 1,
            dbVersion: parseFloat(report.key.split('_v')[1].split('.pdf')[0]),
            type: 'final',
          })).reverse();
        draftPDFs = draftPDFs.sort((a, b) => parseFloat(a.key.split('_v')[1].split('.pdf')[0]) - parseFloat(b.key.split('_v')[1].split('.pdf')[0]))
          .map((report, index) => ({
            ...report,
            displayVersion: index + 1,
            dbVersion: parseFloat(report.key.split('_v')[1].split('.pdf')[0]),
            type: 'draft',
          })).reverse();
        pdfList = finalPDFs.concat(draftPDFs);
        pdfList = pdfList.map((pdf) => {
          const addTime = pdfList.some((report) => moment(pdf.lastModified).isSame(moment(report), 'day'));
          const addSeconds = addTime ? pdfList.some((report) => moment(pdf.lastModified).isSame(moment(report), 'minute')) : false;
          return {
            ...pdf,
            displayName: `${moment(pdf.lastModified).format('MM-DD-YYYY')}` +
              `${addTime ? `_${moment(pdf.lastModified).format(`HH-mm${addSeconds ? '-ss' : ''}`)}` : ''}` +
              `_${pdf.type === 'final' ? 'Final' : 'Draft'}` +
              '_820Val' +
              `_${userData.metaData.investorFirmName.replaceAll(' ', '-')}` +
              `_${userData.metaData.portfolioCompanyName.replaceAll(' ', '-')}`,
          };
        });
        setReportList(pdfList);
        if (pdfList.length) {
          if (reportType === 'draft') setReportToView({ ...reportToView, ...draftPDFs[0] });
          else setReportToView({ ...reportToView, ...pdfList[0] });
        }
        investorUsersRequest({
          url: '/accounts/get-investor-users/',
          urlIds: ['enterpriseCompanyId', 'investorCompanyId', 'userId'],
          onSuccess: (responseData) => {
            setLoadingPDFs(false);
            if (Object.keys(responseData).length === 0) return;
            const primaryInvestorIndex =
              Object.values(responseData).findIndex((account) => account.accountId === userData.metaData.primaryInvestorId);
            if (primaryInvestorIndex === -1) return;
            setPrimaryInvestorEmail(Object.keys(responseData)[primaryInvestorIndex]);
            setPrimaryInvestorName(Object.values(responseData)[primaryInvestorIndex].firstName);
          },
        });
      },
    });
  }

  function savePDFToS3(newPDF) {
    s3PutRequest({
      requestType: 'put',
      key: `${userData.metaData.portfolioCompanyName}_820Report_v${generateNewReport.version}` +
        `${generateNewReport.type === 'final' ? '_final' : ''}.pdf`,
      file: newPDF,
      customPrefix: s3Path,
      onSuccess: () => { listAllPDFs(generateNewReport.type); },
      onFinally: () => {
        setGenerateNewReport({ type: null });
        setReportData(null);
        setSwitchPosition('view-report');
      },
    });
  }

  useEffect(() => { if (!generateNewReport.type) listAllPDFs(null); }, []);

  useEffect(() => { if (reportToView.key) retrieveS3PDF(); }, [reportToView.key]);

  useEffect(() => { if (generateNewReport.type && !parsingPDF) generateNewPDF(); }, [generateNewReport]);

  if (loadingPDFs) {
    return (
      <div className="data-loading-spinner">
        <span className="dots-circle-spinner" />
      </div>
    );
  }

  if (generateNewReport.type) {
    return (
      <>
        <div className="data-loading-spinner">
          <span className="dots-circle-spinner" />
          <p>Generating new report</p>
        </div>
        {!!generateNewReport.type && !parsingPDF && reportData && (
          <BlobProvider document={<PDFRenderer reportData={reportData} />}>
            {({ blob }) => { if (blob) savePDFToS3(blob); }}
          </BlobProvider>
        )}
      </>
    );
  }

  if (!reportList?.length && !loadingPDFs) {
    return (
      <div className="no-report-to-show">
        <div className="header-info-block">
          <h4>
            <MenuBookIcon />
            ASC 820 report
          </h4>
          <p>
            <span>This 820 report will be available&nbsp;</span>
            and you will be able to run and save as many reports as you wish, choose what report to send to the client, and which to finalize, once:
          </p>
          <ul>
            <li>
              all required information has been entered and/or submitted in all other tabs,
            </li>
            <li>
              you run the calculation engine.and generate a report from the Calculations tab
            </li>
          </ul>
        </div>
        <p className="no-report-text">ASC 820 report(s) will show here.</p>
        {!!generateNewReport.type && !parsingPDF && reportData && (
          <BlobProvider document={<PDFRenderer reportData={reportData} />}>
            {({ blob }) => { if (blob) savePDFToS3(blob); }}
          </BlobProvider>
        )}
      </div>
    );
  }

  if (pdfUrl) {
    return (
      <main className="PDFReport">
        <div className="top-btns">
          <div className="switch">
            <Switch
              checked={switchPosition === 'view-report'}
              onChange={() => setSwitchPosition(switchPosition === 'view-report' ? 'edit-notes' : 'view-report')}
            />
            <div className="labels">
              <span className={switchPosition === 'edit-notes' ? 'selected' : ''}>Edit-notes</span>
              <span className={switchPosition === 'view-report' ? 'selected' : ''}>View-reports</span>
            </div>
          </div>
          <div className="generate-report-btns">
            <Button onClick={() => setGenerateNewReport({ type: 'draft', withFootnotes: true })}>
              {generateNewReport.type === 'draft' ? (
                <>
                  <span className="dots-circle-spinner" />
                  Generating draft report
                </>
              ) : (
                <>
                  <AddIcon />
                  New draft report
                </>
              )}
            </Button>
            <Button onClick={() => setGenerateNewReport({ type: 'final' })}>
              {generateNewReport.type === 'final' ? (
                <>
                  <span className="dots-circle-spinner" />
                  Generating final report
                </>
              ) : !reportList.some((report) => report.type === 'final') ? (
                <>
                  <MenuBookIcon />
                  Generate final report
                </>
              ) : (
                <>
                  <AddIcon />
                  New final report
                </>
              )}
            </Button>
          </div>
        </div>
        {showTooltip && (
          <div className="tooltip-info-block">
            <LightbulbOutlinedIcon />
            <div className="tooltip-info-block-text">
              <p>
                Edit data and you can generate another report. You can run and save as many reports as you wish.
                Simply choose what report to send to the client, and which to finalize.
              </p>
            </div>
            <IconButton
              className="close-icon"
              onClick={() => setShowTooltip(false)}
            >
              <CloseIcon />
            </IconButton>
          </div>
        )}
        {switchPosition === 'edit-notes' ? (
          <EditNotes
            userData={userData}
            setUserData={setUserData}
          />
        ) : (
          <>
            {reportList.length > 1 && (
              <PDFList
                userData={userData}
                reportList={reportList}
                listAllPDFs={() => listAllPDFs()}
                reportToView={reportToView}
                setReportToView={setReportToView}
              />
            )}
            <PDFViewer
              userData={userData}
              pdfUrl={pdfUrl}
              reportToView={reportToView}
              pdfLoading={pdfLoading}
              setPdfLoading={setPdfLoading}
              reportList={reportList}
              setGenerateNewReport={setGenerateNewReport}
              primaryInvestorEmail={primaryInvestorEmail}
              primaryInvestorName={primaryInvestorName}
            />
          </>
        )}
      </main>
    );
  }
}

PDFReport.propTypes = {
  userData: PropTypes.object.isRequired,
  setUserData: PropTypes.func.isRequired,
  generateNewReport: PropTypes.object.isRequired,
  setGenerateNewReport: PropTypes.func.isRequired,
  selectedGridValue: PropTypes.object.isRequired,
};
