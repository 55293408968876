import React, { useContext, useState, useCallback, useRef } from 'react';

import AnimateHeight from 'react-animate-height';

import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';

import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import ExpandCircleDownOutlinedIcon from '@mui/icons-material/ExpandCircleDownOutlined';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';

import AddUserDialog from '../../components/AddUserDialog';

import { ReactComponent as LoadingSpinner } from '../../images/loading-spinner.svg';

import { NavWidthContext } from '../../contexts';

import InformationTile from '../CalcInputs/components/InformationTile';

import useFetch from '../../hooks/useFetch';

import './EnterpriseInfo.scss';

export default function EnterpriseInfo() {
  const [companyInfoDropdownHeight, setCompanyInfoDropdownHeight] = useState('auto');
  const [additionalUsersDropdownHeight, setAdditionalUsersDropdownHeight] = useState('auto');
  const { companyName, dba } = JSON.parse(localStorage.getItem('enterpriseCompanyInfo'));

  const [editingCompanyInfo, setEditingCompanyInfo] = useState(false);
  const [companyInfoIsSaving, setCompanyInfoIsSaving] = useState(false);
  const [companyInfo, setNewCompanyInfo] = useState({
    companyName,
    dba,
  });
  const [addingUser, setAddingUser] = useState(false);
  const [enterpriseUsers, setEnterpriseUsers] = useState([]);

  const { setNavWidth } = useContext(NavWidthContext);

  const appWidth = useRef(null);

  const appWidthRef = useCallback((node) => {
    if (appWidth?.current) window.removeEventListener('resize', () => setNavWidth(appWidth?.current.scrollWidth));
    if (node) {
      appWidth.current = node;
      window.addEventListener('resize', () => setNavWidth(appWidth?.current.scrollWidth));
    }
  }, []);

  useFetch({
    url: '/accounts/get-enterprise-users/',
    urlIds: ['enterpriseCompanyId', 'userId'],
    onSuccess: (responseData) => setEnterpriseUsers(responseData.map((user, index) => ({ ...user, id: index }))),
  });

  return (
    <main className="EnterpriseInfo" ref={appWidthRef}>
      <div className="page-header">
        <h4>{companyName}</h4>
        <Button
          onClick={() => {
            setAddingUser(true);
          }}
        >
          <AddIcon />
          Add user
        </Button>
      </div>
      <div className="profile-settings-container">
        <div className="info-dropdown">
          <div
            role="button"
            className="header-row"
            onClick={() => { setCompanyInfoDropdownHeight(companyInfoDropdownHeight ? 0 : 'auto'); }}
            onKeyDown={(e) => { if (e.key === 'Enter') setCompanyInfoDropdownHeight(companyInfoDropdownHeight ? 0 : 'auto'); }}
            tabIndex={0}
          >
            <IconButton
              className="chevron-btn"
              onClick={() => { setCompanyInfoDropdownHeight(companyInfoDropdownHeight ? 0 : 'auto'); }}
            >
              <ExpandCircleDownOutlinedIcon className={`circle-chevron${companyInfoDropdownHeight ? ' upward' : ' downward'}`} />
            </IconButton>
            <h5>Company Info</h5>
            {!editingCompanyInfo && !companyInfoIsSaving ? (
              <IconButton
                className="edit-btn"
                onClick={(e) => {
                  e.stopPropagation();
                  setEditingCompanyInfo(true);
                }}
              >
                <EditIcon />
              </IconButton>
            ) : (
              <div className="btns-container">
                <Button
                  className="save-btn"
                  onClick={(e) => {
                    e.stopPropagation();
                    setCompanyInfoIsSaving(true);
                  }}
                >
                  {!companyInfoIsSaving ? (
                    <>
                      <SaveOutlinedIcon />
                      Save
                    </>
                  ) : (
                    <>
                      <LoadingSpinner className="loading-spinner" />
                      Saving
                    </>
                  )}
                </Button>
                <Button
                  className="cancel-btn"
                  onClick={(e) => {
                    e.stopPropagation();
                    setEditingCompanyInfo(false);
                  }}
                >
                  <CancelOutlinedIcon />
                  Cancel
                </Button>
              </div>
            )}
          </div>
          <div>
            <AnimateHeight duration={500} height={companyInfoDropdownHeight}>
              <div className="data-tiles-grid">
                <InformationTile
                  label="Company name"
                  dataName="companyName"
                  isEditing={editingCompanyInfo}
                  values={companyInfo}
                  setNewValues={setNewCompanyInfo}
                />
                <InformationTile
                  label="Doing Business As (DBA)"
                  dataName="dba"
                  isEditing={editingCompanyInfo}
                  values={companyInfo}
                  setNewValues={setNewCompanyInfo}
                />
              </div>
            </AnimateHeight>
          </div>
        </div>
        <hr />
        <div className="info-dropdown">
          <div
            role="button"
            className="header-row"
            onClick={() => { setAdditionalUsersDropdownHeight(additionalUsersDropdownHeight ? 0 : 'auto'); }}
            onKeyDown={(e) => { if (e.key === 'Enter') setAdditionalUsersDropdownHeight(additionalUsersDropdownHeight ? 0 : 'auto'); }}
            tabIndex={0}
          >
            <IconButton
              className="chevron-btn"
              onClick={() => { setAdditionalUsersDropdownHeight(additionalUsersDropdownHeight ? 0 : 'auto'); }}
            >
              <ExpandCircleDownOutlinedIcon
                className={`circle-chevron${additionalUsersDropdownHeight ? ' upward' : ' downward'}`}
              />
            </IconButton>
            <h5>Additional users</h5>
          </div>
          <div>
            <AnimateHeight duration={500} height={additionalUsersDropdownHeight}>
              <div className="data-tiles-grid users">
                {enterpriseUsers.length > 0 && (
                  <>
                    {enterpriseUsers.map((user) => (
                      <React.Fragment key={user.id}>
                        <InformationTile
                          label="First name"
                          dataName="firstName"
                          isEditing={editingCompanyInfo}
                          values={user}
                          setNewValues={setNewCompanyInfo}
                        />
                        <InformationTile
                          label="Last name"
                          dataName="lastName"
                          isEditing={editingCompanyInfo}
                          values={user}
                          setNewValues={setNewCompanyInfo}
                        />
                        <InformationTile
                          label="Email"
                          dataName="email"
                          isEditing={editingCompanyInfo}
                          values={user}
                          setNewValues={setNewCompanyInfo}
                        />
                        <InformationTile
                          label="Phone number"
                          dataName="phoneNumber"
                          isEditing={editingCompanyInfo}
                          values={user}
                          setNewValues={setNewCompanyInfo}
                        />
                        <hr className="user-divider" />
                      </React.Fragment>
                    ))}
                  </>
                )}
              </div>
            </AnimateHeight>
          </div>
        </div>
        <hr />
      </div>
      <AddUserDialog addingUser={addingUser} setAddingUser={setAddingUser} setEnterpriseUsers={setEnterpriseUsers} />
    </main>
  );
}
