import React from 'react';
import PropTypes from 'prop-types';
import {
  Page,
  View,
  Text,
} from '@react-pdf/renderer';

import TablePageHeader from '../../components/TablePageHeader';
import BottomPagination from '../../components/BottomPagination';
import DraftOverlay from '../../components/DraftOverlay';

import { commaEvery3rdChar } from '../../../../../../utils';

export default function Breakpoints({
  reportData,
  sortedBreakpoints,
  totalPageCount,
  exhibitNumber,
}) {
  const tableData = reportData.BreakpointAnalyses;

  const table1Headers = [
    'Ownership class',
    'Total shares\noutstanding',
    'Original\nissue price',
  ];

  const table2Headers = [
    'Ownership class',
    'Incremental value per share',
  ];

  const table3Headers = [
    'Ownership class',
    'Total value per share',
  ];

  const formatSeriesName = (seriesName) => seriesName.includes(' @ $') ? `${seriesName.split(' @ $')[0]}` +
    ` @ $${new Intl.NumberFormat('en-US', { minimumFractionDigits: 2 })
      .format((seriesName.split(' @ $')[seriesName.split(' @ $').length - 1]))}` : seriesName;

  function returnBreakpointData(breakpointName, breakpointType, rowIndex) {
    if (['incrementalValuePerShare', 'totalValuePerShare'].includes(breakpointType)) {
      return tableData[breakpointType][Object.keys(tableData[breakpointType]).find((key) => key.includes(breakpointName))][rowIndex] ?
        `$${tableData[breakpointType][Object.keys(tableData[breakpointType])
          .find((key) => key.includes(breakpointName))][rowIndex].toFixed(4)}` : '—';
    }
    return tableData[breakpointType][Object.keys(tableData[breakpointType]).find((key) => key.includes(breakpointName))][rowIndex] ?
      `$${commaEvery3rdChar(tableData[breakpointType][Object.keys(tableData[breakpointType])
        .find((key) => key.includes(breakpointName))][rowIndex].toFixed(0))}` : '—';
  }

  function returnCellData(dataName, rowIndex) {
    if (dataName === 'Ownership class') return formatSeriesName(tableData.breakpoints['Ownership Class'][rowIndex]);
    if (dataName === 'Total shares\noutstanding') return commaEvery3rdChar(tableData.breakpoints['Total Shares Outstanding'][rowIndex]);
    if (dataName === 'Original\nissue price') {
      return tableData.breakpoints['Original Issue Price'][rowIndex] ?
        `$${parseFloat(tableData.breakpoints['Original Issue Price'][rowIndex]).toFixed(4).toLocaleString('en-US', {
          minimumFractionDigits: 4,
          maximumFractionDigits: 4,
        })}` : 'N/A';
    }
    return '';
  }

  const incrementalValuesArr = [...sortedBreakpoints.map((breakpointName) => tableData.breakpoints[breakpointName].reduce((a, b) => a + b, 0))];

  function returnIncrementalValue(columnName, columnIndex, isBreakpoint) {
    if (isBreakpoint) {
      return `$${commaEvery3rdChar(incrementalValuesArr[columnIndex].toFixed(0))}`;
    }
    if (columnName === 'Total shares\noutstanding') {
      return commaEvery3rdChar(tableData.breakpoints['Total Shares Outstanding'].reduce((a, b) => a + b, 0));
    }
    return '';
  }

  function returnCumulativeValue(columnIndex) {
    return `$${commaEvery3rdChar([...incrementalValuesArr.slice(0, columnIndex + 1)].reduce((a, b) => a + b, 0).toFixed(0))}`;
  }

  function columnWidth(index) {
    if (index === 0) return '160px';
    if (index === 1) return '88px';
    return '88px';
  }

  const footnote = reportData.footnoteData.bpAnalysis;

  const rowLength = tableData.breakpoints['Ownership Class'].length + (footnote ? Math.ceil(footnote.length / 230) : 0);

  const reduceRowHeight = () => {
    if (rowLength <= 7) return true;
    return false;
  };

  const reduceRowHeightPage2 = () => {
    if (rowLength > 9) return true;
    return false;
  };

  const splitPages = () => {
    if (rowLength > 7) return 2;
    return false;
  };


  return (
    <>
      <Page
        size="TABLOID"
        orientation="landscape"
        style={{ position: 'relative', backgroundColor: '#EAEAEB' }}
      >
        <TablePageHeader companyName={reportData.companyInfo.companyName} pageName={`Breakpoint analysis   |   Exhibit ${exhibitNumber}`} />
        <View
          style={{
            marginTop: splitPages() ? '86px' : '56px',
            marginLeft: '32px',
            display: 'flex',
            flexDirection: 'column',
            width: '1160px',
            border: '1px',
            borderColor: '#AFB1B3',
          }}
        >
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              height: '24px',
              backgroundColor: '#0C2617',
            }}
          >
            <Text
              style={{
                fontFamily: 'RobotoMed',
                fontSize: '10px',
                color: '#8AB9A4',
                paddingLeft: '16px',
                letterSpacing: '0.08px',
                marginRight: '8px',
                width: '160px',
                paddingTop: '6px',
                borderBottom: '1px',
                borderColor: '#CCCCCC',
                height: '24px',
              }}
            >
              (USD$)
            </Text>
            <Text
              style={{
                fontFamily: 'RobotoMed',
                fontSize: '10px',
                color: '#E1E2EC',
                paddingLeft: '16px',
                marginRight: '8px',
                letterSpacing: '0.08px',
                width: '176px',
                paddingTop: '6px',
                borderBottom: '1px',
                borderColor: '#CCCCCC',
                height: '24px',
              }}
            >
              Capitalization details
            </Text>
            <Text
              style={{
                fontFamily: 'RobotoMed',
                fontSize: '10px',
                color: '#E1E2EC',
                paddingLeft: '16px',
                letterSpacing: '0.08px',
                width: '812px',
                paddingTop: '6px',
                borderBottom: '1px',
                borderColor: '#CCCCCC',
                height: '24px',
              }}
            >
              Breakpoint analysis
            </Text>
          </View>
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              height: '40px',
              backgroundColor: '#307157',
              borderBottom: '1px',
              borderColor: '#CCCCCC',
            }}
          >
            {table1Headers.map((dataName, index) => (
              <Text
                key={dataName}
                style={{
                  fontFamily: 'RobotoMed',
                  fontSize: '10px',
                  color: '#E1E2EC',
                  width: columnWidth(index),
                  marginTop: 'auto',
                  paddingBottom: '4px',
                  paddingLeft: index === 0 ? '8px' : '2px',
                  paddingRight: '2px',
                  letterSpacing: '0.4px',
                  ...index !== 0 && { textAlign: 'right' },
                }}
              >
                {dataName}
              </Text>
            ))}
            {sortedBreakpoints.map((breakpointName) => (
              <Text
                key={breakpointName}
                style={{
                  fontFamily: 'RobotoMed',
                  fontSize: '9px',
                  color: '#E1E2EC',
                  width: `${808 / sortedBreakpoints.length}px`,
                  marginTop: 'auto',
                  paddingBottom: '4px',
                  paddingLeft: '8px',
                  paddingRight: '2px',
                  letterSpacing: '0.4px',
                  textAlign: 'right',
                }}
              >
                {breakpointName.includes('|') ? breakpointName.split(' | ')[1]
                  .replace(' Million', '')
                  .replace('to', sortedBreakpoints.length > 10 ? 'to\n' : 'to') : breakpointName}
                <Text
                  key={breakpointName}
                  style={{ color: '#B2C6BE' }}
                >
                  {breakpointName.includes('|') ? '\n$M' : '\n '}
                </Text>
              </Text>
            ))}
          </View>
          {tableData.breakpoints['Ownership Class'].map((cellData, rowIndex) => (
            <View
              key={cellData}
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                height: reduceRowHeight() ? '24px' : '32px',
                backgroundColor: `${rowIndex % 2 ? '#F0F0F3' : '#FAFAFD'}`,
              }}
            >
              {table1Headers.map((dataName, cellIndex) => (
                <Text
                  key={dataName}
                  style={{
                    fontFamily: 'Roboto',
                    fontSize: '10px',
                    color: '#49454F',
                    width: columnWidth(cellIndex),
                    paddingLeft: cellIndex === 0 ? '8px' : '2px',
                    paddingRight: '2px',
                    letterSpacing: '0.4px',
                    ...cellIndex !== 0 && { textAlign: 'right' },
                  }}
                >
                  {returnCellData(dataName, rowIndex) || 'N/A'}
                </Text>
              ))}
              {sortedBreakpoints.map((breakpointName) => (
                <Text
                  key={breakpointName}
                  style={{
                    fontFamily: 'Roboto',
                    fontSize: '10px',
                    color: '#49454F',
                    width: `${808 / sortedBreakpoints.length}px`,
                    paddingLeft: '8px',
                    paddingRight: '2px',
                    letterSpacing: '0.4px',
                    textAlign: 'right',
                  }}
                >
                  {returnBreakpointData(breakpointName, 'breakpoints', rowIndex)}
                </Text>
              ))}
            </View>
          ))}
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              height: reduceRowHeight() ? '24px' : '32px',
              backgroundColor: '#9ECEBB',
              borderBottom: '1px',
              borderColor: '#EAE9EC',
            }}
          >
            {table1Headers.map((dataName, cellIndex) => (
              <Text
                key={dataName}
                style={{
                  fontFamily: 'RobotoMed',
                  fontSize: '10px',
                  color: '#49454F',
                  width: columnWidth(cellIndex),
                  paddingLeft: '16px',
                  paddingRight: '8px',
                  letterSpacing: '0.4px',
                  textAlign: cellIndex !== 0 && 'right',
                }}
              >
                {cellIndex === 0 ? 'Incremental value' : returnIncrementalValue(dataName)}
              </Text>
            ))}
            {sortedBreakpoints.map((breakpointName, breakpointIndex) => (
              <Text
                key={breakpointName}
                style={{
                  fontFamily: 'Roboto',
                  fontSize: '10px',
                  color: '#49454F',
                  width: `${808 / sortedBreakpoints.length}px`,
                  paddingLeft: '8px',
                  paddingRight: '2px',
                  letterSpacing: '0.4px',
                  textAlign: 'right',
                }}
              >
                {returnIncrementalValue(breakpointName, breakpointIndex, true)}
              </Text>
            ))}
          </View>
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              height: reduceRowHeight() ? '24px' : '32px',
              backgroundColor: '#9ECEBB',
            }}
          >
            {table1Headers.map((dataName, cellIndex) => (
              <Text
                key={dataName}
                style={{
                  fontFamily: 'RobotoMed',
                  fontSize: '10px',
                  color: '#49454F',
                  width: columnWidth(cellIndex),
                  paddingLeft: '16px',
                  paddingRight: '8px',
                  letterSpacing: '0.4px',
                  textAlign: cellIndex !== 0 && 'right',
                }}
              >
                {cellIndex === 0 ? 'Cumulative value' : ''}
              </Text>
            ))}
            {sortedBreakpoints.map((breakpointName, breakpointIndex) => (
              <Text
                key={breakpointName}
                style={{
                  fontFamily: 'Roboto',
                  fontSize: '10px',
                  color: '#49454F',
                  width: `${808 / sortedBreakpoints.length}px`,
                  paddingLeft: '8px',
                  paddingRight: '2px',
                  letterSpacing: '0.4px',
                  textAlign: 'right',
                }}
              >
                {returnCumulativeValue(breakpointIndex)}
              </Text>
            ))}
          </View>
        </View>
        {!splitPages() && (
          <>
            <View
              style={{
                marginTop: '8px',
                marginLeft: '32px',
                display: 'flex',
                flexDirection: 'column',
                width: '1160px',
                border: '1px',
                borderColor: '#AFB1B3',
              }}
            >
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  height: '24px',
                  backgroundColor: '#307157',
                  borderBottom: '1px',
                  borderColor: '#CCCCCC',
                }}
              >
                {table2Headers.map((dataName, index) => (
                  <Text
                    key={dataName}
                    style={{
                      fontFamily: 'RobotoMed',
                      fontSize: '10px',
                      color: '#E1E2EC',
                      width: index === 0 ? '367px' : '200px',
                      marginTop: 'auto',
                      paddingBottom: '4px',
                      paddingLeft: '16px',
                      paddingRight: '8px',
                      letterSpacing: '0.5px',
                    }}
                  >
                    {dataName}
                  </Text>
                ))}
              </View>
              {tableData.breakpoints['Ownership Class'].map((cellData, rowIndex) => (
                <View
                  key={cellData}
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    height: reduceRowHeight() ? '24px' : '32px',
                    backgroundColor: `${rowIndex % 2 ? '#F0F0F3' : '#FAFAFD'}`,
                  }}
                >
                  {table1Headers.map((dataName, cellIndex) => (
                    <Text
                      key={dataName}
                      style={{
                        fontFamily: 'Roboto',
                        fontSize: '10px',
                        color: '#49454F',
                        width: columnWidth(cellIndex),
                        paddingLeft: cellIndex === 0 ? '8px' : '2px',
                        paddingRight: '2px',
                        letterSpacing: '0.4px',
                        ...cellIndex !== 0 && { textAlign: 'right' },
                      }}
                    >
                      {cellIndex === 0 ? returnCellData(dataName, rowIndex) || 'N/A' : ''}
                    </Text>
                  ))}
                  {sortedBreakpoints.map((breakpointName) => (
                    <Text
                      key={breakpointName}
                      style={{
                        fontFamily: 'Roboto',
                        fontSize: '10px',
                        color: '#49454F',
                        width: `${808 / sortedBreakpoints.length}px`,
                        paddingLeft: '8px',
                        paddingRight: '2px',
                        letterSpacing: '0.4px',
                        textAlign: 'right',
                      }}
                    >
                      {returnBreakpointData(breakpointName, 'incrementalValuePerShare', rowIndex)}
                    </Text>
                  ))}
                </View>
              ))}
            </View>
            <View
              style={{
                marginTop: '8px',
                marginLeft: '32px',
                display: 'flex',
                flexDirection: 'column',
                width: '1160px',
                border: '1px',
                borderColor: '#AFB1B3',
              }}
            >
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  height: '24px',
                  backgroundColor: '#307157',
                  borderBottom: '1px',
                  borderColor: '#CCCCCC',
                }}
              >
                {table3Headers.map((dataName, index) => (
                  <Text
                    key={dataName}
                    style={{
                      fontFamily: 'RobotoMed',
                      fontSize: '10px',
                      color: '#E1E2EC',
                      width: index === 0 ? '367px' : '200px',
                      marginTop: 'auto',
                      paddingBottom: '4px',
                      paddingLeft: '16px',
                      paddingRight: '8px',
                      letterSpacing: '0.5px',
                    }}
                  >
                    {dataName}
                  </Text>
                ))}
              </View>
              {tableData.breakpoints['Ownership Class'].map((cellData, rowIndex) => (
                <View
                  key={cellData}
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    height: reduceRowHeight() ? '24px' : '32px',
                    backgroundColor: `${rowIndex % 2 ? '#F0F0F3' : '#FAFAFD'}`,
                  }}
                >
                  {table1Headers.map((dataName, cellIndex) => (
                    <Text
                      key={dataName}
                      style={{
                        fontFamily: 'Roboto',
                        fontSize: '10px',
                        color: '#49454F',
                        width: columnWidth(cellIndex),
                        paddingLeft: cellIndex === 0 ? '8px' : '2px',
                        paddingRight: '2px',
                        letterSpacing: '0.4px',
                        ...cellIndex !== 0 && { textAlign: 'right' },
                      }}
                    >
                      {cellIndex === 0 ? returnCellData(dataName, rowIndex) || 'N/A' : ''}
                    </Text>
                  ))}
                  {sortedBreakpoints.map((breakpointName) => (
                    <Text
                      key={breakpointName}
                      style={{
                        fontFamily: 'Roboto',
                        fontSize: '10px',
                        color: '#49454F',
                        width: `${808 / sortedBreakpoints.length}px`,
                        paddingLeft: '8px',
                        paddingRight: '2px',
                        letterSpacing: '0.4px',
                        textAlign: 'right',
                      }}
                    >
                      {returnBreakpointData(breakpointName, 'totalValuePerShare', rowIndex)}
                    </Text>
                  ))}
                </View>
              ))}
            </View>
          </>
        )}
        {!splitPages() && !!footnote && (
          <Text
            style={{
              fontFamily: 'Roboto',
              fontSize: '8px',
              color: '#49454F',
              letterSpacing: '0.4px',
              marginTop: '16px',
              marginLeft: '90px',
              width: '450px',
              lineHeight: '0.55mm',
            }}
          >
            Footnote:&nbsp;
            {footnote}
          </Text>
        )}
        <BottomPagination
          pageNumber={totalPageCount}
          reportData={reportData}
        />
        {reportData.isDraft && <DraftOverlay />}
      </Page>
      {splitPages() === 2 && (
        <Page
          size="TABLOID"
          orientation="landscape"
          style={{ position: 'relative', backgroundColor: '#EAEAEB' }}
        >
          <TablePageHeader companyName={reportData.companyInfo.companyName} pageName="Breakpoint analysis   |   Exhibit 4" />
          <View
            style={{
              marginTop: reduceRowHeightPage2() ? '56px' : '86px',
              marginLeft: '32px',
              display: 'flex',
              flexDirection: 'column',
              width: '1160px',
              border: '1px',
              borderColor: '#AFB1B3',
            }}
          >
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                height: '24px',
                backgroundColor: '#307157',
                borderBottom: '1px',
                borderColor: '#CCCCCC',
              }}
            >
              {table2Headers.map((dataName, index) => (
                <Text
                  key={dataName}
                  style={{
                    fontFamily: 'RobotoMed',
                    fontSize: '10px',
                    color: '#E1E2EC',
                    width: index === 0 ? '367px' : '200px',
                    marginTop: 'auto',
                    paddingBottom: '4px',
                    paddingLeft: '16px',
                    paddingRight: '8px',
                    letterSpacing: '0.5px',
                  }}
                >
                  {dataName}
                </Text>
              ))}
            </View>
            {tableData.breakpoints['Ownership Class'].map((cellData, rowIndex) => (
              <View
                key={cellData}
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  height: reduceRowHeightPage2() ? '24px' : '32px',
                  backgroundColor: `${rowIndex % 2 ? '#F0F0F3' : '#FAFAFD'}`,
                }}
              >
                {table1Headers.map((dataName, cellIndex) => (
                  <Text
                    key={dataName}
                    style={{
                      fontFamily: 'Roboto',
                      fontSize: '10px',
                      color: '#49454F',
                      width: columnWidth(cellIndex),
                      paddingLeft: cellIndex === 0 ? '8px' : '2px',
                      paddingRight: '2px',
                      letterSpacing: '0.4px',
                      ...cellIndex !== 0 && { textAlign: 'right' },
                    }}
                  >
                    {cellIndex === 0 ? returnCellData(dataName, rowIndex) || 'N/A' : ''}
                  </Text>
                ))}
                {sortedBreakpoints.map((breakpointName) => (
                  <Text
                    key={breakpointName}
                    style={{
                      fontFamily: 'Roboto',
                      fontSize: '10px',
                      color: '#49454F',
                      width: `${808 / sortedBreakpoints.length}px`,
                      paddingLeft: '8px',
                      paddingRight: '2px',
                      letterSpacing: '0.4px',
                      textAlign: 'right',
                    }}
                  >
                    {returnBreakpointData(breakpointName, 'incrementalValuePerShare', rowIndex)}
                  </Text>
                ))}
              </View>
            ))}
          </View>
          <View
            style={{
              marginTop: '24px',
              marginLeft: '32px',
              display: 'flex',
              flexDirection: 'column',
              width: '1160px',
              border: '1px',
              borderColor: '#AFB1B3',
            }}
          >
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                height: '24px',
                backgroundColor: '#307157',
                borderBottom: '1px',
                borderColor: '#CCCCCC',
              }}
            >
              {table3Headers.map((dataName, index) => (
                <Text
                  key={dataName}
                  style={{
                    fontFamily: 'RobotoMed',
                    fontSize: '10px',
                    color: '#E1E2EC',
                    width: index === 0 ? '367px' : '200px',
                    marginTop: 'auto',
                    paddingBottom: '4px',
                    paddingLeft: '16px',
                    paddingRight: '8px',
                    letterSpacing: '0.5px',
                  }}
                >
                  {dataName}
                </Text>
              ))}
            </View>
            {tableData.breakpoints['Ownership Class'].map((cellData, rowIndex) => (
              <View
                key={cellData}
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  height: reduceRowHeightPage2() ? '24px' : '32px',
                  backgroundColor: `${rowIndex % 2 ? '#F0F0F3' : '#FAFAFD'}`,
                }}
              >
                {table1Headers.map((dataName, cellIndex) => (
                  <Text
                    key={dataName}
                    style={{
                      fontFamily: 'Roboto',
                      fontSize: '10px',
                      color: '#49454F',
                      width: columnWidth(cellIndex),
                      paddingLeft: cellIndex === 0 ? '8px' : '2px',
                      paddingRight: '2px',
                      letterSpacing: '0.4px',
                      ...cellIndex !== 0 && { textAlign: 'right' },
                    }}
                  >
                    {cellIndex === 0 ? returnCellData(dataName, rowIndex) || 'N/A' : ''}
                  </Text>
                ))}
                {sortedBreakpoints.map((breakpointName) => (
                  <Text
                    key={breakpointName}
                    style={{
                      fontFamily: 'Roboto',
                      fontSize: '10px',
                      color: '#49454F',
                      width: `${808 / sortedBreakpoints.length}px`,
                      paddingLeft: '8px',
                      paddingRight: '2px',
                      letterSpacing: '0.4px',
                      textAlign: 'right',
                    }}
                  >
                    {returnBreakpointData(breakpointName, 'totalValuePerShare', rowIndex)}
                  </Text>
                ))}
              </View>
            ))}
          </View>
          {!!footnote && (
            <Text
              style={{
                fontFamily: 'Roboto',
                fontSize: '8px',
                color: '#49454F',
                letterSpacing: '0.4px',
                marginTop: '16px',
                marginLeft: '90px',
                width: '450px',
                lineHeight: '0.55mm',
              }}
            >
              Footnote:&nbsp;
              {footnote}
            </Text>
          )}
          <BottomPagination
            pageNumber={totalPageCount + 1}
            reportData={reportData}
          />
          {reportData.isDraft && <DraftOverlay />}
        </Page>
      )}
    </>
  );
}

Breakpoints.propTypes = {
  reportData: PropTypes.object.isRequired,
  sortedBreakpoints: PropTypes.array.isRequired,
  totalPageCount: PropTypes.number.isRequired,
  exhibitNumber: PropTypes.number.isRequired,
};
