import React from 'react';
import PropTypes from 'prop-types';
import {
  Page,
  View,
  Text,
} from '@react-pdf/renderer';

import TablePageHeader from '../../components/TablePageHeader';
import BottomPagination from '../../components/BottomPagination';
import DraftOverlay from '../../components/DraftOverlay';

export default function AllocationMethodSummary({ reportData, totalPageCount, exhibitNumber }) {
  const commentaryData = reportData.footnoteData.allocationMethodologySummary.opmDetails;
  return (
    <Page
      size="TABLOID"
      orientation="landscape"
      style={{ position: 'relative', backgroundColor: '#EAEAEB' }}
    >
      <TablePageHeader
        companyName={reportData.companyInfo.companyName}
        pageName={`Allocation methodology summary - OPM   |   Exhibit ${exhibitNumber}`}
      />
      <View
        style={{
          display: 'flex',
          flexDirection: 'row',
        }}
      >
        <View>
          <View
            style={{
              width: '548px',
              height: '152px',
              marginTop: '84px',
              marginLeft: '32px',
              padding: '24px',
              backgroundColor: '#FAFAFD',
            }}
          >
            <Text
              style={{
                fontFamily: 'ManropeSemi',
                fontSize: '16px',
                color: '#49454F',
                letterSpacing: '0.08px',
              }}
            >
              Selected allocation methodology - Option pricing model (OPM)
            </Text>
            <View
              style={{
                marginTop: '16px',
                width: '500px',
                borderBottom: '1px',
                borderColor: '#CCCCCC',
              }}
            />
            <Text
              style={{
                fontFamily: 'RobotoMed',
                fontSize: '14px',
                color: '#49454F',
                marginTop: '16px',
                marginBottom: '16px',
              }}
            >
              Appraisers commentary
            </Text>
            <Text
              style={{
                fontFamily: 'Roboto',
                color: '#49454F',
                fontSize: '12px',
                lineHeight: '0.55mm',
                letterSpacing: '0.25px',
                marginTop: '4px',
              }}
            >
              Given that the Company is unlikely to experience a near term exit, the Option pricing model was utilized.
            </Text>
          </View>
          <View
            style={{
              width: '548px',
              height: '482px',
              marginTop: '24px',
              marginLeft: '32px',
              padding: '24px',
              backgroundColor: '#FAFAFD',
            }}
          >
            <Text
              style={{
                fontFamily: 'RobotoMed',
                fontSize: '14px',
                color: '#49454F',
                marginTop: '16px',
                marginBottom: '16px',
              }}
            >
              Option pricing model (OPM) details
            </Text>
            <View
              style={{
                marginTop: '16px',
                marginBottom: '16px',
                width: '500px',
                borderBottom: '1px',
                borderColor: '#CCCCCC',
              }}
            />
            {commentaryData.sTotalValue && (
              <View style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'flex-start',
                marginBottom: '8px',
              }}
              >
                <Text style={{
                  width: '12px',
                  fontSize: '12px',
                  color: '#49454F',
                }}
                >
                  {'\u2022 '}
                </Text>
                <View>
                  <Text
                    style={{
                      fontFamily: 'RobotoMed',
                      color: '#49454F',
                      fontSize: '12px',
                      lineHeight: '0.55mm',
                      letterSpacing: '0.25px',
                    }}
                  >
                    S = Total value:&nbsp;
                    {commentaryData.sTotalValue.value.map((val) => (
                      <Text
                        key={val}
                        style={{
                          fontFamily: 'RobotoMed',
                          color: '#49454F',
                          fontSize: '12px',
                          lineHeight: '0.55mm',
                          letterSpacing: '0.25px',
                        }}
                      >
                        {val}
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      </Text>
                    ))}
                    <Text
                      style={{
                        fontFamily: 'Roboto',
                        color: '#49454F',
                        fontSize: '12px',
                        lineHeight: '0.55mm',
                        letterSpacing: '0.25px',
                        marginTop: '4px',
                      }}
                    >
                      {commentaryData.sTotalValue.ac}
                    </Text>
                  </Text>
                </View>
              </View>
            )}
            {commentaryData.tTimeExpiration && (
              <View style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'flex-start',
                marginBottom: '8px',
              }}
              >
                <Text style={{
                  width: '12px',
                  fontSize: '12px',
                  color: '#49454F',
                }}
                >
                  {'\u2022 '}
                </Text>
                <View>
                  <Text
                    style={{
                      fontFamily: 'RobotoMed',
                      color: '#49454F',
                      fontSize: '12px',
                      lineHeight: '0.55mm',
                      letterSpacing: '0.25px',
                    }}
                  >
                    {`t = Time to expiration ${commentaryData.tTimeExpiration.value}` +
                      ` ${parseFloat(commentaryData.tTimeExpiration.value) > 1 ? ' years' : ' year'}`}
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <Text style={{ fontFamily: 'Roboto' }}>
                      {commentaryData.tTimeExpiration.ac}
                    </Text>
                  </Text>
                </View>
              </View>
            )}
            <View style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'flex-start',
              marginBottom: '8px',
            }}
            >
              <Text style={{
                width: '12px',
                fontSize: '12px',
                color: '#49454F',
              }}
              >
                {'\u2022 '}
              </Text>
              <View>
                <Text
                  style={{
                    fontFamily: 'RobotoMed',
                    color: '#49454F',
                    fontSize: '12px',
                    lineHeight: '0.55mm',
                    letterSpacing: '0.25px',
                  }}
                >
                  rf = Risk-free rate:&nbsp;
                  {commentaryData.rfRiskFreeRate.value}
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <Text style={{ fontFamily: 'Roboto' }}>
                    {commentaryData.rfRiskFreeRate.ac}
                  </Text>
                </Text>
              </View>
            </View>
            <View style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'flex-start',
              marginBottom: '8px',
            }}
            >
              <Text style={{
                width: '12px',
                fontSize: '12px',
                color: '#49454F',
              }}
              >
                {'\u2022 '}
              </Text>
              <View>
                <Text
                  style={{
                    fontFamily: 'RobotoMed',
                    color: '#49454F',
                    fontSize: '12px',
                    lineHeight: '0.55mm',
                    letterSpacing: '0.25px',
                  }}
                >
                  sd = Volatility:&nbsp;
                  {commentaryData.sdVolatility.value}
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <Text style={{ fontFamily: 'Roboto' }}>
                    {commentaryData.sdVolatility.ac}
                  </Text>
                </Text>
              </View>
            </View>
            {commentaryData.basedOnIndustry && (
              <View style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'flex-start',
                marginBottom: '8px',
              }}
              >
                <Text style={{
                  width: '12px',
                  fontSize: '12px',
                  color: '#49454F',
                }}
                >
                  {'\u2022 '}
                </Text>
                <View>
                  <Text
                    style={{
                      fontFamily: 'RobotoMed',
                      color: '#49454F',
                      fontSize: '12px',
                      lineHeight: '0.55mm',
                      letterSpacing: '0.25px',
                    }}
                  >
                    Based on&nbsp;
                    {commentaryData.basedOnIndustry.value.toLowerCase()}
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <Text style={{ fontFamily: 'Roboto' }}>
                      {commentaryData.basedOnIndustry.ac}
                    </Text>
                  </Text>
                </View>
              </View>
            )}
            <View style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'flex-start',
              marginBottom: '8px',
            }}
            >
              <Text style={{
                width: '12px',
                fontSize: '12px',
                color: '#49454F',
              }}
              >
                {'\u2022 '}
              </Text>
              <View>
                <Text
                  style={{
                    fontFamily: 'RobotoMed',
                    color: '#49454F',
                    fontSize: '12px',
                    lineHeight: '0.55mm',
                    letterSpacing: '0.25px',
                  }}
                >
                  Number of companies:&nbsp;
                  {commentaryData.numOfCompanies.value}
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <Text style={{ fontFamily: 'Roboto' }}>
                    {commentaryData.numOfCompanies.ac}
                  </Text>
                </Text>
              </View>
            </View>
          </View>
        </View>
        <View
          style={{
            width: '548px',
            height: '580px',
            marginTop: '84px',
            marginLeft: '64px',
            padding: '24px',
            backgroundColor: '#FAFAFD',
          }}
        >
          <Text
            style={{
              fontFamily: 'RobotoMed',
              fontSize: '14px',
              color: '#49454F',
              marginBottom: '16px',
            }}
          >
            Option pricing model (OPM) details continued
          </Text>
          {commentaryData.primaryIndustry?.value && (
            <View style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'flex-start',
              marginBottom: '8px',
            }}
            >
              <Text style={{
                width: '12px',
                fontSize: '12px',
                color: '#49454F',
              }}
              >
                {'\u2022 '}
              </Text>
              <View>
                <Text
                  style={{
                    fontFamily: 'RobotoMed',
                    color: '#49454F',
                    fontSize: '12px',
                    lineHeight: '0.55mm',
                    letterSpacing: '0.25px',
                  }}
                >
                  Primary industry:&nbsp;
                  {commentaryData.primaryIndustry.value}
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <Text style={{ fontFamily: 'Roboto' }}>
                    {commentaryData.primaryIndustry.ac}
                  </Text>
                </Text>
              </View>
            </View>
          )}
          {commentaryData.volatilitySelectionMethodology && (
            <View style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'flex-start',
              marginBottom: '8px',
            }}
            >
              <Text style={{
                width: '12px',
                fontSize: '12px',
                color: '#49454F',
              }}
              >
                {'\u2022 '}
              </Text>
              <View>
                <Text
                  style={{
                    fontFamily: 'RobotoMed',
                    color: '#49454F',
                    fontSize: '12px',
                    lineHeight: '0.55mm',
                    letterSpacing: '0.25px',
                  }}
                >
                  {commentaryData.volatilitySelectionMethodology.value}
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <Text style={{ fontFamily: 'Roboto' }}>
                    {commentaryData.volatilitySelectionMethodology.ac}
                  </Text>
                </Text>
              </View>
            </View>
          )}
          {commentaryData.xExercisePrice && (
            <View style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'flex-start',
              marginBottom: '8px',
            }}
            >
              <Text style={{
                width: '12px',
                fontSize: '12px',
                color: '#49454F',
              }}
              >
                {'\u2022 '}
              </Text>
              <View>
                <Text
                  style={{
                    fontFamily: 'RobotoMed',
                    color: '#49454F',
                    fontSize: '12px',
                    lineHeight: '0.55mm',
                    letterSpacing: '0.25px',
                  }}
                >
                  X = Exercise price
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <Text style={{ fontFamily: 'Roboto' }}>
                    {commentaryData.xExercisePrice.ac}
                  </Text>
                </Text>
              </View>
            </View>
          )}
        </View>
      </View>
      <BottomPagination
        pageNumber={totalPageCount}
        reportData={reportData}
      />
      {reportData.isDraft && <DraftOverlay />}
    </Page>
  );
}

AllocationMethodSummary.propTypes = {
  reportData: PropTypes.object.isRequired,
  totalPageCount: PropTypes.number.isRequired,
  exhibitNumber: PropTypes.number.isRequired,
};
