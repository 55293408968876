import React from 'react';
import PropTypes from 'prop-types';
import { Page, View, Text } from '@react-pdf/renderer';

import TablePageHeader from '../../components/TablePageHeader';
import BottomPagination from '../../components/BottomPagination';
import DraftOverlay from '../../components/DraftOverlay';

export default function PubCoDescriptions({
  reportData,
  sortedPubCoDescriptions,
  totalPageCount,
  exhibitNumber,
}) {
  const tableData = reportData.PublicCompanyDescriptions;

  const tableHeaders = [
    'Company name',
    'Ticker',
    'Company description',
  ];

  function returnCellData(dataName, rowIndex, pageIndex) {
    if (dataName === 'Company name') {
      return tableData.Name[rowIndex + sortedPubCoDescriptions[pageIndex]];
    }
    if (dataName === 'Company description') {
      return tableData['Company Description'][rowIndex + sortedPubCoDescriptions[pageIndex]];
    }
    return tableData[dataName][rowIndex + sortedPubCoDescriptions[pageIndex]];
  }

  const footnote = reportData.footnoteData.gpcDescription;


  return [...Array(sortedPubCoDescriptions.length - 1)].map((_, i) => i).map((pageNumber, pageIndex) => (
    <Page
      key={pageNumber}
      size="TABLOID"
      orientation="landscape"
      style={{ position: 'relative', backgroundColor: '#EAEAEB' }}
    >
      <TablePageHeader
        companyName={reportData.companyInfo.companyName}
        pageName={`Public company descriptions   |   Exhibit ${exhibitNumber}`}
      />
      <View
        style={{
          marginTop: '84px',
          marginLeft: '80px',
          display: 'flex',
          flexDirection: 'column',
          width: '1064px',
          border: '1px',
          borderColor: '#AFB1B3',
        }}
      >
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            height: '56px',
            backgroundColor: '#307157',
            borderBottom: '1px',
            borderColor: '#CCCCCC',
          }}
        >
          {tableHeaders.map((dataName, index) => (
            <Text
              key={dataName}
              style={{
                fontFamily: 'RobotoMed',
                fontSize: '12px',
                color: '#E1E2EC',
                width: index === 0 ? '200px' : index === 1 ? '114px' : '862px',
                marginTop: 'auto',
                ...index === 0 && { paddingLeft: '16px' },
                paddingRight: '16px',
                letterSpacing: '0.4px',
                lineHeight: '0.55mm',
              }}
            >
              {dataName}
            </Text>
          ))}
        </View>
        {tableData.Name.slice(sortedPubCoDescriptions[pageIndex], sortedPubCoDescriptions[pageIndex + 1]).map((cellData, rowIndex) => (
          <View
            key={cellData}
            style={{
              display: 'flex',
              flexDirection: 'row',
              minHeight: '32px',
              backgroundColor: `${rowIndex % 2 ? '#F0F0F3' : '#FAFAFD'}`,
            }}
          >
            {tableHeaders.map((dataName, cellIndex) => (
              <Text
                key={dataName}
                style={{
                  fontFamily: 'Roboto',
                  fontSize: '10px',
                  color: '#49454F',
                  width: cellIndex === 0 ? '200px' : cellIndex === 1 ? '114px' : '862px',
                  paddingLeft: '16px',
                  paddingRight: '16px',
                  paddingTop: '8px',
                  paddingBottom: '8px',
                  letterSpacing: '0.4px',
                }}
              >
                {returnCellData(dataName, rowIndex, pageIndex)}
              </Text>
            ))}
          </View>
        ))}
      </View>
      {!!footnote && pageIndex === sortedPubCoDescriptions.length - 2 && (
        <Text
          style={{
            fontFamily: 'Roboto',
            fontSize: '8px',
            color: '#49454F',
            letterSpacing: '0.4px',
            marginTop: '8px',
            marginLeft: '90px',
            width: '450px',
            lineHeight: '0.55mm',
          }}
        >
          Footnote:&nbsp;
          {footnote}
        </Text>
      )}
      <BottomPagination
        pageNumber={totalPageCount + pageIndex}
        reportData={reportData}
      />
      {reportData.isDraft && <DraftOverlay />}
    </Page>
  ));
}

PubCoDescriptions.propTypes = {
  reportData: PropTypes.object.isRequired,
  sortedPubCoDescriptions: PropTypes.array.isRequired,
  totalPageCount: PropTypes.number.isRequired,
  exhibitNumber: PropTypes.number.isRequired,
};
