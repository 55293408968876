import React, { useState, useEffect, useContext } from 'react';
import { Auth } from 'aws-amplify';

import AnimateHeight from 'react-animate-height';

import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';

import EditIcon from '@mui/icons-material/Edit';
import ExpandCircleDownOutlinedIcon from '@mui/icons-material/ExpandCircleDownOutlined';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';

import { ReactComponent as LoadingSpinner } from '../../images/loading-spinner.svg';

import { ErrorMessageContext } from '../../contexts';

import { getUserEmail, getUserId, getUserName, getUserPhoneNumber } from '../../utils/auth';

import useFetch from '../../hooks/useFetch';

import './BasicInfo.scss';

export default function BasicInfo() {
  const [height, setHeight] = useState('auto');

  const [editingAccountInfo, setEditingAccountInfo] = useState(false);
  const [userInfo, setUserInfo] = useState({});
  const [newUserInfo, setNewUserInfo] = useState({});

  const { setShowErrorMessage } = useContext(ErrorMessageContext);

  async function getUserData() {
    try {
      const accountData = {
        firstName: getUserName('firstName'),
        lastName: getUserName('lastName'),
        companyName: localStorage.getItem('enterpriseCompanyInfo').companyName,
        phoneNumber: getUserPhoneNumber(),
        userId: getUserId(),
        email: getUserEmail(),
      };
      setUserInfo(accountData);
      setNewUserInfo(accountData);
    } catch (e) {
      setShowErrorMessage(e.toString());
    }
    return null;
  }

  useEffect(() => { getUserData(); }, []);

  const [{ loading: accountInfoIsSaving }, updateEnterpriseUserRequest] = useFetch();

  async function updateData() {
    if (newUserInfo.firstName === '' || newUserInfo.lastName === '' || newUserInfo.phoneNumber === '') {
      // if (newUserInfo.firstName === '') setFirstNameInputHasError(true);
      // if (newUserInfo.lastName === '') setLastNameInputHasError(true);
      // if (newUserInfo.phoneNumber === '') setPhoneNumberInputHasError(true);
      return;
    }

    if (JSON.stringify(userInfo) !== JSON.stringify(newUserInfo)) {
      const userData = {
        firstName: newUserInfo.firstName,
        lastName: newUserInfo.lastName,
        email: newUserInfo.email,
        phoneNumber: newUserInfo.phoneNumber,
      };
      updateEnterpriseUserRequest({
        url: '/accounts/update-enterprise-user',
        method: 'post',
        body: userData,
        bodyIds: ['enterpriseCompanyId', 'requestUserId'],
        onSuccess: async () => {
          const user = await Auth.currentAuthenticatedUser();
          await Auth.updateUserAttributes(user, {
            'custom:firstName': newUserInfo.firstName,
            'custom:lastName': newUserInfo.lastName,
            'custom:phoneNumber': newUserInfo.phoneNumber,
          });
        },
      });
    }
  }

  return (
    <main className="BasicInfo">
      <div className="info-dropdown">
        <div
          role="button"
          className="header-row"
          onClick={() => { setHeight(height ? 0 : 'auto'); }}
          onKeyDown={(e) => { if (e.key === 'Enter') setHeight(height ? 0 : 'auto'); }}
          tabIndex={0}
        >
          <IconButton
            className="chevron-btn"
            onClick={() => { setHeight(height ? 0 : 'auto'); }}
          >
            <ExpandCircleDownOutlinedIcon
              className={`circle-chevron${height ? ' upward' : ' downward'}`}
            />
          </IconButton>
          <h5>Your information</h5>
          {!editingAccountInfo && !accountInfoIsSaving ? (
            <IconButton
              className="edit-btn"
              onClick={(e) => {
                e.stopPropagation();
                setEditingAccountInfo(true);
              }}
            >
              <EditIcon />
            </IconButton>
          ) : (
            <div className="btns-container">
              <Button
                className="save-btn"
                onClick={(e) => {
                  e.stopPropagation();
                  updateData();
                }}
              >
                {!accountInfoIsSaving ? (
                  <>
                    <SaveOutlinedIcon />
                    Save
                  </>
                ) : (
                  <>
                    <LoadingSpinner className="loading-spinner" />
                    Saving
                  </>
                )}
              </Button>
              <Button
                className="cancel-btn"
                onClick={(e) => {
                  e.stopPropagation();
                  setEditingAccountInfo(false);
                }}
              >
                <CancelOutlinedIcon />
                Cancel
              </Button>
            </div>
          )}
        </div>
        <div>
          <AnimateHeight duration={500} height={height}>
            <div className="data-tiles-grid">
              {!editingAccountInfo ? (
                <div className="data-tile">
                  <span className="data-name">First name</span>
                  <span className="data">{newUserInfo.firstName}</span>
                </div>
              ) : (
                <TextField
                  label="First name"
                  value={newUserInfo.firstName}
                  onChange={(e) => setNewUserInfo({ ...newUserInfo, firstName: e.target.value })}
                />
              )}
              {!editingAccountInfo ? (
                <div className="data-tile">
                  <span className="data-name">Last name</span>
                  <span className="data">{newUserInfo.lastName}</span>
                </div>
              ) : (
                <TextField
                  label="Last name"
                  value={newUserInfo.lastName}
                  onChange={(e) => setNewUserInfo({ ...newUserInfo, lastName: e.target.value })}
                />
              )}
              <div className="data-tile">
                <span className="data-name">Email</span>
                <span className="data">{newUserInfo.email}</span>
              </div>
              {!editingAccountInfo ? (
                <div className="data-tile">
                  <span className="data-name">Phone number</span>
                  <span className="data">{newUserInfo.phoneNumber}</span>
                </div>
              ) : (
                <TextField
                  label="Phone number"
                  value={newUserInfo.phoneNumber}
                  onChange={(e) => setNewUserInfo({ ...newUserInfo, phoneNumber: e.target.value })}
                />
              )}
            </div>
          </AnimateHeight>
        </div>
      </div>
    </main>
  );
}
