import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  MenuItem,
  Menu,
  IconButton,
} from '@mui/material';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';
import StorefrontIcon from '@mui/icons-material/Storefront';
import DataThresholdingOutlinedIcon from '@mui/icons-material/DataThresholdingOutlined';
import NotificationImportantOutlinedIcon from '@mui/icons-material/NotificationImportantOutlined';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';

import { SubNavStateContext, NavWidthContext } from '../../contexts';

import StatusBadgeDropdown from '../../pages/EnterpriseProjects/StatusBadgeDropdown';

import useFetch from '../../hooks/useFetch';

import './SubNav.scss';

export default function SubNav() {
  const { subNavState, setSubNavState } = useContext(SubNavStateContext);
  const { navWidth } = useContext(NavWidthContext);
  const [currentStatus, setCurrentStatus] = useState(null);
  const [blockerStatus, setBlockerStatus] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const [menuOpen, setMenuOpen] = useState(false);
  const [projectStatus, setProjectStatus] = useState(null);
  const [priority, setPriority] = useState(subNavState?.projectToView?.priority || 2);

  useEffect(() => {
    if (subNavState?.transactionToView) {
      setCurrentStatus(subNavState.transactionToView.status);
      setBlockerStatus(subNavState.transactionToView.blockerStatus);
    }
    if (subNavState?.projectToView?.priority) {
      setPriority(parseInt(subNavState.projectToView.priority, 10) || 2);
    }
  }, [subNavState.projectToView, subNavState.transactionToView]);

  useEffect(() => {
    if (subNavState?.status?.updateStatus) {
      setCurrentStatus(subNavState?.status?.updateStatus);
    }
    if (subNavState?.status?.blockerStatus) {
      setBlockerStatus(subNavState?.status?.blockerStatus);
    }
  }, [subNavState]);

  const nav = useNavigate();

  const [, updatePriorityRequest] = useFetch();

  function updatePriority(value) {
    const priorityData = {
      priority: value,
      projectId: subNavState.projectToView.projectId,
    };
    updatePriorityRequest({
      url: '/homepages/enterprise/update-priority',
      method: 'post',
      body: priorityData,
      bodyIds: ['enterpriseCompanyId', 'requestUserId'],
    });
  }

  if (subNavState.projectToView) {
    return (
      <div className="SubNav" style={{ minWidth: navWidth }}>
        <Button
          onClick={() => {
            nav(-1);
            setSubNavState({ ...subNavState, transactionToView: null, currentPage: 'company-list' });
          }}
        >
          <ArrowBackIcon />
        </Button>
        <Button
          onClick={() => {
            if (projectStatus) setSubNavState({ ...subNavState, projectStatus });
            nav('/');
          }}
        >
          <HomeOutlinedIcon />
          Home
        </Button>
        <Button className="no-pointer-events">
          <AccountBalanceOutlinedIcon />
          {subNavState.projectToView.investorFirmName}
        </Button>
        <Button
          className={`${subNavState.currentPage === 'company-list' ? 'active' : ''}`}
          onClick={() => {
            nav(`/?pId=${subNavState.projectToView.projectId}`);
            setSubNavState({
              ...subNavState,
              transactionToView: null,
              currentPage: 'company-list',
              status: {
                companyId: subNavState.transactionToView.transactionId,
                updateStatus: currentStatus,
                blockerStatus,
              },
            });
          }}
        >
          <DataThresholdingOutlinedIcon />
          {`ASC 820 | ${subNavState.projectToView.createdDate}`}
        </Button>
        {subNavState.currentPage === 'calc-inputs' && (
          <Button className="active">
            <StorefrontIcon />
            {subNavState.companyName}
          </Button>
        )}
        <div className="top-nav-right">
          {subNavState.transactionToView && (
            <StatusBadgeDropdown
              setInsideElementHover={() => { }}
              company={subNavState?.transactionToView}
              setProjectCompanies={() => { }}
              projectCompanies={[]}
              currentStatus={currentStatus}
              setCurrentStatus={setCurrentStatus}
              blockerStatus={blockerStatus}
              setBlockerStatus={setBlockerStatus}
              setProjectStatus={setProjectStatus}
              subNav
            />
          )}
          <div className="priority-wrapper">
            <IconButton
              className="priority-button"
              onClick={(e) => {
                setMenuOpen(!menuOpen);
                setAnchorEl(e.currentTarget);
              }}
            >
              {priority === 1 ?
                <NotificationImportantOutlinedIcon className="high-icon" /> :
                priority === 2 ?
                  'Priority' :
                  <ArrowDownwardIcon className="low-icon" />}
              <KeyboardArrowDownOutlinedIcon sx={{ marginRight: '2px' }} />
            </IconButton>
            <Menu
              id="priority-menu"
              anchorEl={anchorEl}
              value={priority || ''}
              open={menuOpen}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
              transformOrigin={{ vertical: 'top', horizontal: 'center' }}
              onClose={() => setMenuOpen(!menuOpen)}
              onChange={(e) => { updatePriority(e.target.value); }}
            >
              <MenuItem
                className={priority === 1 ? 'active' : ''}
                value={1}
                onClick={(e) => {
                  setPriority(e.target.value);
                  setMenuOpen(!menuOpen);
                  updatePriority(e.target.value);
                }}
              >
                <NotificationImportantOutlinedIcon className="high-icon" />
                High
              </MenuItem>
              <MenuItem
                className={priority === 2 ? 'active' : ''}
                value={2}
                onClick={(e) => {
                  setPriority(e.target.value);
                  setMenuOpen(!menuOpen);
                  updatePriority(e.target.value);
                }}
              >
                <ArrowDownwardIcon sx={{ visibility: 'hidden', marginRight: '2px' }} />
                Normal
              </MenuItem>
              <MenuItem
                className={priority === 3 ? 'active' : ''}
                value={3}
                onClick={(e) => {
                  setPriority(e.target.value);
                  setMenuOpen(!menuOpen);
                  updatePriority(e.target.value);
                }}
              >
                <ArrowDownwardIcon className="low-icon" />
                Low
              </MenuItem>
            </Menu>
          </div>
        </div>
      </div>
    );
  }
}
