/* eslint-disable jsx-a11y/mouse-events-have-key-events */
// TODO Handle accessibility for mouse over events
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import AnimateHeight from 'react-animate-height';

import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Checkbox from '@mui/material/Checkbox';
import Tooltip from '@mui/material/Tooltip';

import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import RemoveRoundedIcon from '@mui/icons-material/RemoveRounded';
import FlagIcon from '@mui/icons-material/Flag';
import FlagOutlinedIcon from '@mui/icons-material/FlagOutlined';

import { commaEvery3rdChar } from '../../../../utils';

import './CompTableRow.scss';

export default function CompTableRow({
  compData,
  rowIndex,
  tabToView,
  filterSelected,
  setShowConfirmationModal,
  fiscalYears,
  filters,
  filterValues,
  valuationDate,
  transactionDate,
  financialStatementDate,
  reSummarize,
  descriptionsToShow,
  setDescriptionsToShow,
  descriptionsRef,
  checkboxesRef,
  subCompScroll,
  overlayScrollbarRef,
  overlayScroll,
}) {
  const [shortDescBtnFocused, setShortDescBtnFocused] = useState(-1);
  const [shortDescBtnHovered, setShortDescBtnHovered] = useState(-1);
  const [longDescBtnFocused, setLongDescBtnFocused] = useState(-1);
  const [longDescBtnHovered, setLongDescBtnHovered] = useState(-1);

  const [checkboxHovered, setCheckboxHovered] = useState(-1);

  const [rowHovered, setRowHovered] = useState(-1);


  const getYear = (dataIndex) => {
    const increment = dataIndex / 4 + 0.25;
    return `${increment.toFixed(increment.toFixed(2).toString().charAt(increment.toFixed(2).toString().length - 1) === '0' ? 1 : 2)} year`;
  };

  const getPrice = (dataName, index) => compData[dataName]?.[index] ? commaEvery3rdChar((compData[dataName][index] * 1000000).toFixed(0)) : '-';

  const getPerc = (dataName, index, isGrossMargin) => compData[dataName]?.[index] ?
    `${(compData[dataName][rowIndex] * (!isGrossMargin ? 100 : 1)).toFixed(2)}%` : '-';

  const getMultiple = (dataName, index) => compData[dataName]?.[index] ? `${(compData[dataName][index]).toFixed(2)}x` : '-';

  const rowClassName = (index) => {
    let className = 'comp-table-row-data';
    if (overlayScroll) className += ' overlay-scroll';
    if (index === 0) className += ' sub-company';
    if (rowHovered === index) className += ' row-hovered';
    if ((tabToView === 6 && !compData.Multiples[index]) || (tabToView === 5 && !compData.Active[index])) className += ' red-inactive-row';
    if (tabToView === 6 && compData.Multiples[index] && !compData.Active[index]) className += ' yellow-multiples-disabled-row';
    if ((index + 1) % 2 === 0) className += ' even';
    else className += ' odd';
    return className;
  };


  return (
    <div className="CompTableRow">
      <div
        className={rowClassName(rowIndex)}
        onMouseOver={() => { if (rowHovered !== rowIndex) setRowHovered(rowIndex); }}
        onMouseLeave={() => setRowHovered(-1)}
      >
        <div className="fixed-left">
          <div className="row-data-group comp-list">
            {rowIndex !== 0 ? (
              <>
                <div
                  className="table-cell"
                  onMouseOver={() => { if (checkboxHovered !== rowIndex) setCheckboxHovered(rowIndex); }}
                  onMouseLeave={() => setCheckboxHovered(-1)}
                >
                  <Tooltip
                    PopperProps={{ className: 'bottom-arrow-tooltip select-deselect' }}
                    title={
                      compData.Active[rowIndex] && compData.Multiples[rowIndex] ?
                        (
                          <span>
                            Click to remove
                          </span>
                        ) : tabToView === 6 && !compData.Active[rowIndex] ? (
                          <span>
                            Automatically removed when
                            <br />
                            removed from volatility tab.
                          </span>
                        ) : (
                          <span>
                            Click to include
                          </span>
                        )
                    }
                    enterDelay={500}
                    enterNextDelay={1500}
                    placement="top"
                    arrow
                  >
                    <Checkbox
                      inputRef={rowIndex !== 0 ? checkboxesRef.current[rowIndex] : null}
                      className={tabToView === 6 && compData.Multiples[rowIndex] && !compData.Active[rowIndex] ? 'yellow' : ''}
                      checked={tabToView === 6 ? !!compData.Active[rowIndex] && !!compData.Multiples[rowIndex] : !!compData.Active[rowIndex]}
                      onChange={() => {
                        if (tabToView === 6 && compData.Multiples[rowIndex] && !compData.Active[rowIndex]) {
                          setShowConfirmationModal(rowIndex);
                          return;
                        }
                        reSummarize(rowIndex, tabToView === 5 ? 'active' : 'multiple');
                      }}
                      icon={<div className="remove-check-icon"><RemoveRoundedIcon /></div>}
                      checkedIcon={<div className="checked-icon"><CheckRoundedIcon /></div>}
                    />
                  </Tooltip>
                </div>
                <div className="table-cell">
                  {compData.Name[rowIndex].replace(/ *\([^)]*\) */g, '').trim().length > 41 ? (
                    <Tooltip title={compData.Name[rowIndex].replace(/ *\([^)]*\) */g, '').trim()} placement="top">
                      <span>{`${compData.Name[rowIndex].slice(0, 41).trim()}...`}</span>
                    </Tooltip>
                  ) : compData.Name[rowIndex].replace(/ *\([^)]*\) */g, '').trim()}
                </div>
                <div className="table-cell">
                  {compData?.Outlier?.[rowIndex] ? (
                    <IconButton
                      className="outlier-flag-icon is-outlier"
                      onClick={() => { reSummarize(rowIndex, 'outlier'); }}
                    >
                      <FlagIcon />
                    </IconButton>
                  ) : (
                    <Tooltip
                      disableInteractive
                      title="Flag as outlier"
                      PopperProps={{ className: 'bottom-arrow-tooltip' }}
                      placement="top"
                      enterDelay={500}
                      enterNextDelay={1500}
                      arrow
                    >
                      <IconButton
                        className={`outlier-flag-icon${rowHovered !== rowIndex ? ' hidden' : ''}`}
                        onClick={() => { reSummarize(rowIndex, 'outlier'); }}
                      >
                        <FlagOutlinedIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                </div>
              </>
            ) : (
              <>
                <div className="table-cell" />
                <div className="table-cell">{compData.Name[0]}</div>
                <div className="table-cell" />
              </>
            )}
          </div>
        </div>
        <div className="right-scrollable" ref={overlayScroll ? overlayScrollbarRef : rowIndex === 0 ? subCompScroll : null}>
          {(!filterSelected || filters.showCompanyInfo || tabToView === 5) && (
            <div className="row-data-group basic-info">
              <div className="table-cell">{compData.Identifier[rowIndex]}</div>
              <div className="table-cell">{compData.Ticker[rowIndex]}</div>
              {tabToView === 6 && (
                <>
                  <div className="table-cell industry">
                    {compData.Industry[rowIndex].length >= 18 ? (
                      <Tooltip title={compData.Industry[rowIndex]} placement="top">
                        <span>{`${compData.Industry[rowIndex].slice(0, 18).trim()}...`}</span>
                      </Tooltip>
                    ) : compData.Industry[rowIndex]}
                  </div>
                  {/* <div className="table-cell">xx/xx/xxxx</div> */}
                  <div className="table-cell">{compData['Last FYE Date'][rowIndex] || '-'}</div>
                  <div className="table-cell">{compData['IPO Date'][rowIndex] || '-'}</div>
                  <div className="table-cell">
                    {rowIndex !== 0 ? (
                      <Button
                        onMouseDown={(e) => {
                          if (descriptionsToShow[rowIndex] !== 'short') {
                            const newDescriptionToShow = [...descriptionsToShow];
                            newDescriptionToShow[rowIndex] = 'short';
                            setDescriptionsToShow(newDescriptionToShow);
                          } else {
                            const newDescriptionToShow = [...descriptionsToShow];
                            newDescriptionToShow[rowIndex] = '';
                            setDescriptionsToShow(newDescriptionToShow);
                          }
                          if (!e.target.className.includes('Mui-focusVisible')) e.preventDefault();
                        }}
                        onFocus={() => setShortDescBtnFocused(rowIndex)}
                        onBlur={() => setShortDescBtnFocused(-1)}
                        onMouseOver={() => { if (shortDescBtnHovered !== rowIndex) setShortDescBtnHovered(rowIndex); }}
                        onMouseLeave={() => setShortDescBtnHovered(-1)}
                      >
                        {descriptionsToShow[rowIndex] === 'short' ? 'Hide' : 'View'}
                      </Button>
                    ) : '-'}
                  </div>
                  <div className="table-cell">
                    {rowIndex !== 0 ? (
                      <Button
                        onMouseDown={(e) => {
                          if (descriptionsToShow[rowIndex] !== 'long') {
                            const newDescriptionToShow = [...descriptionsToShow];
                            newDescriptionToShow[rowIndex] = 'long';
                            setDescriptionsToShow(newDescriptionToShow);
                          } else {
                            const newDescriptionToShow = [...descriptionsToShow];
                            newDescriptionToShow[rowIndex] = '';
                            setDescriptionsToShow(newDescriptionToShow);
                          }
                          if (!e.target.className.includes('Mui-focusVisible')) e.preventDefault();
                        }}
                        onFocus={() => setLongDescBtnFocused(rowIndex)}
                        onBlur={() => setLongDescBtnFocused(-1)}
                        onMouseOver={() => { if (longDescBtnHovered !== rowIndex) setLongDescBtnHovered(rowIndex); }}
                        onMouseLeave={() => setLongDescBtnHovered(-1)}
                      >
                        {descriptionsToShow[rowIndex] === 'long' ? 'Hide' : 'View'}
                      </Button>
                    ) : '-'}
                  </div>
                </>
              )}
            </div>
          )}
          {tabToView === 5 && (!filterSelected || filters.showVolatilityValDate) && (
            <div className="row-data-group val-volatility">
              {[...Array(40)].map((el, dataIndex) => (
                (!filterValues.yearsFilterValues.length || filterValues.yearsFilterValues.includes(dataIndex / 4 + 0.25 <= 1 ?
                  `${dataIndex / 4 + 0.25} year` : `${dataIndex / 4 + 0.25} years`)) && (
                  <div key={getYear(dataIndex)} className="table-cell">
                    {compData[`Volatility - Valuation Date | ${getYear(dataIndex)}`][rowIndex] ?
                      `${((compData[`Volatility - Valuation Date | ${getYear(dataIndex)}`][rowIndex]) * 100).toFixed(2)}%` : '-'}
                  </div>
                )))}
            </div>
          )}
          {tabToView === 5 && (!filterSelected || filters.showVolatilityTrDate) && (
            <div className="row-data-group tr-volatility">
              {[...Array(40)].map((el, dataIndex) => (
                (!filterValues.yearsFilterValues.length || filterValues.yearsFilterValues.includes(dataIndex / 4 + 0.25 <= 1 ?
                  `${dataIndex / 4 + 0.25} year` : `${dataIndex / 4 + 0.25} years`)) && (
                  <div key={getYear(dataIndex)} className="table-cell">
                    {compData[`Volatility - Transaction Date | ${getYear(dataIndex)}`][rowIndex] ?
                      `${((compData[`Volatility - Transaction Date | ${getYear(dataIndex)}`][rowIndex]) * 100).toFixed(2)}%` : '-'}
                  </div>
                )
              ))}
            </div>
          )}
          {(!filterSelected || filters.showMarketCap) && (
            <div className="row-data-group market-cap">
              <div className="table-cell">{getPrice(`Market Capitalization | Transaction Date - ${transactionDate}`, rowIndex)}</div>
              <div className="table-cell">{getPrice(`Market Capitalization | Valuation Date - ${valuationDate}`, rowIndex)}</div>
              <div className="table-cell">{getPrice(`Market Capitalization | Financial Statement Date - ${financialStatementDate}`, rowIndex)}</div>
            </div>
          )}
          {tabToView === 6 && (!filterSelected || filterValues.growthFilterValues.includes('Market growth')) && (
            <div className="row-data-group market-growth">
              <div className="table-cell">{getPerc('Market Growth | Transaction Date to Valuation Date', rowIndex)}</div>
            </div>
          )}
          {(!filterSelected || filters.showRevenue) && (
            <div className="row-data-group revenue">
              {fiscalYears.map((fiscalYear) => {
                if (!filterValues.fiscalPeriodFilterValues.length ||
                  filterValues.fiscalPeriodFilterValues.some((val) => val.replace(/ *\([^)]*\) */g, '')
                    .replace('12', 'twelve') === fiscalYear.title)) {
                  return (
                    <div key={fiscalYear.title} className="table-cell">
                      {getPrice(`Revenue | ${fiscalYear.legend.replace(' ', '')}`, rowIndex)}
                    </div>
                  );
                }
                return null;
              })}
            </div>
          )}
          {tabToView === 6 && (!filterSelected || filters.showEBITDA) && (
            <div className="row-data-group EBITDA">
              {fiscalYears.map((fiscalYear) => {
                if (!filterValues.fiscalPeriodFilterValues.length ||
                  filterValues.fiscalPeriodFilterValues.some((val) => val.replace(/ *\([^)]*\) */g, '')
                    .replace('12', 'twelve') === fiscalYear.title)) {
                  return (
                    <div key={fiscalYear.title} className="table-cell">
                      {getPrice(`EBITDA | ${fiscalYear.legend.replace(' ', '')}`, rowIndex)}
                    </div>
                  );
                }
                return null;
              })}
            </div>
          )}
          {tabToView === 6 && (!filterSelected || filters.showGrossProfit) && (
            <div className="row-data-group gross-profit">
              {fiscalYears.map((fiscalYear) => {
                if (!filterValues.fiscalPeriodFilterValues.length ||
                  filterValues.fiscalPeriodFilterValues.some((val) => val.replace(/ *\([^)]*\) */g, '')
                    .replace('12', 'twelve') === fiscalYear.title)) {
                  return (
                    <div key={fiscalYear.title} className="table-cell">
                      {getPrice(`Gross Profit | ${fiscalYear.legend.replace(' ', '')}`, rowIndex)}
                    </div>
                  );
                }
                return null;
              })}
            </div>
          )}
          {tabToView === 6 && (!filterSelected || filterValues.growthFilterValues.includes('Revenue growth')) && (
            <div className="row-data-group revenue-growth">
              {fiscalYears.map((fiscalYear) => {
                if (!filterValues.fiscalPeriodFilterValues.length ||
                  filterValues.fiscalPeriodFilterValues.some((val) => val.replace(/ *\([^)]*\) */g, '')
                    .replace('12', 'twelve') === fiscalYear.title)) {
                  return (
                    <div key={fiscalYear.title} className="table-cell">
                      {getPerc(`Revenue Growth Rate | ${fiscalYear.legend.replace(' ', '')}`, rowIndex)}
                    </div>
                  );
                }
                return null;
              })}
            </div>
          )}
          {tabToView === 6 && (!filterSelected || filterValues.growthFilterValues.includes('EBITDA growth')) && (
            <div className="row-data-group EBITDA-growth">
              {fiscalYears.map((fiscalYear) => {
                if (!filterValues.fiscalPeriodFilterValues.length ||
                  filterValues.fiscalPeriodFilterValues.some((val) => val.replace(/ *\([^)]*\) */g, '')
                    .replace('12', 'twelve') === fiscalYear.title)) {
                  return (
                    <div key={fiscalYear.title} className="table-cell">
                      {getPerc(`EBITDA Growth Rate | ${fiscalYear.legend.replace(' ', '')}`, rowIndex)}
                    </div>
                  );
                }
                return null;
              })}
            </div>
          )}
          {tabToView === 6 && (!filterSelected || filterValues.growthFilterValues.includes('Gross Profit growth')) && (
            <div className="row-data-group gross-profit-growth">
              {fiscalYears.map((fiscalYear) => {
                if (!filterValues.fiscalPeriodFilterValues.length ||
                  filterValues.fiscalPeriodFilterValues.some((val) => val.replace(/ *\([^)]*\) */g, '')
                    .replace('12', 'twelve') === fiscalYear.title)) {
                  return (
                    <div key={fiscalYear.title} className="table-cell">
                      {getPerc(`Gross Profit Growth Rate | ${fiscalYear.legend.replace(' ', '')}`, rowIndex)}
                    </div>
                  );
                }
                return null;
              })}
            </div>
          )}
          {tabToView === 6 && (!filterSelected || filterValues.multiplesFilterValues.includes('Revenue multiples')) && (
            <div className="row-data-group revenue-multiples">
              {fiscalYears.map((fiscalYear) => {
                if (!filterValues.fiscalPeriodFilterValues.length ||
                  filterValues.fiscalPeriodFilterValues.some((val) => val.replace(/ *\([^)]*\) */g, '')
                    .replace('12', 'twelve') === fiscalYear.title)) {
                  return (
                    <div key={fiscalYear.title} className="table-cell">
                      {getMultiple(`Revenue Multiple${fiscalYear.legend.includes('NTM') ? 's' : ''} | ` +
                        `${fiscalYear.legend.replace(' ', '')}`, rowIndex)}
                    </div>
                  );
                }
                return null;
              })}
            </div>
          )}
          {tabToView === 6 && (!filterSelected || filterValues.multiplesFilterValues.includes('EBITDA multiples')) && (
            <div className="row-data-group EBITDA-multiples">
              {fiscalYears.map((fiscalYear) => {
                if (!filterValues.fiscalPeriodFilterValues.length ||
                  filterValues.fiscalPeriodFilterValues.some((val) => val.replace(/ *\([^)]*\) */g, '')
                    .replace('12', 'twelve') === fiscalYear.title)) {
                  return (
                    <div key={fiscalYear.title} className="table-cell">
                      {getMultiple(`EBITDA Multiple${fiscalYear.legend.includes('NTM') ? 's' : ''} | ` +
                        `${fiscalYear.legend.replace(' ', '')}`, rowIndex)}
                    </div>
                  );
                }
                return null;
              })}
            </div>
          )}
          {tabToView === 6 && (!filterSelected || filterValues.growthFilterValues.includes('EBITDA Margin')) && (
            <div className="row-data-group EBITDA-margin">
              {fiscalYears.map((fiscalYear) => {
                if (!filterValues.fiscalPeriodFilterValues.length ||
                  filterValues.fiscalPeriodFilterValues.some((val) => val.replace(/ *\([^)]*\) */g, '')
                    .replace('12', 'twelve') === fiscalYear.title)) {
                  return (
                    <div key={fiscalYear.title} className="table-cell">
                      {getPerc(`EBITDA Margin | ${fiscalYear.legend.replace(' ', '')}`, rowIndex)}
                    </div>
                  );
                }
                return null;
              })}
            </div>
          )}
          {tabToView === 6 && (!filterSelected || filterValues.growthFilterValues.includes('Gross Margin')) && (
            <div className="row-data-group gross-margin">
              {fiscalYears.map((fiscalYear) => {
                if (!filterValues.fiscalPeriodFilterValues.length ||
                  filterValues.fiscalPeriodFilterValues.some((val) => val.replace(/ *\([^)]*\) */g, '')
                    .replace('12', 'twelve') === fiscalYear.title)) {
                  return (
                    <div key={fiscalYear.title} className="table-cell">
                      {getPerc(`Gross Margin | ${fiscalYear.legend.replace(' ', '')}`, rowIndex, true)}
                    </div>
                  );
                }
                return null;
              })}
            </div>
          )}
        </div>
      </div>
      <AnimateHeight
        duration={300}
        height={(descriptionsToShow[rowIndex] === 'long' || descriptionsToShow[rowIndex] === 'short') ? 'auto' : 0}
      >
        <div className={`comp-description${(rowIndex + 1) % 2 === 0 ? ' even' : ' odd'}`}>
          {descriptionsToShow[rowIndex] === 'short' && (
            <div ref={rowIndex !== 0 ? descriptionsRef.current[rowIndex] : 0}>
              <p
                className={`description${shortDescBtnFocused === rowIndex ? ' focused' : ''}` +
                  `${shortDescBtnHovered === rowIndex ? ' hovered' : ''}`}
              >
                {compData['Short Description'][rowIndex]}
              </p>
            </div>
          )}
          {descriptionsToShow[rowIndex] === 'long' && (
            <div ref={rowIndex !== 0 ? descriptionsRef.current[rowIndex] : 0}>
              <p
                className={`description${longDescBtnFocused === rowIndex ? ' focused' : ''}` +
                  `${longDescBtnHovered === rowIndex ? ' hovered' : ''}`}
              >
                {compData['Long Description'][rowIndex]}
              </p>
            </div>
          )}
          <div className="comp-description-background" />
        </div>
      </AnimateHeight>
    </div>
  );
}

CompTableRow.propTypes = {
  compData: PropTypes.object.isRequired,
  tabToView: PropTypes.number.isRequired,
  filterSelected: PropTypes.bool.isRequired,
  fiscalYears: PropTypes.array.isRequired,
  filters: PropTypes.object.isRequired,
  filterValues: PropTypes.object.isRequired,
  setShowConfirmationModal: PropTypes.func,
  subCompScroll: PropTypes.object,
  valuationDate: PropTypes.string.isRequired,
  transactionDate: PropTypes.string.isRequired,
  financialStatementDate: PropTypes.string,
  reSummarize: PropTypes.func,
  descriptionsToShow: PropTypes.array,
  setDescriptionsToShow: PropTypes.func,
  descriptionsRef: PropTypes.object,
  checkboxesRef: PropTypes.object,
  rowIndex: PropTypes.number.isRequired,
  overlayScrollbarRef: PropTypes.func,
  overlayScroll: PropTypes.bool,
};

CompTableRow.defaultProps = {
  subCompScroll: {},
  descriptionsRef: {},
  checkboxesRef: {},
  descriptionsToShow: [],
  setDescriptionsToShow: () => { },
  setShowConfirmationModal: () => { },
  reSummarize: () => { },
  overlayScrollbarRef: () => { },
  overlayScroll: false,
  financialStatementDate: '',
};
