import React from 'react';
import PropTypes from 'prop-types';
import {
  Page,
  View,
  Text,
} from '@react-pdf/renderer';

import TablePageHeader from '../../components/TablePageHeader';
import BottomPagination from '../../components/BottomPagination';
import DraftOverlay from '../../components/DraftOverlay';

import { commaEvery3rdChar } from '../../../../../../utils';

export default function CapTable({ reportData, exhibitNumber }) {
  const tableData = reportData.CapitalizationTable;

  const table1Headers = [
    'Ownership class',
    'Outstanding',
    'Percent of total',
    'Conversion ratio',
    'Fully diluted\nshares outstanding',
    'Percent of fully diluted\nshares outstanding',
  ];

  const table2Headers = [
    'Ownership class',
    'Liquidation rank',
    'Liquidation ratio',
    'Issue price/\nstrike price',
    'Liquidation\npreference\n($/share)',
    'Total\nliquidation\npreference',
    'Participation\nrights',
    'Participation\ncap',
    'Mandatory\ndividends',
    'Dividend\npercentage',
  ];

  const formatSeriesName = (seriesName) => seriesName.includes(' @ $') ? `${seriesName.split(' @ $')[0]}` +
    ` @ $${new Intl.NumberFormat('en-US', { minimumFractionDigits: 2 })
      .format((seriesName.split(' @ $')[seriesName.split(' @ $').length - 1]))}` : seriesName;

  function returnCellData(dataName, rowIndex) {
    if (dataName === 'Percent of fully diluted\nshares outstanding') {
      return `${(tableData['Fully Diluted Shares Outstanding %'][rowIndex] * 100).toFixed(2)}%`;
    }
    if (dataName === 'Issue price/\nstrike price') {
      return tableData['Strike Price'][rowIndex] === 'NA' ? 'N/A' : `$${tableData['Strike Price'][rowIndex].toFixed(4)}`;
    }
    if (dataName === 'Liquidation\npreference\n($/share)') {
      return tableData['Liquidation Preference'][rowIndex] === 'NA' ? 'N/A' : `$${tableData['Liquidation Preference'][rowIndex].toFixed(4)}`;
    }
    if (dataName === 'Total\nliquidation\npreference') {
      return tableData['Total Liquidation Preference'][rowIndex] === 'NA' ? 'N/A' :
        `$${commaEvery3rdChar(tableData['Total Liquidation Preference'][rowIndex].toFixed(0))}`;
    }
    const formattedDataName = dataName.replaceAll('\n', ' ').split(' ').map((word) => word !== 'of' ?
      word[0].toUpperCase() + word.substr(1) : word).join(' ');
    if (['Outstanding', 'Fully diluted\nshares outstanding'].includes(dataName)) {
      return `${commaEvery3rdChar(tableData[formattedDataName][rowIndex].toString())}`;
    }
    if (['Conversion ratio', 'Liquidation ratio'].includes(dataName)) {
      return tableData[formattedDataName][rowIndex] === 'NA' ? 'N/A' : `${parseFloat(tableData[formattedDataName][rowIndex]).toFixed(2)}x`;
    }
    if (dataName === 'Percent of total') {
      return `${(tableData[formattedDataName][rowIndex] * 100).toFixed(2)}%`;
    }
    if (dataName === 'Ownership class') return formatSeriesName(tableData[formattedDataName][rowIndex]);
    return tableData[formattedDataName][rowIndex].toString() === 'NA' ? 'N/A' : tableData[formattedDataName][rowIndex].toString();
  }

  function returnTotal(dataName) {
    if (dataName === 'Ownership class') return 'Total';
    if (dataName === 'Outstanding') return commaEvery3rdChar(tableData.Outstanding.reduce((a, b) => a + b, 0));
    if (dataName === 'Fully diluted\nshares outstanding') {
      return commaEvery3rdChar(tableData['Fully Diluted Shares Outstanding'].reduce((a, b) => a + b, 0) + tableData.remainingOptionPool || 0);
    }
    if (['Percent of total', 'Percent of fully diluted\nshares outstanding'].includes(dataName)) return '100%';
    if (dataName === 'Total\nliquidation\npreference') {
      return `$${commaEvery3rdChar(tableData['Total Liquidation Preference'].reduce((a, b) => a + (b !== 'NA' ? b : 0), 0).toFixed(0))}`;
    }
    return '';
  }

  function columnWidth(index) {
    if (index === 0) return '200px';
    if ([1, 2].includes(index)) return '88px';
    if (index === 4) return '80px';
    return '113px';
  }
  const footnote = reportData.footnoteData.capTable;

  const rowLength = tableData['Ownership Class'].length + (footnote ? Math.ceil(footnote.length / 230) : 0);

  const reduceRowHeight = () => {
    if (rowLength > 6 && rowLength <= 9) return true;
    if (rowLength > 16) return true;
    return false;
  };

  const splitPage = () => {
    if (rowLength > 9) return true;
    return false;
  };

  return (
    <>
      <Page
        size="TABLOID"
        orientation="landscape"
        style={{ position: 'relative', backgroundColor: '#EAEAEB' }}
      >
        <TablePageHeader companyName={reportData.companyInfo.companyName} pageName={`Capitalization Table   |   Exhibit ${exhibitNumber}`} />
        <View
          style={{
            marginTop: '76px',
            marginLeft: '32px',
            display: 'flex',
            flexDirection: 'column',
            width: '1160px',
            border: '1px',
            borderColor: '#AFB1B3',
          }}
        >
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              height: '24px',
              backgroundColor: '#0C2617',
            }}
          >
            <Text
              style={{
                fontFamily: 'RobotoMed',
                fontSize: '10px',
                color: '#8AB9A4',
                paddingLeft: '16px',
                letterSpacing: '0.08px',
                marginRight: '8px',
                width: '192px',
                paddingTop: '6px',
                borderBottom: '1px',
                borderColor: '#CCCCCC',
                height: '24px',
              }}
            >
              (USD$)
            </Text>
            <Text
              style={{
                fontFamily: 'RobotoMed',
                fontSize: '10px',
                color: '#E1E2EC',
                paddingLeft: '16px',
                letterSpacing: '0.08px',
                width: '960px',
                paddingTop: '6px',
                borderBottom: '1px',
                borderColor: '#CCCCCC',
                height: '24px',
              }}
            >
              Shares
            </Text>
          </View>
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              height: '40px',
              backgroundColor: '#307157',
              borderBottom: '1px',
              borderColor: '#CCCCCC',
            }}
          >
            {table1Headers.map((dataName, index) => (
              <Text
                key={dataName}
                style={{
                  fontFamily: 'RobotoMed',
                  fontSize: '10px',
                  color: '#E1E2EC',
                  width: index === 0 ? '200px' : '192px',
                  marginTop: 'auto',
                  paddingBottom: '4px',
                  paddingLeft: '16px',
                  paddingRight: '16px',
                  letterSpacing: '0.5px',
                  textAlign: index !== 0 && 'right',
                }}
              >
                {dataName}
              </Text>
            ))}
          </View>
          {tableData['Ownership Class'].map((cellData, rowIndex) => (
            <View
              key={cellData}
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                height: reduceRowHeight() ? '24px' : '32px',
                backgroundColor: `${rowIndex % 2 ? '#F0F0F3' : '#FAFAFD'}`,
              }}
            >
              {table1Headers.map((dataName, cellIndex) => (
                <Text
                  key={dataName}
                  style={{
                    fontFamily: 'Roboto',
                    fontSize: '10px',
                    color: '#49454F',
                    width: cellIndex === 0 ? '200px' : '192px',
                    paddingLeft: '16px',
                    paddingRight: '16px',
                    letterSpacing: '0.4px',
                    textAlign: cellIndex !== 0 && 'right',
                  }}
                >
                  {returnCellData(dataName, rowIndex) || 'N/A'}
                </Text>
              ))}
            </View>
          ))}
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              height: reduceRowHeight() ? '24px' : '32px',
              backgroundColor: '#F0F0F3',
              borderTop: '1px',
              borderBottom: '1px',
              borderColor: '#999BA5',
            }}
          >
            {table1Headers.map((dataName, cellIndex) => (
              <Text
                key={dataName}
                style={{
                  fontFamily: 'RobotoMed',
                  fontSize: '10px',
                  color: '#49454F',
                  width: cellIndex === 0 ? '200px' : '192px',
                  paddingLeft: '16px',
                  paddingRight: '16px',
                  letterSpacing: '0.4px',
                  textAlign: cellIndex !== 0 && 'right',
                }}
              >
                {cellIndex === 0 ? 'Remaining Option Pool' :
                  dataName === 'Fully diluted\nshares outstanding' ?
                    `${commaEvery3rdChar(tableData.remainingOptionPool)}` : 'N/A'}
              </Text>
            ))}
          </View>
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              height: reduceRowHeight() ? '24px' : '32px',
              backgroundColor: '#9ECEBB',
            }}
          >
            {table1Headers.map((dataName, cellIndex) => (
              <Text
                key={dataName}
                style={{
                  fontFamily: 'RobotoMed',
                  fontSize: '10px',
                  color: '#49454F',
                  width: cellIndex === 0 ? '200px' : '192px',
                  paddingLeft: '16px',
                  paddingRight: '16px',
                  letterSpacing: '0.4px',
                  textAlign: cellIndex !== 0 && 'right',
                }}
              >
                {returnTotal(dataName)}
              </Text>
            ))}
          </View>
        </View>
        {!splitPage() && (
          <View
            style={{
              marginTop: '24px',
              marginLeft: '32px',
              display: 'flex',
              flexDirection: 'column',
              width: '1160px',
              border: '1px',
              borderColor: '#AFB1B3',
            }}
          >
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                height: '24px',
                backgroundColor: '#0C2617',
              }}
            >
              <Text
                style={{
                  fontFamily: 'RobotoMed',
                  fontSize: '10px',
                  color: '#8AB9A4',
                  paddingLeft: '16px',
                  letterSpacing: '0.08px',
                  marginRight: '8px',
                  width: '192px',
                  paddingTop: '6px',
                  borderBottom: '1px',
                  borderColor: '#CCCCCC',
                  height: '24px',
                }}
              >
                (USD$)
              </Text>
              <Text
                style={{
                  fontFamily: 'RobotoMed',
                  fontSize: '10px',
                  color: '#E1E2EC',
                  paddingLeft: '16px',
                  letterSpacing: '0.08px',
                  width: '960px',
                  paddingTop: '6px',
                  borderBottom: '1px',
                  borderColor: '#CCCCCC',
                  height: '24px',
                }}
              >
                Liquidation preferences
              </Text>
            </View>
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                height: '48px',
                backgroundColor: '#307157',
                borderBottom: '1px',
                borderColor: '#CCCCCC',
              }}
            >
              {table2Headers.map((dataName, index) => (
                <Text
                  key={dataName}
                  style={{
                    fontFamily: 'RobotoMed',
                    fontSize: '10px',
                    color: '#E1E2EC',
                    width: columnWidth(index),
                    marginTop: 'auto',
                    paddingBottom: '4px',
                    paddingLeft: '16px',
                    paddingRight: '16px',
                    letterSpacing: '0.5px',
                    textAlign: index !== 0 && 'right',
                  }}
                >
                  {dataName}
                </Text>
              ))}
            </View>
            {tableData['Ownership Class'].map((cellData, rowIndex) => (
              <View
                key={cellData}
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  height: reduceRowHeight() ? '24px' : '32px',
                  backgroundColor: `${rowIndex % 2 ? '#F0F0F3' : '#FAFAFD'}`,
                }}
              >
                {table2Headers.map((dataName, cellIndex) => (
                  <Text
                    key={dataName}
                    style={{
                      fontFamily: 'Roboto',
                      fontSize: '10px',
                      color: '#49454F',
                      width: columnWidth(cellIndex),
                      paddingLeft: '16px',
                      paddingRight: '16px',
                      letterSpacing: '0.4px',
                      textAlign: cellIndex !== 0 && 'right',
                    }}
                  >
                    {returnCellData(dataName, rowIndex) || 'N/A'}
                  </Text>
                ))}
              </View>
            ))}
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                height: reduceRowHeight() ? '24px' : '32px',
                backgroundColor: '#9ECEBB',
              }}
            >
              {table2Headers.map((dataName, cellIndex) => (
                <Text
                  key={dataName}
                  style={{
                    fontFamily: 'RobotoMed',
                    fontSize: '10px',
                    color: '#49454F',
                    width: columnWidth(cellIndex),
                    paddingLeft: '16px',
                    paddingRight: '16px',
                    letterSpacing: '0.4px',
                    textAlign: cellIndex !== 0 && 'right',
                  }}
                >
                  {returnTotal(dataName)}
                </Text>
              ))}
            </View>
          </View>
        )}
        {!splitPage() && !!footnote && (
          <Text
            style={{
              fontFamily: 'Roboto',
              fontSize: '8px',
              color: '#49454F',
              letterSpacing: '0.4px',
              marginTop: '16px',
              marginLeft: '90px',
              width: '450px',
              lineHeight: '0.55mm',
            }}
          >
            Footnote:&nbsp;
            {footnote}
          </Text>
        )}
        <BottomPagination
          pageNumber={6}
          reportData={reportData}
        />
        {reportData.isDraft && <DraftOverlay />}
      </Page>
      {splitPage() && (
        <Page
          size="TABLOID"
          orientation="landscape"
          style={{ position: 'relative', backgroundColor: '#EAEAEB' }}
        >
          <TablePageHeader companyName={reportData.companyInfo.companyName} pageName="Capitalization Table   |   Exhibit 3" />
          <View
            style={{
              marginTop: '84px',
              marginLeft: '32px',
              display: 'flex',
              flexDirection: 'column',
              width: '1160px',
              border: '1px',
              borderColor: '#AFB1B3',
            }}
          >
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                height: '24px',
                backgroundColor: '#0C2617',
              }}
            >
              <Text
                style={{
                  fontFamily: 'RobotoMed',
                  fontSize: '10px',
                  color: '#8AB9A4',
                  paddingLeft: '16px',
                  letterSpacing: '0.08px',
                  marginRight: '8px',
                  width: '192px',
                  paddingTop: '6px',
                  borderBottom: '1px',
                  borderColor: '#CCCCCC',
                  height: '24px',
                }}
              >
                (USD$)
              </Text>
              <Text
                style={{
                  fontFamily: 'RobotoMed',
                  fontSize: '10px',
                  color: '#E1E2EC',
                  paddingLeft: '16px',
                  letterSpacing: '0.08px',
                  width: '960px',
                  paddingTop: '6px',
                  borderBottom: '1px',
                  borderColor: '#CCCCCC',
                  height: '24px',
                }}
              >
                Liquidation preferences
              </Text>
            </View>
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                height: '48px',
                backgroundColor: '#307157',
                borderBottom: '1px',
                borderColor: '#CCCCCC',
              }}
            >
              {table2Headers.map((dataName, index) => (
                <Text
                  key={dataName}
                  style={{
                    fontFamily: 'RobotoMed',
                    fontSize: '10px',
                    color: '#E1E2EC',
                    width: columnWidth(index),
                    marginTop: 'auto',
                    paddingBottom: '4px',
                    paddingLeft: '16px',
                    paddingRight: '16px',
                    letterSpacing: '0.5px',
                    textAlign: index !== 0 && 'right',
                  }}
                >
                  {dataName}
                </Text>
              ))}
            </View>
            {tableData['Ownership Class'].map((cellData, rowIndex) => (
              <View
                key={cellData}
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  height: reduceRowHeight() ? '24px' : '32px',
                  backgroundColor: `${rowIndex % 2 ? '#F0F0F3' : '#FAFAFD'}`,
                }}
              >
                {table2Headers.map((dataName, cellIndex) => (
                  <Text
                    key={dataName}
                    style={{
                      fontFamily: 'Roboto',
                      fontSize: '10px',
                      color: '#49454F',
                      width: columnWidth(cellIndex),
                      paddingLeft: '16px',
                      paddingRight: '16px',
                      letterSpacing: '0.4px',
                      textAlign: cellIndex !== 0 && 'right',
                    }}
                  >
                    {returnCellData(dataName, rowIndex) || 'N/A'}
                  </Text>
                ))}
              </View>
            ))}
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                height: reduceRowHeight() ? '24px' : '32px',
                backgroundColor: '#9ECEBB',
              }}
            >
              {table2Headers.map((dataName, cellIndex) => (
                <Text
                  key={dataName}
                  style={{
                    fontFamily: 'RobotoMed',
                    fontSize: '10px',
                    color: '#49454F',
                    width: columnWidth(cellIndex),
                    paddingLeft: '16px',
                    paddingRight: '16px',
                    letterSpacing: '0.4px',
                    textAlign: cellIndex !== 0 && 'right',
                  }}
                >
                  {returnTotal(dataName)}
                </Text>
              ))}
            </View>
          </View>
          {!!footnote && (
            <Text
              style={{
                fontFamily: 'Roboto',
                fontSize: '8px',
                color: '#49454F',
                letterSpacing: '0.4px',
                marginTop: '16px',
                marginLeft: '90px',
                width: '450px',
                lineHeight: '0.55mm',
              }}
            >
              Footnote:&nbsp;
              {footnote}
            </Text>
          )}
          <BottomPagination
            pageNumber={7}
            reportData={reportData}
          />
          {reportData.isDraft && <DraftOverlay />}
        </Page>
      )}
    </>
  );
}

CapTable.propTypes = {
  reportData: PropTypes.object.isRequired,
  exhibitNumber: PropTypes.number.isRequired,
};
