import React, {
  useState,
  useEffect,
  useContext,
  useRef,
  createRef,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import AnimateHeight from 'react-animate-height';
import isEqual from 'lodash.isequal';

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';

import SelectAllIcon from '@mui/icons-material/SelectAll';
// import LightbulbOutlinedIcon from '@mui/icons-material/LightbulbOutlined';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import AddIcon from '@mui/icons-material/Add';
import AddBusinessOutlinedIcon from '@mui/icons-material/AddBusinessOutlined';
import FlagIcon from '@mui/icons-material/Flag';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

import FilterMenus from './FilterMenus';
import CompTable from './CompTable/CompTable';
import SummariesTable from './SummariesTable';
import EquityInputs from './EquityInputs';
import AddCompModal from './AddCompModal';

import { getUserId } from '../../../utils/auth';

import { ErrorMessageContext } from '../../../contexts';

import { copy } from '../../../utils';

import useFetch from '../../../hooks/useFetch';
import useS3 from '../../../hooks/useS3';

import './index.scss';
import RefreshCompsModal from './RefreshCompsModal';

export default function Comps({
  userData,
  setUserData,
  tabToView,
  setTabToView,
  runningComps,
  setRunningComps,
  setShowRunningCompSnackbar,
  setCompSuccess,
  setCheckForMissingCompInputs,
  setSelectedGridValue,
}) {
  const [fetchingComps, setFetchingComps] = useState(true);
  const [identifyingOutliersSuccess, setIdentifyingOutliersSuccess] = useState(false);

  const [compData, setCompData] = useState({});
  const [summariesData, setSummariesData] = useState({});
  const [transactionData, setTransactionData] = useState(userData.transactionData || {});

  const [industries, setIndustries] = useState(['']);
  const [searchIndustryInput, setSearchIndustryInput] = useState(['']);
  const [currentIndustries, setCurrentIndustries] = useState(['']);

  const [showAddCompModal, setShowAddCompModal] = useState(false);
  const [compsToAdd, setCompsToAdd] = useState([{ rowId: 0, compInput: '' }]);

  const [showRefreshCompsModal, setShowRefreshCompsModal] = useState(false);

  const [showIndustriesModal, setShowIndustriesModal] = useState(false);

  const [descriptionsToShow, setDescriptionsToShow] = useState([]);

  const [transactionDate, setTransactionDate] = useState('');
  const [valuationDate, setValuationDate] = useState('');
  const [financialStatementDate, setFinancialStatementDate] = useState('');

  const [previousCalcState, setPreviousCalcState] = useState(userData.calcData || {});

  const [calcDataChanged, setCalcDataChanged] = useState(false);

  // Filter options
  const growthFilterOptions = ['Market growth', 'Revenue growth', 'EBITDA growth', 'Gross Profit growth', 'EBITDA Margin', 'Gross Margin'];
  const multiplesFilterOptions = ['Revenue multiples', 'EBITDA multiples'];
  const yearsFilterOptions = [...Array(40)].map((val, index) => index / 4 + 0.25 <= 1 ? `${index / 4 + 0.25} year` : `${index / 4 + 0.25} years`);
  const fiscalPeriodFilterOptions = ['Last fiscal year -1 (LFY-1)', 'Last fiscal year (LFY)', 'Last 12 months (LTM)',
    'Current fiscal year (CFY)', 'Next 12 months (NTM)', 'Next fiscal year (NFY)', 'Next fiscal year +1 (NFY+1)', 'Next fiscal year +2 (NFY+2)'];

  const fiscalYears = [
    { title: 'Last fiscal year -1', legend: 'LFY -1' },
    { title: 'Last fiscal year', legend: 'LFY' },
    { title: 'Last twelve months', legend: 'LTM' },
    { title: 'Current fiscal year', legend: 'CFY' },
    { title: 'Next twelve months', legend: 'NTM' },
    { title: 'Next fiscal year', legend: 'NFY' },
    { title: 'Next fiscal year +1', legend: 'NFY +1' },
    { title: 'Next fiscal year +2', legend: 'NFY +2' },
  ];

  const [filterSelected, setFilterSelected] = useState(false);

  const [filters, setFilters] = useState({
    showCompanyInfo: false,
    showRevenue: false,
    showEBITDA: false,
    showGrossProfit: false,
    showMarketCap: false,
    showVolatilityValDate: false,
    showVolatilityTrDate: false,
  });

  const [filterValues, setFilterValues] = useState({
    growthFilterValues: [],
    multiplesFilterValues: [],
    yearsFilterValues: [],
    fiscalPeriodFilterValues: [],
  });

  const [currentCompRowWidth, setCurrentCompRowWidth] = useState('');

  const [industryError, setIndustryError] = useState(false);

  const [showVolatilityFootnote, setShowVolatilityFootnote] = useState(false);
  const [showBacksolveFootnote, setShowBacksolveFootnote] = useState(false);

  const [showComps, setShowComps] = useState(false);

  const [showWaitLongerMsg, setShowWaitLongerMsg] = useState(false);

  const { setShowErrorMessage } = useContext(ErrorMessageContext);

  const headerScroll = useRef(null);
  const subCompScroll = useRef(null);
  const rightCompXScroll = useRef(null);
  const rightCompYScroll = useRef(null);
  const overlayScroll = useRef(null);
  const summariesScroll = useRef(null);

  const checkCompStatusRef = useRef(null);

  const descriptionsRef = useRef({});
  const checkboxesRef = useRef({});

  function handleRightCompXScroll() {
    headerScroll.current.scrollLeft = rightCompXScroll.current.scrollLeft;
    subCompScroll.current.scrollLeft = rightCompXScroll.current.scrollLeft;
    if (overlayScroll.current) overlayScroll.current.scrollLeft = rightCompXScroll.current.scrollLeft;
    summariesScroll.current.scrollLeft = rightCompXScroll.current.scrollLeft;
  }

  function handleOverlayScroll() {
    headerScroll.current.scrollLeft = overlayScroll.current.scrollLeft;
    subCompScroll.current.scrollLeft = overlayScroll.current.scrollLeft;
    rightCompXScroll.current.scrollLeft = overlayScroll.current.scrollLeft;
    summariesScroll.current.scrollLeft = overlayScroll.current.scrollLeft;
  }

  function handleSummariesScroll() {
    headerScroll.current.scrollLeft = summariesScroll.current.scrollLeft;
    subCompScroll.current.scrollLeft = summariesScroll.current.scrollLeft;
    rightCompXScroll.current.scrollLeft = summariesScroll.current.scrollLeft;
    if (overlayScroll.current) overlayScroll.current.scrollLeft = summariesScroll.current.scrollLeft;
  }

  function handleRightCompYScroll() {
    if (Math.abs(rightCompYScroll.current.scrollHeight - rightCompYScroll.current.scrollTop - rightCompYScroll.current.clientHeight) < 16 &&
      !navigator.userAgent.includes('Firefox')) {
      rightCompYScroll.current.scrollTop = rightCompYScroll.current.scrollHeight - rightCompYScroll.current.clientHeight - 16;
    }
  }

  const rightCompXScrollRef = useCallback((node) => {
    if (rightCompXScroll?.current) rightCompXScroll.current.removeEventListener('scroll', handleRightCompXScroll);
    if (node) node.addEventListener('scroll', handleRightCompXScroll, { passive: true });
    rightCompXScroll.current = node;
  }, []);

  const rightCompYScrollRef = useCallback((node) => {
    if (rightCompYScroll?.current) rightCompYScroll.current.removeEventListener('scroll', handleRightCompYScroll);
    if (node) node.addEventListener('scroll', handleRightCompYScroll, { passive: true });
    rightCompYScroll.current = node;
  }, []);

  const overlayScrollbarRef = useCallback((node) => {
    if (overlayScroll?.current) overlayScroll.current.removeEventListener('scroll', handleOverlayScroll);
    if (node) node.addEventListener('scroll', handleOverlayScroll, { passive: true });
    overlayScroll.current = node;
  }, []);

  const summariesScrollRef = useCallback((node) => {
    if (summariesScroll?.current) summariesScroll.current.removeEventListener('scroll', handleSummariesScroll);
    if (node) node.addEventListener('scroll', handleSummariesScroll, { passive: true });
    summariesScroll.current = node;
  }, []);

  useEffect(() => {
    if (rightCompXScroll?.current) setCurrentCompRowWidth(rightCompXScroll.current.scrollWidth);
  }, [filters, filterValues]);

  const [, transactionDataRequest] = useFetch();
  const [{ response: gotCompData }, compDataRequest] = useFetch();
  const [{ response: gotSummaryData }, summaryDataRequest] = useFetch();
  const [, updateTransactionRequest] = useFetch();
  const [, runCompRequest] = useFetch();
  const [, refreshCompRequest] = useFetch();
  const [{ loading: identifyingOutliers }, identifyOutliersRequest] = useFetch();
  const [{ loading: reSummarizing }, summarizeRequest] = useFetch();
  const [{ loading: removingIndustry }, removeIndustryRequest] = useFetch();
  const [, saveFootnotesRequest] = useFetch();
  const [, updateCalcEngineObjectRequest] = useFetch();

  const s3Bucket = `${process.env.REACT_APP_CLIENT_FILES_BUCKET}-${process.env.REACT_APP_ENV_LABEL}`;

  const [{ response: compS3Location }, getS3CompRequest] = useS3();
  const [{ response: summaryS3Location }, getS3SummaryRequest] = useS3();

  useEffect(() => {
    if (compS3Location && summaryS3Location) {
      compDataRequest({
        url: compS3Location,
        s3Call: true,
        onSuccess: (responseData) => {
          setCompData(responseData);
          descriptionsRef.current = responseData.Identifier.map((_, i) => descriptionsRef.current[i] ?? createRef());
          checkboxesRef.current = responseData.Identifier.map((_, i) => checkboxesRef.current[i] ?? createRef());
          setDescriptionsToShow([...Array(responseData.Identifier.length)].map(() => ''));
        },
      });
      summaryDataRequest({ url: summaryS3Location, s3Call: true, onSuccess: setSummariesData });
    }
  }, [compS3Location, summaryS3Location]);

  useEffect(() => { if (gotCompData && gotSummaryData) setShowComps(true); }, [gotCompData, gotSummaryData]);

  function checkCompStatus() {
    if (checkCompStatusRef.current) {
      transactionDataRequest({
        url: '/transactions/asc820/get-enterprise-transaction-data/',
        urlIds: ['enterpriseCompanyId', 'portfolioCompanyId', 'transactionId', 'userId'],
        onSuccess: (responseData) => {
          if (responseData.transactionData.compStatus === 'complete' &&
            responseData.transactionData.compsLocation && responseData.transactionData.compSummaryLocation) {
            setTransactionData(responseData.transactionData);
            getS3CompRequest({
              key: responseData.transactionData.compsLocation.replace(`s3://${s3Bucket}/820Comps`, ''),
              customPrefix: '820Comps',
              requestType: 'get',
            });
            getS3SummaryRequest({
              key: responseData.transactionData.compSummaryLocation.replace(`s3://${s3Bucket}/820Comps`, ''),
              customPrefix: '820Comps',
              requestType: 'get',
            });
            setIndustries(userData.transactionData.basicInfo.companyIndustry && userData.transactionData.basicInfo.companyIndustry.length ?
              userData.transactionData.basicInfo.companyIndustry : ['']);
            setSearchIndustryInput(userData.transactionData.basicInfo.companyIndustry && userData.transactionData.basicInfo.companyIndustry.length ?
              userData.transactionData.basicInfo.companyIndustry : ['']);
            setCurrentIndustries(userData.transactionData.basicInfo.companyIndustry && userData.transactionData.basicInfo.companyIndustry.length ?
              userData.transactionData.basicInfo.companyIndustry : ['']);
            setTransactionDate(responseData.transactionData.basicInfo.transactionDate);
            setValuationDate(responseData.transactionData.basicInfo?.valuationDate);
            setFinancialStatementDate(responseData.transactionData.basicInfo?.financialStatementDate);
            clearInterval(checkCompStatusRef.current);
            checkCompStatusRef.current = null;
            setCompsToAdd([{ rowId: 0, compInput: '' }]);
            setRunningComps(false);
            setShowRunningCompSnackbar(false);
            setCompSuccess(true);
            setShowAddCompModal(false);
            setShowIndustriesModal(false);
            setShowWaitLongerMsg(false);
            setUserData({ ...userData, transactionData: responseData.transactionData });
          } else if (responseData.transactionData.compStatus === 'error') {
            setShowErrorMessage(responseData.transactionData.compError);
            setRunningComps(false);
            setShowAddCompModal(false);
            setShowIndustriesModal(false);
            setShowRunningCompSnackbar(false);
            setShowWaitLongerMsg(false);
            setCompsToAdd([{ rowId: 0, compInput: '' }]);
            setUserData({ ...userData, transactionData: responseData.transactionData });
            clearInterval(checkCompStatusRef.current);
            checkCompStatusRef.current = null;
          }
        },
      });
    }
  }

  function parseTransactionInfo() {
    setTransactionData(userData.transactionData);
    setIndustries(userData.transactionData.basicInfo.companyIndustry && userData.transactionData.basicInfo.companyIndustry.length ?
      userData.transactionData.basicInfo.companyIndustry : ['']);
    setSearchIndustryInput(userData.transactionData.basicInfo.companyIndustry && userData.transactionData.basicInfo.companyIndustry.length ?
      userData.transactionData.basicInfo.companyIndustry : ['']);
    setCurrentIndustries(userData.transactionData.basicInfo.companyIndustry && userData.transactionData.basicInfo.companyIndustry.length ?
      userData.transactionData.basicInfo.companyIndustry : ['']);
    setTransactionDate(userData.transactionData.basicInfo.transactionDate);
    setValuationDate(userData.transactionData.basicInfo.valuationDate);
    setFinancialStatementDate(userData.transactionData.basicInfo.financialStatementDate ?
      moment(userData.transactionData.basicInfo.financialStatementDate, 'YYYY-MM-DD').format('MM/DD/YYYY') : null);
    if (userData.transactionData?.compsLocation && userData.transactionData?.compSummaryLocation) {
      getS3CompRequest({
        key: userData.transactionData.compsLocation.replace(`s3://${s3Bucket}/820Comps`, ''),
        customPrefix: '820Comps',
        requestType: 'get',
      });
      getS3SummaryRequest({
        key: userData.transactionData.compSummaryLocation.replace(`s3://${s3Bucket}/820Comps`, ''),
        customPrefix: '820Comps',
        requestType: 'get',
      });
    }
    if (userData.transactionData.compStatus === 'running') {
      setRunningComps(true);
      if (!checkCompStatusRef.current) {
        checkCompStatusRef.current = setInterval(() => { checkCompStatus(); }, 5000);
      }
      setRunningComps(true);
      if (!showAddCompModal && !showIndustriesModal) {
        setShowRunningCompSnackbar(true);
      }
    }
    setFetchingComps(false);
  }

  useEffect(() => { if (userData.transactionData) parseTransactionInfo(); }, [userData.transactionData]);

  function runComps() {
    setRunningComps(true);
    const newTransactionData = {
      ...transactionData,
      basicInfo: {
        ...transactionData.basicInfo,
        companyIndustry: industries,
      },
      version: parseInt(transactionData.version, 10),
    };
    delete newTransactionData.lastModified;
    updateTransactionRequest({
      url: '/transactions/asc820/enterprise-update-transaction',
      method: 'post',
      body: newTransactionData,
      bodyIds: ['requestUserId'],
      onError: () => setRunningComps(false),
      onSuccess: (responseData) => {
        const newCompData = {
          companyId: userData.metaData.portfolioCompanyId,
          transactionDate: transactionData.basicInfo.transactionDate ?
            transactionData.basicInfo.transactionDate :
            userData.transactionData.basicInfo.transactionDate,
          valuationDate: transactionData.basicInfo.valuationDate ?
            transactionData.basicInfo.valuationDate :
            userData.transactionData.basicInfo.transactionDate,
          financialStatementDate: transactionData.basicInfo.financialStatementDate ?
            moment(transactionData.basicInfo.financialStatementDate, 'YYYY-MM-DD').format('MM/DD/YYYY') :
            moment(userData.transactionData.basicInfo.financialStatementDate, 'YYYY-MM-DD').format('MM/DD/YYYY'),
          ...compsToAdd[0].compInput && {
            companies: compsToAdd.filter((comp) => comp.compInput !== '').map((compToAdd) => compToAdd.compInput),
          },
          reRun: 0,
        };
        runCompRequest({
          url: '/comps/run-comps',
          method: 'post',
          body: newCompData,
          bodyIds: ['transactionId', 'requestUserId'],
          onSuccess: () => setUserData({ ...userData, transactionData: { ...responseData, compStatus: 'running' } }),
          onError: () => {
            setRunningComps(false);
            setUserData({ ...userData, transactionData: { ...userData.transactionData, compStatus: 'error' } });
          },
        });
      },
    });
  }


  function refreshComps() {
    setRunningComps(true);
    setShowRunningCompSnackbar(true);
    const compDataObject = {
      companyId: userData.metaData.portfolioCompanyId,
      compData,
      transaction: userData.transactionData,
    };
    refreshCompRequest({
      url: '/comps/refresh-comps',
      method: 'post',
      body: compDataObject,
      bodyIds: ['transactionId', 'requestUserId'],
      onSuccess: () => setUserData({ ...userData, transactionData: { ...userData.transactionData, compStatus: 'running' } }),
      onError: () => {
        setRunningComps(false);
        clearInterval(checkCompStatusRef.current);
        checkCompStatusRef.current = null;
        setUserData({ ...userData, transactionData: { ...userData.transactionData, compStatus: 'error' } });
      },
    });
  }

  function identifyOutliers() {
    const userId = getUserId();
    const compDataObject = {
      companyId: transactionData.companyId, // This is the portfolio company id
      transactionId: transactionData.transactionId,
      enterpriseCompanyId: transactionData.enterpriseCompanyId,
      compData: { ...compData, Outlier: compData.Outlier.map(() => 0) },
      requestUserId: userId,
    };
    identifyOutliersRequest({
      url: '/comps/identify-outliers',
      method: 'post',
      body: compDataObject,
      onSuccess: (responseData) => {
        setSummariesData(responseData?.summary);
        setCompData({ ...compData, Outlier: responseData?.comps.Outlier });
        setIdentifyingOutliersSuccess(true);
        setTimeout(() => { setIdentifyingOutliersSuccess(false); }, 3000);
      },
    });
  }

  function reSummarize(indexChanged, typeChanged) {
    let newActiveArr = [...compData.Active];
    let newMultiplesArr = [...compData.Multiples];
    const newOutlierArr = [...compData.Outlier];
    if (indexChanged === 'all') {
      if (typeChanged === 'active') {
        newActiveArr = newActiveArr.some((active) => active) ? newActiveArr.map(() => 0) : newActiveArr.map(() => 1);
      } else {
        newMultiplesArr = newMultiplesArr.some((active) => active) ? newMultiplesArr.map(() => 0) : newMultiplesArr.map(() => 1);
      }
    } else if (typeChanged === 'add-both') {
      newActiveArr[indexChanged] = 1;
      newMultiplesArr[indexChanged] = 1;
    } else if (typeChanged === 'active') {
      newActiveArr[indexChanged] = newActiveArr[indexChanged] ? 0 : 1;
    } else if (typeChanged === 'multiple') {
      newMultiplesArr[indexChanged] = newMultiplesArr[indexChanged] ? 0 : 1;
    } else {
      newOutlierArr[indexChanged] = newOutlierArr[indexChanged] ? 0 : 1;
    }
    setCompData({
      ...compData,
      ...typeChanged === 'active' && { Active: newActiveArr },
      ...typeChanged === 'multiple' && { Multiples: newMultiplesArr },
      ...typeChanged === 'outlier' && { Outlier: newOutlierArr },
    });
    const compDataObject = {
      compData: {
        ...compData,
        ...typeChanged === 'active' && { Active: newActiveArr },
        ...typeChanged === 'multiple' && { Multiples: newMultiplesArr },
        ...typeChanged === 'outlier' && { Outlier: newOutlierArr },
      },
    };
    summarizeRequest({
      url: '/comps/summarize-and-save-comps',
      method: 'post',
      body: compDataObject,
      bodyIds: ['portfolioCompanyId', 'transactionId', 'enterpriseCompanyId', 'requestUserId'],
      onSuccess: (res) => {
        setSummariesData(res.summary);
        setUserData({
          ...userData,
          calcData: {
            ...userData.calcData,
            methods: {
              ...userData.calcData.methods,
              backsolve: {
                ...userData.calcData.methods.backsolve,
                suggestedMarketAdjustment: res.suggestedMarketAdjustment,
              },
            },
          },
        });
      },
    });
  }

  function removeIndustryFromComp(industryToRemove) {
    const compDataObject = {
      compData,
      industry: industryToRemove,
    };
    removeIndustryRequest({
      url: '/comps/remove-industry',
      method: 'post',
      body: compDataObject,
      bodyIds: ['portfolioCompanyId', 'transactionId', 'enterpriseCompanyId', 'requestUserId'],
      onSuccess: (responseData) => {
        const summariesRes = responseData?.summary;
        setSummariesData(summariesRes);
        const compResponse = responseData?.comps;
        setCompData(compResponse);
        descriptionsRef.current = compResponse.Identifier.map((_, i) => descriptionsRef.current[i] ?? createRef());
        checkboxesRef.current = compResponse.Identifier.map((_, i) => checkboxesRef.current[i] ?? createRef());
        setDescriptionsToShow([...Array(compResponse.Identifier.length)].map(() => ''));
        setCurrentIndustries(currentIndustries.filter((el) => el !== industryToRemove));
      },
    });
  }

  function saveFootnotes(data, dataName) {
    const saveFootnoteData = {
      methodsFootnotes: [],
      payload: data,
      dataName,
      ...userData?.gridData?.defaultScenarios.term && userData?.gridData?.defaultScenarios.volatility ? {
        term: new Intl.NumberFormat('en-US', {
          minimumIntegerDigits: 1,
          minimumFractionDigits: 1,
        }).format(userData.gridData?.defaultScenarios.term),
        volatility: userData.gridData.defaultScenarios.volatility,
      } : {
        term: new Intl.NumberFormat('en-US', {
          minimumIntegerDigits: 1,
          minimumFractionDigits: 1,
        }).format(userData.gridData?.defaultScenarios?.term),
        volatility: userData.gridData?.defaultScenarios?.volatility.toFixed(1).toString(),
      },
    };
    saveFootnotesRequest({
      url: '/footnotes/save-footnotes',
      method: 'post',
      body: saveFootnoteData,
      bodyIds: ['portfolioCompanyId', 'enterpriseCompanyId', 'transactionId', 'requestUserId'],
    });
  }

  useEffect(() => {
    if (calcDataChanged && !isEqual(userData.calcData, previousCalcState)) {
      updateCalcEngineObjectRequest({
        url: '/calc-engine/update-calc-engine-object',
        method: 'post',
        body: userData.calcData,
        onSuccess: () => setPreviousCalcState(userData.calcData),
        onFinally: () => setCalcDataChanged(false),
      });
    }
  }, [calcDataChanged, userData.calcData, previousCalcState]);

  function getSuggestedMarketAdjustment() {
    if (Object.keys(summariesData).length > 0) {
      const suggestedMarketAdjustment = (summariesData['Market Growth | Transaction Date to Valuation Date'][4] * 100).toFixed(2);
      setUserData({
        ...userData,
        calcData: {
          ...userData.calcData,
          methods: {
            ...userData.calcData.methods,
            backsolve: {
              ...userData.calcData.methods.backsolve,
              suggestedMarketAdjustment,
            },
          },
        },
      });
    }
  }

  useEffect(() => { getSuggestedMarketAdjustment(); }, [summariesData]);

  if (!fetchingComps) {
    return (
      <div className="Comps">
        {!!transactionData.basicInfo?.valuationDate &&
          !!transactionData.basicInfo?.transactionDate &&
          !!transactionData.basicInfo.financialStatementDate ?
          (
            <>
              {tabToView === 6 && (
                <div className="header-info-block">
                  <h4>
                    <SelectAllIcon />
                    Select industries, comps & equity
                  </h4>
                  <p>
                    Add companies, select comparable companies, and weight scenarios at the bottom of this page before you head over
                    <br />
                    to the Calculations tab to calculate Backsolve, PubCo and M&A methods.
                    {showComps && (
                      <>
                        <span> Need to update the data in the company list?</span>
                        <Button onClick={() => { setShowRefreshCompsModal(true); }}>Click here</Button>
                        .
                      </>
                    )}
                  </p>
                </div>
              )}
              {tabToView === 5 && (
                <div className="header-info-block">
                  <h4>
                    <QueryStatsIcon />
                    Choose volatility
                  </h4>
                </div>
              )}
              {tabToView === 5 && (
                <div className="volatility-inputs-wrapper">
                  <div className="volatility-inputs-header">
                    <span>Choose a volatility percentile</span>
                    <p>
                      The volatility percentile can be selected and edited from this tab and from the Calculations tab.
                      Our system updates both tabs simultaneously. Don&apos;t worry about being precise,
                      you&apos;ll be able to run a range of volatilities and make a final selection from the Calculations tab,
                      plus you can edit this and rerun models on the Calculations tab at any time.
                    </p>
                  </div>
                  <div className="volatility-inputs-content">
                    <div className={`volatility-input-wrapper ${showVolatilityFootnote ? ' show-radius' : ''}`}>
                      <div className="volatility-input-top">
                        <TextField
                          label="Volatility percentile"
                          value={userData.calcData?.limits.volatility.quantile || ''}
                          onChange={(e) => {
                            const newData = copy(userData);
                            newData.calcData.limits.volatility.quantile = e.target.value;
                            setUserData(newData);
                            setCalcDataChanged(true);
                          }}
                          select
                          SelectProps={{ MenuProps: { disableScrollLock: true, classes: { paper: 'select-dropdown' } } }}
                        >
                          {[
                            { value: 'min', label: 'Minimum' },
                            { value: '5%', label: '5th percentile' },
                            { value: '10%', label: '10th percentile' },
                            { value: '25%', label: '25th percentile' },
                            { value: '50%', label: 'Median' },
                            { value: '75%', label: '75th percentile' },
                            { value: '90%', label: '90th percentile' },
                            { value: '95%', label: '95th percentile' },
                            { value: 'max', label: 'Maximum' },
                            { value: 'mean', label: 'Average' },
                          ].map((menuItem) => (
                            <MenuItem key={menuItem.label} value={menuItem.value}>
                              {menuItem.label}
                            </MenuItem>
                          ))}
                        </TextField>
                        <Button onClick={() => setShowVolatilityFootnote(!showVolatilityFootnote)}>
                          {`${showVolatilityFootnote ? 'Hide' : 'Show'} footnote`}
                        </Button>
                      </div>
                      <AnimateHeight duration={300} height={showVolatilityFootnote ? 'auto' : 0}>
                        <div className="volatility-input-footnote">
                          <span>Volatility footnote&nbsp;</span>
                          <span>will appear in client&apos;s report. </span>
                          <TextField
                            value={userData?.footnoteData?.volPercentFn || ''}
                            onChange={(e) => setUserData((prev) => (
                              { ...prev, footnoteData: { ...prev.footnoteData, volPercentFn: e.target.value } }
                            ))}
                            onBlur={() => saveFootnotes({ volPercentFn: userData?.footnoteData?.volPercentFn || '' }, 'volPercentFn')}
                            multiline
                            maxRows={5}
                            inputProps={{ maxLength: 1000 }}
                          />
                          <div className="footnote-characters-left">
                            {`${userData?.footnoteData?.volPercentFn ? userData?.footnoteData?.volPercentFn?.length : 0}/1000`}
                          </div>
                        </div>
                      </AnimateHeight>
                    </div>
                    <div className={`volatility-input-wrapper ${showBacksolveFootnote ? ' show-radius' : ''}`}>
                      <div className="volatility-input-top">
                        <TextField
                          label="Volatility for Backsolve (optional)"
                          value={userData.calcData?.methods.backsolve.manualTransactionDateVolatility || ''}
                          onChange={(e) => {
                            const newData = copy(userData);
                            newData.calcData.methods.backsolve.manualTransactionDateVolatility = e.target.value;
                            setUserData(newData);
                          }}
                          onBlur={(e) => {
                            const newData = copy(userData);
                            newData.calcData.methods.backsolve.manualTransactionDateVolatility =
                              e.target.value ? `${e.target.value.replaceAll('%', '')}%` : null;
                            setUserData(newData);
                            setCalcDataChanged(true);
                          }}
                        />
                        <TextField
                          label="Term for Backsolve (optional)"
                          value={userData.calcData?.methods.backsolve.manualTransactionDateTerm || ''}
                          onChange={(e) => {
                            const newData = copy(userData);
                            newData.calcData.methods.backsolve.manualTransactionDateTerm = e.target.value;
                            setUserData(newData);
                          }}
                          onBlur={(e) => {
                            const newData = copy(userData);
                            let formattedNum = new Intl.NumberFormat('en-US').format(e.target.value.replace(/[^\d.]/g, '').trim());
                            if (parseFloat(formattedNum) > 10) formattedNum = '10';
                            newData.calcData.methods.backsolve.manualTransactionDateTerm = formattedNum !== '0' || !formattedNum ?
                              `${formattedNum} year${parseFloat(formattedNum) > 1 ? 's' : ''}` :
                              null;
                            setUserData(newData);
                            setCalcDataChanged(true);
                          }}
                        />
                        <Button onClick={() => setShowBacksolveFootnote(!showBacksolveFootnote)}>
                          {`${showBacksolveFootnote ? 'Hide' : 'Show'} footnote`}
                        </Button>
                      </div>
                      <AnimateHeight duration={300} height={showBacksolveFootnote ? 'auto' : 0}>
                        <div className="volatility-input-footnote">
                          <span>Transaction date volatility for Backsolve footnote&nbsp;</span>
                          <span>will appear in client&apos;s report. </span>
                          <TextField
                            value={userData?.footnoteData?.tdVolatilityBacksolve || ''}
                            onChange={(e) => setUserData((prev) => ({
                              ...prev, footnoteData: { ...prev.footnoteData, tdVolatilityBacksolve: e.target.value },
                            }))}
                            onBlur={() => saveFootnotes({ tdVolatilityBacksolve: userData?.footnoteData?.tdVolatilityBacksolve || '' })}
                            multiline
                            maxRows={5}
                            inputProps={{ maxLength: 1000 }}
                          />
                          <div className="footnote-characters-left">
                            {`${userData?.footnoteData?.tdVolatilityBacksolve ? userData?.footnoteData?.tdVolatilityBacksolve?.length : 0}/1000`}
                          </div>
                        </div>
                      </AnimateHeight>
                    </div>
                  </div>
                </div>
              )}
            </>
          ) : (
            <div className="header-info-block">
              <h4>
                <SelectAllIcon />
                Industries, comps & equity
              </h4>
              <p>
                <span>Unlock this tab&nbsp;</span>
                - click &apos;check for missing information&apos;, then:
              </p>
              <ul>
                <li>
                  if all necessary information has been filled in on the &apos;820 inputs&apos; tab,
                  like the transaction date and valuation date, this tab will unlock.
                </li>
                <li>
                  if you haven&apos;t submitted all necessary information, you&apos;ll be redirected to where all missing information is located.
                  All required information will be highlighted for your convenience.
                </li>
              </ul>
              <Button
                className="reroute-btn"
                onClick={() => {
                  setCheckForMissingCompInputs(true);
                  setTabToView(2);
                }}
              >
                Check for missing information now
              </Button>
            </div>
          )}
        {!!transactionData.basicInfo?.valuationDate &&
          !!transactionData.basicInfo?.transactionDate &&
          !!transactionData.basicInfo.financialStatementDate ?
          (
            <div className="industries-select-wrapper">
              <div className="add-companies-view">
                <div className="add-companies-description">
                  <h6>Add companies</h6>
                </div>
                <div className="submit-btns-container">
                  <p>
                    Click &apos;Add industries&apos; to add pre-curated industry list of companies.
                    To add individual companies or to add companies in bulk, click &apos;Add companies&apos;.
                  </p>
                  <Button
                    className="industries-btn"
                    onClick={() => {
                      setIndustryError(false);
                      setShowIndustriesModal(true);
                    }}
                  >
                    <AddBusinessOutlinedIcon />
                    Add industries
                  </Button>
                  <Button
                    className="submit-btn"
                    onClick={() => {
                      setIndustryError(false);
                      setShowAddCompModal(true);
                    }}
                  >
                    <AddIcon />
                    Add companies
                  </Button>
                </div>
              </div>
            </div>
          ) : <p className="no-comps-to-show">Company and industry info will show here.</p>}
        {showComps && !!valuationDate && !!transactionDate && !!financialStatementDate && (
          <>
            <div className="comps-wrapper">
              <div className="comps-header">
                <div className="comps-header-text">
                  <div>
                    {tabToView === 5 && <h6>Select volatility guideline companies</h6>}
                    {tabToView === 6 && <h6>Select comparable companies</h6>}
                    <p>
                      Click the checkbox next to a company&apos;s name to select and deselect individual companies.
                      To select or deselect all, click the checkbox in the Company list header.
                    </p>
                  </div>
                  {identifyingOutliersSuccess ? (
                    <div className="outlier-status">
                      <CheckCircleOutlineIcon />
                      Outliers flagged
                    </div>
                  ) : identifyingOutliers ? (
                    <div className="outlier-status">
                      <span className="dots-circle-spinner" />
                      Checking for outliers
                    </div>
                  ) : (
                    <Tooltip
                      title={(
                        <>
                          This will override any currently selected outliers.
                          <br />
                          It will automatically flag outliers using the volatility&apos;s
                          <br />
                          Inter-Quartial (IQR) method.
                          <br />
                          To read more about IQR,&nbsp;
                          <a className="iqr-link" target="_blank" rel="noopener noreferrer" href="https://en.wikipedia.org/wiki/Interquartile_range">
                            click here
                          </a>
                          .
                        </>
                      )}
                      PopperProps={{ className: 'bottom-arrow-tooltip iqr-tooltip' }}
                      placement="top"
                      arrow
                    >
                      <Button onClick={() => identifyOutliers()}>
                        <FlagIcon />
                        Check for outliers
                      </Button>
                    </Tooltip>
                  )}
                </div>
                {/* <div className="industry-tooltip">
                  <LightbulbOutlinedIcon />
                  <div>
                    We&apos;ve used a lot of abbreviations.
                    <br />
                    <Button className="tooltip-link">Click here</Button>
                    to see what they stand for.
                  </div>
                </div> */}
              </div>
              <FilterMenus
                tabToView={tabToView}
                setFilterSelected={setFilterSelected}
                growthFilterOptions={growthFilterOptions}
                multiplesFilterOptions={multiplesFilterOptions}
                yearsFilterOptions={yearsFilterOptions}
                fiscalPeriodFilterOptions={fiscalPeriodFilterOptions}
                filters={filters}
                setFilters={setFilters}
                filterValues={filterValues}
                setFilterValues={setFilterValues}
                descriptionsToShow={descriptionsToShow}
                setDescriptionsToShow={setDescriptionsToShow}
              />
              <CompTable
                tabToView={tabToView}
                compData={compData}
                setCompData={setCompData}
                fiscalYears={fiscalYears}
                filterSelected={filterSelected}
                filters={filters}
                filterValues={filterValues}
                setShowAddCompModal={setShowAddCompModal}
                headerScroll={headerScroll}
                subCompScroll={subCompScroll}
                rightCompXScroll={rightCompXScroll}
                currentCompRowWidth={currentCompRowWidth}
                overlayScrollbarRef={overlayScrollbarRef}
                rightCompXScrollRef={rightCompXScrollRef}
                rightCompYScroll={rightCompYScroll}
                rightCompYScrollRef={rightCompYScrollRef}
                valuationDate={moment(valuationDate, 'YYYY-MM-DD').format('MM/DD/YYYY')}
                transactionDate={moment(transactionDate, 'YYYY-MM-DD').format('MM/DD/YYYY')}
                financialStatementDate={financialStatementDate}
                // eslint-disable-next-line
                reSummarize={reSummarize}
                runningComps={runningComps}
                descriptionsToShow={descriptionsToShow}
                setDescriptionsToShow={setDescriptionsToShow}
                descriptionsRef={descriptionsRef}
                checkboxesRef={checkboxesRef}
              />
              <SummariesTable
                tabToView={tabToView}
                compData={compData}
                summariesData={summariesData}
                fiscalYears={fiscalYears}
                reSummarizing={reSummarizing || removingIndustry}
                filterSelected={filterSelected}
                filters={filters}
                filterValues={filterValues}
                summariesScrollRef={summariesScrollRef}
                valuationDate={moment(valuationDate, 'YYYY-MM-DD').format('MM/DD/YYYY')}
                transactionDate={moment(transactionDate, 'YYYY-MM-DD').format('MM/DD/YYYY')}
                financialStatementDate={financialStatementDate}
              />
            </div>
            {tabToView === 6 && <EquityInputs userData={userData} setUserData={setUserData} setSelectedGridValue={setSelectedGridValue} />}
          </>
        )}
        <AddCompModal
          showAddCompModal={showAddCompModal}
          setShowAddCompModal={setShowAddCompModal}
          showIndustriesModal={showIndustriesModal}
          setShowIndustriesModal={setShowIndustriesModal}
          runningComps={runningComps}
          compsToAdd={compsToAdd}
          setCompsToAdd={setCompsToAdd}
          setShowRunningCompSnackbar={setShowRunningCompSnackbar}
          runComps={() => runComps()}
          setIndustryError={setIndustryError}
          industryError={industryError}
          userData={userData}
          industries={industries}
          setIndustries={setIndustries}
          searchIndustryInput={searchIndustryInput}
          setSearchIndustryInput={setSearchIndustryInput}
          currentIndustries={currentIndustries}
          // eslint-disable-next-line
          removeIndustryFromComp={removeIndustryFromComp}
          refreshComps={() => refreshComps()}
          compData={compData}
          showWaitLongerMsg={showWaitLongerMsg}
          setShowWaitLongerMsg={setShowWaitLongerMsg}
        />
        <RefreshCompsModal
          showRefreshCompsModal={showRefreshCompsModal}
          setShowRefreshCompsModal={setShowRefreshCompsModal}
          refreshComps={() => refreshComps()}
        />
      </div>
    );
  }
}

Comps.propTypes = {
  userData: PropTypes.object.isRequired,
  tabToView: PropTypes.number.isRequired,
  setTabToView: PropTypes.func.isRequired,
  setUserData: PropTypes.func.isRequired,
  runningComps: PropTypes.bool.isRequired,
  setRunningComps: PropTypes.func.isRequired,
  setShowRunningCompSnackbar: PropTypes.func.isRequired,
  setCompSuccess: PropTypes.func.isRequired,
  setCheckForMissingCompInputs: PropTypes.func.isRequired,
  setSelectedGridValue: PropTypes.func.isRequired,
};
