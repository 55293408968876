import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import moment from 'moment';
import AnimateHeight from 'react-animate-height';
import { v4 as uuidv4 } from 'uuid';

import {
  Autocomplete,
  Button,
  InputAdornment,
  TextField,
} from '@mui/material';

import RemoveOutlinedIcon from '@mui/icons-material/RemoveOutlined';
import ErrorIcon from '@mui/icons-material/Error';

import { getUserName } from '../utils/auth';

import './AddCompanyBlock.scss';

export default function AddCompanyBlock({
  projectCompanies,
  setProjectCompanies,
  company,
  index,
  companyNameErrorList,
  setCompanyNameErrorList,
  portfolioCompaniesNames,
  investor,
  setCompanyRowAdded,
  companyListRef,
  companiesInProject,
}) {
  const [companyName, setCompanyName] = useState(company.companyName || '');
  const [note, setNote] = useState(company?.note || '');

  useEffect(() => {
    const copyCompanyNameErrorList = [...companyNameErrorList];
    if (companyNameErrorList[index] === true) {
      if (companyName.length > 0) {
        copyCompanyNameErrorList[index] = false;
        setCompanyNameErrorList(copyCompanyNameErrorList);
      }
    }
    if (companyNameErrorList.every((error) => error === false)) {
      setCompanyNameErrorList(copyCompanyNameErrorList);
    }
  }, [companyNameErrorList, companyName, projectCompanies]);

  const updateCompany = (updatedCompany) => {
    const updatedCompanies = [...projectCompanies];
    updatedCompanies[index] = updatedCompany;
    setProjectCompanies(updatedCompanies);
  };

  const handleCompanyNameChange = (value) => {
    const updatedCompany = { ...company, companyName: value };
    setCompanyName(value);
    updateCompany(updatedCompany);
  };

  const handleNoteChange = (e) => {
    const { value } = e.target;
    const updatedCompany = {
      ...company,
      note: value,
      noteCreatedDatetime: moment().format('YYYY-MM-DD HH:mm:ss'),
      noteAuthor: investor ? `${getUserName()} (Investor)` : getUserName(),
    };
    setNote(value);
    updateCompany(updatedCompany);
  };

  const removeBlock = () => {
    setTimeout(() => {
      const updatedCompanies = projectCompanies.filter((comp) => comp.companyId !== company.companyId);
      setProjectCompanies(updatedCompanies);
    }, 300);
    if (companyNameErrorList[index] === true) {
      const copyNameErrorList = [...companyNameErrorList];
      copyNameErrorList[index] = false;
      setCompanyNameErrorList(copyNameErrorList);
    }
  };

  return (
    <AnimateHeight duration={300} height={projectCompanies[index] ? 'auto' : 0}>
      <div className="company-block">
        <div className="company-input">
          {portfolioCompaniesNames.length ? (
            <Autocomplete
              freeSolo
              options={portfolioCompaniesNames}
              getOptionLabel={(option) => option}
              onInputChange={(event, value) => handleCompanyNameChange(value)}
              value={companyName || ''}
              getOptionDisabled={(option) => {
                const chosenNames = projectCompanies.map((comp) => comp.companyName);
                const isDisabled = chosenNames.includes(option) || companiesInProject.includes(option);
                return isDisabled;
              }}
              className="add-company-block name"
              renderInput={(params) => (
                <TextField
                  placeholder="Company name"
                  // eslint-disable-next-line
                  {...params}
                  value={companyName || ''}
                  inputRef={companyListRef}
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: (
                      <>
                        <InputAdornment position="start">
                          {index + 1}
                          .
                        </InputAdornment>
                        {params.InputProps.startAdornment}
                      </>
                    ),
                  }}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') e.preventDefault();
                    if (e.key === 'Enter' && index === projectCompanies.length - 1) {
                      const newCompany = {
                        companyId: uuidv4(),
                        companyName: '',
                        capIqIdentifier: '',
                        note: '',
                        noteCreatedDatetime: moment().format('YYYY-MM-DD HH:mm:ss'),
                        noteAuthor: getUserName(),
                      };
                      setProjectCompanies([...projectCompanies, newCompany]);
                      setCompanyRowAdded(true);
                    }
                  }}
                  error={companyNameErrorList[index]}
                  helperText={companyNameErrorList[index] ? (
                    <>
                      <ErrorIcon />
                      Please enter company name
                    </>
                  ) : ' '}
                />
              )}
            />
          ) : (
            <TextField
              className="add-company-block name"
              placeholder="Company name"
              value={companyName || ''}
              onChange={(e) => handleCompanyNameChange(e.target.value)}
              InputProps={{
                startAdornment: <InputAdornment position="start">{index + 1}</InputAdornment>,
              }}
              error={companyNameErrorList[index]}
              helperText={companyNameErrorList[index] ? (
                <>
                  <ErrorIcon />
                  Please enter company name
                </>
              ) : ' '}
            />
          )}
          <TextField
            multiline
            maxRows={4}
            className="add-company-block note"
            placeholder="Type a note, for communication & tracking (optional)"
            value={note || ''}
            onChange={handleNoteChange}
            inputProps={{ maxLength: 750 }}
            helperText={(
              <span>{`${note.length}/750`}</span>
            )}
          />
          <div className="remove-company">
            <Button
              className="remove-company-btn"
              onClick={removeBlock}
            >
              <RemoveOutlinedIcon />
              Remove
            </Button>
          </div>
        </div>
      </div>
    </AnimateHeight>
  );
}

AddCompanyBlock.propTypes = {
  projectCompanies: PropTypes.array.isRequired,
  setProjectCompanies: PropTypes.func.isRequired,
  company: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  companyNameErrorList: PropTypes.array.isRequired,
  setCompanyNameErrorList: PropTypes.func.isRequired,
  investor: PropTypes.bool,
  portfolioCompaniesNames: PropTypes.array,
  setCompanyRowAdded: PropTypes.func.isRequired,
  companyListRef: PropTypes.object.isRequired,
  companiesInProject: PropTypes.array,
};

AddCompanyBlock.defaultProps = {
  portfolioCompaniesNames: [],
  investor: false,
  companiesInProject: [],
};
