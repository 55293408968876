import React from 'react';
import PropTypes from 'prop-types';
import {
  Page,
  View,
  Text,
} from '@react-pdf/renderer';
import moment from 'moment';

import FlagIcon from '../../assets/icons/FlagIcon';

import TablePageHeader from '../../components/TablePageHeader';
import BottomPagination from '../../components/BottomPagination';
import DraftOverlay from '../../components/DraftOverlay';

export default function MarketEquityAdjustment({ reportData, totalPageCount, exhibitNumber }) {
  const tableData = reportData.MarketEquityAdjustment;
  const footnote = '';
  const selectedME = tableData.selectedMarketEquityAdjustment;
  const table1Headers = tableData.tableHeaders;

  function returnCellData(dataName, rowIndex, pageIndex) {
    if (dataName === 'Company name') return tableData.Name[rowIndex + (pageIndex * 37)];
    if (dataName === 'Ticker') return tableData.Ticker[rowIndex + (pageIndex * 37)];
    let valToReturn = null;
    if (dataName.includes('TD')) {
      const transactionDate = dataName.replace(/TD/g, '');
      const transactionDateFormatted = moment(transactionDate, 'MMMM DD, YYYY').format('MM/DD/YYYY');
      valToReturn = tableData[`Market Capitalization | Transaction Date - ${transactionDateFormatted}`][rowIndex + (pageIndex * 37)];
    }
    if (dataName.includes('VD')) {
      const valuationDate = dataName.replace(/VD/g, '');
      const valuationDateFormatted = moment(valuationDate, 'MMMM DD, YYYY').format('MM/DD/YYYY');
      valToReturn = tableData[`Market Capitalization | Valuation Date - ${valuationDateFormatted}`][rowIndex + (pageIndex * 37)];
    }
    if (dataName === 'Market Growth') {
      const marketGrowthColumn = tableData['Market Growth | Transaction Date to Valuation Date'][rowIndex + (pageIndex * 37)];
      const marketGrowthValue = parseFloat(marketGrowthColumn);
      const formattedMarketGrowth = Number.isNaN(marketGrowthValue) ?
        'N/A' :
        `${(marketGrowthValue * 100).toFixed(1).replace(/\d(?=(\d{3})+\.)/g, '$&,')}%`;
      return formattedMarketGrowth;
    }
    return valToReturn ? `$${(valToReturn).toFixed(1).replace(/\d(?=(\d{3})+\.)/g, '$&,')}` : 'N/A';
  }

  function returnSummaryData(dataName, rowIndex) {
    let valToReturn;
    if (dataName.includes('TD')) {
      const transactionDate = dataName.replace(/TD/g, '');
      const transactionDateFormatted = moment(transactionDate, 'MMMM DD, YYYY').format('MM/DD/YYYY');
      valToReturn = tableData[`Market Capitalization | Transaction Date - ${transactionDateFormatted} Summary`][rowIndex];
    }
    if (dataName.includes('VD')) {
      const valuationDate = dataName.replace(/VD/g, '');
      const valuationDateFormatted = moment(valuationDate, 'MMMM DD, YYYY').format('MM/DD/YYYY');
      valToReturn = tableData[`Market Capitalization | Valuation Date - ${valuationDateFormatted} Summary`][rowIndex];
    }
    if (dataName === 'Market Growth') {
      const marketGrowthColumn = tableData['Market Growth | Transaction Date to Valuation Date Summary'][rowIndex];
      const marketGrowthValue = parseFloat(marketGrowthColumn);
      const formattedMarketGrowth = Number.isNaN(marketGrowthValue) ?
        'N/A' :
        `${(marketGrowthValue * 100).toFixed(1).replace(/\d(?=(\d{3})+\.)/g, '$&,')}%`;
      return formattedMarketGrowth;
    }
    return valToReturn ? `$${(valToReturn).toFixed(1).replace(/\d(?=(\d{3})+\.)/g, '$&,')}` : 'N/A';
  }

  const numberOfPages = Math.floor((reportData.ValuationDateVolatility.volatilityObject.Name.length + 10) / 37) || 1;

  return (
    <>
      {[...Array(numberOfPages)].map((_, i) => i).map((pageNumber, pageIndex) => (
        <Page
          key={pageNumber}
          size="TABLOID"
          orientation="landscape"
          style={{ position: 'relative', backgroundColor: '#EAEAEB' }}
        >
          <TablePageHeader
            companyName={reportData.companyInfo.companyName}
            pageName={`Market Equity Adjustment, Industry   |   Exhibit ${exhibitNumber}`}
          />
          <View
            style={{
              marginTop: '84px',
              marginLeft: '32px',
              display: 'flex',
              flexDirection: 'column',
              width: '1160px',
              border: '1px',
              borderColor: '#AFB1B3',
            }}
          >
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                height: '24px',
                backgroundColor: '#0C2617',
              }}
            >
              <Text
                style={{
                  fontFamily: 'RobotoMed',
                  fontSize: '10px',
                  color: '#8AB9A4',
                  paddingLeft: '16px',
                  letterSpacing: '0.08px',
                  width: '60px',
                  paddingTop: '6px',
                  borderBottom: '1px',
                  borderColor: '#CCCCCC',
                  height: '24px',
                }}
              >
                (USD$)
              </Text>
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  height: '24px',
                  width: '385px',
                  marginRight: '8px',
                  paddingLeft: '6px',
                  borderBottom: '1px',
                  borderColor: '#CCCCCC',
                }}
              >
                <Text
                  style={{
                    fontFamily: 'RobotoMed',
                    fontSize: '10px',
                    color: '#8AB9A4',
                    letterSpacing: '0.08px',
                    paddingTop: '6px',
                    height: '24px',
                  }}
                >
                  (
                </Text>
                <View
                  style={{
                    height: '16px',
                    width: '12px',
                    paddingTop: '1px',
                  }}
                >
                  <FlagIcon fillColor="#98B8AA" />
                </View>
                <Text
                  style={{
                    fontFamily: 'RobotoMed',
                    fontSize: '10px',
                    color: '#8AB9A4',
                    letterSpacing: '0.08px',
                    marginRight: '8px',
                    paddingTop: '6px',
                    height: '24px',
                  }}
                >
                  = Outlier)
                </Text>
              </View>
              <Text
                style={{
                  fontFamily: 'RobotoMed',
                  fontSize: '10px',
                  color: '#E1E2EC',
                  paddingLeft: '16px',
                  letterSpacing: '0.08px',
                  width: '475px',
                  paddingTop: '6px',
                  borderBottom: '1px',
                  borderColor: '#CCCCCC',
                  height: '24px',
                  textAlign: 'center',
                }}
              >
                Market capitalization
              </Text>
              <View
                style={{
                  height: '24px',
                  width: '240px',
                  marginLeft: '8px',
                  paddingLeft: '6px',
                  borderBottom: '1px',
                  borderColor: '#CCCCCC',
                }}
              />
            </View>
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                height: '40px',
                backgroundColor: '#307157',
                borderBottom: '1px',
                borderColor: '#CCCCCC',
              }}
            >
              {table1Headers.map((dataName, index) => (
                <View key={dataName}>
                  {dataName === 'Outlier' ? (
                    <View
                      style={{
                        height: '16px',
                        width: '24px',
                        paddingLeft: '10px',
                        marginTop: 'auto',
                      }}
                    >
                      <FlagIcon fillColor="#98B8AA" />
                    </View>
                  ) : (
                    <Text
                      key={dataName}
                      style={{
                        fontFamily: 'RobotoMed',
                        fontSize: '10px',
                        color: '#E1E2EC',
                        width: index === 0 ? '20px' : index === 1 ? '200px' : '235px',
                        marginTop: 'auto',
                        paddingLeft: '16px',
                        paddingRight: '16px',
                        letterSpacing: '0.5px',
                        textAlign: index > 1 && 'right',
                        lineHeight: '0.55mm',
                      }}
                    >
                      {dataName.replace(/VD|TD/g, '')}
                    </Text>
                  )}
                </View>
              ))}
            </View>
            {tableData.Name.slice(pageIndex * 37, (pageIndex + 1) * 37).map((compName, rowIndex) => (
              <View
                key={compName}
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  height: '16px',
                  backgroundColor: `${rowIndex % 2 ? '#F0F0F3' : '#FAFAFD'}`,
                }}
              >
                {table1Headers.map((dataName, cellIndex) => (
                  <View key={dataName}>
                    {cellIndex === 0 ? (
                      <View
                        style={{
                          height: '16px',
                          width: '24px',
                          paddingLeft: '10px',
                          marginTop: '2px',
                        }}
                      >
                        {tableData.Outlier[rowIndex + (pageIndex * 37)] === 1 && (
                          <FlagIcon fillColor="#307157" />
                        )}
                      </View>
                    ) : (
                      <Text
                        key={dataName}
                        style={{
                          fontFamily: 'Roboto',
                          fontSize: '10px',
                          color: '#49454F',
                          width: cellIndex === 0 ? '20px' : cellIndex === 1 ? '200px' : '235px',
                          paddingLeft: '16px',
                          paddingRight: '16px',
                          letterSpacing: '0.4px',
                          textAlign: cellIndex > 1 && 'right',
                        }}
                      >
                        {returnCellData(dataName, rowIndex, pageIndex) || 'N/A'}
                      </Text>
                    )}
                  </View>
                ))}
              </View>
            ))}
          </View>
          {(pageIndex === numberOfPages - 1) && (tableData.Name.slice(pageIndex * 37, (pageIndex + 1) * 37).length <= 24) && (
            <>
              <View
                style={{
                  marginTop: '16px',
                  marginLeft: '32px',
                  display: 'flex',
                  flexDirection: 'column',
                  width: '1160px',
                  border: '1px',
                  borderColor: '#AFB1B3',
                }}
              >
                {['Maximum', '95th percentile', '90th percentile',
                  '75th percentile', 'Median', '25th percentile',
                  '10th percentile', '5th percentile', 'Minimum', 'Average',
                ].map((rowName, rowIndex) => (
                  <View
                    key={rowName}
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      height: '16px',
                      backgroundColor: `${rowIndex % 2 ? '#F0F0F3' : '#FAFAFD'}`,
                    }}
                  >
                    {table1Headers.map((dataName, cellIndex) => (
                      <Text
                        key={dataName}
                        style={{
                          fontFamily: cellIndex === 0 ? 'RobotoMed' : 'Roboto',
                          fontSize: '10px',
                          color: '#49454F',
                          width: cellIndex === 0 ? '200px' : '245px',
                          display: cellIndex === 1 ? 'none' : 'block',
                          paddingLeft: '16px',
                          paddingRight: '16px',
                          letterSpacing: '0.4px',
                          textAlign: cellIndex !== 0 && 'right',
                        }}
                      >
                        {cellIndex === 0 ? rowName : returnSummaryData(dataName, rowIndex)}
                      </Text>
                    ))}
                  </View>
                ))}
              </View>
              <View
                style={{
                  width: '1160px',
                  marginTop: '16px',
                  marginLeft: '32px',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                  height: '24px',
                  backgroundColor: '#9ECEBB',
                }}
              >
                <Text
                  style={{
                    fontFamily: 'RobotoMed',
                    fontSize: '14px',
                    color: '#49454F',
                    paddingRight: '16px',
                    letterSpacing: '0.08px',
                  }}
                >
                  Selected Market Equity Adjustment:&nbsp;
                  <Text
                    style={{
                      fontFamily: 'RobotoBold',
                      fontSize: '16px',
                      color: '#000000',
                      letterSpacing: '0.1px',
                    }}
                  >
                    {selectedME}
                    %
                  </Text>
                  <Text
                    style={{
                      color: '#000',
                      fontFamily: 'RobotoBold',
                      fontSize: '14px',
                      fontStyle: 'normal',
                      fontWeight: '600',
                      lineHeight: '20px',
                      letterSpacing: '0.075px',
                    }}
                  >
                    :
                    {selectedME}
                  </Text>
                </Text>
              </View>
            </>
          )}
          <BottomPagination
            pageNumber={totalPageCount + pageIndex}
            reportData={reportData}
          />
          {reportData.isDraft && <DraftOverlay />}
        </Page>
      ))}
      {tableData.Name.slice((numberOfPages - 1) * 37, numberOfPages * 37).length > 24 && (
        <Page
          size="TABLOID"
          orientation="landscape"
          style={{ position: 'relative', backgroundColor: '#EAEAEB' }}
        >
          <TablePageHeader
            companyName={reportData.companyInfo.companyName}
            pageName={`Market Equity Adjustment, Industry   |   Exhibit ${exhibitNumber}`}
          />
          <View
            style={{
              marginTop: '84px',
              marginLeft: '32px',
              display: 'flex',
              flexDirection: 'column',
              width: '1160px',
              border: '1px',
              borderColor: '#AFB1B3',
            }}
          >
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end',
                height: '40px',
                backgroundColor: '#307157',
                borderBottom: '1px',
                borderColor: '#CCCCCC',
              }}
            >
              {table1Headers.map((dataName, index) => (
                <Text
                  key={dataName}
                  style={{
                    display: 'flex',
                    fontFamily: 'RobotoMed',
                    fontSize: '10px',
                    color: '#E1E2EC',
                    width: index > 2 ? '240px' : '0px',
                    marginTop: 'auto',
                    paddingLeft: index > 2 ? '16px' : '0px',
                    paddingRight: index > 2 ? '16px' : '0px',
                    letterSpacing: '0.5px',
                    textAlign: index > 2 && 'right',
                    lineHeight: '0.55mm',
                  }}
                >
                  {index > 2 && dataName.replace(/VD|TD/g, '')}
                </Text>
              ))}
            </View>
            {['Maximum', '95th percentile', '90th percentile',
              '75th percentile', 'Median', '25th percentile',
              '10th percentile', '5th percentile', 'Minimum', 'Average',
            ].map((rowName, rowIndex) => (
              <View
                key={rowName}
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  height: '16px',
                  backgroundColor: `${rowIndex % 2 ? '#F0F0F3' : '#FAFAFD'}`,
                }}
              >
                {table1Headers.map((dataName, cellIndex) => (
                  <Text
                    key={dataName}
                    style={{
                      fontFamily: cellIndex === 0 ? 'RobotoMed' : 'Roboto',
                      fontSize: '10px',
                      color: '#49454F',
                      width: cellIndex === 0 ? '200px' : '245px',
                      display: cellIndex === 1 ? 'none' : 'block',
                      paddingLeft: '16px',
                      paddingRight: '16px',
                      letterSpacing: '0.4px',
                      textAlign: cellIndex !== 0 && 'right',
                    }}
                  >
                    {cellIndex === 0 ? rowName : cellIndex === 1 ? '' : cellIndex === 2 ? '' : returnSummaryData(dataName, rowIndex)}
                  </Text>
                ))}
              </View>
            ))}
          </View>
          <View
            style={{
              width: '1160px',
              marginTop: '16px',
              marginLeft: '32px',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
              alignItems: 'center',
              height: '24px',
              backgroundColor: '#9ECEBB',
            }}
          >
            <Text
              style={{
                fontFamily: 'RobotoMed',
                fontSize: '14px',
                color: '#49454F',
                paddingRight: '16px',
                letterSpacing: '0.08px',
              }}
            >
              Selected Market Equity Adjustment:&nbsp;
              <Text
                style={{
                  fontFamily: 'RobotoBold',
                  fontSize: '16px',
                  color: '#000000',
                  letterSpacing: '0.1px',
                }}
              >
                {selectedME}
                %
              </Text>
            </Text>
          </View>
          {!!footnote && (
            <Text
              style={{
                fontFamily: 'Roboto',
                fontSize: '8px',
                color: '#49454F',
                letterSpacing: '0.4px',
                marginTop: '16px',
                marginLeft: '90px',
                width: '450px',
                lineHeight: '0.55mm',
              }}
            >
              Footnote:&nbsp;
              {footnote}
            </Text>
          )}
          <BottomPagination
            pageNumber={totalPageCount + numberOfPages}
            reportData={reportData}
          />
          {reportData.isDraft && <DraftOverlay />}
        </Page>
      )}
    </>
  );
}

MarketEquityAdjustment.propTypes = {
  reportData: PropTypes.object.isRequired,
  totalPageCount: PropTypes.number.isRequired,
  exhibitNumber: PropTypes.number.isRequired,
};
