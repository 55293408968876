import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import AnimateHeight from 'react-animate-height';

import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import CalculateOutlinedIcon from '@mui/icons-material/CalculateOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import { copy, concatCharacter, onlyNums, commaEvery3rdChar } from '../../../utils';
import { getUserId, getUserName } from '../../../utils/auth';

import useFetch from '../../../hooks/useFetch';

import './EquityInputs.scss';

export default function EquityInputs({ userData, setUserData, setSelectedGridValue }) {
  const [equityResults, setEquityResults] = useState({});
  const [equityRows, setEquityRows] = useState([{ rowId: 0 }]);
  const [rowsAdded, setRowsAdded] = useState(0);

  const [fiscalPeriodDropdownValues, setFiscalPeriodDropdownValues] = useState([]);
  const [kpiDropdownValues, setKpiDropdownValues] = useState([[]]);
  const [fiscalValueSelected, setFiscalValueSelected] = useState(null);

  const [inputWasChanged, setInputWasChanged] = useState(false);
  const [inputWasCleared, setInputWasCleared] = useState(false);

  const [showTip, setShowTip] = useState(false);

  const [showFootnoteHeights, setShowFootnoteHeights] = useState([]);

  const [calcInProgress, setCalcInProgress] = useState(false);

  const [, runASC820Request] = useFetch();


  const EBITDAFiscalYearValues = [
    {
      fiscalYear: 'Last fiscal year -1 (LFY-1)',
      variableName: 'lastFiscalYearEBITDAM1',
    },
    {
      fiscalYear: 'Last fiscal year (LFY)',
      variableName: 'lastFiscalYearEBITDA',
    },
    {
      fiscalYear: 'Last 12 months (LTM)',
      variableName: 'last12MonthsEBITDA',
    },
    {
      fiscalYear: 'Current fiscal year (CFY)',
      variableName: 'currentFiscalYearEBITDA',
    },
    {
      fiscalYear: 'Next 12 months (NTM)',
      variableName: 'next12MonthsEBITDA',
    },
    {
      fiscalYear: 'Next fiscal year (NFY)',
      variableName: 'nextFiscalYearEBITDA',
    },
    {
      fiscalYear: 'Next fiscal year +1 (NFY+1)',
      variableName: 'nextFiscalYearEBITDAP1',
    },
    {
      fiscalYear: 'Next fiscal year +2 (NFY+2)',
      variableName: 'nextFiscalYearEBITDAP2',
    },
  ];

  const revenueFiscalYearValues = [
    {
      fiscalYear: 'Last fiscal year -1 (LFY-1)',
      variableName: 'lastFiscalYearRevenueM1',
    },
    {
      fiscalYear: 'Last fiscal year (LFY)',
      variableName: 'lastFiscalYearRevenue',
    },
    {
      fiscalYear: 'Last 12 months (LTM)',
      variableName: 'last12MonthsRevenue',
    },
    {
      fiscalYear: 'Current fiscal year (CFY)',
      variableName: 'currentFiscalYearRevenue',
    },
    {
      fiscalYear: 'Next 12 months (NTM)',
      variableName: 'next12MonthsRevenue',
    },
    {
      fiscalYear: 'Next fiscal year (NFY)',
      variableName: 'nextFiscalYearRevenue',
    },
    {
      fiscalYear: 'Next fiscal year +1 (NFY+1)',
      variableName: 'nextFiscalYearRevenueP1',
    },
    {
      fiscalYear: 'Next fiscal year +2 (NFY+2)',
      variableName: 'nextFiscalYearRevenueP2',
    },
  ];

  function tryFloat(val, defaultFixed = 0) {
    try {
      // remove all string stuff ('%', 'x', ',')
      const cleanedVal = val.replace(/[^0-9.]/g, '');
      // if the cleaned value is empty, return empty string
      if (!cleanedVal) return '';
      // if the cleaned value is a number, try to float it
      if (!Number.isNaN(parseFloat(cleanedVal))) return parseFloat(cleanedVal).toFixed(defaultFixed);
      return val;
    }
    catch (e) {
      return val;
    }
  }

  function getVariableName(fiscalYear, kpi) {
    let variableName = '';
    if (kpi === 'EBITDA') variableName = EBITDAFiscalYearValues.find((el) => el.fiscalYear === fiscalYear).variableName;
    if (kpi === 'Revenue') variableName = revenueFiscalYearValues.find((el) => el.fiscalYear === fiscalYear).variableName;
    return variableName;
  }

  const [, calculateSubMethodsRequest] = useFetch();
  const [, updateCalcObjectRequest] = useFetch();
  const [, saveFootnotesRequest] = useFetch();

  function calculateSubMethods() {
    const equityRowsData = copy(equityRows);
    (equityRows).forEach((el, rowIndex) => {
      const variableName = getVariableName(el.period, el.kpi);
      equityRowsData[rowIndex].kpiValue = userData.transactionData.enterpriseFinancialInfo?.[variableName]?.value || null;
      equityRowsData[rowIndex].weight = equityRowsData[rowIndex].weight ? equityRowsData[rowIndex].weight.replaceAll('%', '') : '';
      equityRowsData[rowIndex].multiple = equityRowsData[rowIndex].multiple ? equityRowsData[rowIndex].multiple.replaceAll('x', '') : '';
    });
    if (equityRowsData.some((el) => el.kpiValue === null)) {
      setEquityResults({
        concludedEnterpriseValue: '',
        concludedEquityValue: '',
        totalWeight: '',
      });
      return;
    }
    const cash = parseInt(userData.transactionData.clientFinancialInfo.currentCash?.value.replaceAll(',', ''), 10) || 0;
    const convertibleDebt = parseInt(userData.transactionData.clientFinancialInfo.convertibleDebt?.value.replaceAll(',', ''), 10) || 0;
    const nonConvertibleDebt = parseInt(userData.transactionData.clientFinancialInfo.nonConvertibleDebt?.value.replaceAll(',', ''), 10) || 0;
    const combinedDebt = convertibleDebt + nonConvertibleDebt;
    const totalDebt = parseInt(userData.transactionData.clientFinancialInfo.totalDebt?.value.replaceAll(',', ''), 10) || combinedDebt;
    const subMethodData = {
      method: 'pubco',
      cash,
      totalDebt,
      subMethods: equityRowsData,
    };
    calculateSubMethodsRequest({
      url: '/calc-engine/calculate-sub-methods',
      method: 'post',
      body: subMethodData,
      bodyIds: ['enterpriseCompanyId', 'requestUserId'],
      onSuccess: (responseData) => {
        const equityRowsCopy = [...equityRows];
        equityRows.forEach((el, rowIndex) => {
          equityRowsCopy[rowIndex].enterpriseValue = responseData.subMethods[rowIndex].enterpriseValue || '';
          equityRowsCopy[rowIndex].weightedEnterpriseValue = responseData.subMethods[rowIndex].weightedEnterpriseValue || '';
        });
        setEquityRows(equityRowsCopy);
        setEquityResults({
          concludedEnterpriseValue: responseData.concludedEnterpriseValue,
          concludedEquityValue: responseData.concludedEquityValue,
          totalWeight: responseData.totalWeight,
        });
        if (responseData.totalWeight === 100) setShowTip(true);
        const newCalcData = copy(userData.calcData);
        newCalcData.methods.pubCo.enterpriseValue = responseData.concludedEnterpriseValue;
        newCalcData.methods.pubCo.equityValue = responseData.concludedEquityValue;
        newCalcData.methods.pubCo.subMethods = responseData.subMethods;
        updateCalcObjectRequest({
          url: '/calc-engine/update-calc-engine-object',
          method: 'post',
          body: newCalcData,
        });
        setUserData({ ...userData, calcData: newCalcData });
      },
    });
  }

  function runASC820() {
    setCalcInProgress(true);
    const calcDataCopy = copy(userData.calcData);
    calcDataCopy.limits.volatility.calculateRange = 1;
    calcDataCopy.limits.term.calculateRange = 1;
    calcDataCopy.limits.term.selectedTerm = new Intl.NumberFormat('en-US', { minimumFractionDigits: 1 })
      .format(userData.calcData.limits.term.selectedTerm.replace(/[^\d.]/g, '').trim());
    calcDataCopy.methods.backsolve.marketAdjustment = parseFloat(calcDataCopy.methods.backsolve.marketAdjustment.replace('%', ''));
    calcDataCopy.methods.backsolve.weight = calcDataCopy.methods.backsolve.weight ?
      parseFloat(calcDataCopy.methods.backsolve.weight.replace('%', '')) : 100;
    calcDataCopy.methods.pubCo.weight = calcDataCopy.methods.pubCo.weight ? parseFloat(calcDataCopy.methods.pubCo.weight.replace('%', '')) : 0;
    calcDataCopy.methods.mna.weight = calcDataCopy.methods.mna.weight ? parseFloat(calcDataCopy.methods.mna.weight.replace('%', '')) : 0;
    if (!calcDataCopy.limits.term.lowerBuffer) calcDataCopy.limits.term.lowerBuffer = '0';
    updateCalcObjectRequest({
      url: '/calc-engine/update-calc-engine-object',
      method: 'post',
      body: calcDataCopy,
      onError: () => setCalcInProgress(false),
      onSuccess: () => {
        const methodDBData = {
          portfolioCompanyId: userData.metaData.portfolioCompanyId,
          transactionId: userData.metaData.transactionId,
          methods: ['backsolve', 'pubco', 'mna'],
        };
        runASC820Request({
          url: '/calcEngine/run_asc820',
          method: 'post',
          body: methodDBData,
          bodyIds: ['enterpriseCompanyId', 'requestUserId'],
          calcEngineCall: true,
          onFinally: () => setCalcInProgress(false),
          onSuccess: (responseData) => {
            const updatedCalcObject = responseData.calcObject;
            const selectedTerm = parseFloat(updatedCalcObject.limits.term.selectedTerm);
            updatedCalcObject.limits.term.selectedTerm = `${selectedTerm} year${selectedTerm > 1 ? 's' : ''}`;
            updatedCalcObject.methods.backsolve.marketAdjustment = `${updatedCalcObject.methods.backsolve.marketAdjustment}%`;
            setUserData({ ...userData, calcData: updatedCalcObject, gridData: responseData });
            setSelectedGridValue({
              term: parseFloat(responseData.defaultScenarios?.middleTerm),
              volatility: responseData.defaultScenarios?.middleVolatility.toFixed(1),
            });
          },
        });
      },
    });
  }

  function getKpiOptions(fiscalVal, rowIndex) {
    const kpiOptions = [];
    const revenueVariableName = revenueFiscalYearValues.find((el) => el.fiscalYear === fiscalVal)?.variableName;
    if (userData.transactionData.enterpriseFinancialInfo[revenueVariableName]?.value &&
      !(equityRows.some((equityRow, index) => equityRow.period === fiscalVal && equityRow.kpi === 'Revenue' && index !== rowIndex))) {
      kpiOptions.push('Revenue');
    }
    const EBITDAVariableName = EBITDAFiscalYearValues.find((el) => el.fiscalYear === fiscalVal)?.variableName;
    const ebitdaValue = userData.transactionData.enterpriseFinancialInfo[EBITDAVariableName]?.value;
    if (ebitdaValue && parseFloat(ebitdaValue) > 0 &&
      !(equityRows.some((equityRow, index) => equityRow.period === fiscalVal && equityRow.kpi === 'EBITDA' && index !== rowIndex))) {
      kpiOptions.push('EBITDA');
    }
    return kpiOptions;
  }

  function saveFootnotes(rowIdx, type) {
    const dataCopy = copy(userData);
    const row = equityRows[rowIdx];
    const methodFootnotes = dataCopy?.footnoteData?.methodsFootnotes || [];
    const methodCheck = methodFootnotes.find((r) => r.period === row.period && r.kpi === row.kpi);

    const footnoteData = {
      editorId: getUserId(),
      editor: getUserName(),
      kpi: row.kpi || methodCheck.kpi,
      period: row.period || methodCheck.period,
      weight: row.weight || methodCheck.weight,
      footnote: row.footnote || methodCheck.footnote,
    };

    const existingFootnoteIndex = methodFootnotes.findIndex((fn) => fn.period === footnoteData.period && fn.kpi === footnoteData.kpi);
    if (existingFootnoteIndex !== -1) methodFootnotes[existingFootnoteIndex] = { ...footnoteData };
    else methodFootnotes.push({ ...footnoteData });
    dataCopy.footnoteData.methodsFootnotes = [...methodFootnotes];

    const footnotePayload = {
      dataName: type,
      payload: {
        methodsFootnotes: dataCopy.footnoteData.methodsFootnotes,
      },
    };
    saveFootnotesRequest({
      url: '/footnotes/save-footnotes',
      method: 'post',
      body: footnotePayload,
      bodyIds: ['companyId', 'enterpriseCompanyId', 'transactionId', 'portfolioCompanyId', 'requestUserId'],
      onSuccess: () => setUserData(dataCopy),
    });
  }

  useEffect(() => {
    if (inputWasChanged) {
      if (equityRows.every((equityInput) => (
        equityInput.period &&
        equityInput.kpi
      ))) {
        calculateSubMethods();
      }
    }
    setInputWasChanged(false);
  }, [inputWasChanged]);

  useEffect(() => {
    if (inputWasCleared) calculateSubMethods();
    setInputWasCleared(false);
  }, [inputWasCleared]);

  useEffect(() => {
    const newFiscalDropdownValues = [];
    EBITDAFiscalYearValues.forEach((fiscalPeriodOption) => {
      const ebitdaValue = userData.transactionData.enterpriseFinancialInfo[fiscalPeriodOption.variableName]?.value;
      if (ebitdaValue && parseFloat(ebitdaValue) > 0) {
        if (!newFiscalDropdownValues.includes(fiscalPeriodOption.fiscalYear)) newFiscalDropdownValues.push(fiscalPeriodOption.fiscalYear);
      }
    });
    revenueFiscalYearValues.forEach((fiscalPeriodOption) => {
      if (userData.transactionData.enterpriseFinancialInfo[fiscalPeriodOption.variableName]?.value) {
        if (!newFiscalDropdownValues.includes(fiscalPeriodOption.fiscalYear)) newFiscalDropdownValues.push(fiscalPeriodOption.fiscalYear);
      }
    });
    setFiscalPeriodDropdownValues(newFiscalDropdownValues);
    if (userData.calcData?.methods?.pubCo?.subMethods?.length) {
      const newEquityValues = copy(userData.calcData.methods.pubCo.subMethods);
      let methodsFootnotes;
      if (userData.footnoteData?.methodsFootnotes) {
        methodsFootnotes = Object.values(copy(userData.footnoteData?.methodsFootnotes));
      }
      setEquityRows(newEquityValues.map((el, index) => {
        const footnote = methodsFootnotes ? methodsFootnotes.find((r) => r.period === el.period && r.kpi === el.kpi) : '';
        return {
          ...el,
          rowId: index + 1,
          weight: el.weight ? `${el.weight}%` : '',
          multiple: el.multiple ? `${el.multiple}x` : '',
          footnote: footnote ? footnote.footnote : '',
        };
      }));
      setRowsAdded(newEquityValues.length + 1);
      const newKpiDropdownValues = [...kpiDropdownValues];
      newEquityValues.forEach((el, rowIndex) => { newKpiDropdownValues[rowIndex] = getKpiOptions(el.period, rowIndex); });
      setKpiDropdownValues(newKpiDropdownValues);
      setInputWasChanged(true);
    }
    // set the total weight on load
    if (userData.calcData.methods.pubCo.subMethods?.length) {
      const subMethods = copy(userData.calcData.methods.pubCo.subMethods);
      let totalWeight = 0;
      subMethods.forEach((el) => { totalWeight += parseFloat(el.weight); });
      setEquityResults({ ...equityResults, totalWeight });
    }
  }, []);

  useEffect(() => {
    if (fiscalValueSelected) {
      const newKpiDropdownValues = [...kpiDropdownValues];
      newKpiDropdownValues[fiscalValueSelected.index] = getKpiOptions(fiscalValueSelected.value, fiscalValueSelected.index);
      setKpiDropdownValues(newKpiDropdownValues);
    }
    setFiscalValueSelected(null);
  }, [fiscalValueSelected]);

  return (
    <div className="EquityInputs">
      <div className="header-row">
        <h6>Calculate equity</h6>
        <p>
          Run scenarios and get results in real-time. Fiscal period data not looking right? Edit on &apos;820 input form&apos; tab.
          <br />
          You can run calc engine once total weight equals 100%.
        </p>
        <div className="vl" />
        <div className="value-block">
          <span>
            {equityResults.concludedEnterpriseValue && equityResults.concludedEnterpriseValue !== 'N/A' ?
              `$${commaEvery3rdChar(equityResults.concludedEnterpriseValue.toFixed(2))}` : '$'}
          </span>
          <span>
            Concluded
            <br />
            enterprise value
          </span>
        </div>
        <div className="value-block">
          <span>
            {equityResults.concludedEquityValue && equityResults.concludedEquityValue !== 'N/A' ?
              `$${commaEvery3rdChar(equityResults.concludedEquityValue.toFixed(2))}` : '$'}
          </span>
          <span>
            Concluded
            <br />
            equity value
          </span>
        </div>
        <div className="value-block">
          <span>
            {equityResults.totalWeight && equityResults.totalWeight !== 'N/A' ?
              `${commaEvery3rdChar(equityResults.totalWeight)}%` : '%'}
          </span>
          <span>
            Total weight
            <br />
            (must total 100%)
          </span>
        </div>
      </div>
      <AnimateHeight duration={600} height={(equityResults.totalWeight === 100 && showTip) ? 'auto' : 0}>
        <div className="run-calc-tip">
          <CheckCircleIcon />
          <p>
            Run calc engine now with system defaults, or head over to the Calculations tab and
            set values manually before running calc engine.
            <br />
            You don&apos;t need to rerun calc engine
            if all you&apos;re doing is adjusting weights, but weights must equal 100% to run the calc engine.
          </p>
          <Button
            className="run-calc-btn"
            onClick={() => { runASC820(); }}
          >
            {calcInProgress ? (
              <div className="dots-circle-spinner" />
            ) : (
              <CalculateOutlinedIcon />
            )}
            Run calc engine
          </Button>
          <Button
            className="close-tip-btn"
            onClick={() => setShowTip(!showTip)}
          >
            <CloseOutlinedIcon />
          </Button>
        </div>
      </AnimateHeight>
      <div className="equity-inputs-rows">
        {equityRows.map((equityRow, index) => {
          const variableName = getVariableName(equityRow.period, equityRow.kpi);
          return (
            <div className="equity-row" key={equityRow.rowId}>
              <div className="equity-row-title">
                <h6>{`Scenario ${index + 1}`}</h6>
                <Button
                  onClick={() => {
                    setShowFootnoteHeights(showFootnoteHeights.includes(index) ?
                      showFootnoteHeights.filter((item) => item !== index) : [...showFootnoteHeights, index]);
                  }}
                >
                  {showFootnoteHeights.includes(index) ? 'Hide ' : 'Show '}
                  footnote
                </Button>
              </div>
              <div className="inputs-row">
                <TextField
                  select
                  label="Fiscal period"
                  value={equityRow.period || ''}
                  onChange={(e) => {
                    const equityRowsCopy = [...equityRows];
                    equityRowsCopy[index].period = e.target.value;
                    equityRowsCopy[index].kpi = '';
                    setEquityRows(equityRowsCopy);
                  }}
                  SelectProps={{ MenuProps: { disableScrollLock: true, classes: { paper: 'select-dropdown fiscal-period' } } }}
                  onBlur={(e) => {
                    setInputWasChanged(true);
                    setFiscalValueSelected({ index, value: e.target.value });
                  }}
                >
                  {fiscalPeriodDropdownValues.map((menuItem) => (
                    <MenuItem
                      key={menuItem}
                      value={menuItem}
                      className={equityRows.slice(0, index).some((row) => row.period === menuItem &&
                        getKpiOptions(row.period, index).every((kpiOption) => equityRows.some((eRow) => eRow.period === menuItem &&
                          eRow.kpi === kpiOption))) ? ' hidden' : ''}
                    >
                      {menuItem}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  select
                  label="KPI"
                  value={equityRow.kpi || ''}
                  onChange={(e) => {
                    const equityRowsCopy = [...equityRows];
                    equityRowsCopy[index].kpi = e.target.value;
                    setEquityRows(equityRowsCopy);
                  }}
                  SelectProps={{ MenuProps: { disableScrollLock: true, classes: { paper: 'select-dropdown' } } }}
                  onBlur={() => setInputWasChanged(true)}
                >
                  {kpiDropdownValues[index].map((menuItem) => (
                    <MenuItem key={menuItem} value={menuItem}>
                      {menuItem}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  label="Multiple"
                  value={equityRow.multiple || ''}
                  onChange={(e) => {
                    const equityRowsCopy = [...equityRows];
                    equityRowsCopy[index].multiple = onlyNums(e.target.value);
                    setEquityRows(equityRowsCopy);
                  }}
                  onBlur={(e) => {
                    const equityRowsCopy = [...equityRows];
                    equityRowsCopy[index].multiple = concatCharacter(e.target.value, 'x');
                    setEquityRows(equityRowsCopy);
                    setInputWasChanged(true);
                  }}
                />
                <TextField
                  label="Weight"
                  value={equityRow.weight || ''}
                  onChange={(e) => {
                    const equityRowsCopy = [...equityRows];
                    equityRowsCopy[index].weight = onlyNums(e.target.value);
                    setEquityRows(equityRowsCopy);
                  }}
                  onBlur={(e) => {
                    const equityRowsCopy = [...equityRows];
                    equityRowsCopy[index].weight = concatCharacter(e.target.value, '%');
                    setEquityRows(equityRowsCopy);
                    setInputWasChanged(true);
                  }}
                />
                <div className="vl" />
                <div className={`value-block${!equityRow.kpi || !equityRow.period ? ' awaiting-input' : ''}`}>
                  {!equityRow.kpi || !equityRow.period ? (
                    <span>
                      Fiscal period data
                      <br />
                      will show here
                    </span>
                  ) : (
                    <>
                      <span>
                        {userData.transactionData.enterpriseFinancialInfo?.[variableName]?.value ?
                          `$${commaEvery3rdChar(userData.transactionData.enterpriseFinancialInfo?.[variableName]?.value)}` :
                          '-'}
                      </span>
                      <span>
                        {equityRow.period}
                        <br />
                        {equityRow.kpi}
                      </span>
                    </>
                  )}
                </div>
                <div className={`value-block${tryFloat(!equityRow.enterpriseValue) ? ' awaiting-input' : ''}`}>
                  {!equityRow.enterpriseValue ? (
                    <span>
                      Scenario&apos;s enterprise
                      <br />
                      will show here
                    </span>
                  ) : (
                    <>
                      <span>{`$${commaEvery3rdChar(tryFloat(equityRow.enterpriseValue, 2))}`}</span>
                      <span>Enterprise value</span>
                    </>
                  )}
                </div>
                <div className={`value-block${tryFloat(!equityRow.weightedEnterpriseValue) ? ' awaiting-input' : ''}`}>
                  {!equityRow.weightedEnterpriseValue ? (
                    <span>
                      Probability weighted
                      <br />
                      enterprise value
                      <br />
                      will show here
                    </span>
                  ) : (
                    <>
                      <span>{`$${commaEvery3rdChar(equityRow.weightedEnterpriseValue.toFixed(2))}`}</span>
                      <span>
                        Probability weighted
                        <br />
                        enterprise value
                      </span>
                    </>
                  )}
                </div>
                <IconButton
                  className="industry-add-btn"
                  onClick={() => {
                    setEquityRows([...equityRows, { rowId: rowsAdded + 1 }]);
                    setKpiDropdownValues([...kpiDropdownValues, []]);
                    setRowsAdded(rowsAdded + 1);
                  }}
                >
                  <AddCircleOutlineIcon />
                </IconButton>
                <IconButton onClick={() => {
                  if (equityRows.length === 1) {
                    setEquityRows([{ rowId: rowsAdded + 1 }]);
                    setKpiDropdownValues([[]]);
                    setRowsAdded(rowsAdded + 1);
                    setInputWasCleared(true);
                  } else {
                    setEquityRows([...equityRows.slice(0, index), ...equityRows.slice(index + 1)]);
                    setKpiDropdownValues([...kpiDropdownValues.slice(0, index), ...kpiDropdownValues.slice(index + 1)]);
                    setInputWasChanged(true);
                  }
                }}
                >
                  <RemoveCircleOutlineIcon />
                </IconButton>
              </div>
              <AnimateHeight duration={500} height={showFootnoteHeights.includes(index) ? 'auto' : 0}>
                <div className="footnote">
                  <div className="footnote-title">
                    <h6>{`Scenario ${index + 1} footnote`}</h6>
                    <p>The below text will automatically appear in the client&apos;s ASC 820 report.</p>
                  </div>
                  <TextField
                    value={equityRow.footnote || ''}
                    onChange={(e) => {
                      setEquityRows((prevRows) => {
                        const prevEquityRow = prevRows[index];
                        return equityRow.footnote === e.target.value ? prevRows :
                          [...prevRows.slice(0, index), { ...prevEquityRow, footnote: e.target.value }, ...prevRows.slice(index + 1)];
                      });
                    }}
                    onBlur={() => {
                      if (equityRow.kpi && equityRow.period && equityRow.multiple) {
                        saveFootnotes(index, `${equityRow?.period}-${equityRow?.kpi}`);
                      }
                    }}
                  />
                  <div className="footnote-characters-left">{`${equityRow.footnote?.length || 0}/1000`}</div>
                </div>
              </AnimateHeight>
            </div>
          );
        })}
      </div>
    </div>
  );
}

EquityInputs.propTypes = {
  userData: PropTypes.object.isRequired,
  setUserData: PropTypes.func.isRequired,
  setSelectedGridValue: PropTypes.func.isRequired,
};
