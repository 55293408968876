import moment from 'moment';
import { copy } from '../../../utils';

export function assignDefaultValues(tableSchema, rowID) {
  const defaultData = [];
  tableSchema.forEach((data, tableIndex) => {
    defaultData.push({
      ...data,
      ...(tableIndex === 0 && { rowID: `${data.name}+-+-${rowID}` }),
    });
  });
  return defaultData;
}

// TODO Make this better
export function allocateDBData(DBData, tableSchema, defaultTableValues, dataType) {
  const newData = [];
  Object.values(DBData).forEach((dbRowData, dbRowIndex) => {
    const newTableValues = copy(defaultTableValues);
    tableSchema.forEach((data, dataIndex) => {
      if (dataIndex === 0) {
        newTableValues[data.name].rowID = `${data.name}+-+-${dbRowIndex + 1}`;
        if (dataType !== 'options' && dataType !== 'warrants') {
          newTableValues[data.name].value =
            Object.keys(DBData)[dbRowIndex];
        } else if (dataType === 'options') {
          const newValue = Object.keys(DBData)[dbRowIndex].split(' Option @')[0];
          newTableValues[data.name].value = newValue;
          const newTypeValue = Object.values(DBData)[dbRowIndex].type;
          newTableValues[data.name].type = newTypeValue;
        } else {
          const newValue = Object.keys(DBData)[dbRowIndex].split(' Warrant @')[0];
          newTableValues[data.name].value = newValue;
          const newTypeValue = Object.values(DBData)[dbRowIndex].type;
          newTableValues[data.name].type = newTypeValue;
        }
      } else if (data.isDate) {
        newTableValues[data.name].value = dbRowData[data.name] ? moment(dbRowData[data.name], 'YYYY-MM-DD') : null;
      } else if (dataType === 'options' || dataType === 'warrants') {
        const outstandingValue = Object.values(DBData)[dbRowIndex].quantity;
        if (newTableValues[data.name].headTitle === 'Outstanding') {
          newTableValues[data.name].value = outstandingValue ? data.formatter(outstandingValue) : '';
        }
        const strikePriceValue = Object.values(DBData)[dbRowIndex].strikePrice;
        if (newTableValues[data.name].headTitle === 'Exercise Price') {
          newTableValues[data.name].value = strikePriceValue ? data.formatter(strikePriceValue) : '';
        }
      } else if (dataType === 'common' && data.name === 'outstanding') {
        newTableValues[data.name].value =
          (dbRowData.common && data.formatter(dbRowData.common)) || '';
      } else if (data.name === 'isDividends' || data.name === 'dividendCompounding') {
        newTableValues[data.name].value = data.formatter(dbRowData[data.name]);
      } else {
        newTableValues[data.name].value =
          (dbRowData[data.name] && (data.formatter ?
            data.formatter(dbRowData[data.name]) : dbRowData[data.name])) || '';
      }
    });
    newData.push(newTableValues);
  });
  return newData;
}


export function convertToDBData(tableData) {
  function appendDateIfDuplicate(value, rowIndex, keyName) {
    if (/\d{4}-(0?[1-9]|1[012])-(0?[1-9]|[12][0-9]|3[01])*/g.test(value)) {
      return value;
    }
    let instancesOfValue = 0;
    tableData.forEach((row) => {
      if (row[keyName].value === value) {
        instancesOfValue += 1;
      }
    });
    if (instancesOfValue >= 2) {
      return `${value}${tableData[rowIndex].transactionDate.value ? ` - ${tableData[rowIndex].transactionDate.value.format('YYYY-MM-DD')}` : ''}`;
    }
    return value;
  }
  function mapFrontendValuesToDBValues(row, rowIndex) {
    let mappedData;
    let keyName = '';
    let valuesToSave = null;
    Object.keys(row).forEach((key) => {
      if (row[key].rowID) {
        keyName = appendDateIfDuplicate(row[key].value, rowIndex, row[key].name);
      } else {
        if (!valuesToSave) valuesToSave = {};
        valuesToSave = {
          ...valuesToSave,
          [row[key].name]:
            (row[key].value && (row[key].dbFormat ? row[key].dbFormat(row[key].value) :
              row[key].value)) || null,
        };
      }
      mappedData = {
        ...mappedData,
        [keyName]: valuesToSave,
      };
    });
    return mappedData;
  }
  let dataToSend = null;
  tableData.forEach((tableRow, rowIndex) => {
    if ((tableRow.preferred) && (tableRow.preferred.value.length > 0)) {
      const rowToSend = mapFrontendValuesToDBValues(tableRow, rowIndex);
      dataToSend = {
        ...dataToSend,
        ...rowToSend,
      };
    } else if ((tableRow.safeConvertible) && (tableRow.safeConvertible.value.length > 0)) {
      const rowToSend = mapFrontendValuesToDBValues(tableRow, rowIndex);
      dataToSend = {
        ...dataToSend,
        ...rowToSend,
      };
    }
  });
  return dataToSend;
}

export function checkForErrorOnTable(tableData) {
  const errorsInTable = [];
  const transactionDateList = [];
  tableData.forEach((tableRow) => {
    const errorCheck = {};
    transactionDateList.push(tableRow.transactionDate.value);
    Object.keys(tableRow).forEach((column) => {
      if (
        ((tableRow.safeConvertible && tableRow.safeConvertible.value) ||
          (tableRow.preferred && tableRow.preferred.value))
      ) {
        if (
          tableRow[column].name !== 'participationCap' &&
          tableRow[column].name !== 'isDividends' &&
          tableRow[column].name !== 'dividendRate' &&
          tableRow[column].name !== 'dividendCompounding' &&
          tableRow[column].name !== 'preferred' &&
          tableRow[column].name !== 'participationRights' &&
          tableRow[column].name !== 'dividendExpirationDate' &&
          tableRow[column].name !== 'valuationCap' &&
          tableRow[column].name !== 'preMoneyPostMoney' &&
          tableRow[column].name !== 'interestRate' &&
          tableRow[column].name !== 'interestCompounding' &&
          tableRow[column].name !== 'maturityDate' &&
          tableRow[column].name !== 'transactionDate' &&
          !tableRow[column].value
        ) {
          errorCheck[column] = 0;
        }
        if (tableRow.isDividends) {
          if (tableRow.isDividends.value === 1) {
            if (!tableRow.dividendRate.value) errorCheck.dividendRate = 0;
          }
        }
        if (tableRow.participationRights) {
          if (tableRow.participationRights.value === 'capped') {
            if (!tableRow.participationCap.value) errorCheck.participationCap = 0;
          }
        }
      }
      if (tableRow.safeConvertible) {
        if (!tableRow.transactionDate.value && tableRow.safeConvertible.value) {
          errorCheck.transactionDate = 0;
        }
      }
    });
    errorsInTable.push(errorCheck);
  });
  const dateError = false;
  // TODO IMPLEMENT later
  // tableData.forEach((tableRow) => {
  //   if (
  //     ((tableRow.safeConvertible && tableRow.safeConvertible.value.length > 0) ||
  //       (tableRow.preferred && tableRow.preferred.value.length > 0))
  //   ) {
  //     if (!transactionDateList.some(value => value !== null)) {
  //       dateError = true;
  //     }
  //   }
  // })
  const response = {
    dateError,
    tableErrors: errorsInTable,
  };
  return response;
}

export default {
  assignDefaultValues,
  allocateDBData,
  convertToDBData,
};
