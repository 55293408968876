import { useState, useEffect } from 'react';
import { Storage } from 'aws-amplify';

import useAsync from './useAsync';

export default function useS3(givenRequest) {
  const [request, setRequest] = useState(givenRequest);

  const [response, setResponse] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [progress, setProgress] = useState(null);
  const [callEnded, setCallEnded] = useState(false);

  const defaultBucket = `${process.env.REACT_APP_CLIENT_FILES_BUCKET}-${process.env.REACT_APP_ENV_LABEL}`;

  const [, listRequest] = useAsync();
  const [, getRequest] = useAsync();
  const [, putRequest] = useAsync();
  const [, removeRequest] = useAsync();

  useEffect(() => {
    if (request?.requestType && !loading) {
      setCallEnded(false);
      setSuccess(false);
      setError(null);
      setResponse(null);

      setLoading(true);

      const s3Options = {
        bucket: request.bucket || defaultBucket,
        ...request.customPrefix && { customPrefix: { public: request.customPrefix } },
        ...request.contentDisposition && { contentDisposition: request.contentDisposition },
        ...request.requestType === 'list' && { pageSize: 'ALL' },
        ...request.requestType === 'put' && { progressCallback: setProgress },
      };

      const onError = () => setError(true);

      const onSuccess = (successResponse) => {
        setResponse(successResponse);
        setSuccess(true);
        if (request.onSuccess) request.onSuccess(successResponse);
      };

      if (request?.requestType === 'list') listRequest({ promise: () => Storage.list('', s3Options), onError, onSuccess });
      if (request?.requestType === 'get') getRequest({ promise: () => Storage.get(request.key, s3Options), onError, onSuccess });
      if (request?.requestType === 'put') putRequest({ promise: () => Storage.put(request.key, request.file, s3Options), onError, onSuccess });
      if (request?.requestType === 'remove') removeRequest({ promise: () => Storage.remove(request.key, s3Options), onError, onSuccess });
    }
  }, [request]);

  useEffect(() => { if (error && request?.onError) request.onError(); }, [error]);

  useEffect(() => setCallEnded(success || error), [success, error]);

  useEffect(() => {
    if (callEnded) {
      if (request.onFinally) request.onFinally();
      setRequest({});
      setLoading(false);
    }
  }, [callEnded]);

  return [{ response, loading, success, callEnded, progress }, setRequest];
}
