import React, { useState, useEffect, useContext } from 'react';

import LogRocket from 'logrocket';

import Dialog from '@mui/material/Dialog';

import RouterProvider from './Routes';

import NavBar from './components/Navigation/NavBar';
import AlertDisplay from './components/AlertDisplay';
import InactivityTimeout from './components/InactivityTimeout';

import { authenticationCheck, getAllAttributes } from './utils/auth';

import { UserContext, IsSigningOutContext } from './contexts';

import './App.scss';

function App() {
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const { isSigningOut } = useContext(IsSigningOutContext);
  const { userData, setUserData } = useContext(UserContext);

  async function checkCurrentAuthSession() {
    await authenticationCheck();
    setUserData({ ...userData, userAttributes: getAllAttributes() });
    setIsAuthenticated(true);
    setIsAuthenticating(false);
  }

  useEffect(() => { checkCurrentAuthSession(); }, []);

  const useLogRocket = process.env.REACT_APP_USE_LOG_ROCKET === 'true';

  if (useLogRocket && isAuthenticated) {
    LogRocket.init(process.env.REACT_APP_LOG_ROCKET_APP_ID);

    LogRocket.identify(userData.userAttributes.userId, {
      name: `${userData.userAttributes.firstName} ${userData.userAttributes.lastName}`,
      email: userData.userAttributes.email,
      companyName: userData.userAttributes.companyName,
      companyId: userData.userAttributes.companyId,
    });
  }

  if (!isAuthenticating && isAuthenticated) {
    return (
      <main className="App">
        <AlertDisplay />
        <NavBar />
        <RouterProvider />
        <InactivityTimeout />
        <Dialog className="loading-dialog" open={isSigningOut} disableScrollLock>
          <div className="dots-circle-spinner" />
        </Dialog>
      </main>
    );
  }
}

export default App;
