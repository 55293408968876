import React, { useState } from 'react';

import useFetch from '../../hooks/useFetch';

import EnterpriseProjects from '../EnterpriseProjects';
import SplashPage from './SplashPage';

import './index.scss';

export default function Home() {
  const [enterpriseProjects, setEnterpriseProjects] = useState(null);
  const [enterpriseUsers, setEnterpriseUsers] = useState(null);

  const [investorCompaniesList, setInvestorCompaniesList] = useState(null);
  const [portfolioCompaniesList, setPortfolioCompaniesList] = useState(null);
  const [investorCompaniesNames, setInvestorCompaniesNames] = useState(null);
  const [portfolioCompaniesNames, setPortfolioCompaniesNames] = useState(null);

  useFetch({
    url: '/homepages/enterprise/',
    urlIds: ['enterpriseCompanyId', 'userId'],
    onSuccess: setEnterpriseProjects,
  });

  useFetch({
    url: '/accounts/get-enterprise-users/',
    urlIds: ['enterpriseCompanyId', 'userId'],
    onSuccess: setEnterpriseUsers,
  });

  useFetch({
    url: '/companies/get_investor_and_portfolio_companies/',
    urlIds: ['enterpriseCompanyId', 'userId'],
    onSuccess: (responseData) => {
      setPortfolioCompaniesList(responseData.portfolioCompanies);
      setInvestorCompaniesList(responseData.investorCompanies);
      setPortfolioCompaniesNames(Object.keys(responseData.portfolioCompanies));
      setInvestorCompaniesNames(Object.keys(responseData.investorCompanies));
    },
  });

  if (!enterpriseProjects || !enterpriseUsers ||
    !investorCompaniesList || !portfolioCompaniesList ||
    !investorCompaniesNames || !portfolioCompaniesNames) {
    return <div className="data-loading-spinner"><div className="dots-circle-spinner" /></div>;
  }

  if (enterpriseProjects.length > 0) {
    return (
      <EnterpriseProjects
        enterpriseProjects={enterpriseProjects}
        setEnterpriseProjects={setEnterpriseProjects}
        enterpriseUsers={enterpriseUsers}
        investorCompaniesList={investorCompaniesList}
        portfolioCompaniesList={portfolioCompaniesList}
        investorCompaniesNames={investorCompaniesNames}
        portfolioCompaniesNames={portfolioCompaniesNames}
        setPortfolioCompaniesNames={setPortfolioCompaniesNames}
      />
    );
  }

  return (
    <SplashPage
      setEnterpriseProjects={setEnterpriseProjects}
      enterpriseUsers={enterpriseUsers}
      enterpriseProjects={enterpriseProjects}
      investorCompaniesList={investorCompaniesList}
      portfolioCompaniesList={portfolioCompaniesList}
      investorCompaniesNames={investorCompaniesNames}
      portfolioCompaniesNames={portfolioCompaniesNames}
      setPortfolioCompaniesNames={setPortfolioCompaniesNames}
    />
  );
}
