import React from 'react';
import PropTypes from 'prop-types';
import {
  Page,
  View,
  Text,
} from '@react-pdf/renderer';

import TablePageHeader from '../../components/TablePageHeader';
import BottomPagination from '../../components/BottomPagination';
import DraftOverlay from '../../components/DraftOverlay';

import { commaEvery3rdChar } from '../../../../../../utils';

export default function Holdings({ reportData, exhibitNumber }) {
  const tableData = reportData.FundHoldingsSummary;

  const tableHeaders = [
    'Ownership class',
    'Original issue price\nor strike price',
    'Fair value per share',
    'Number of shares',
    'Aggregate value',
  ];

  function returnCellData(cellData, dataName, rowIndex) {
    if (dataName === 'Original issue price\nor strike price') return `$${cellData['Original Issue Price'][rowIndex].toFixed(4)}`;
    if (dataName === 'Fair value per share') return `$${cellData['Fair Value Per Share'][rowIndex].toFixed(4)}`;
    if (dataName === 'Number of shares') return commaEvery3rdChar(cellData['Number of Shares'][rowIndex]);
    if (dataName === 'Aggregate value') return `$${commaEvery3rdChar(cellData['Aggregate Value'][rowIndex].toFixed(2))}`;
    const formattedDataName = dataName.split(' ').map((word) => word !== 'of' ? word[0].toUpperCase() + word.substr(1) : word).join(' ');
    return cellData[formattedDataName][rowIndex].toString();
  }

  const fundsList = Object.keys(tableData.holdingsObject).filter((fundName) => fundName !== 'Project Totals');

  const numberOfFunds = fundsList.length;

  const totalHoldings = fundsList.reduce((a, b) => tableData.holdingsObject[b].Holdings['Ownership Class'].length + a, 0);

  const footnote = reportData.footnoteData.fundHoldingSummary;

  const reduceRowHeight = () => {
    if (totalHoldings + (numberOfFunds * 5) + (footnote ? Math.ceil(footnote.length / 230) : 0) > 17) return true;
    return false;
    // TODO Handle multiple pages
  };


  return (
    <Page
      size="TABLOID"
      orientation="landscape"
      style={{ position: 'relative', backgroundColor: '#EAEAEB' }}
    >
      <TablePageHeader companyName={reportData.companyInfo.companyName} pageName={`Fund Holdings Summary   |   Exhibit ${exhibitNumber}`} />
      {fundsList.map((fundName, fundIndex) => (
        <View
          key={fundName}
          style={{
            marginTop: fundIndex === 0 ? '84px' : '32px',
            marginLeft: '80px',
            display: 'flex',
            flexDirection: 'column',
            width: '1064px',
            border: '1px',
            borderColor: '#AFB1B3',
          }}
        >
          <View
            style={{
              height: reduceRowHeight() ? '38px' : '56px',
              backgroundColor: '#0C2617',
              borderBottom: '1px',
              borderColor: '#CCCCCC',
            }}
          >
            <Text
              style={{
                fontFamily: 'RobotoMed',
                fontSize: '14px',
                color: '#E1E2EC',
                marginTop: 'auto',
                paddingBottom: '8px',
                paddingLeft: '16px',
                letterSpacing: '0.08px',
              }}
            >
              {fundName}
              {' '}
              <Text
                style={{
                  color: '#8AB9A4',
                }}
              >
                (USD$)
              </Text>
            </Text>
          </View>
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              height: reduceRowHeight() ? '42px' : '56px',
              backgroundColor: '#307157',
              borderBottom: '1px',
              borderColor: '#CCCCCC',
            }}
          >
            {tableHeaders.map((dataName, index) => (
              <Text
                key={dataName}
                style={{
                  fontFamily: 'RobotoMed',
                  fontSize: '12px',
                  color: '#E1E2EC',
                  width: '200px',
                  marginTop: 'auto',
                  paddingBottom: '4px',
                  paddingLeft: '16px',
                  letterSpacing: '0.5px',
                  textAlign: index !== 0 && 'right',
                }}
              >
                {dataName}
              </Text>
            ))}
          </View>
          {tableData.holdingsObject[fundName].Holdings['Ownership Class'].map((cellData, rowIndex) => (
            <View
              key={cellData}
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                height: reduceRowHeight() ? '24px' : '32px',
                backgroundColor: `${rowIndex % 2 ? '#F0F0F3' : '#FAFAFD'}`,
              }}
            >
              {tableHeaders.map((dataName, cellIndex) => (
                <Text
                  key={dataName}
                  style={{
                    fontFamily: 'Roboto',
                    fontSize: '12px',
                    color: '#49454F',
                    width: '200px',
                    paddingLeft: '16px',
                    letterSpacing: '0.4px',
                    textAlign: cellIndex !== 0 && 'right',
                  }}
                >
                  {returnCellData(tableData.holdingsObject[fundName].Holdings, dataName, rowIndex) || 'N/A'}
                </Text>
              ))}
            </View>
          ))}
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              height: reduceRowHeight() ? '24px' : '32px',
              backgroundColor: '#9ECEBB',
            }}
          >
            <Text
              style={{
                fontFamily: 'RobotoMed',
                fontSize: '12px',
                color: '#49454F',
                width: '600px',
                paddingLeft: '16px',
                letterSpacing: '0.5px',
              }}
            >
              Fair value of
              {' '}
              {fundName}
            </Text>
            <Text
              style={{
                fontFamily: 'RobotoMed',
                fontSize: '12px',
                color: '#49454F',
                width: '200px',
                paddingLeft: '16px',
                letterSpacing: '0.4px',
                textAlign: 'right',
              }}
            >
              {commaEvery3rdChar(tableData.holdingsObject[fundName]['Number of Shares'].toString()) || 'N/A'}
            </Text>
            <Text
              style={{
                fontFamily: 'RobotoMed',
                fontSize: '12px',
                color: '#49454F',
                width: '200px',
                paddingLeft: '16px',
                letterSpacing: '0.4px',
                textAlign: 'right',
              }}
            >
              {`$${commaEvery3rdChar(tableData.holdingsObject[fundName]['Aggregate Value'].toFixed(2))}` || 'N/A'}
            </Text>
          </View>
          {Object.keys(tableData.holdingsObject).length - 2 === fundIndex && (
            <>
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  height: reduceRowHeight() ? '24px' : '36px',
                  backgroundColor: '#FAFAFD',
                }}
              />
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  height: reduceRowHeight() ? '24px' : '36px',
                  backgroundColor: '#9ECEBB',
                }}
              >
                <Text
                  style={{
                    fontFamily: 'RobotoMed',
                    fontSize: '12px',
                    color: '#49454F',
                    width: '600px',
                    paddingLeft: '16px',
                    letterSpacing: '0.5px',
                  }}
                >
                  Fair value of all holdings
                </Text>
                <Text
                  style={{
                    fontFamily: 'RobotoMed',
                    fontSize: '12px',
                    color: '#49454F',
                    width: '200px',
                    paddingLeft: '16px',
                    letterSpacing: '0.4px',
                    textAlign: 'right',
                  }}
                >
                  {commaEvery3rdChar(tableData.holdingsObject['Project Totals']['Number of Shares'].toString()) || 'N/A'}
                </Text>
                <Text
                  style={{
                    fontFamily: 'RobotoMed',
                    fontSize: '12px',
                    color: '#49454F',
                    width: '200px',
                    paddingLeft: '16px',
                    letterSpacing: '0.4px',
                    textAlign: 'right',
                  }}
                >
                  {`$${commaEvery3rdChar(tableData.holdingsObject['Project Totals']['Aggregate Value'].toFixed(2))}` || 'N/A'}
                </Text>
              </View>
            </>
          )}
        </View>
      ))}
      {!!footnote && (
        <Text
          style={{
            fontFamily: 'Roboto',
            fontSize: '8px',
            color: '#49454F',
            letterSpacing: '0.4px',
            marginTop: '16px',
            marginLeft: '90px',
            width: '450px',
            lineHeight: '0.55mm',
          }}
        >
          Footnote:&nbsp;
          {footnote}
        </Text>
      )}
      <BottomPagination
        pageNumber={4}
        reportData={reportData}
      />
      {reportData.isDraft && <DraftOverlay />}
    </Page>
  );
}

Holdings.propTypes = {
  reportData: PropTypes.object.isRequired,
  exhibitNumber: PropTypes.number.isRequired,
};
