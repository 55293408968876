/* eslint-disable jsx-a11y/mouse-events-have-key-events */
// TODO Handle accessibility for mouse over events
import React, {
  useState,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import IconButton from '@mui/material/IconButton';
import Checkbox from '@mui/material/Checkbox';
import Tooltip from '@mui/material/Tooltip';

import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import { copy } from '../../../../utils';

import './CompTableHeader.scss';

function SortingChevron({
  sortedColumn,
  columnSorting,
  setColumnSorting,
  columnHeaderHovered,
}) {
  return (
    <IconButton
      className={`chevron-icon${columnSorting.column === sortedColumn ? ' active' : ''}` +
        `${columnHeaderHovered !== sortedColumn ? ' hidden' : ''}`}
      onClick={() => {
        if (columnSorting.column !== sortedColumn || columnSorting.order === 'ascending') {
          setColumnSorting({ column: sortedColumn, order: 'descending' });
        } else {
          setColumnSorting({ column: sortedColumn, order: 'ascending' });
        }
      }}
    >
      {columnSorting.column !== sortedColumn || columnSorting.order === 'descending' ?
        <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
    </IconButton>
  );
}

SortingChevron.propTypes = {
  columnSorting: PropTypes.object.isRequired,
  setColumnSorting: PropTypes.func.isRequired,
  columnHeaderHovered: PropTypes.string.isRequired,
  sortedColumn: PropTypes.string.isRequired,
};

export default function CompTableHeader({
  tabToView,
  filterSelected,
  compData,
  setCompData,
  fiscalYears,
  filters,
  filterValues,
  headerScroll,
  valuationDate,
  transactionDate,
  financialStatementDate,
  reSummarize,
}) {
  const [columnHeaderHovered, setColumnHeaderHovered] = useState('');

  const [columnSorting, setColumnSorting] = useState({});

  useEffect(() => {
    if (columnSorting.column) {
      const sortByDate = ['Last FYE Date', 'IPO Date'].includes(columnSorting.column);
      const sortAlphabetically = ['Name', 'Identifier', 'Ticker', 'Industry'].includes(columnSorting.column);
      const indices = [...compData.Identifier.keys()];
      indices.shift();
      indices.sort((a, b) => {
        const sortOrder = columnSorting.order === 'descending' ? [a, b] : [b, a];
        if (!compData[columnSorting.column]?.[a]) return 1;
        if (!compData[columnSorting.column]?.[b]) return -1;
        if (sortByDate) {
          return moment(compData[columnSorting.column][sortOrder[0]], 'MM/DD/YYYY') -
            moment(compData[columnSorting.column][sortOrder[1]], 'MM/DD/YYYY');
        }
        if (sortAlphabetically) {
          return compData[columnSorting.column][sortOrder[0]].localeCompare(compData[columnSorting.column][sortOrder[1]]);
        }
        return compData[columnSorting.column][sortOrder[0]] - compData[columnSorting.column][sortOrder[1]];
      });
      indices.unshift(0);
      const sortedCompData = copy(compData);
      Object.keys(compData).forEach((compDataKey) => {
        sortedCompData[compDataKey] = indices.map((i) => sortedCompData[compDataKey][i]);
      });
      setCompData(sortedCompData);
    }
  }, [columnSorting]);

  const yearsColumnWidth = (yearsFilterValuesLength) => {
    if (!yearsFilterValuesLength) return `${40 * 109.6}px`;
    if (yearsFilterValuesLength === 1) return `${yearsFilterValuesLength * 95}px`;
    if (yearsFilterValuesLength === 2) return `${yearsFilterValuesLength * 100}px`;
    if (yearsFilterValuesLength < 5) return `${yearsFilterValuesLength * 105}px`;
    if (yearsFilterValuesLength < 8) return `${yearsFilterValuesLength * 107}px`;
    if (yearsFilterValuesLength < 13) return `${yearsFilterValuesLength * 108}px`;
    if (yearsFilterValuesLength < 20) return `${yearsFilterValuesLength * 109}px`;
    return `${yearsFilterValuesLength * 109.6}px`;
  };

  const getYear = (dataIndex) => {
    const increment = dataIndex / 4 + 0.25;
    return `${increment.toFixed(increment.toFixed(2).toString().charAt(increment.toFixed(2).toString().length - 1) === '0' ? 1 : 2)} year`;
  };

  return (
    <div className="CompTableHeader">
      <div className="fixed-left">
        <div className="table-header-top-titles">
          <div className="comp-list"><h6>Company list</h6></div>
        </div>
        <div className="table-sub-headers">
          <div className="sub-header-group comp-list">
            <Tooltip
              PopperProps={{ className: 'bottom-arrow-tooltip select-deselect top-start' }}
              title="Selects and deselects all"
              placement="top-start"
              enterDelay={500}
              enterNextDelay={1500}
              arrow
            >
              <Checkbox
                checked={tabToView === 6 ? compData.Active.some((active) => active) &&
                  compData.Multiples.some((active) => active) : compData.Active.some((active) => active)}
                onChange={() => { reSummarize('all', tabToView === 5 ? 'active' : 'multiple'); }}
                icon={<div className="unchecked-icon" />}
                checkedIcon={<div className="checked-icon"><CheckRoundedIcon /></div>}
              />
            </Tooltip>
            <div
              className="sub-header"
              onMouseOver={() => { if (columnHeaderHovered !== 'Name') setColumnHeaderHovered('Name'); }}
              onMouseLeave={() => setColumnHeaderHovered('')}
            >
              <div className="sub-header-title">
                <h6>Full Company name</h6>
                <span>&nbsp;</span>
              </div>
              <SortingChevron
                sortedColumn="Name"
                columnSorting={columnSorting}
                setColumnSorting={setColumnSorting}
                columnHeaderHovered={columnHeaderHovered}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="right-scrollable" ref={headerScroll}>
        <div className="table-header-top-titles">
          {(!filterSelected || filters.showCompanyInfo || tabToView === 5) &&
            <div className="basic-info" style={{ minWidth: tabToView === 6 ? '860px' : '242px' }}><h6>Basic information</h6></div>}
          {tabToView === 5 && (!filterSelected || filters.showVolatilityValDate) && (
            <div
              className="val-volatility"
              style={{ minWidth: yearsColumnWidth(filterValues.yearsFilterValues.length) }}
            >
              <h6>
                {!filterValues.yearsFilterValues.length ||
                  filterValues.yearsFilterValues.length >= 3 ?
                  'Volatility since valuation date' : 'Val. Vol.'}
              </h6>
            </div>
          )}
          {tabToView === 5 && (!filterSelected || filters.showVolatilityTrDate) && (
            <div
              className="tr-volatility"
              style={{ minWidth: yearsColumnWidth(filterValues.yearsFilterValues.length) }}
            >
              <h6>
                {!filterValues.yearsFilterValues.length ||
                  filterValues.yearsFilterValues.length >= 3 ?
                  'Volatility since transaction date' : 'Tr. Vol.'}
              </h6>
            </div>
          )}
          {(!filterSelected || filters.showMarketCap) &&
            <div className="market-cap"><h6>Market capitalization</h6></div>}
          {tabToView === 6 && (!filterSelected || filterValues.growthFilterValues.includes('Market growth')) &&
            <div className="market-growth"><h6>Market growth</h6></div>}
          {(!filterSelected || filters.showRevenue) && (
            <div
              className="revenue"
              style={{
                minWidth: !filterValues.fiscalPeriodFilterValues.length ? `${8 * 164}px` :
                  `${filterValues.fiscalPeriodFilterValues.length * 164}px`,
              }}
            >
              <h6>Revenue</h6>
            </div>
          )}
          {tabToView === 6 && (!filterSelected || filters.showEBITDA) && (
            <div
              className="EBITDA"
              style={{
                minWidth: !filterValues.fiscalPeriodFilterValues.length ? `${8 * 164}px` :
                  `${filterValues.fiscalPeriodFilterValues.length * 164}px`,
              }}
            >
              <h6>EBITDA</h6>
            </div>
          )}
          {tabToView === 6 && (!filterSelected || filters.showGrossProfit) && (
            <div
              className="gross-profit"
              style={{
                minWidth: !filterValues.fiscalPeriodFilterValues.length ? `${8 * 164}px` :
                  `${filterValues.fiscalPeriodFilterValues.length * 164}px`,
              }}
            >
              <h6>Gross Profit</h6>
            </div>
          )}
          {tabToView === 6 && (!filterSelected || filterValues.growthFilterValues.includes('Revenue growth')) && (
            <div
              className="revenue-growth"
              style={{
                minWidth: !filterValues.fiscalPeriodFilterValues.length ? `${8 * 164}px` :
                  `${filterValues.fiscalPeriodFilterValues.length * 164}px`,
              }}
            >
              <h6>Revenue growth rate</h6>
            </div>
          )}
          {tabToView === 6 && (!filterSelected || filterValues.growthFilterValues.includes('EBITDA growth')) && (
            <div
              className="EBITDA-growth"
              style={{
                minWidth: !filterValues.fiscalPeriodFilterValues.length ? `${8 * 164}px` :
                  `${filterValues.fiscalPeriodFilterValues.length * 164}px`,
              }}
            >
              <h6>EBITDA growth rate</h6>
            </div>
          )}
          {tabToView === 6 && (!filterSelected || filterValues.growthFilterValues.includes('Gross Profit growth')) && (
            <div
              className="EBITDA-growth"
              style={{
                minWidth: !filterValues.fiscalPeriodFilterValues.length ? `${8 * 164}px` :
                  `${filterValues.fiscalPeriodFilterValues.length * 164}px`,
              }}
            >
              <h6>Gross Profit growth rate</h6>
            </div>
          )}
          {tabToView === 6 && (!filterSelected || filterValues.multiplesFilterValues.includes('Revenue multiples')) && (
            <div
              className="revenue-multiples"
              style={{
                minWidth: !filterValues.fiscalPeriodFilterValues.length ? `${8 * 164}px` :
                  `${filterValues.fiscalPeriodFilterValues.length * 164}px`,
              }}
            >
              <h6>Revenue multiples</h6>
            </div>
          )}
          {tabToView === 6 && (!filterSelected || filterValues.multiplesFilterValues.includes('EBITDA multiples')) && (
            <div
              className="ebitda-multiples"
              style={{
                minWidth: !filterValues.fiscalPeriodFilterValues.length ? `${8 * 164}px` :
                  `${filterValues.fiscalPeriodFilterValues.length * 164}px`,
              }}
            >
              <h6>EBITDA multiples</h6>
            </div>
          )}
          {tabToView === 6 && (!filterSelected || filterValues.growthFilterValues.includes('EBITDA Margin')) && (
            <div
              className="EBITDA-margin"
              style={{
                minWidth: !filterValues.fiscalPeriodFilterValues.length ? `${8 * 164}px` :
                  `${filterValues.fiscalPeriodFilterValues.length * 164}px`,
              }}
            >
              <h6>EBITDA Margin</h6>
            </div>
          )}
          {tabToView === 6 && (!filterSelected || filterValues.growthFilterValues.includes('Gross Margin')) && (
            <div
              className="gross-margin"
              style={{
                minWidth: !filterValues.fiscalPeriodFilterValues.length ? `${8 * 164}px` :
                  `${filterValues.fiscalPeriodFilterValues.length * 164}px`,
              }}
            >
              <h6>Gross Margin</h6>
            </div>
          )}
        </div>
        <div className="table-sub-headers">
          {(!filterSelected || filters.showCompanyInfo || tabToView === 5) && (
            <div className="sub-header-group basic-info">
              <div
                className="sub-header"
                onMouseOver={() => { if (columnHeaderHovered !== 'Identifier') setColumnHeaderHovered('Identifier'); }}
                onMouseLeave={() => setColumnHeaderHovered('')}
              >
                <div className="sub-header-title">
                  <h6>Capital IQ</h6>
                  <span>Identifier</span>
                </div>
                <SortingChevron
                  sortedColumn="Identifier"
                  columnSorting={columnSorting}
                  setColumnSorting={setColumnSorting}
                  columnHeaderHovered={columnHeaderHovered}
                />
              </div>
              <div
                className="sub-header"
                onMouseOver={() => { if (columnHeaderHovered !== 'Ticker') setColumnHeaderHovered('Ticker'); }}
                onMouseLeave={() => setColumnHeaderHovered('')}
              >
                <div className="sub-header-title">
                  <h6>Ticker</h6>
                  <span>(Market:Ticker)</span>
                </div>
                <SortingChevron
                  sortedColumn="Ticker"
                  columnSorting={columnSorting}
                  setColumnSorting={setColumnSorting}
                  columnHeaderHovered={columnHeaderHovered}
                />
              </div>
              {tabToView === 6 && (
                <>
                  <div
                    className="sub-header"
                    onMouseOver={() => { if (columnHeaderHovered !== 'Industry') setColumnHeaderHovered('Industry'); }}
                    onMouseLeave={() => setColumnHeaderHovered('')}
                  >
                    <div className="sub-header-title">
                      <h6>Industry</h6>
                      <span>(Primary)</span>
                    </div>
                    <SortingChevron
                      sortedColumn="Industry"
                      columnSorting={columnSorting}
                      setColumnSorting={setColumnSorting}
                      columnHeaderHovered={columnHeaderHovered}
                    />
                  </div>
                  {/* <div className="sub-header">
                        <div className="sub-header-title">
                          <h6>Last FQE Date</h6>
                          <span>(mm/dd/yyyy)</span>
                        </div>
                      </div> */}
                  <div
                    className="sub-header"
                    onMouseOver={() => { if (columnHeaderHovered !== 'Last FYE Date') setColumnHeaderHovered('Last FYE Date'); }}
                    onMouseLeave={() => setColumnHeaderHovered('')}
                  >
                    <div className="sub-header-title">
                      <h6>Last FYE Date</h6>
                      <span>(mm/dd/yyyy)</span>
                    </div>
                    <SortingChevron
                      sortedColumn="Last FYE Date"
                      columnSorting={columnSorting}
                      setColumnSorting={setColumnSorting}
                      columnHeaderHovered={columnHeaderHovered}
                    />
                  </div>
                  <div
                    className="sub-header"
                    onMouseOver={() => { if (columnHeaderHovered !== 'IPO Date') setColumnHeaderHovered('IPO Date'); }}
                    onMouseLeave={() => setColumnHeaderHovered('')}
                  >
                    <div className="sub-header-title">
                      <h6>IPO Date</h6>
                      <span>(mm/dd/yyyy)</span>
                    </div>
                    <SortingChevron
                      sortedColumn="IPO Date"
                      columnSorting={columnSorting}
                      setColumnSorting={setColumnSorting}
                      columnHeaderHovered={columnHeaderHovered}
                    />
                  </div>
                  <div className="sub-header">
                    <div className="sub-header-title">
                      <h6>Description</h6>
                      <span>(Short)</span>
                    </div>
                  </div>
                  <div className="sub-header">
                    <div className="sub-header-title">
                      <h6>Description</h6>
                      <span>(Long)</span>
                    </div>
                  </div>
                </>
              )}
            </div>
          )}
          {tabToView === 5 && (!filterSelected || filters.showVolatilityValDate) && (
            <div className="sub-header-group val-volatility">
              {[...Array(40)].map((el, dataIndex) => (
                (!filterValues.yearsFilterValues.length || filterValues.yearsFilterValues
                  .includes(dataIndex / 4 + 0.25 <= 1 ? `${dataIndex / 4 + 0.25} year` : `${dataIndex / 4 + 0.25} years`)) && (
                  <div
                    key={getYear(dataIndex)}
                    className="sub-header"
                    onMouseOver={() => {
                      const columnName = `Volatility - Valuation Date | ${getYear(dataIndex)}`;
                      if (columnHeaderHovered !== columnName) setColumnHeaderHovered(columnName);
                    }}
                    onMouseLeave={() => setColumnHeaderHovered('')}
                  >
                    <div className="sub-header-title">
                      <h6>
                        {new Intl.NumberFormat('en-US', { minimumIntegerDigits: 1, minimumFractionDigits: 2 }).format(dataIndex / 4 + 0.25)}
                      </h6>
                      <span>{dataIndex / 4 + 0.25 <= 1 ? '(yr)' : '(yrs)'}</span>
                    </div>
                    <SortingChevron
                      sortedColumn={`Volatility - Valuation Date | ${getYear(dataIndex)}`}
                      columnSorting={columnSorting}
                      setColumnSorting={setColumnSorting}
                      columnHeaderHovered={columnHeaderHovered}
                    />
                  </div>
                )
              ))}
            </div>
          )}
          {tabToView === 5 && (!filterSelected || filters.showVolatilityTrDate) && (
            <div className="sub-header-group tr-volatility">
              {[...Array(40)].map((el, dataIndex) => (
                (!filterValues.yearsFilterValues.length || filterValues.yearsFilterValues
                  .includes(dataIndex / 4 + 0.25 <= 1 ? `${dataIndex / 4 + 0.25} year` : `${dataIndex / 4 + 0.25} years`)) && (
                  <div
                    key={getYear(dataIndex)}
                    className="sub-header"
                    onMouseOver={() => {
                      const columnName = `Volatility - Transaction Date | ${getYear(dataIndex)}`;
                      if (columnHeaderHovered !== columnName) setColumnHeaderHovered(columnName);
                    }}
                    onMouseLeave={() => setColumnHeaderHovered('')}
                  >
                    <div className="sub-header-title">
                      <h6>&nbsp;</h6>
                      <span>{dataIndex / 4 + 0.25 <= 1 ? `${dataIndex / 4 + 0.25} year` : `${dataIndex / 4 + 0.25} years`}</span>
                    </div>
                    <SortingChevron
                      sortedColumn={`Volatility - Transaction Date | ${getYear(dataIndex)}`}
                      columnSorting={columnSorting}
                      setColumnSorting={setColumnSorting}
                      columnHeaderHovered={columnHeaderHovered}
                    />
                  </div>
                )
              ))}
            </div>
          )}
          {(!filterSelected || filters.showMarketCap) && (
            <div className="sub-header-group market-cap">
              <div
                className="sub-header"
                onMouseOver={() => {
                  const columnName = `Market Capitalization | Transaction Date - ${transactionDate}`;
                  if (columnHeaderHovered !== columnName) setColumnHeaderHovered(columnName);
                }}
                onMouseLeave={() => setColumnHeaderHovered('')}
              >
                <div className="sub-header-title">
                  <h6>Transaction date</h6>
                  <span>($)</span>
                </div>
                <SortingChevron
                  sortedColumn={`Market Capitalization | Transaction Date - ${transactionDate}`}
                  columnSorting={columnSorting}
                  setColumnSorting={setColumnSorting}
                  columnHeaderHovered={columnHeaderHovered}
                />
              </div>
              <div
                className="sub-header"
                onMouseOver={() => {
                  const columnName = `Market Capitalization | Valuation Date - ${valuationDate}`;
                  if (columnHeaderHovered !== columnName) setColumnHeaderHovered(columnName);
                }}
                onMouseLeave={() => setColumnHeaderHovered('')}
              >
                <div className="sub-header-title">
                  <h6>Valuation date</h6>
                  <span>($)</span>
                </div>
                <SortingChevron
                  sortedColumn={`Market Capitalization | Valuation Date - ${valuationDate}`}
                  columnSorting={columnSorting}
                  setColumnSorting={setColumnSorting}
                  columnHeaderHovered={columnHeaderHovered}
                />
              </div>
              <div
                className="sub-header"
                onMouseOver={() => {
                  const columnName = `Market Capitalization | Financial Statement Date - ${financialStatementDate}`;
                  if (columnHeaderHovered !== columnName) setColumnHeaderHovered(columnName);
                }}
                onMouseLeave={() => setColumnHeaderHovered('')}
              >
                <div className="sub-header-title">
                  <h6>Financial statement date</h6>
                  <span>($)</span>
                </div>
                <SortingChevron
                  sortedColumn={`Market Capitalization | Financial Statement Date - ${financialStatementDate}`}
                  columnSorting={columnSorting}
                  setColumnSorting={setColumnSorting}
                  columnHeaderHovered={columnHeaderHovered}
                />
              </div>
            </div>
          )}
          {tabToView === 6 && (!filterSelected || filterValues.growthFilterValues.includes('Market growth')) && (
            <div className="sub-header-group market-growth">
              <div
                className="sub-header"
                onMouseOver={() => {
                  const columnName = 'Market Growth | Transaction Date to Valuation Date';
                  if (columnHeaderHovered !== columnName) setColumnHeaderHovered(columnName);
                }}
                onMouseLeave={() => setColumnHeaderHovered('')}
              >
                <div className="sub-header-title">
                  <h6>Transaction date</h6>
                  <span>to valuation date</span>
                </div>
                <SortingChevron
                  sortedColumn="Market Growth | Transaction Date to Valuation Date"
                  columnSorting={columnSorting}
                  setColumnSorting={setColumnSorting}
                  columnHeaderHovered={columnHeaderHovered}
                />
              </div>
            </div>
          )}
          {(!filterSelected || filters.showRevenue) && (
            <div className="sub-header-group revenue">
              {fiscalYears.map((fiscalYear) => {
                if (!filterValues.fiscalPeriodFilterValues.length || filterValues.fiscalPeriodFilterValues
                  .some((val) => val.replace(/ *\([^)]*\) */g, '').replace('12', 'twelve') === fiscalYear.title)) {
                  return (
                    <div
                      key={fiscalYear.title}
                      className="sub-header"
                      onMouseOver={() => {
                        const columnName = `Revenue | ${fiscalYear.legend.replace(' ', '')}`;
                        if (columnHeaderHovered !== columnName) setColumnHeaderHovered(columnName);
                      }}
                      onMouseLeave={() => setColumnHeaderHovered('')}
                    >
                      <div className="sub-header-title">
                        <h6>{fiscalYear.title}</h6>
                        <span>{`${fiscalYear.legend} ($)`}</span>
                      </div>
                      <SortingChevron
                        sortedColumn={`Revenue | ${fiscalYear.legend.replace(' ', '')}`}
                        columnSorting={columnSorting}
                        setColumnSorting={setColumnSorting}
                        columnHeaderHovered={columnHeaderHovered}
                      />
                    </div>
                  );
                }
                return null;
              })}
            </div>
          )}
          {tabToView === 6 && (!filterSelected || filters.showEBITDA) && (
            <div className="sub-header-group EBITDA">
              {fiscalYears.map((fiscalYear) => {
                if (!filterValues.fiscalPeriodFilterValues.length || filterValues.fiscalPeriodFilterValues
                  .some((val) => val.replace(/ *\([^)]*\) */g, '').replace('12', 'twelve') === fiscalYear.title)) {
                  return (
                    <div
                      key={fiscalYear.title}
                      className="sub-header"
                      onMouseOver={() => {
                        const columnName = `EBITDA | ${fiscalYear.legend.replace(' ', '')}`;
                        if (columnHeaderHovered !== columnName) setColumnHeaderHovered(columnName);
                      }}
                      onMouseLeave={() => setColumnHeaderHovered('')}
                    >
                      <div className="sub-header-title">
                        <h6>{fiscalYear.title}</h6>
                        <span>{`${fiscalYear.legend} ($)`}</span>
                      </div>
                      <SortingChevron
                        sortedColumn={`EBITDA | ${fiscalYear.legend.replace(' ', '')}`}
                        columnSorting={columnSorting}
                        setColumnSorting={setColumnSorting}
                        columnHeaderHovered={columnHeaderHovered}
                      />
                    </div>
                  );
                }
                return null;
              })}
            </div>
          )}
          {tabToView === 6 && (!filterSelected || filters.showGrossProfit) && (
            <div className="sub-header-group gross-profit">
              {fiscalYears.map((fiscalYear) => {
                if (!filterValues.fiscalPeriodFilterValues.length || filterValues.fiscalPeriodFilterValues
                  .some((val) => val.replace(/ *\([^)]*\) */g, '').replace('12', 'twelve') === fiscalYear.title)) {
                  return (
                    <div
                      key={fiscalYear.title}
                      className="sub-header"
                      onMouseOver={() => {
                        const columnName = `Gross Profit | ${fiscalYear.legend.replace(' ', '')}`;
                        if (columnHeaderHovered !== columnName) setColumnHeaderHovered(columnName);
                      }}
                      onMouseLeave={() => setColumnHeaderHovered('')}
                    >
                      <div className="sub-header-title">
                        <h6>{fiscalYear.title}</h6>
                        <span>{`${fiscalYear.legend} ($)`}</span>
                      </div>
                      <SortingChevron
                        sortedColumn={`Gross Profit | ${fiscalYear.legend.replace(' ', '')}`}
                        columnSorting={columnSorting}
                        setColumnSorting={setColumnSorting}
                        columnHeaderHovered={columnHeaderHovered}
                      />
                    </div>
                  );
                }
                return null;
              })}
            </div>
          )}
          {tabToView === 6 && (!filterSelected || filterValues.growthFilterValues.includes('Revenue growth')) && (
            <div className="sub-header-group revenue-growth">
              {fiscalYears.map((fiscalYear) => {
                if (!filterValues.fiscalPeriodFilterValues.length || filterValues.fiscalPeriodFilterValues
                  .some((val) => val.replace(/ *\([^)]*\) */g, '').replace('12', 'twelve') === fiscalYear.title)) {
                  return (
                    <div
                      key={fiscalYear.title}
                      className="sub-header"
                      onMouseOver={() => {
                        const columnName = `Revenue Growth Rate | ${fiscalYear.legend.replace(' ', '')}`;
                        if (columnHeaderHovered !== columnName) setColumnHeaderHovered(columnName);
                      }}
                      onMouseLeave={() => setColumnHeaderHovered('')}
                    >
                      <div className="sub-header-title">
                        <h6>{fiscalYear.title}</h6>
                        <span>{`${fiscalYear.legend} ($)`}</span>
                      </div>
                      <SortingChevron
                        sortedColumn={`Revenue Growth Rate | ${fiscalYear.legend.replace(' ', '')}`}
                        columnSorting={columnSorting}
                        setColumnSorting={setColumnSorting}
                        columnHeaderHovered={columnHeaderHovered}
                      />
                    </div>
                  );
                }
                return null;
              })}
            </div>
          )}
          {tabToView === 6 && (!filterSelected || filterValues.growthFilterValues.includes('EBITDA growth')) && (
            <div className="sub-header-group EBITDA-growth">
              {fiscalYears.map((fiscalYear) => {
                if (!filterValues.fiscalPeriodFilterValues.length || filterValues.fiscalPeriodFilterValues
                  .some((val) => val.replace(/ *\([^)]*\) */g, '').replace('12', 'twelve') === fiscalYear.title)) {
                  return (
                    <div
                      key={fiscalYear.title}
                      className="sub-header"
                      onMouseOver={() => {
                        const columnName = `EBITDA Growth Rate | ${fiscalYear.legend.replace(' ', '')}`;
                        if (columnHeaderHovered !== columnName) setColumnHeaderHovered(columnName);
                      }}
                      onMouseLeave={() => setColumnHeaderHovered('')}
                    >
                      <div className="sub-header-title">
                        <h6>{fiscalYear.title}</h6>
                        <span>{`${fiscalYear.legend} ($)`}</span>
                      </div>
                      <SortingChevron
                        sortedColumn={`EBITDA Growth Rate | ${fiscalYear.legend.replace(' ', '')}`}
                        columnSorting={columnSorting}
                        setColumnSorting={setColumnSorting}
                        columnHeaderHovered={columnHeaderHovered}
                      />
                    </div>
                  );
                }
                return null;
              })}
            </div>
          )}
          {tabToView === 6 && (!filterSelected || filterValues.growthFilterValues.includes('Gross Profit growth')) && (
            <div className="sub-header-group gross-profit-growth">
              {fiscalYears.map((fiscalYear) => {
                if (!filterValues.fiscalPeriodFilterValues.length || filterValues.fiscalPeriodFilterValues
                  .some((val) => val.replace(/ *\([^)]*\) */g, '').replace('12', 'twelve') === fiscalYear.title)) {
                  return (
                    <div
                      key={fiscalYear.title}
                      className="sub-header"
                      onMouseOver={() => {
                        const columnName = `Gross Profit Growth Rate | ${fiscalYear.legend.replace(' ', '')}`;
                        if (columnHeaderHovered !== columnName) setColumnHeaderHovered(columnName);
                      }}
                      onMouseLeave={() => setColumnHeaderHovered('')}
                    >
                      <div className="sub-header-title">
                        <h6>{fiscalYear.title}</h6>
                        <span>{`${fiscalYear.legend} ($)`}</span>
                      </div>
                      <SortingChevron
                        sortedColumn={`Gross Profit Growth Rate | ${fiscalYear.legend.replace(' ', '')}`}
                        columnSorting={columnSorting}
                        setColumnSorting={setColumnSorting}
                        columnHeaderHovered={columnHeaderHovered}
                      />
                    </div>
                  );
                }
                return null;
              })}
            </div>
          )}
          {tabToView === 6 && (!filterSelected || filterValues.multiplesFilterValues.includes('Revenue multiples')) && (
            <div className="sub-header-group revenue-multiples">
              {fiscalYears.map((fiscalYear) => {
                if (!filterValues.fiscalPeriodFilterValues.length || filterValues.fiscalPeriodFilterValues
                  .some((val) => val.replace(/ *\([^)]*\) */g, '').replace('12', 'twelve') === fiscalYear.title)) {
                  return (
                    <div
                      key={fiscalYear.title}
                      className="sub-header"
                      onMouseOver={() => {
                        const columnName = `Revenue Multiple${fiscalYear.legend.includes('NTM') ? 's' : ''} | ` +
                          `${fiscalYear.legend.replace(' ', '')}`;
                        if (columnHeaderHovered !== columnName) setColumnHeaderHovered(columnName);
                      }}
                      onMouseLeave={() => setColumnHeaderHovered('')}
                    >
                      <div className="sub-header-title">
                        <h6>{fiscalYear.title}</h6>
                        <span>{`${fiscalYear.legend} ($)`}</span>
                      </div>
                      <SortingChevron
                        sortedColumn={`Revenue Multiple${fiscalYear.legend.includes('NTM') ? 's' : ''} | ${fiscalYear.legend.replace(' ', '')}`}
                        columnSorting={columnSorting}
                        setColumnSorting={setColumnSorting}
                        columnHeaderHovered={columnHeaderHovered}
                      />
                    </div>
                  );
                }
                return null;
              })}
            </div>
          )}
          {tabToView === 6 && (!filterSelected || filterValues.multiplesFilterValues.includes('EBITDA multiples')) && (
            <div className="sub-header-group EBITDA-multiples">
              {fiscalYears.map((fiscalYear) => {
                if (!filterValues.fiscalPeriodFilterValues.length || filterValues.fiscalPeriodFilterValues
                  .some((val) => val.replace(/ *\([^)]*\) */g, '').replace('12', 'twelve') === fiscalYear.title)) {
                  return (
                    <div
                      key={fiscalYear.title}
                      className="sub-header"
                      onMouseOver={() => {
                        const columnName = `EBITDA Multiple${fiscalYear.legend.includes('NTM') ? 's' : ''} | ` +
                          `${fiscalYear.legend.replace(' ', '')}`;
                        if (columnHeaderHovered !== columnName) setColumnHeaderHovered(columnName);
                      }}
                      onMouseLeave={() => setColumnHeaderHovered('')}
                    >
                      <div className="sub-header-title">
                        <h6>{fiscalYear.title}</h6>
                        <span>{`${fiscalYear.legend} ($)`}</span>
                      </div>
                      <SortingChevron
                        sortedColumn={`EBITDA Multiple${fiscalYear.legend.includes('NTM') ? 's' : ''} | ${fiscalYear.legend.replace(' ', '')}`}
                        columnSorting={columnSorting}
                        setColumnSorting={setColumnSorting}
                        columnHeaderHovered={columnHeaderHovered}
                      />
                    </div>
                  );
                }
                return null;
              })}
            </div>
          )}
          {tabToView === 6 && (!filterSelected || filterValues.growthFilterValues.includes('EBITDA Margin')) && (
            <div className="sub-header-group EBITDA-margin">
              {fiscalYears.map((fiscalYear) => {
                if (!filterValues.fiscalPeriodFilterValues.length || filterValues.fiscalPeriodFilterValues
                  .some((val) => val.replace(/ *\([^)]*\) */g, '').replace('12', 'twelve') === fiscalYear.title)) {
                  return (
                    <div
                      key={fiscalYear.title}
                      className="sub-header"
                      onMouseOver={() => {
                        const columnName = `EBITDA Margin | ${fiscalYear.legend.replace(' ', '')}`;
                        if (columnHeaderHovered !== columnName) setColumnHeaderHovered(columnName);
                      }}
                      onMouseLeave={() => setColumnHeaderHovered('')}
                    >
                      <div className="sub-header-title">
                        <h6>{fiscalYear.title}</h6>
                        <span>{`${fiscalYear.legend} ($)`}</span>
                      </div>
                      <SortingChevron
                        sortedColumn={`EBITDA Margin | ${fiscalYear.legend.replace(' ', '')}`}
                        columnSorting={columnSorting}
                        setColumnSorting={setColumnSorting}
                        columnHeaderHovered={columnHeaderHovered}
                      />
                    </div>
                  );
                }
                return null;
              })}
            </div>
          )}
          {tabToView === 6 && (!filterSelected || filterValues.growthFilterValues.includes('Gross Margin')) && (
            <div className="sub-header-group gross-margin">
              {fiscalYears.map((fiscalYear) => {
                if (!filterValues.fiscalPeriodFilterValues.length || filterValues.fiscalPeriodFilterValues
                  .some((val) => val.replace(/ *\([^)]*\) */g, '').replace('12', 'twelve') === fiscalYear.title)) {
                  return (
                    <div
                      key={fiscalYear.title}
                      className="sub-header"
                      onMouseOver={() => {
                        const columnName = `Gross Margin | ${fiscalYear.legend.replace(' ', '')}`;
                        if (columnHeaderHovered !== columnName) setColumnHeaderHovered(columnName);
                      }}
                      onMouseLeave={() => setColumnHeaderHovered('')}
                    >
                      <div className="sub-header-title">
                        <h6>{fiscalYear.title}</h6>
                        <span>{`${fiscalYear.legend} ($)`}</span>
                      </div>
                      <SortingChevron
                        sortedColumn={`Gross Margin | ${fiscalYear.legend.replace(' ', '')}`}
                        columnSorting={columnSorting}
                        setColumnSorting={setColumnSorting}
                        columnHeaderHovered={columnHeaderHovered}
                      />
                    </div>
                  );
                }
                return null;
              })}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

CompTableHeader.propTypes = {
  tabToView: PropTypes.number.isRequired,
  filterSelected: PropTypes.bool.isRequired,
  fiscalYears: PropTypes.array.isRequired,
  filters: PropTypes.object.isRequired,
  filterValues: PropTypes.object.isRequired,
  compData: PropTypes.object.isRequired,
  setCompData: PropTypes.func.isRequired,
  headerScroll: PropTypes.object.isRequired,
  valuationDate: PropTypes.string.isRequired,
  transactionDate: PropTypes.string.isRequired,
  financialStatementDate: PropTypes.string.isRequired,
  reSummarize: PropTypes.func.isRequired,
};
