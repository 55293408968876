import React from 'react';
import PropTypes from 'prop-types';

import UploadBlock from './components/UploadBlock';

export default function Step4({
  transactionsData,
  setTransactionsData,
  index,
  setActiveStep,
  setSaveTransactionData,
  disableFields,
}) {
  return (
    <>
      <h4>Document Upload</h4>
      <p style={{ marginTop: '12px' }}>
        We accept the following file types, within a max. file size of 35 MB:
        <span style={{ fontWeight: 700 }}> Microsoft Excel </span>
        (.xls, .xlsx)
        <span style={{ fontWeight: 700 }}> Word </span>
        (.doc, .docx),
        <span style={{ fontWeight: 700 }}> PowerPoint </span>
        (.ppt, pptx, .pps, .ppsx), and
        <span style={{ fontWeight: 700 }}> image files </span>
        (.jpg, .jpeg, .heic, and .png).
      </p>
      <UploadBlock
        uploadType="cap-table"
        transactionsData={transactionsData}
        setTransactionsData={setTransactionsData}
        index={index}
        setActiveStep={setActiveStep}
        setSaveTransactionData={setSaveTransactionData}
        disableFields={disableFields}
      />
      <UploadBlock
        uploadType="articles"
        transactionsData={transactionsData}
        setTransactionsData={setTransactionsData}
        index={index}
        setActiveStep={setActiveStep}
        setSaveTransactionData={setSaveTransactionData}
        disableFields={disableFields}
      />
      <UploadBlock
        uploadType="financials"
        transactionsData={transactionsData}
        setTransactionsData={setTransactionsData}
        index={index}
        setActiveStep={setActiveStep}
        setSaveTransactionData={setSaveTransactionData}
        disableFields={disableFields}
      />
      <UploadBlock
        uploadType="additional-documents"
        transactionsData={transactionsData}
        setTransactionsData={setTransactionsData}
        index={index}
        setActiveStep={setActiveStep}
        setSaveTransactionData={setSaveTransactionData}
        disableFields={disableFields}
      />
    </>
  );
}

Step4.propTypes = {
  transactionsData: PropTypes.array.isRequired,
  setTransactionsData: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  setActiveStep: PropTypes.func.isRequired,
  setSaveTransactionData: PropTypes.func.isRequired,
  disableFields: PropTypes.bool.isRequired,
};
