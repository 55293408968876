import React from 'react';
import PropTypes from 'prop-types';
import {
  Page,
  View,
  Text,
} from '@react-pdf/renderer';

import TablePageHeader from '../../components/TablePageHeader';
import BottomPagination from '../../components/BottomPagination';
import DraftOverlay from '../../components/DraftOverlay';

import { commaEvery3rdChar } from '../../../../../../utils';

export default function GPCAllocation({
  reportData,
  sortedBreakpoints,
  totalPageCount,
  exhibitNumber,
}) {
  const tableData = reportData.OptionPricingAllocation.pubco;

  const table2Headers = [
    'Ownership class',
    'Total shares\noutstanding',
    'Incremental option value allocation',
  ];

  const table3Headers = [
    'Ownership class',
    'Total aggregate\nvalue',
    'Aggregate value per breakpoint per equity class',
  ];

  const formatSeriesName = (seriesName) => seriesName.includes(' @ $') ? `${seriesName.split(' @ $')[0]}` +
    ` @ $${new Intl.NumberFormat('en-US', { minimumFractionDigits: 2 })
      .format((seriesName.split(' @ $')[seriesName.split(' @ $').length - 1]))}` : seriesName;

  function returnRowTitle(rowIndex) {
    if (rowIndex === 0) return 'Breakpoint value';
    if (rowIndex === 1) return 'Value of call option';
    if (rowIndex === 2) return 'Follow-on option';
    if (rowIndex === 3) return 'Incremental option value';
    return null;
  }

  function returnTopTableData(rowIndex, columnIndex) {
    if (rowIndex === 0) {
      return `$${commaEvery3rdChar(tableData.incrementalOptionValue[sortedBreakpoints[columnIndex]].breakpointValue.toFixed(0))}`;
    }
    if (rowIndex === 1) {
      return `$${commaEvery3rdChar(tableData.incrementalOptionValue[sortedBreakpoints[columnIndex]].value_of_call_option.toFixed(0))}`;
    }
    if (rowIndex === 2) {
      return `$${commaEvery3rdChar(tableData.incrementalOptionValue[sortedBreakpoints[columnIndex]].follow_on_option.toFixed(0))}`;
    }
    if (rowIndex === 3) {
      return `$${commaEvery3rdChar(tableData.incrementalOptionValue[sortedBreakpoints[columnIndex]].incremental_option_value.toFixed(0))}`;
    }
    return 'N/A';
  }

  function returnCellData(dataType, rowIndex, columnIndex) {
    if (dataType === 'incrementalOptionValueAllocation') {
      return tableData[dataType][sortedBreakpoints[columnIndex]][rowIndex] ?
        `${(tableData[dataType][sortedBreakpoints[columnIndex]][rowIndex] * 100).toFixed(2)}%` : '—';
    }
    return tableData[dataType][sortedBreakpoints[columnIndex]][rowIndex] ?
      `$${commaEvery3rdChar(tableData[dataType][sortedBreakpoints[columnIndex]][rowIndex].toFixed(0))}` : '—';
  }

  function returnScholesData(scholesInput) {
    if (scholesInput === 'Equity value at valuation date') return `$${commaEvery3rdChar(parseFloat(tableData.equityValue).toFixed(0))}`;
    if (scholesInput === 'Period to liquidity event') {
      return `${tableData.periodToLiquidity} ${parseFloat(tableData.periodToLiquidity) > 1 ? 'years' : 'year'}`;
    }
    if (scholesInput === 'Dividend yield') return `${tableData.dividendYield}%`;
    if (scholesInput === 'Selected volatility') return `${tableData.selectedVolatility}%`;
    if (scholesInput === 'Risk free rate (Rf)') return `${(parseFloat(tableData.riskFreeRate)).toFixed(2)}%`;
    return null;
  }

  const footnote = reportData.footnoteData.gpcAllocation;

  const rowLength = tableData.incrementalOptionValueAllocation['Ownership Class'].length + (footnote ? Math.ceil(footnote.length / 230) : 0);

  const splitPages = () => {
    if (rowLength > 10) return 2;
    return false;
  };

  return (
    <>
      <Page
        size="TABLOID"
        orientation="landscape"
        style={{ position: 'relative', backgroundColor: '#EAEAEB' }}
      >
        <TablePageHeader
          companyName={reportData.companyInfo.companyName}
          pageName={`Guideline public company (GPC) OPM Allocation    |   Exhibit ${exhibitNumber}`}
        />
        <View
          style={{
            marginTop: '72px',
            marginLeft: '42px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-around',
            alignItems: 'center',
            width: '1160px',
            height: '24px',
            backgroundColor: '#FAFAFD',
            paddingLeft: '16px',
            paddingRight: '24px',
          }}
        >
          <Text
            style={{
              fontFamily: 'RobotoMed',
              fontSize: '10px',
              color: '#49454F',
              letterSpacing: '0.5px',
            }}
          >
            Black scholes inputs
          </Text>
          {[
            'Equity value at valuation date',
            'Period to liquidity event',
            'Dividend yield',
            'Selected volatility',
            'Risk free rate (Rf)',
          ].map((scholesInput) => (
            <View
              key={scholesInput}
              style={{
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <Text
                style={{
                  fontFamily: 'Roboto',
                  fontSize: '10px',
                  color: '#49454F',
                  paddingLeft: '16px',
                  letterSpacing: '0.5px',
                  textAlign: 'right',
                }}
              >
                {scholesInput}
              </Text>
              <Text
                style={{
                  fontFamily: 'Roboto',
                  fontSize: '10px',
                  color: '#49454F',
                  letterSpacing: '0.5px',
                  paddingLeft: '16px',
                }}
              >
                {returnScholesData(scholesInput)}
              </Text>
            </View>
          ))}
        </View>
        <View
          style={{
            marginTop: '12px',
            marginLeft: '24px',
            display: 'flex',
            flexDirection: 'column',
            width: '1184px',
            border: '1px',
            borderColor: '#AFB1B3',
          }}
        >
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              height: '24px',
              backgroundColor: '#0C2617',
            }}
          >
            <Text
              style={{
                fontFamily: 'RobotoMed',
                fontSize: '10px',
                color: '#8AB9A4',
                paddingLeft: '16px',
                letterSpacing: '0.08px',
                marginRight: '8px',
                width: '232px',
                paddingTop: '6px',
                borderBottom: '1px',
                borderColor: '#CCCCCC',
                height: '24px',
              }}
            >
              (USD$)
            </Text>
            <Text
              style={{
                fontFamily: 'RobotoMed',
                fontSize: '10px',
                color: '#E1E2EC',
                paddingLeft: '16px',
                letterSpacing: '0.08px',
                width: '1000px',
                paddingTop: '6px',
                borderBottom: '1px',
                borderColor: '#CCCCCC',
                height: '24px',
              }}
            >
              Incremental option value
            </Text>
          </View>
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              height: '40px',
              backgroundColor: '#307157',
              borderBottom: '1px',
              borderColor: '#CCCCCC',
            }}
          >
            {sortedBreakpoints.map((breakpointName, index) => (
              <Text
                key={breakpointName}
                style={{
                  fontFamily: 'RobotoMed',
                  fontSize: '9px',
                  color: '#E1E2EC',
                  width: `${952 / sortedBreakpoints.length}px`,
                  marginTop: 'auto',
                  paddingBottom: '4px',
                  paddingRight: '4px',
                  letterSpacing: '0.4px',
                  textAlign: 'right',
                  ...index === 0 && { marginLeft: '232px' },
                }}
              >
                {breakpointName.includes('|') ? breakpointName.split(' | ')[1]
                  .replace(' Million', '')
                  .replace('to', sortedBreakpoints.length > 10 ? 'to\n' : 'to') :
                  breakpointName.replace('All Participate', '\nAll\nParticipate')}
                <Text
                  key={breakpointName}
                  style={{
                    color: '#B2C6BE',
                  }}
                >
                  {breakpointName.includes('|') ? '\n$M' : '\n '}
                </Text>
              </Text>
            ))}
          </View>
          {[...Array(3)].map((_, i) => i).map((cellData, rowIndex) => (
            <View
              key={cellData}
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                height: '16px',
                backgroundColor: `${rowIndex % 2 ? '#F0F0F3' : '#FAFAFD'}`,
              }}
            >
              <Text
                style={{
                  fontFamily: [1, 3].includes(rowIndex) ? 'RobotoMed' : 'Roboto',
                  fontSize: '10px',
                  color: '#49454F',
                  width: '232px',
                  paddingLeft: rowIndex === 2 ? '24px' : '16px',
                  paddingRight: '4px',
                  letterSpacing: '0.4px',
                }}
              >
                {returnRowTitle(rowIndex)}
              </Text>
              {sortedBreakpoints.map((dataName, columnIndex) => (
                <Text
                  key={dataName}
                  style={{
                    fontFamily: [1, 3].includes(rowIndex) ? 'RobotoMed' : 'Roboto',
                    fontSize: '10px',
                    color: '#49454F',
                    width: `${952 / sortedBreakpoints.length}px`,
                    paddingRight: '4px',
                    letterSpacing: '0.4px',
                    textAlign: 'right',
                  }}
                >
                  {returnTopTableData(rowIndex, columnIndex) || 'N/A'}
                </Text>
              ))}
            </View>
          ))}
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              height: '16px',
              backgroundColor: '#9ECEBB',
            }}
          >
            <Text
              style={{
                fontFamily: 'RobotoMed',
                fontSize: '10px',
                color: '#49454F',
                width: '232px',
                paddingLeft: '16px',
                paddingRight: '4px',
                letterSpacing: '0.4px',
              }}
            >
              Incremental value
            </Text>
            {sortedBreakpoints.map((dataName, columnIndex) => (
              <Text
                key={dataName}
                style={{
                  fontFamily: 'RobotoMed',
                  fontSize: '10px',
                  color: '#49454F',
                  width: `${952 / sortedBreakpoints.length}px`,
                  paddingRight: '4px',
                  letterSpacing: '0.4px',
                  textAlign: 'right',
                }}
              >
                {returnTopTableData(3, columnIndex)}
              </Text>
            ))}
          </View>
        </View>
        <View
          style={{
            marginTop: '8px',
            marginLeft: '24px',
            display: 'flex',
            flexDirection: 'column',
            width: '1184px',
            borderTop: '1px',
            borderLeft: '1px',
            borderRight: '1px',
            borderColor: '#AFB1B3',
          }}
        >
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              height: '42px',
              backgroundColor: '#307157',
            }}
          >
            {table2Headers.map((dataName, index) => (
              <Text
                key={dataName}
                style={{
                  fontFamily: 'RobotoMed',
                  fontSize: '10px',
                  color: '#E1E2EC',
                  width: index === 0 ? '158px' : index === 1 ? '70px' : '950px',
                  marginTop: 'auto',
                  paddingBottom: '4px',
                  paddingLeft: [0, 2].includes(index) ? '16px' : '2px',
                  marginRight: '1px',
                  marginLeft: '1px',
                  marginBottom: '1px',
                  letterSpacing: '0.5px',
                  textAlign: index === 1 && 'right',
                  borderBottom: '2px',
                  borderColor: '#9ECEBB',
                }}
              >
                {dataName}
              </Text>
            ))}
          </View>
          {tableData.incrementalOptionValueAllocation['Ownership Class'].map((ownershipClass, rowIndex) => (
            <View
              key={ownershipClass}
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                height: '16px',
                backgroundColor: `${rowIndex % 2 ? '#F0F0F3' : '#FAFAFD'}`,
              }}
            >
              <Text
                style={{
                  fontFamily: 'Roboto',
                  fontSize: '10px',
                  color: '#49454F',
                  width: '160px',
                  paddingLeft: '8px',
                  letterSpacing: '0.4px',
                }}
              >
                {formatSeriesName(ownershipClass)}
              </Text>
              <Text
                style={{
                  fontFamily: 'Roboto',
                  fontSize: '10px',
                  color: '#49454F',
                  width: '72px',
                  paddingRight: '4px',
                  letterSpacing: '0.4px',
                  textAlign: 'right',
                }}
              >
                {commaEvery3rdChar(tableData.incrementalOptionValueAllocation['Total Shares Outstanding'][rowIndex])}
              </Text>
              {sortedBreakpoints.map((breakpointName, columnIndex) => (
                <Text
                  key={breakpointName}
                  style={{
                    fontFamily: 'Roboto',
                    fontSize: '10px',
                    color: '#49454F',
                    width: `${952 / sortedBreakpoints.length}px`,
                    paddingRight: '4px',
                    letterSpacing: '0.4px',
                    textAlign: 'right',
                  }}
                >
                  {returnCellData('incrementalOptionValueAllocation', rowIndex, columnIndex) || 'N/A'}
                </Text>
              ))}
            </View>
          ))}
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              height: '16px',
              backgroundColor: '#9ECEBB',
              borderTop: '1px',
              borderBottom: '1px',
              borderColor: '#999BA5',
            }}
          >
            <Text
              style={{
                fontFamily: 'RobotoMed',
                fontSize: '10px',
                color: '#49454F',
                width: '160px',
                paddingLeft: '16px',
                paddingRight: '4px',
                letterSpacing: '0.4px',
              }}
            >
              Total
            </Text>
            <Text
              style={{
                fontFamily: 'RobotoMed',
                fontSize: '10px',
                color: '#49454F',
                width: '72px',
                paddingRight: '4px',
                letterSpacing: '0.4px',
                textAlign: 'right',
              }}
            >
              {commaEvery3rdChar(tableData.incrementalOptionValueAllocation['Total Shares Outstanding'].reduce((a, b) => a + b, 0))}
            </Text>
            {sortedBreakpoints.map((breakpointName) => (
              <Text
                key={breakpointName}
                style={{
                  fontFamily: 'Roboto',
                  fontSize: '10px',
                  color: '#49454F',
                  width: `${952 / sortedBreakpoints.length}px`,
                  paddingRight: '4px',
                  letterSpacing: '0.4px',
                  textAlign: 'right',
                }}
              >
                100.00%
              </Text>
            ))}
          </View>
        </View>
        {!splitPages() && (
          <View
            style={{
              marginTop: '8px',
              marginLeft: '24px',
              display: 'flex',
              flexDirection: 'column',
              width: '1184px',
              borderTop: '1px',
              borderLeft: '1px',
              borderRight: '1px',
              borderColor: '#AFB1B3',
            }}
          >
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                height: '42px',
                backgroundColor: '#307157',
              }}
            >
              {table3Headers.map((dataName, index) => (
                <Text
                  key={dataName}
                  style={{
                    fontFamily: 'RobotoMed',
                    fontSize: '10px',
                    color: '#E1E2EC',
                    width: index === 0 ? '160px' : index === 1 ? '86px' : '936px',
                    marginTop: 'auto',
                    paddingBottom: '4px',
                    paddingLeft: [0, 2].includes(index) ? '16px' : '0px',
                    marginRight: '1px',
                    marginLeft: '1px',
                    marginBottom: '1px',
                    letterSpacing: '0.5px',
                    textAlign: index === 1 && 'right',
                    borderBottom: '2px',
                    borderColor: '#9ECEBB',
                  }}
                >
                  {dataName}
                </Text>
              ))}
            </View>
            {tableData.aggregateValuePerSharePerEquityClass['Ownership Class'].map((ownershipClass, rowIndex) => (
              <View
                key={ownershipClass}
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  height: '16px',
                  backgroundColor: `${rowIndex % 2 ? '#F0F0F3' : '#FAFAFD'}`,
                }}
              >
                <Text
                  style={{
                    fontFamily: 'Roboto',
                    fontSize: '10px',
                    color: '#49454F',
                    width: '160px',
                    paddingLeft: '8px',
                    letterSpacing: '0.4px',
                  }}
                >
                  {formatSeriesName(ownershipClass)}
                </Text>
                <Text
                  style={{
                    fontFamily: 'Roboto',
                    fontSize: '10px',
                    color: '#49454F',
                    width: '86px',
                    paddingRight: '4px',
                    letterSpacing: '0.4px',
                    textAlign: 'right',
                  }}
                >
                  {`$${commaEvery3rdChar(tableData.aggregateValuePerSharePerEquityClass['Total Aggregate Values'][rowIndex]?.toFixed(0))}`}
                </Text>
                {sortedBreakpoints.map((breakpointName, columnIndex) => (
                  <Text
                    key={breakpointName}
                    style={{
                      fontFamily: 'Roboto',
                      fontSize: '10px',
                      color: '#49454F',
                      width: `${952 / sortedBreakpoints.length}px`,
                      paddingRight: '4px',
                      letterSpacing: '0.4px',
                      textAlign: 'right',
                    }}
                  >
                    {returnCellData('aggregateValuePerSharePerEquityClass', rowIndex, columnIndex) || 'N/A'}
                  </Text>
                ))}
              </View>
            ))}
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                height: '16px',
                backgroundColor: '#9ECEBB',
                borderTop: '1px',
                borderBottom: '1px',
                borderColor: '#999BA5',
              }}
            >
              <Text
                style={{
                  fontFamily: 'RobotoMed',
                  fontSize: '10px',
                  color: '#49454F',
                  width: '160px',
                  paddingLeft: '8px',
                  paddingRight: '4px',
                  letterSpacing: '0.4px',
                }}
              >
                Total
              </Text>
              <Text
                style={{
                  fontFamily: 'RobotoMed',
                  fontSize: '10px',
                  color: '#49454F',
                  width: '86px',
                  paddingRight: '4px',
                  letterSpacing: '0.4px',
                  textAlign: 'right',
                }}
              >
                {`$${commaEvery3rdChar(tableData.aggregateValuePerSharePerEquityClass['Total Aggregate Values']
                  .reduce((a, b) => a + b, 0).toFixed(0))}`}
              </Text>
              {sortedBreakpoints.map((breakpointName) => (
                <Text
                  key={breakpointName}
                  style={{
                    fontFamily: 'Roboto',
                    fontSize: '10px',
                    color: '#49454F',
                    width: `${952 / sortedBreakpoints.length}px`,
                    paddingRight: '4px',
                    letterSpacing: '0.4px',
                    textAlign: 'right',
                  }}
                >
                  {`$${commaEvery3rdChar(tableData.aggregateValuePerSharePerEquityClass[breakpointName].reduce((a, b) => a + b, 0).toFixed(0))}`}
                </Text>
              ))}
            </View>
          </View>
        )}
        {!!footnote && !splitPages() && (
          <Text
            style={{
              fontFamily: 'Roboto',
              fontSize: '8px',
              color: '#49454F',
              letterSpacing: '0.4px',
              marginTop: '8px',
              marginLeft: '90px',
              width: '450px',
              lineHeight: '0.55mm',
            }}
          >
            Footnote:&nbsp;
            {footnote}
          </Text>
        )}
        <BottomPagination
          pageNumber={totalPageCount}
          reportData={reportData}
        />
        {reportData.isDraft && <DraftOverlay />}
      </Page>
      {(splitPages() === 2) && (
        <Page
          size="TABLOID"
          orientation="landscape"
          style={{ position: 'relative', backgroundColor: '#EAEAEB' }}
        >
          <TablePageHeader
            companyName={reportData.companyInfo.companyName}
            pageName="Guideline Public Company Method (GPC) Allocation    |   Exhibit 5"
          />
          <View
            style={{
              marginTop: '72px',
              marginLeft: '24px',
              display: 'flex',
              flexDirection: 'column',
              width: '1184px',
              borderTop: '1px',
              borderLeft: '1px',
              borderRight: '1px',
              borderColor: '#AFB1B3',
            }}
          >
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                height: '42px',
                backgroundColor: '#307157',
              }}
            >
              {table3Headers.map((dataName, index) => (
                <Text
                  key={dataName}
                  style={{
                    fontFamily: 'RobotoMed',
                    fontSize: '10px',
                    color: '#E1E2EC',
                    width: index === 0 ? '160px' : index === 1 ? '86px' : '950px',
                    marginTop: 'auto',
                    paddingBottom: '4px',
                    paddingLeft: [0, 2].includes(index) ? '16px' : '0px',
                    marginRight: '1px',
                    marginLeft: '1px',
                    marginBottom: '1px',
                    letterSpacing: '0.5px',
                    textAlign: index === 1 && 'right',
                    borderBottom: '2px',
                    borderColor: '#9ECEBB',
                  }}
                >
                  {dataName}
                </Text>
              ))}
            </View>
            {tableData.aggregateValuePerSharePerEquityClass['Ownership Class'].map((ownershipClass, rowIndex) => (
              <View
                key={ownershipClass}
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  height: '16px',
                  backgroundColor: `${rowIndex % 2 ? '#F0F0F3' : '#FAFAFD'}`,
                }}
              >
                <Text
                  style={{
                    fontFamily: 'Roboto',
                    fontSize: '10px',
                    color: '#49454F',
                    width: '160px',
                    paddingLeft: '8px',
                    letterSpacing: '0.4px',
                  }}
                >
                  {formatSeriesName(ownershipClass)}
                </Text>
                <Text
                  style={{
                    fontFamily: 'Roboto',
                    fontSize: '10px',
                    color: '#49454F',
                    width: '72px',
                    paddingRight: '4px',
                    letterSpacing: '0.4px',
                    textAlign: 'right',
                  }}
                >
                  {`$${commaEvery3rdChar(tableData.aggregateValuePerSharePerEquityClass['Total Aggregate Values'][rowIndex]?.toFixed(0))}`}
                </Text>
                {sortedBreakpoints.map((breakpointName, columnIndex) => (
                  <Text
                    key={breakpointName}
                    style={{
                      fontFamily: 'Roboto',
                      fontSize: '10px',
                      color: '#49454F',
                      width: `${952 / sortedBreakpoints.length}px`,
                      paddingRight: '4px',
                      letterSpacing: '0.4px',
                      textAlign: 'right',
                    }}
                  >
                    {returnCellData('aggregateValuePerSharePerEquityClass', rowIndex, columnIndex) || 'N/A'}
                  </Text>
                ))}
              </View>
            ))}
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                height: '16px',
                backgroundColor: '#9ECEBB',
                borderTop: '1px',
                borderBottom: '1px',
                borderColor: '#999BA5',
              }}
            >
              <Text
                style={{
                  fontFamily: 'RobotoMed',
                  fontSize: '10px',
                  color: '#49454F',
                  width: '160px',
                  paddingLeft: '8px',
                  paddingRight: '4px',
                  letterSpacing: '0.4px',
                }}
              >
                Total
              </Text>
              <Text
                style={{
                  fontFamily: 'RobotoMed',
                  fontSize: '10px',
                  color: '#49454F',
                  width: '72px',
                  paddingRight: '4px',
                  letterSpacing: '0.4px',
                  textAlign: 'right',
                }}
              >
                {`$${commaEvery3rdChar(tableData.aggregateValuePerSharePerEquityClass['Total Aggregate Values']
                  .reduce((a, b) => a + b, 0).toFixed(0))}`}
              </Text>
              {sortedBreakpoints.map((breakpointName) => (
                <Text
                  key={breakpointName}
                  style={{
                    fontFamily: 'Roboto',
                    fontSize: '10px',
                    color: '#49454F',
                    width: `${952 / sortedBreakpoints.length}px`,
                    paddingRight: '4px',
                    letterSpacing: '0.4px',
                    textAlign: 'right',
                  }}
                >
                  {`$${commaEvery3rdChar(tableData.aggregateValuePerSharePerEquityClass[breakpointName].reduce((a, b) => a + b, 0).toFixed(0))}`}
                </Text>
              ))}
            </View>
          </View>
          {!!footnote && (
            <Text
              style={{
                fontFamily: 'Roboto',
                fontSize: '8px',
                color: '#49454F',
                letterSpacing: '0.4px',
                marginTop: '8px',
                marginLeft: '90px',
                width: '450px',
                lineHeight: '0.55mm',
              }}
            >
              Footnote:&nbsp;
              {footnote}
            </Text>
          )}
          <BottomPagination
            pageNumber={totalPageCount + 1}
            reportData={reportData}
          />
          {reportData.isDraft && <DraftOverlay />}
        </Page>
      )}
    </>
  );
}

GPCAllocation.propTypes = {
  reportData: PropTypes.object.isRequired,
  sortedBreakpoints: PropTypes.array.isRequired,
  totalPageCount: PropTypes.number.isRequired,
  exhibitNumber: PropTypes.number.isRequired,
};
