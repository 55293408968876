import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Button from '@mui/material/Button';
import DoneAllOutlinedIcon from '@mui/icons-material/DoneAllOutlined';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';

import ProjectStatusCard from './ProjectStatusCard';

import './CompletedProjects.scss';

export default function CompletedProjects({
  enterpriseUsers,
  completedProjects,
  setCompletedProjects,
  setShowCompletedProjects,
  projectToView,
  setProjectToView,
  setInvestorCompanyDetailToView,
}) {
  const [columnOne, setColumnOne] = useState([]);
  const [columnTwo, setColumnTwo] = useState([]);
  const [columnThree, setColumnThree] = useState([]);

  useEffect(() => {
    const [column1, column2, column3] = completedProjects.reduce((result, obj, index) => {
      const targetArray = result[index % 3];
      targetArray.push(obj);
      return result;
    }, [[], [], []]);
    setColumnOne(column1);
    setColumnTwo(column2);
    setColumnThree(column3);
  }, []);

  return (
    <main className="CompletedProjects">
      <div className="complete-projects-wrapper">
        <Button
          className="home-btn"
          onClick={() => {
            setShowCompletedProjects(false);
          }}
        >
          <HomeOutlinedIcon />
          Home
        </Button>
        <div className="archived-header">
          <h2>
            <DoneAllOutlinedIcon />
            Completed 820 valuation projects
          </h2>
        </div>
        <div className="archived-container">
          <h2>Complete</h2>
          <div className="projects-gird">
            <div>
              {columnOne.map((project) => (
                <ProjectStatusCard
                  key={project.resource}
                  projectData={project}
                  projectToView={projectToView}
                  setProjectToView={setProjectToView}
                  enterpriseUsers={enterpriseUsers}
                  projectList={completedProjects}
                  setProjectList={setCompletedProjects}
                  setInvestorCompanyDetailToView={setInvestorCompanyDetailToView}
                />
              ))}
            </div>
            <div>
              {columnTwo.map((project) => (
                <ProjectStatusCard
                  key={project.resource}
                  projectData={project}
                  projectToView={projectToView}
                  setProjectToView={setProjectToView}
                  enterpriseUsers={enterpriseUsers}
                  projectList={completedProjects}
                  setProjectList={setCompletedProjects}
                  setInvestorCompanyDetailToView={setInvestorCompanyDetailToView}
                />
              ))}
            </div>
            <div>
              {columnThree.map((project) => (
                <ProjectStatusCard
                  key={project.resource}
                  projectData={project}
                  projectToView={projectToView}
                  setProjectToView={setProjectToView}
                  enterpriseUsers={enterpriseUsers}
                  projectList={completedProjects}
                  setProjectList={setCompletedProjects}
                  setInvestorCompanyDetailToView={setInvestorCompanyDetailToView}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

CompletedProjects.propTypes = {
  enterpriseUsers: PropTypes.array,
  completedProjects: PropTypes.array,
  setCompletedProjects: PropTypes.func,
  setShowCompletedProjects: PropTypes.func,
  projectToView: PropTypes.object,
  setProjectToView: PropTypes.func,
  setInvestorCompanyDetailToView: PropTypes.func,
};

CompletedProjects.defaultProps = {
  enterpriseUsers: [],
  completedProjects: [],
  setCompletedProjects: () => { },
  setShowCompletedProjects: () => { },
  projectToView: {},
  setProjectToView: () => { },
  setInvestorCompanyDetailToView: () => { },
};
