import React, { useState, useEffect, useRef, useContext, useCallback } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import isEqual from 'lodash.isequal';

import { Storage } from 'aws-amplify';

import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import LinearProgress from '@mui/material/LinearProgress';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';

import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import UploadFileOutlinedIcon from '@mui/icons-material/UploadFileOutlined';
import RequestPageOutlinedIcon from '@mui/icons-material/RequestPageOutlined';
import ListAltOutlinedIcon from '@mui/icons-material/ListAltOutlined';

import { ErrorMessageContext } from '../../../contexts';

import useS3 from '../../../hooks/useS3';
import useFetch from '../../../hooks/useFetch';

import { copy } from '../../../utils';

export default function UploadBlock({
  uploadType,
  transactionsData,
  setTransactionsData,
  index,
  setActiveStep,
  setSaveTransactionData,
  disableFields,
}) {
  const [filesList, setFilesList] = useState([]);

  const [fileUploading, setFileUploading] = useState(false);
  const [numberOfFilesUploading, setNumberOfFilesUploading] = useState(0);
  const [fileValue, setFileValue] = useState('');

  const [uploadProgress, setUploadProgress] = useState([]);
  const [uploadCompleted, setUploadCompleted] = useState(false);

  const [fileIsDraggingOver, setFileIsDraggingOver] = useState(false);

  const [currentNumberOfFiles, setCurrentNumberOfFiles] = useState(-1);

  const { setShowErrorMessage } = useContext(ErrorMessageContext);

  const uploadEl = useRef(null);
  const dragAndDropAreaRef = useRef(null);

  const transactionDataRef = useRef(transactionsData);

  const uploadsVariableNames = {
    'cap-table': 'capTableUploads',
    articles: 'articlesUploads',
    financials: 'financialForecastUploads',
    'additional-documents': 'additionalFinancialUploads',
    'schedule-of-investments': 'scheduleOfInvestmentsUploads',
    'secondary-transactions': 'secondaryTransactionsUploads',
  };

  const uploadDataRef = useRef(transactionsData[index]?.[uploadsVariableNames[uploadType]] || []);

  const validFileTypes = ['.xlsx', '.xls', '.xlsm', '.doc', '.docx', '.ppt', '.pptx', '.pps',
    '.ppsx', '.txt', '.pdf', '.csv', '.jpg', '.jpeg', '.heic', '.png', '.odt',
    '.fodt', '.odf', '.fods', '.odp', '.fodp', '.odg', '.fodg', '.odf', '.odb',
    '.sxw', '.stw', '.sxc', '.stc', '.sxi', '.sti', '.sxd', '.std', '.sxm'];

  const [, s3DeleteRequest] = useS3();

  const [, updateProjectRequest] = useFetch();

  async function updateProjectData(data) {
    updateProjectRequest({
      url: '/projects/asc820/update-project-details', method: 'post',
      body: data,
      bodyIds: ['requestUserId'],
    });
  }

  function handleIntakeChange(dataName, dataValue) {
    setActiveStep(4);
    const newData = copy(transactionDataRef.current);
    newData[index] = { ...newData[index], [dataName]: dataValue };
    setTransactionsData(newData);
    if (!isEqual(transactionsData, newData)) {
      if (uploadType !== 'schedule-of-investments') setSaveTransactionData(index);
      else updateProjectData(newData[index]);
    }
  }

  useEffect(() => {
    if (transactionsData[index]) {
      const transactionUploadData = transactionsData[index][uploadsVariableNames[uploadType]] || [];
      setFilesList(transactionUploadData);
      if (currentNumberOfFiles === -1) setCurrentNumberOfFiles(transactionUploadData.length);
    }
  }, [transactionsData]);

  useEffect(() => { uploadDataRef.current = filesList; }, [filesList]);

  useEffect(() => { transactionDataRef.current = transactionsData; }, [transactionsData]);

  async function handleFileUpload(event, filesToUpload) {
    event.preventDefault();
    if (!filesToUpload || !Object.keys(filesToUpload).length) return;
    if (Object.keys(filesToUpload).some((fileToUpload) => uploadDataRef.current
      .some((file) => file.fileName === filesToUpload[fileToUpload].name))) {
      setShowErrorMessage('One or more files of the same name have already been uploaded');
      return;
    }
    if (Object.keys(filesToUpload).some((file) => (filesToUpload[file].size >= 35000000))) {
      setShowErrorMessage('File size is too big');
      return;
    }
    if (!validFileTypes.some((fileType) => (Object.keys(filesToUpload)
      .some((file) => filesToUpload[file].name.toLowerCase().endsWith(fileType))))) {
      setShowErrorMessage('Invalid file(s) type uploaded');
      return;
    }
    try {
      setFileUploading(true);
      setNumberOfFilesUploading(Object.keys(filesToUpload).length);
      const dateTime = moment().format('YYYY-MM-DD');
      const newFileList = copy(uploadDataRef.current);
      Object.keys(filesToUpload).forEach((fileToUpload) => {
        const fileName = filesToUpload[fileToUpload].name;
        const location = `${uploadType === 'schedule-of-investments' ? `/${transactionsData[index]?.projectId}` :
          `/${transactionsData[index]?.portfolioCompanyId}/${transactionsData[index]?.transactionId}`}` +
          `/${dateTime}-${fileName}`;
        newFileList.push({ fileName, dateTime, location });
      });
      setFilesList(newFileList);
      handleIntakeChange(uploadsVariableNames[uploadType], newFileList);
      await Promise.all(Object.keys(filesToUpload).map(async (fileToUpload, fileIndex) => {
        const location = `${uploadType === 'schedule-of-investments' ? `/${transactionsData[index]?.projectId}` :
          `/${transactionsData[index]?.portfolioCompanyId}/${transactionsData[index]?.transactionId}`}` +
          `/${dateTime}-${filesToUpload[fileToUpload].name}`;
        await Storage.put(location, filesToUpload[fileToUpload], {
          customPrefix: { public: '820Uploads' },
          bucket: `${process.env.REACT_APP_UPLOADS_BUCKET}-${process.env.REACT_APP_ENV_LABEL}`,
          progressCallback(progress) {
            setUploadProgress((prevProgress) => {
              const newProgress = copy(prevProgress);
              newProgress[fileIndex] = progress;
              return newProgress;
            });
          },
        });
        if (!uploadProgress[fileIndex]) {
          setUploadProgress((prevProgress) => {
            const newProgress = copy(prevProgress);
            newProgress[fileIndex] = { total: 1, loaded: 1 };
            return newProgress;
          });
        }
      }));
      setTimeout(() => setUploadCompleted(true), 1000);
      setTimeout(() => {
        setUploadCompleted(false);
        setFileUploading(false);
        setUploadProgress([]);
      }, 3000);
    } catch (e) {
      setShowErrorMessage(e.toString());
      setUploadProgress([]);
      setFileUploading(false);
    }
  }

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setFileIsDraggingOver(true);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setFileIsDraggingOver(false);
    handleFileUpload(e, e.dataTransfer.files);
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setFileIsDraggingOver(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setFileIsDraggingOver(false);
  };

  const dragAndDropArea = useCallback((node) => {
    if (dragAndDropAreaRef?.current) {
      dragAndDropAreaRef.current.removeEventListener('dragover', handleDragOver);
      dragAndDropAreaRef.current.removeEventListener('drop', handleDrop);
      dragAndDropAreaRef.current.removeEventListener('dragenter', handleDragEnter);
      dragAndDropAreaRef.current.removeEventListener('dragleave', handleDragLeave);
    }
    if (node) {
      node.addEventListener('dragover', handleDragOver);
      node.addEventListener('drop', handleDrop);
      node.addEventListener('dragenter', handleDragEnter);
      node.addEventListener('dragleave', handleDragLeave);
    }
    dragAndDropAreaRef.current = node;
  }, []);

  function removeFile(file, fileIndex) {
    const key = `${uploadType === 'schedule-of-investments' ? `/${transactionsData[index]?.projectId}` :
      `/${transactionsData[index]?.portfolioCompanyId}/${transactionsData[index]?.transactionId}`}` +
      `/${file.dateTime}-${file.fileName}`;
    s3DeleteRequest({
      key,
      customPrefix: '820Uploads',
      bucket: `${process.env.REACT_APP_UPLOADS_BUCKET}-${process.env.REACT_APP_ENV_LABEL}`,
      requestType: 'remove',
      onSuccess: () => {
        const newFileList = copy(filesList);
        newFileList.splice(fileIndex, 1);
        setFilesList(newFileList);
        handleIntakeChange(uploadsVariableNames[uploadType], newFileList);
      },
    });
  }

  return (
    <div className={`upload-block ${uploadType}`}>
      <div className="upload-block-header">
        {uploadType === 'cap-table' ? (
          <>
            <AssessmentOutlinedIcon />
            <div>
              <h4>Upload Capitalization Table</h4>
              <p>
                Post-Close or a Pro Forma Cap Table. It must be current and capture all intended financing.
                <br />
                Please also upload this company&apos;s Option and Warrant Ledgers.
              </p>
            </div>
          </>
        ) : uploadType === 'articles' ? (
          <>
            <AccountBalanceIcon />
            <div>
              <h4>Upload Articles of incorporation</h4>
              <p>The most recent version of the revised and restated articles of incorporation.</p>
            </div>
          </>
        ) : uploadType === 'financials' ? (
          <>
            <RequestPageOutlinedIcon />
            <div>
              <h4>Financial documents</h4>
              <p className="bold">Please upload all of the following:</p>
              <ul>
                <li>Balance sheet as of valuation date, or most recent available, for last two fiscal years</li>
                <li>Cash flow documents</li>
                <li>Income statements:</li>
                <ul>
                  <li>last 12 months</li>
                  <li>last 12 months as of the most recent financing transaction date</li>
                  <li>next 12 months as of the most recent financing transaction date</li>
                  <li>and a forecast income statement (1-5 years, as available)</li>
                </ul>
              </ul>
            </div>
          </>
        ) : uploadType === 'schedule-of-investments' ? (
          <>
            <ListAltOutlinedIcon />
            <div>
              <h4>Schedule of investments</h4>
              <p>
                Please upload the &apos;Schedule of investments&apos; to provide investment cost, share holdings, date of investment,
                <br />
                and latest fair value/carrying value for all companies in this project.
                <br />
                You can upload multiple documents at the same time.
              </p>
            </div>
          </>
        ) : uploadType === 'additional-documents' ? (
          <>
            <UploadFileOutlinedIcon />
            <div>
              <h4>Additional documents</h4>
              <p>
                Upload as many additional documents as you have that could be relevant to this valuation,
                <br />
                such as: 409A valuations, investor memos, pitch decks or board decks.
              </p>
            </div>
          </>
        ) : null}
      </div>
      <div className="file-upload-container">
        <div
          className={`drag-and-drop-area${fileIsDraggingOver ? ' file-dragging' : ''}`}
          ref={dragAndDropArea}
        >
          {fileUploading && <div className="file-upload-loading-wrapper"><span className="dots-circle-spinner" /></div>}
          <div className="browse-or-drop">
            <UploadFileIcon />
            <span>Drop files here</span>
            <span>or</span>
            <Button
              onClick={() => {
                setActiveStep(4);
                setFileValue('');
                uploadEl.current.click();
              }}
              onKeyDown={(e) => { e.stopPropagation(); }}
            >
              Browse
            </Button>
          </div>
          <input
            ref={uploadEl}
            type="file"
            className="file-style"
            accept={validFileTypes.join(',')}
            value={fileValue}
            multiple="multiple"
            onChange={(e) => { handleFileUpload(e, e.target.files); }}
          />
        </div>
        <div className="uploaded-files">
          {filesList.map((file, fileIndex) => (
            <div
              className={`file${uploadCompleted && fileIndex >= filesList.length - numberOfFilesUploading ? ' completed' : ''}`}
              key={`${file.fileName}-${file.dateTime}`}
            >
              <div className="file-and-progress">
                <div className="file-name">
                  {uploadCompleted && fileIndex >= filesList.length - numberOfFilesUploading && <CheckCircleOutlineIcon className="check-icon" />}
                  {fileUploading && fileIndex >= filesList.length - numberOfFilesUploading && !uploadCompleted && (
                    <div className="dots-circle-spinner" />
                  )}
                  {uploadCompleted && fileIndex >= filesList.length - numberOfFilesUploading ? 'Uploaded ' :
                    fileUploading && fileIndex >= filesList.length - numberOfFilesUploading ? 'Uploading ' : ''}
                  {(!fileUploading || fileIndex < filesList.length - numberOfFilesUploading) && <InsertDriveFileOutlinedIcon />}
                  {(file.fileName.length >= 28) ? `${file.fileName.slice(0, 28).trim()}...` : file.fileName}
                </div>
                {fileUploading && fileIndex >= filesList.length - numberOfFilesUploading && (
                  <LinearProgress
                    variant="determinate"
                    value={uploadProgress[fileIndex - (filesList.length - numberOfFilesUploading)] ?
                      ((uploadProgress[fileIndex - (filesList.length - numberOfFilesUploading)].loaded /
                        uploadProgress[fileIndex - (filesList.length - numberOfFilesUploading)].total) * 100) : 0}
                  />
                )}
              </div>
              {!fileUploading && !(disableFields && fileIndex < currentNumberOfFiles) && (
                <IconButton className="clear-icon" onClick={() => removeFile(file, fileIndex)}>
                  <DeleteOutlineIcon />
                </IconButton>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

UploadBlock.propTypes = {
  uploadType: PropTypes.string.isRequired,
  transactionsData: PropTypes.array.isRequired,
  setTransactionsData: PropTypes.func.isRequired,
  index: PropTypes.number,
  setActiveStep: PropTypes.func,
  setSaveTransactionData: PropTypes.func.isRequired,
  disableFields: PropTypes.bool.isRequired,
};

UploadBlock.defaultProps = {
  setActiveStep: () => { },
  index: 0,
};
