import React, {
  useState,
  useRef,
  useContext,
  useCallback,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { Storage } from 'aws-amplify';

import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import LinearProgress from '@mui/material/LinearProgress';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';

import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

import { ErrorMessageContext } from '../../../contexts';

import { copy } from '../../../utils';

import useS3 from '../../../hooks/useS3';

import './UploadTile.scss';

export default function UploadTile({
  uploadData,
  setUploadData,
  isEditing,
  portfolioCompanyId,
  transactionId,
  setTriggerSave,
  projectId,
  isScheduleOfInvestments,
}) {
  const [fileUploading, setFileUploading] = useState(false);
  const [numberOfFilesUploading, setNumberOfFilesUploading] = useState(0);
  const [fileValue, setFileValue] = useState('');

  const [uploadProgress, setUploadProgress] = useState([]);
  const [uploadCompleted, setUploadCompleted] = useState(false);

  const [fileIsDraggingOver, setFileIsDraggingOver] = useState(false);

  const { setShowErrorMessage } = useContext(ErrorMessageContext);

  const uploadEl = useRef(null);
  const dragAndDropAreaRef = useRef(null);

  const uploadDataRef = useRef(uploadData);

  const validFileTypes = ['.xlsx', '.xls', '.xlsm', '.doc', '.docx', '.ppt', '.pptx', '.pps',
    '.ppsx', '.txt', '.pdf', '.csv', '.jpg', '.jpeg', '.heic', '.png', '.odt',
    '.fodt', '.odf', '.fods', '.odp', '.fodp', '.odg', '.fodg', '.odf', '.odb',
    '.sxw', '.stw', '.sxc', '.stc', '.sxi', '.sti', '.sxd', '.std', '.sxm', '.msg', '.eml'];

  const [, s3GetRequest] = useS3();
  const [, s3DeleteRequest] = useS3();

  useEffect(() => { uploadDataRef.current = uploadData; }, [uploadData]);

  async function handleFileUpload(event, filesToUpload) {
    event.preventDefault();
    if (!filesToUpload || !Object.keys(filesToUpload).length) return;
    if (Object.keys(filesToUpload).some((fileToUpload) => uploadDataRef.current.some((file) => file.fileName === filesToUpload[fileToUpload].name))) {
      setShowErrorMessage('One or more files of the same name have already been uploaded');
      return;
    }
    if (Object.keys(filesToUpload).some((file) => (filesToUpload[file].size >= 35000000))) {
      setShowErrorMessage('File size is too big');
      return;
    }
    if (!validFileTypes.some((fileType) => (Object.keys(filesToUpload).some((file) => filesToUpload[file].name.toLowerCase().endsWith(fileType))))) {
      setShowErrorMessage('Invalid file(s) type uploaded');
      return;
    }
    try {
      setFileUploading(true);
      setNumberOfFilesUploading(Object.keys(filesToUpload).length);
      const dateTime = moment().format('YYYY-MM-DD');
      const newFileList = copy(uploadDataRef.current);
      Object.keys(filesToUpload).forEach((fileToUpload) => {
        const fileName = filesToUpload[fileToUpload].name;
        const location = `${isScheduleOfInvestments ? `/${projectId}` : `/${portfolioCompanyId}/${transactionId}`}/${dateTime}-${fileName}`;
        newFileList.push({ fileName, dateTime, location });
      });
      setUploadData(newFileList);
      await Promise.all(Object.keys(filesToUpload).map(async (fileToUpload, fileIndex) => {
        const location = `${isScheduleOfInvestments ? `/${projectId}` : `/${portfolioCompanyId}/${transactionId}`}` +
          `/${dateTime}-${filesToUpload[fileToUpload].name}`;
        await Storage.put(location, filesToUpload[fileToUpload], {
          customPrefix: { public: '820Uploads' },
          bucket: `${process.env.REACT_APP_UPLOADS_BUCKET}-${process.env.REACT_APP_ENV_LABEL}`,
          progressCallback(progress) {
            setUploadProgress((prevProgress) => {
              const newProgress = copy(prevProgress);
              newProgress[fileIndex] = progress;
              return newProgress;
            });
          },
        });
        if (!uploadProgress[fileIndex]) {
          setUploadProgress((prevProgress) => {
            const newProgress = copy(prevProgress);
            newProgress[fileIndex] = { total: 1, loaded: 1 };
            return newProgress;
          });
        }
      }));
      setTimeout(() => setUploadCompleted(true), 1000);
      setTimeout(() => {
        setUploadCompleted(false);
        setFileUploading(false);
        setUploadProgress([]);
      }, 3000);
      setTriggerSave(true);
    } catch (e) {
      setShowErrorMessage(e.toString());
      setUploadProgress([]);
      setFileUploading(false);
    }
  }

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setFileIsDraggingOver(true);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setFileIsDraggingOver(false);
    handleFileUpload(e, e.dataTransfer.files);
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setFileIsDraggingOver(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setFileIsDraggingOver(false);
  };

  const dragAndDropArea = useCallback((node) => {
    if (dragAndDropAreaRef?.current) {
      dragAndDropAreaRef.current.removeEventListener('dragover', handleDragOver);
      dragAndDropAreaRef.current.removeEventListener('drop', handleDrop);
      dragAndDropAreaRef.current.removeEventListener('dragenter', handleDragEnter);
      dragAndDropAreaRef.current.removeEventListener('dragleave', handleDragLeave);
    }
    if (node) {
      node.addEventListener('dragover', handleDragOver);
      node.addEventListener('drop', handleDrop);
      node.addEventListener('dragenter', handleDragEnter);
      node.addEventListener('dragleave', handleDragLeave);
    }
    dragAndDropAreaRef.current = node;
  }, []);

  async function removeFile(file, fileIndex) {
    const key = `${isScheduleOfInvestments ? `/${projectId}` : `/${portfolioCompanyId}/${transactionId}`}/${file.dateTime}-${file.fileName}`;
    s3DeleteRequest({
      key,
      customPrefix: '820Uploads',
      requestType: 'remove',
      onSuccess: () => {
        const newFileList = copy(uploadData);
        newFileList.splice(fileIndex, 1);
        setUploadData(newFileList);
        setTriggerSave(true);
      },
    });
  }

  return (
    <div className="UploadTile">
      {isEditing && (
        <div
          className={`drag-and-drop-area${fileIsDraggingOver ? ' file-dragging' : ''}`}
          ref={dragAndDropArea}
        >
          {fileUploading && (
            <div className="file-upload-loading-wrapper">
              <span className="dots-circle-spinner" />
            </div>
          )}
          <div className="browse-or-drop">
            <span>Drop files here</span>
            <span>or</span>
            <Button
              onClick={() => {
                setFileValue('');
                uploadEl.current.click();
              }}
            >
              Browse
            </Button>
          </div>
          <input
            ref={uploadEl}
            type="file"
            className="file-style"
            accept={validFileTypes.join(',')}
            value={fileValue}
            multiple="multiple"
            onChange={(e) => { handleFileUpload(e, e.target.files); }}
          />
        </div>
      )}
      <div className="uploaded-files">
        {uploadData.map((file, fileIndex) => (
          <div
            className={`file${uploadCompleted && fileIndex >= uploadData.length - numberOfFilesUploading ? ' completed' : ''}`}
            key={`${file.fileName}-${file.dateTime}`}
          >
            {isEditing ? (
              <>
                <div className="file-and-progress">
                  <div className="file-name">
                    {uploadCompleted && fileIndex >= uploadData.length - numberOfFilesUploading && <CheckCircleOutlineIcon className="check-icon" />}
                    {fileUploading && fileIndex >= uploadData.length - numberOfFilesUploading && !uploadCompleted && (
                      <div className="dots-circle-spinner" />
                    )}
                    {uploadCompleted && fileIndex >= uploadData.length - numberOfFilesUploading ? 'Uploaded ' :
                      fileUploading && fileIndex >= uploadData.length - numberOfFilesUploading ? 'Uploading ' : ''}
                    {(!fileUploading || fileIndex < uploadData.length - numberOfFilesUploading) && <InsertDriveFileOutlinedIcon />}
                    {(file.fileName.length >= 28) ? `${file.fileName.slice(0, 28).trim()}...` : file.fileName}
                  </div>
                  {fileUploading && fileIndex >= uploadData.length - numberOfFilesUploading && (
                    <LinearProgress
                      variant="determinate"
                      value={uploadProgress[fileIndex - (uploadData.length - numberOfFilesUploading)] ?
                        ((uploadProgress[fileIndex - (uploadData.length - numberOfFilesUploading)].loaded /
                          uploadProgress[fileIndex - (uploadData.length - numberOfFilesUploading)].total) * 100) : 0}
                    />
                  )}
                </div>
                {!fileUploading && (
                  <IconButton
                    className="clear-icon"
                    onClick={() => removeFile(file, fileIndex)}
                  >
                    <DeleteOutlineIcon />
                    Delete file
                  </IconButton>
                )}
              </>
            ) : (
              <Button onClick={() => {
                s3GetRequest({
                  requestType: 'get',
                  key: file.location,
                  customPrefix: '820Uploads',
                  bucket: `${process.env.REACT_APP_UPLOADS_BUCKET}-${process.env.REACT_APP_ENV_LABEL}`,
                  onSuccess: (s3Response) => { window.open(s3Response); },
                });
              }}
              >
                <InsertDriveFileOutlinedIcon />
                {(file.fileName.length >= 28) ? `${file.fileName.slice(0, 28).trim()}...` : file.fileName}
              </Button>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

UploadTile.propTypes = {
  uploadData: PropTypes.array.isRequired,
  setUploadData: PropTypes.func.isRequired,
  isEditing: PropTypes.bool.isRequired,
  portfolioCompanyId: PropTypes.string.isRequired,
  transactionId: PropTypes.string.isRequired,
  setTriggerSave: PropTypes.func,
  projectId: PropTypes.string,
  isScheduleOfInvestments: PropTypes.bool,
};

UploadTile.defaultProps = {
  projectId: null,
  isScheduleOfInvestments: false,
  setTriggerSave: () => { },
};
