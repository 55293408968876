import React from 'react';
import PropTypes from 'prop-types';
import {
  Page,
  View,
  Text,
} from '@react-pdf/renderer';

import TablePageHeader from '../../components/TablePageHeader';
import BottomPagination from '../../components/BottomPagination';
import DraftOverlay from '../../components/DraftOverlay';

import { commaEvery3rdChar } from '../../../../../../utils';


export default function FairValue({
  reportData,
  totalPageCount,
  exhibitNumber,
}) {
  const tableData = reportData.FairValuePerShare;

  const topHeaders = [
    '',
    `Weight ${`${reportData.weights.backsolve}%`}`,
    ...tableData.pubco ? [`Weight ${`${reportData.weights.pubco}%`}`] : [],
    ...tableData.mna ? [`Weight ${`${reportData.weights.mna}%`}`] : [],
    'Concluded',
  ];

  const tableHeaders = [
    {
      header: 'Class',
      backendName: 'Ownership Class',
      formatting: ['series'],
    },
    {
      header: 'Shares',
      backendName: 'Shares',
      formatting: ['comma'],
      fix: 0,
    },
    {
      header: 'Aggregate\nvalue',
      backendName: 'Aggregate Value',
      formatting: ['comma', 'price'],
      fix: 0,
    },
    {
      header: 'Original\nissue price',
      backendName: 'Original Issue Price',
      formatting: ['price'],
      fix: 4,
    },
    {
      header: 'Backsolve fair value\n(per share)',
      backendName: 'Fair Value',
      formatting: ['price'],
      fix: 4,
    },
    ...tableData.pubco ? [
      {
        header: 'GPC fair value\n(per share)',
        backendName: 'Fair Value',
        formatting: ['price'],
        fix: 4,
      },
    ] : [],
    ...tableData.mna ? [
      {
        header: 'Backsolve fair value\n(per share)',
        backendName: 'Fair Value',
        formatting: ['price'],
        fix: 4,
      },
    ] : [],
    {
      header: 'Weighted fair value\n(per share)',
      backendName: 'Fair Value',
      formatting: ['price'],
      fix: 4,
    },
  ];

  const formatters = {
    comma: (val) => commaEvery3rdChar(val),
    price: (val) => `$${val}`,
    percent: (val) => `${val}%`,
    series: (val) => val.includes(' @ $') ? `${val.split(' @ $')[0]} @ $${new Intl.NumberFormat('en-US', { minimumFractionDigits: 2 })
      .format((val.split(' @ $')[val.split(' @ $').length - 1]))}` : val,
  };

  function returnCellData(tableHeader, rowIndex) {
    let dataToReturn;
    if (['GPC fair value\n(per share)', 'Weighted fair value\n(per share)', 'DLOM*'].includes(tableHeader.header)) {
      if (tableHeader.header === 'GPC fair value\n(per share)') dataToReturn = tableData.pubco[tableHeader.backendName][rowIndex];
      if (tableHeader.header === 'Weighted fair value\n(per share)') {
        if (tableData.pubco) {
          dataToReturn = (tableData.backsolve[tableHeader.backendName][rowIndex] * (reportData.weights.backsolve / 100) +
            tableData.pubco[tableHeader.backendName][rowIndex] * (reportData.weights.pubco / 100));
        } else {
          dataToReturn = tableData.backsolve[tableHeader.backendName][rowIndex];
        }
      }
    } else {
      dataToReturn = tableData.backsolve[tableHeader.backendName][rowIndex];
    }
    if (tableHeader.fix !== undefined) dataToReturn = dataToReturn.toFixed(tableHeader.fix);
    tableHeader.formatting.forEach((formatter) => { dataToReturn = formatters[formatter](dataToReturn); });
    return dataToReturn;
  }

  function returnTotal(tableHeader) {
    if (['Shares', 'Aggregate\nvalue'].includes(tableHeader.header)) {
      let total = tableData.backsolve[tableHeader.backendName].reduce((a, b) => a + b, 0);
      if (tableHeader.fix !== undefined) total = total.toFixed(tableHeader.fix);
      tableHeader.formatting.forEach((formatter) => { total = formatters[formatter](total); });
      return total;
    }
    return '';
  }

  const topHeaderWidth = (index) => {
    if (index === 0) return '540px';
    if (topHeaders.length === 5) return '128px';
    if (topHeaders.length === 4 && [1, 2].includes(index)) return '192px';
    if (topHeaders.length === 3 && index === 1) return '384px';
    return '128px';
  };

  const columnWidth = (index) => {
    if (index === 0) return '156px';
    if ([1, 2, 3].includes(index)) return '96px';
    if (topHeaders.length === 5) return '144px';
    if (topHeaders.length === 4 && [4, 5, 6].includes(index)) return '160px';
    if (topHeaders.length === 3 && index === 5) return '432px';
    return '144px';
  };

  const footnote = reportData.footnoteData.fairValuePerShare;

  return (
    <Page
      size="TABLOID"
      orientation="landscape"
      style={{ position: 'relative', backgroundColor: '#EAEAEB' }}
    >
      <TablePageHeader
        companyName={reportData.companyInfo.companyName}
        pageName={`Fair value per share   |   Exhibit ${exhibitNumber}`}
      />
      <View
        style={{
          marginTop: '84px',
          marginLeft: '80px',
          display: 'flex',
          flexDirection: 'column',
          width: '1064px',
          border: '1px',
          borderColor: '#AFB1B3',
        }}
      >
        <View
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '4px',
            paddingTop: '12px',
            paddingLeft: '16px',
            height: '56px',
            backgroundColor: '#0C2617',
            borderBottom: '1px',
            borderColor: '#CCCCCC',
          }}
        >
          <Text
            style={{
              fontFamily: 'RobotoMed',
              fontSize: '10px',
              color: '#E1E2EC',
              letterSpacing: '0.08px',
            }}
          >
            Fair value per share conclusion
          </Text>
          <Text
            style={{
              fontFamily: 'RobotoMed',
              fontSize: '10px',
              color: '#A4ABC0',
              letterSpacing: '0.08px',
            }}
          >
            Summary of results
          </Text>
        </View>
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            height: '32px',
            backgroundColor: '#307157',
          }}
        >
          {topHeaders.map((dataName, index) => (
            <Text
              key={dataName}
              style={{
                fontFamily: 'RobotoMed',
                fontSize: '10px',
                color: '#E1E2EC',
                width: topHeaderWidth(index),
                marginTop: 'auto',
                marginRight: '16px',
                paddingBottom: '6px',
                letterSpacing: '0.5px',
                textAlign: 'center',
                borderBottom: '1px',
                borderColor: '#CCCCCC',
              }}
            >
              {dataName}
            </Text>
          ))}
        </View>
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            height: '56px',
            backgroundColor: '#307157',
            borderBottom: '1px',
            borderColor: '#CCCCCC',
          }}
        >
          {tableHeaders.map((dataName, index) => (
            <Text
              key={dataName.header}
              style={{
                fontFamily: 'RobotoMed',
                fontSize: '10px',
                color: '#E1E2EC',
                width: columnWidth(index),
                marginTop: 'auto',
                ...index === 0 && { paddingLeft: '16px' },
                letterSpacing: '0.5px',
                textAlign: index !== 0 && 'center',
                lineHeight: '0.68mm',
                ...index === 3 && { marginRight: 'auto' },
                ...index === 4 && { marginRight: '42px' },
              }}
            >
              {dataName.header}
            </Text>
          ))}
        </View>
        {tableData.backsolve['Ownership Class'].map((equityClass, rowIndex) => (
          <View
            key={equityClass}
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              height: '32px',
              backgroundColor: `${rowIndex % 2 ? '#F0F0F3' : '#FAFAFD'}`,
            }}
          >
            {tableHeaders.map((tableHeader, cellIndex) => (
              <Text
                key={tableHeader.header}
                style={{
                  fontFamily: 'Roboto',
                  fontSize: '10px',
                  color: '#49454F',
                  width: columnWidth(cellIndex),
                  ...cellIndex === 0 && { paddingLeft: '16px' },
                  letterSpacing: '0.4px',
                  ...cellIndex !== 0 && { textAlign: 'center' },
                  ...cellIndex === 3 && { marginRight: 'auto' },
                  ...cellIndex === 4 && { marginRight: '42px' },
                }}
              >
                {returnCellData(tableHeader, rowIndex) || 'N/A'}
              </Text>
            ))}
          </View>
        ))}
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            height: '32px',
            backgroundColor: '#9ECEBB',
          }}
        >
          {tableHeaders.map((tableHeader, cellIndex) => (
            <Text
              key={tableHeader.header}
              style={{
                fontFamily: 'Roboto',
                fontSize: '10px',
                color: '#49454F',
                width: columnWidth(cellIndex),
                ...cellIndex === 0 && { paddingLeft: '16px' },
                letterSpacing: '0.4px',
                ...cellIndex !== 0 && { textAlign: 'center' },
              }}
            >
              {returnTotal(tableHeader)}
            </Text>
          ))}
        </View>
      </View>
      {!!footnote && (
        <Text
          style={{
            fontFamily: 'Roboto',
            fontSize: '8px',
            color: '#49454F',
            letterSpacing: '0.4px',
            marginTop: '16px',
            marginLeft: '90px',
            width: '450px',
            lineHeight: '0.55mm',
          }}
        >
          Footnote:&nbsp;
          {footnote}
        </Text>
      )}
      <BottomPagination
        pageNumber={totalPageCount}
        reportData={reportData}
      />
      {reportData.isDraft && <DraftOverlay />}
    </Page>
  );
}

FairValue.propTypes = {
  reportData: PropTypes.object.isRequired,
  totalPageCount: PropTypes.number.isRequired,
  exhibitNumber: PropTypes.number.isRequired,
};
