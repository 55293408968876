import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import { createBrowserHistory } from 'history';
import { useLocation } from 'react-router-dom';
import { NavigationContext } from '.';

const history = createBrowserHistory();

export default function NavigationProvider({ children }) {
  const [from, setFrom] = useState(undefined);
  const [to, setTo] = useState(undefined);
  const location = useLocation();

  useEffect(() => { setFrom(location); }, [location]);

  useEffect(() => {
    history.listen((update) => { if (update.action === 'POP') setTo(update.location); });
  }, []);

  const navigationBundle = useMemo(() => ({ from, to }), [from, to]);

  return <NavigationContext.Provider value={navigationBundle}>{children}</NavigationContext.Provider>;
}

NavigationProvider.propTypes = { children: PropTypes.element.isRequired };
