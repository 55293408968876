import React from 'react';
import PropTypes from 'prop-types';
import {
  Path,
  Svg,
} from '@react-pdf/renderer';

export default function FlagIcon({ fillColor }) {
  return (
    <Svg width="12" height="12">
      {/* eslint-disable-next-line */}
      <Path d="M6.95 3.67188L6.83 3.07188C6.785 2.84188 6.58 2.67188 6.34 2.67188H2.75C2.475 2.67188 2.25 2.89687 2.25 3.17188V10.6719C2.25 10.9469 2.475 11.1719 2.75 11.1719C3.025 11.1719 3.25 10.9469 3.25 10.6719V7.67188H6.05L6.17 8.27187C6.215 8.50687 6.42 8.67188 6.66 8.67188H9.25C9.525 8.67188 9.75 8.44688 9.75 8.17188V4.17188C9.75 3.89687 9.525 3.67188 9.25 3.67188H6.95Z" fill={fillColor} />
    </Svg>
  );
}

FlagIcon.propTypes = {
  fillColor: PropTypes.string,
};

FlagIcon.defaultProps = {
  fillColor: '#307157',
};
