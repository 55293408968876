import React from 'react';

import { Image } from '@react-pdf/renderer';

import draftOverlay from '../assets/images/draft-overlay.png';

export default function DraftOverlay() {
  return (
    <Image
      src={draftOverlay}
      style={{
        position: 'absolute',
        width: '1090px',
        height: '619px',
        top: '86.5px',
        left: '67px',
      }}
    />
  );
}
