import React from 'react';
import PropTypes from 'prop-types';

import { commaEvery3rdChar } from '../../../utils';

import './SummariesTable.scss';

export default function SummariesTable({
  tabToView,
  filterSelected,
  summariesData,
  fiscalYears,
  reSummarizing,
  filters,
  filterValues,
  summariesScrollRef,
  transactionDate,
  valuationDate,
  financialStatementDate,
}) {
  const getYear = (dataIndex) => {
    const increment = dataIndex / 4 + 0.25;
    return `${increment.toFixed(increment.toFixed(2).toString().charAt(increment.toFixed(2).toString().length - 1) === '0' ? 1 : 2)} year`;
  };

  const getPrice = (dataName, index) => summariesData[dataName]?.[index] ?
    commaEvery3rdChar((summariesData[dataName][index] * 1000000).toFixed(0)) : '-';

  const getPerc = (dataName, index, isGrossMargin) => summariesData[dataName]?.[index] ?
    `${(summariesData[dataName][index] * (!isGrossMargin ? 100 : 1)).toFixed(2)}%` : '-';

  const getMultiple = (dataName, index) => summariesData[dataName]?.[index] ? `${(summariesData[dataName][index]).toFixed(2)}x` : '-';

  return (
    <main className="SummariesTable">
      <div className="fixed-left">
        <div className="summaries-table-rows">
          {summariesData.stats?.length && ['Maximum', '95th percentile', '90th percentile',
            '75th percentile', 'Median', '25th percentile',
            '10th percentile', '5th percentile', 'Minimum', 'Average',
          ].map((rowName) => (
            <div key={rowName} className="summaries-table-row-data">
              <div className="row-data-group summaries-list">
                <div className="table-cell">{rowName}</div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="right-scrollable">
        {reSummarizing && <div className="loading-overlay"><div className="dots-circle-spinner" /></div>}
        <div className="summaries-table-rows" ref={summariesScrollRef}>
          {summariesData.stats?.length && ['Maximum', '95th percentile', '90th percentile',
            '75th percentile', 'Average', 'Median', '25th percentile',
            '10th percentile', '5th percentile', 'Minimum',
          ].map((rowName, index) => (
            <div key={rowName} className="summaries-table-row-data">
              {(!filterSelected || filters.showCompanyInfo || tabToView === 5) && (
                <div className="row-data-group basic-info">
                  <div className="table-cell" />
                  <div className="table-cell" />
                  {tabToView === 6 && (
                    <>
                      <div className="table-cell" />
                      <div className="table-cell" />
                      <div className="table-cell" />
                      <div className="table-cell" />
                      <div className="table-cell" />
                      <div className="table-cell" />
                    </>
                  )}
                </div>
              )}
              {tabToView === 5 && (!filterSelected || filters.showVolatilityValDate) && (
                <div className="row-data-group val-volatility">
                  {[...Array(40)].map((el, dataIndex) => (
                    (!filterValues.yearsFilterValues.length || filterValues.yearsFilterValues
                      .includes(dataIndex / 4 + 0.25 <= 1 ? `${dataIndex / 4 + 0.25} year` : `${dataIndex / 4 + 0.25} years`)) && (
                      <div key={getYear(dataIndex)} className="table-cell">
                        {summariesData[`Volatility - Valuation Date | ${getYear(dataIndex)}`][index] ?
                          `${((summariesData[`Volatility - Valuation Date | ${getYear(dataIndex)}`][index]) * 100).toFixed(2)}%` : '-'}
                      </div>
                    )))}
                </div>
              )}
              {tabToView === 5 && (!filterSelected || filters.showVolatilityTrDate) && (
                <div className="row-data-group tr-volatility">
                  {[...Array(40)].map((el, dataIndex) => (
                    (!filterValues.yearsFilterValues.length || filterValues.yearsFilterValues
                      .includes(dataIndex / 4 + 0.25 <= 1 ? `${dataIndex / 4 + 0.25} year` : `${dataIndex / 4 + 0.25} years`)) && (
                      <div key={getYear(dataIndex)} className="table-cell">
                        {summariesData[`Volatility - Transaction Date | ${getYear(dataIndex)}`][index] ?
                          `${((summariesData[`Volatility - Transaction Date | ${getYear(dataIndex)}`][index]) * 100).toFixed(2)}%` : '-'}
                      </div>
                    )
                  ))}
                </div>
              )}
              {(!filterSelected || filters.showMarketCap) && (
                <div className="row-data-group market-cap">
                  <div className="table-cell">{getPrice(`Market Capitalization | Transaction Date - ${transactionDate}`, index)}</div>
                  <div className="table-cell">{getPrice(`Market Capitalization | Valuation Date - ${valuationDate}`, index)}</div>
                  <div className="table-cell">{getPrice(`Market Capitalization | Financial Statement Date - ${financialStatementDate}`, index)}</div>
                </div>
              )}
              {tabToView === 6 && (!filterSelected || filterValues.growthFilterValues.includes('Market growth')) && (
                <div className="row-data-group market-growth">
                  <div className="table-cell">{getPerc('Market Growth | Transaction Date to Valuation Date', index)}</div>
                </div>
              )}
              {(!filterSelected || filters.showRevenue) && (
                <div className="row-data-group revenue">
                  {fiscalYears.map((fiscalYear) => {
                    if (!filterValues.fiscalPeriodFilterValues.length || filterValues.fiscalPeriodFilterValues
                      .some((val) => val.replace(/ *\([^)]*\) */g, '').replace('12', 'twelve') === fiscalYear.title)) {
                      return (
                        <div key={fiscalYear.title} className="table-cell">
                          {getPrice(`Revenue | ${fiscalYear.legend.replace(' ', '')}`, index)}
                        </div>
                      );
                    }
                    return null;
                  })}
                </div>
              )}
              {tabToView === 6 && (!filterSelected || filters.showEBITDA) && (
                <div className="row-data-group EBITDA">
                  {fiscalYears.map((fiscalYear) => {
                    if (!filterValues.fiscalPeriodFilterValues.length ||
                      filterValues.fiscalPeriodFilterValues.some((val) => val.replace(/ *\([^)]*\) */g, '')
                        .replace('12', 'twelve') === fiscalYear.title)) {
                      return (
                        <div key={fiscalYear.title} className="table-cell">
                          {getPrice(`EBITDA | ${fiscalYear.legend.replace(' ', '')}`, index)}
                        </div>
                      );
                    }
                    return null;
                  })}
                </div>
              )}
              {tabToView === 6 && (!filterSelected || filters.showGrossProfit) && (
                <div className="row-data-group gross-profit">
                  {fiscalYears.map((fiscalYear) => {
                    if (!filterValues.fiscalPeriodFilterValues.length ||
                      filterValues.fiscalPeriodFilterValues.some((val) => val.replace(/ *\([^)]*\) */g, '')
                        .replace('12', 'twelve') === fiscalYear.title)) {
                      return (
                        <div key={fiscalYear.title} className="table-cell">
                          {getPrice(`Gross Profit | ${fiscalYear.legend.replace(' ', '')}`, index)}
                        </div>
                      );
                    }
                    return null;
                  })}
                </div>
              )}
              {tabToView === 6 && (!filterSelected || filterValues.growthFilterValues.includes('Revenue growth')) && (
                <div className="row-data-group revenue-growth">
                  {fiscalYears.map((fiscalYear) => {
                    if (!filterValues.fiscalPeriodFilterValues.length ||
                      filterValues.fiscalPeriodFilterValues.some((val) => val.replace(/ *\([^)]*\) */g, '')
                        .replace('12', 'twelve') === fiscalYear.title)) {
                      return (
                        <div key={fiscalYear.title} className="table-cell">
                          {getPerc(`Revenue Growth Rate | ${fiscalYear.legend.replace(' ', '')}`, index)}
                        </div>
                      );
                    }
                    return null;
                  })}
                </div>
              )}
              {tabToView === 6 && (!filterSelected || filterValues.growthFilterValues.includes('EBITDA growth')) && (
                <div className="row-data-group EBITDA-growth">
                  {fiscalYears.map((fiscalYear) => {
                    if (!filterValues.fiscalPeriodFilterValues.length ||
                      filterValues.fiscalPeriodFilterValues.some((val) => val.replace(/ *\([^)]*\) */g, '')
                        .replace('12', 'twelve') === fiscalYear.title)) {
                      return (
                        <div key={fiscalYear.title} className="table-cell">
                          {getPerc(`EBITDA Growth Rate | ${fiscalYear.legend.replace(' ', '')}`, index)}
                        </div>
                      );
                    }
                    return null;
                  })}
                </div>
              )}
              {tabToView === 6 && (!filterSelected || filterValues.growthFilterValues.includes('Gross Profit growth')) && (
                <div className="row-data-group gross-profit-growth">
                  {fiscalYears.map((fiscalYear) => {
                    if (!filterValues.fiscalPeriodFilterValues.length ||
                      filterValues.fiscalPeriodFilterValues.some((val) => val.replace(/ *\([^)]*\) */g, '')
                        .replace('12', 'twelve') === fiscalYear.title)) {
                      return (
                        <div key={fiscalYear.title} className="table-cell">
                          {getPerc(`Gross Profit Growth Rate | ${fiscalYear.legend.replace(' ', '')}`, index)}
                        </div>
                      );
                    }
                    return null;
                  })}
                </div>
              )}
              {tabToView === 6 && (!filterSelected || filterValues.multiplesFilterValues.includes('Revenue multiples')) && (
                <div className="row-data-group revenue-multiples">
                  {fiscalYears.map((fiscalYear) => {
                    if (!filterValues.fiscalPeriodFilterValues.length ||
                      filterValues.fiscalPeriodFilterValues.some((val) => val.replace(/ *\([^)]*\) */g, '')
                        .replace('12', 'twelve') === fiscalYear.title)) {
                      return (
                        <div key={fiscalYear.title} className="table-cell">
                          {getMultiple(`Revenue Multiple${fiscalYear.legend.includes('NTM') ? 's' : ''} | ` +
                            `${fiscalYear.legend.replace(' ', '')}`, index)}
                        </div>
                      );
                    }
                    return null;
                  })}
                </div>
              )}
              {tabToView === 6 && (!filterSelected || filterValues.multiplesFilterValues.includes('EBITDA multiples')) && (
                <div className="row-data-group EBITDA-multiples">
                  {fiscalYears.map((fiscalYear) => {
                    if (!filterValues.fiscalPeriodFilterValues.length ||
                      filterValues.fiscalPeriodFilterValues.some((val) => val.replace(/ *\([^)]*\) */g, '')
                        .replace('12', 'twelve') === fiscalYear.title)) {
                      return (
                        <div key={fiscalYear.title} className="table-cell">
                          {getMultiple(`EBITDA Multiple${fiscalYear.legend.includes('NTM') ? 's' : ''} | ` +
                            `${fiscalYear.legend.replace(' ', '')}`, index)}
                        </div>
                      );
                    }
                    return null;
                  })}
                </div>
              )}
              {tabToView === 6 && (!filterSelected || filterValues.growthFilterValues.includes('EBITDA Margin')) && (
                <div className="row-data-group EBITDA-margin">
                  {fiscalYears.map((fiscalYear) => {
                    if (!filterValues.fiscalPeriodFilterValues.length ||
                      filterValues.fiscalPeriodFilterValues.some((val) => val.replace(/ *\([^)]*\) */g, '')
                        .replace('12', 'twelve') === fiscalYear.title)) {
                      return (
                        <div key={fiscalYear.title} className="table-cell">
                          {getPerc(`EBITDA Margin | ${fiscalYear.legend.replace(' ', '')}`, index)}
                        </div>
                      );
                    }
                    return null;
                  })}
                </div>
              )}
              {tabToView === 6 && (!filterSelected || filterValues.growthFilterValues.includes('Gross Margin')) && (
                <div className="row-data-group gross-margin">
                  {fiscalYears.map((fiscalYear) => {
                    if (!filterValues.fiscalPeriodFilterValues.length ||
                      filterValues.fiscalPeriodFilterValues.some((val) => val.replace(/ *\([^)]*\) */g, '')
                        .replace('12', 'twelve') === fiscalYear.title)) {
                      return (
                        <div key={fiscalYear.title} className="table-cell">
                          {getPerc(`Gross Margin | ${fiscalYear.legend.replace(' ', '')}`, index, true)}
                        </div>
                      );
                    }
                    return null;
                  })}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </main>
  );
}

SummariesTable.propTypes = {
  tabToView: PropTypes.number.isRequired,
  filterSelected: PropTypes.bool.isRequired,
  fiscalYears: PropTypes.array.isRequired,
  reSummarizing: PropTypes.bool.isRequired,
  filters: PropTypes.object.isRequired,
  filterValues: PropTypes.object.isRequired,
  summariesData: PropTypes.object.isRequired,
  summariesScrollRef: PropTypes.func.isRequired,
  transactionDate: PropTypes.string.isRequired,
  valuationDate: PropTypes.string.isRequired,
  financialStatementDate: PropTypes.string,
};

SummariesTable.defaultProps = {
  financialStatementDate: '',
};
