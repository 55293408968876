import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  createRef,
} from 'react';

import PropTypes from 'prop-types';

import {
  Document,
  Page,
  Thumbnail,
  pdfjs,
} from 'react-pdf';

import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import Dialog from '@mui/material/Dialog';

import ApprovalOutlinedIcon from '@mui/icons-material/ApprovalOutlined';
import SendIcon from '@mui/icons-material/Send';
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';

import useS3 from '../../../hooks/useS3';
import useFetch from '../../../hooks/useFetch';

export default function PDFViewer({
  userData,
  pdfUrl,
  reportToView,
  pdfLoading,
  setPdfLoading,
  reportList,
  setGenerateNewReport,
  primaryInvestorEmail,
  primaryInvestorName,
}) {
  const [pageInView, setPageInView] = useState(null);
  const [pageToView, setPageToView] = useState(null);
  const [numberOfPages, setNumberOfPages] = useState(null);

  const [showSendEmailConfirmation, setShowEmailConfirmation] = useState(false);

  const reportScrollRef = useRef(null);
  const thumbnailScrollRef = useRef(null);
  const reportPagesRef = useRef([]);
  const thumbnailsRef = useRef([]);
  const pagePositions = useRef([]);

  const [{ loading: sendingEmail }, sendReportEmailRequest] = useFetch();

  pdfjs.GlobalWorkerOptions.workerSrc = '//unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js';

  function handleReportScroll() {
    const currentPageInView = pagePositions.current.findIndex((_, i) => pagePositions.current[i] <= reportScrollRef.current.scrollTop &&
      pagePositions.current[i + 1] > reportScrollRef.current.scrollTop) + 1;
    if (currentPageInView !== 0) setPageInView(currentPageInView);
    else setPageInView(pagePositions.current.length);
  }

  const reportViewerRef = useCallback((node) => {
    if (reportScrollRef?.current) reportScrollRef.current.removeEventListener('scroll', handleReportScroll);
    if (node) node.addEventListener('scroll', handleReportScroll, { passive: true });
    reportScrollRef.current = node;
  }, []);

  useEffect(() => {
    if (pageToView) {
      const pageToViewPosition = reportPagesRef.current?.[pageToView - 1]?.current?.offsetTop;
      if (pageToViewPosition) reportScrollRef.current.scrollTo({ top: pageToViewPosition - 52, behavior: 'smooth' });
      setPageInView(pageToView);
      setPageToView(null);
    }
  }, [pageToView]);

  useEffect(() => {
    const thumbnailToViewPosition = thumbnailsRef.current[pageInView - 1]?.current?.offsetTop;
    thumbnailScrollRef.current?.scrollTo({ top: thumbnailToViewPosition - 300, behavior: 'smooth' });
  }, [pageInView]);

  function onDocumentLoadSuccess(pdf) {
    setNumberOfPages(pdf.numPages);
    setPageInView(1);
    reportPagesRef.current = [...Array(pdf.numPages)].map((_, i) => reportPagesRef.current[i] ?? createRef());
    thumbnailsRef.current = [...Array(pdf.numPages)].map((_, i) => thumbnailsRef.current[i] ?? createRef());
    pagePositions.current = ([...Array(pdf.numPages)].map((_, i) => i === 0 ? 0 : (i * 678) + 52));
    setPdfLoading(false);
  }

  const [, s3DownloadRequest] = useS3();
  const [{ loading: gettingS3File }, s3GetRequest] = useS3();

  async function downloadPDF() {
    s3DownloadRequest({
      key: reportToView.key,
      customPrefix: `820PortfolioReports/${userData.metaData.portfolioCompanyId}/${userData.metaData.transactionId}`,
      requestType: 'get',
      contentDisposition: `attachment; filename ="${reportToView.displayName}.pdf"`,
      onSuccess: (s3Response) => { window.open(s3Response); },
    });
  }

  async function sendReportToClient() {
    s3GetRequest({
      key: reportToView.key,
      customPrefix: `820PortfolioReports/${userData.metaData.portfolioCompanyId}/${userData.metaData.transactionId}`,
      requestType: 'get',
      onSuccess: (s3Response) => {
        sendReportEmailRequest({
          url: '/email/send-html-email-with-attachment',
          method: 'post',
          body: {
            recipient: primaryInvestorEmail,
            templateName: reportToView.type === 'draft' ? '820draftReportReady' : '820finalReportReady',
            attachmentLink: s3Response,
            attachmentName: `${reportToView.displayName}.pdf`,
            templateData: [
              userData.metaData.portfolioCompanyName,
              primaryInvestorName,
              'Tim', // TODO: change hardcoded value
              'Redwood Valuations', // TODO: change hardcoded value
              reportToView.type === 'draft' ? 'info@initio.software' : // TODO: change hardcoded value
                userData.metaData.investorFirmName,
            ],
          },
          onSuccess: () => setShowEmailConfirmation(false),
        });
      },
    });
  }

  return (
    <>
      <div className="page-list-and-report-viewer-wrapper">
        <div className="page-list" ref={thumbnailScrollRef}>
          <h6>Pages</h6>
          {pdfLoading ? <div className="dots-circle-spinner" /> : (
            <Document file={pdfUrl}>
              {[...Array(numberOfPages)].map((_, pageIndex) => pageIndex + 1).map((pageNumber, pageIndex) => (
                <div ref={thumbnailsRef.current[pageIndex]} key={pageNumber}>
                  <div
                    role="button"
                    aria-label="View page"
                    className={`page-thumbnail${pageInView === pageNumber ? ' page-in-view' : ''}`}
                    onKeyDown={(e) => { if (e.key === 'Enter') setPageToView(pageNumber); }}
                    tabIndex={0}
                  >
                    <Thumbnail
                      width={144}
                      pageNumber={pageNumber}
                      onItemClick={() => setPageToView(pageNumber)}
                    />
                  </div>
                  <p>{new Intl.NumberFormat('en-US', { minimumIntegerDigits: 2 }).format(pageNumber)}</p>
                </div>
              ))}
            </Document>
          )}
        </div>
        <div className="report-viewer-wrapper">
          <div className="report-viewer" ref={reportViewerRef}>
            <p className="report-title">{reportToView.displayName}</p>
            <Document file={pdfUrl} onLoadSuccess={(pdf) => onDocumentLoadSuccess(pdf)} loading=" " error=" ">
              {pdfLoading ? (
                <div className="loading-pdf">
                  <div className="dots-circle-spinner" />
                  <p>Loading PDF...</p>
                </div>
              ) : (
                <>
                  {[...Array(numberOfPages)].map((_, pageIndex) => pageIndex + 1).map((pageNumber, pageIndex) => (
                    <Page
                      key={pageNumber}
                      inputRef={reportPagesRef.current[pageIndex]}
                      width={1054}
                      pageNumber={pageNumber}
                      renderAnnotationLayer={false}
                      renderTextLayer={false}
                    />
                  ))}
                </>
              )}
            </Document>
          </div>
        </div>
        <div className="report-footer">
          <div className="right-btns">
            <Button onClick={() => downloadPDF()}>
              <DownloadOutlinedIcon />
              Download
            </Button>
            {reportToView.type === 'final' ||
              reportList.filter((report) => report.type === 'final').some((finalReport) => finalReport.dbVersion === reportToView.dbVersion) ?
              (
                <Button disabled>
                  <ApprovalOutlinedIcon />
                  Finalize draft
                </Button>
              ) : (
                <Tooltip
                  disableInteractive
                  title={'Finalized reports don\'t have the word \'draft\' covering the pages.\
                    You can finalize as many drafts as you like and choose which to send to your client and when to send them.'}
                  PopperProps={{ className: 'bottom-arrow-tooltip' }}
                  placement="top"
                  arrow
                >
                  <Button onClick={() => setGenerateNewReport({ type: 'final', version: reportToView.dbVersion })}>
                    <ApprovalOutlinedIcon />
                    Finalize draft
                  </Button>
                </Tooltip>
              )}
            {!primaryInvestorEmail ? (
              <Button disabled>
                <SendIcon />
                Send to client
              </Button>
            ) : (
              <Tooltip
                disableInteractive
                title={primaryInvestorEmail ? 'Send report to client.' : 'No primary investor email.'}
                PopperProps={{ className: 'bottom-arrow-tooltip' }}
                placement="top"
                arrow
              >
                <Button onClick={() => setShowEmailConfirmation(true)}>
                  <SendIcon />
                  Send to client
                </Button>
              </Tooltip>
            )}
          </div>
        </div>
      </div>
      <Dialog className="confirm-send-email" open={showSendEmailConfirmation}>
        <h4>
          <WarningAmberRoundedIcon />
          Send report to client?
        </h4>
        <p>{`Confirm sending ${reportToView.displayName} to client?`}</p>
        <div className="bottom-btns">
          <Button onClick={() => setShowEmailConfirmation(null)}>Cancel</Button>
          <Button onClick={() => { sendReportToClient(); }}>
            {gettingS3File || sendingEmail ? (
              <>
                <div className="dots-circle-spinner" />
                Sending...
              </>
            ) : 'Send'}
          </Button>
        </div>
      </Dialog>
    </>
  );
}

PDFViewer.propTypes = {
  userData: PropTypes.object.isRequired,
  pdfUrl: PropTypes.string.isRequired,
  reportToView: PropTypes.object.isRequired,
  pdfLoading: PropTypes.bool.isRequired,
  setPdfLoading: PropTypes.func.isRequired,
  reportList: PropTypes.array.isRequired,
  setGenerateNewReport: PropTypes.func.isRequired,
  primaryInvestorEmail: PropTypes.string.isRequired,
  primaryInvestorName: PropTypes.string.isRequired,
};
