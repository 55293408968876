import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import isEqual from 'lodash.isequal';

import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import InputAdornment from '@mui/material/InputAdornment';

import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';

import {
  commaEvery3rdChar,
  copy,
  formatUSCurrencyWithoutDollarSign,
} from '../../utils';
import { cleanValue } from './utils';

export default function Step2({
  transactionsData,
  setTransactionsData,
  index,
  setActiveStep,
  setSaveTransactionData,
  disableFields,
}) {
  const [focussedTextFields, setFocussedTextFields] = useState([]);

  const [blockData, setBlockData] = useState({});

  function handleIntakeChange(dataName, dataValue) {
    const newBlockData = copy(blockData);
    setBlockData({ ...newBlockData, [dataName]: dataValue });
  }

  function handleOnBlurChange(dataName, dataValue, dataIndex) {
    setActiveStep(2);
    const newData = copy(transactionsData);
    newData[dataIndex].clientFinancialInfo = {
      ...newData[dataIndex].clientFinancialInfo,
      [dataName]: dataValue?.value ? {
        value: formatUSCurrencyWithoutDollarSign.format(parseFloat(cleanValue(dataValue.value))),
      } : dataValue,
    };
    setTransactionsData(newData);
    if (!isEqual(transactionsData, newData)) setSaveTransactionData(index);
  }

  function setValue(data) {
    if (data) {
      const dataCopy = copy(data);
      Object.entries(dataCopy).forEach((value) => {
        if (value[1]?.value) {
          dataCopy[value[0]] = {
            value: formatUSCurrencyWithoutDollarSign.format(parseFloat(cleanValue(value[1]?.value))),
          };
        }
      });
      return dataCopy;
    }
    return transactionsData[index]?.clientFinancialInfo;
  }


  useEffect(() => setBlockData(setValue(transactionsData[index]?.clientFinancialInfo) || {}), [transactionsData]);

  return (
    <>
      <h4>Financials</h4>
      <p className="block-header-text">All fields are optional.</p>
      <div className="form-grid">
        <TextField
          select
          label="Key performance indicator"
          value={transactionsData[index]?.clientFinancialInfo?.keyPerformanceIndicator !== 'Dont know' ?
            transactionsData[index]?.clientFinancialInfo?.keyPerformanceIndicator ?
              transactionsData[index]?.clientFinancialInfo?.keyPerformanceIndicator :
              '' :
            'Don\'t know'}
          onChange={(e) => { handleOnBlurChange('keyPerformanceIndicator', e.target.value, index); }}
          onFocus={() => setActiveStep(2)}
          SelectProps={{ MenuProps: { disableScrollLock: true, classes: { paper: 'select-dropdown' } } }}
          onKeyDown={(e) => { e.stopPropagation(); }}
          disabled={disableFields}
          helperText=" "
        >
          {[
            'Revenue',
            'EBITDA',
            'Don\'t know',
          ].map((menuItem) => (
            <MenuItem key={menuItem} value={menuItem}>
              {menuItem}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          label="Total invested capital"
          value={blockData?.totalInvestedCapital?.value || ''}
          onChange={(e) => { handleIntakeChange('totalInvestedCapital', { value: commaEvery3rdChar(e.target.value) }); }}
          onFocus={() => {
            setFocussedTextFields([...focussedTextFields, 'totalInvestedCapital']);
            setActiveStep(2);
          }}
          onBlur={() => {
            setFocussedTextFields(focussedTextFields.filter((field) => field !== 'totalInvestedCapital'));
            handleOnBlurChange('totalInvestedCapital', blockData?.totalInvestedCapital, index);
          }}
          InputLabelProps={{
            shrink: focussedTextFields.includes('totalInvestedCapital') ||
              !!transactionsData[index]?.clientFinancialInfo?.totalInvestedCapital?.value,
            className: focussedTextFields.includes('totalInvestedCapital') ||
              !!transactionsData[index]?.clientFinancialInfo?.totalInvestedCapital?.value ? '' : 'inputLabelNoShrink',
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <AttachMoneyIcon />
              </InputAdornment>
            ),
          }}
          disabled={disableFields}
          helperText=" "
        />
        <TextField
          label="Total current debt"
          value={blockData?.totalDebt?.value || ''}
          onChange={(e) => { handleIntakeChange('totalDebt', { value: commaEvery3rdChar(e.target.value) }); }}
          onFocus={() => {
            setFocussedTextFields([...focussedTextFields, 'totalDebt']);
            setActiveStep(2);
          }}
          onBlur={() => {
            setFocussedTextFields(focussedTextFields.filter((field) => field !== 'totalDebt'));
            handleOnBlurChange('totalDebt', blockData?.totalDebt, index);
          }}
          InputLabelProps={{
            shrink: focussedTextFields.includes('totalDebt') ||
              !!transactionsData[index]?.clientFinancialInfo?.totalDebt?.value,
            className: focussedTextFields.includes('totalDebt') ||
              !!transactionsData[index]?.clientFinancialInfo?.totalDebt?.value ? '' : 'inputLabelNoShrink',
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <AttachMoneyIcon />
              </InputAdornment>
            ),
          }}
          disabled={disableFields}
          helperText=" "
        />
        <TextField
          label="Total non-convertible debt"
          value={blockData?.nonConvertibleDebt?.value || ''}
          onChange={(e) => { handleIntakeChange('nonConvertibleDebt', { value: commaEvery3rdChar(e.target.value) }); }}
          onFocus={() => {
            setFocussedTextFields([...focussedTextFields, 'nonConvertibleDebt']);
            setActiveStep(2);
          }}
          onBlur={() => {
            setFocussedTextFields(focussedTextFields.filter((field) => field !== 'nonConvertibleDebt'));
            handleOnBlurChange('nonConvertibleDebt', blockData?.nonConvertibleDebt, index);
          }}
          InputLabelProps={{
            shrink: focussedTextFields.includes('nonConvertibleDebt') ||
              !!transactionsData[index]?.clientFinancialInfo?.nonConvertibleDebt?.value,
            className: focussedTextFields.includes('nonConvertibleDebt') ||
              !!transactionsData[index]?.clientFinancialInfo?.nonConvertibleDebt?.value ? '' : 'inputLabelNoShrink',
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <AttachMoneyIcon />
              </InputAdornment>
            ),
          }}
          disabled={disableFields}
          helperText=" "
        />
        <TextField
          label="Total current cash"
          value={blockData?.currentCash?.value || ''}
          onChange={(e) => { handleIntakeChange('currentCash', { value: commaEvery3rdChar(e.target.value) }); }}
          onFocus={() => {
            setFocussedTextFields([...focussedTextFields, 'currentCash']);
            setActiveStep(2);
          }}
          onBlur={() => {
            setFocussedTextFields(focussedTextFields.filter((field) => field !== 'currentCash'));
            handleOnBlurChange('currentCash', blockData?.currentCash, index);
          }}
          InputLabelProps={{
            shrink: focussedTextFields.includes('currentCash') ||
              !!transactionsData[index]?.clientFinancialInfo?.currentCash?.value,
            className: focussedTextFields.includes('currentCash') ||
              !!transactionsData[index]?.clientFinancialInfo?.currentCash?.value ? '' : 'inputLabelNoShrink',
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <AttachMoneyIcon />
              </InputAdornment>
            ),
          }}
          disabled={disableFields}
          helperText=" "
        />
        <TextField
          label="Current monthly cash burn"
          value={blockData?.monthlyCashBurn?.value || ''}
          onChange={(e) => { handleIntakeChange('monthlyCashBurn', { value: commaEvery3rdChar(e.target.value) }); }}
          onFocus={() => {
            setFocussedTextFields([...focussedTextFields, 'monthlyCashBurn']);
            setActiveStep(2);
          }}
          onBlur={() => {
            setFocussedTextFields(focussedTextFields.filter((field) => field !== 'monthlyCashBurn'));
            handleOnBlurChange('monthlyCashBurn', cleanValue(blockData?.monthlyCashBurn), index);
          }}
          InputLabelProps={{
            shrink: focussedTextFields.includes('monthlyCashBurn') ||
              !!transactionsData[index]?.clientFinancialInfo?.monthlyCashBurn?.value,
            className: focussedTextFields.includes('monthlyCashBurn') ||
              !!transactionsData[index]?.clientFinancialInfo?.monthlyCashBurn?.value ? '' : 'inputLabelNoShrink',
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <AttachMoneyIcon />
              </InputAdornment>
            ),
          }}
          disabled={disableFields}
          helperText=" "
        />
      </div>
      <div className="ebitda-checkbox">
        <Checkbox
          checked={!!transactionsData[index]?.clientFinancialInfo?.EBITDAChecked || false}
          onChange={() => { handleOnBlurChange('EBITDAChecked', transactionsData[index]?.clientFinancialInfo?.EBITDAChecked ? 0 : 1, index); }}
          icon={<div className="unchecked-icon" />}
          disabled={disableFields}
          checkedIcon={(
            <div className="checked-icon">
              <CheckRoundedIcon />
            </div>
          )}
        />
        <span className="ebitda-checkbox-text">
          Please check if the company has generated or will generate revenue
          over $1 million in the next year.
        </span>
      </div>
      {!!transactionsData[index]?.clientFinancialInfo?.EBITDAChecked && (
        <div className="form-grid">
          <TextField
            label="Total current fiscal year revenue"
            value={blockData?.currentFiscalYearRevenue?.value || ''}
            onChange={(e) => { handleIntakeChange('currentFiscalYearRevenue', { value: commaEvery3rdChar(e.target.value) }); }}
            onFocus={() => {
              setFocussedTextFields([...focussedTextFields, 'currentFiscalYearRevenue']);
              setActiveStep(2);
            }}
            onBlur={() => {
              setFocussedTextFields(focussedTextFields.filter((field) => field !== 'currentFiscalYearRevenue'));
              handleOnBlurChange('currentFiscalYearRevenue', blockData?.currentFiscalYearRevenue, index);
            }}
            InputLabelProps={{
              shrink: focussedTextFields.includes('currentFiscalYearRevenue') ||
                !!transactionsData[index]?.clientFinancialInfo?.currentFiscalYearRevenue?.value,
              className: focussedTextFields.includes('currentFiscalYearRevenue') ||
                !!transactionsData[index]?.clientFinancialInfo?.currentFiscalYearRevenue?.value ? '' : 'inputLabelNoShrink',
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <AttachMoneyIcon />
                </InputAdornment>
              ),
            }}
            disabled={disableFields}
            helperText=" "
          />
          <TextField
            label="Total next fiscal year revenue"
            value={blockData?.nextFiscalYearRevenue?.value || ''}
            onChange={(e) => { handleIntakeChange('nextFiscalYearRevenue', { value: commaEvery3rdChar(e.target.value) }); }}
            onFocus={() => {
              setFocussedTextFields([...focussedTextFields, 'nextFiscalYearRevenue']);
              setActiveStep(2);
            }}
            onBlur={() => {
              setFocussedTextFields(focussedTextFields.filter((field) => field !== 'nextFiscalYearRevenue'));
              handleOnBlurChange('nextFiscalYearRevenue', blockData?.nextFiscalYearRevenue, index);
            }}
            InputLabelProps={{
              shrink: focussedTextFields.includes('nextFiscalYearRevenue') ||
                !!transactionsData[index]?.clientFinancialInfo?.nextFiscalYearRevenue?.value,
              className: focussedTextFields.includes('nextFiscalYearRevenue') ||
                !!transactionsData[index]?.clientFinancialInfo?.nextFiscalYearRevenue?.value ? '' : 'inputLabelNoShrink',
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <AttachMoneyIcon />
                </InputAdornment>
              ),
            }}
            disabled={disableFields}
            helperText=" "
          />
          <TextField
            label="Total current fiscal year EBITDA"
            value={blockData?.currentFiscalYearEBITDA?.value || ''}
            onChange={(e) => { handleIntakeChange('currentFiscalYearEBITDA', { value: commaEvery3rdChar(e.target.value) }); }}
            onFocus={() => {
              setFocussedTextFields([...focussedTextFields, 'currentFiscalYearEBITDA']);
              setActiveStep(2);
            }}
            onBlur={() => {
              setFocussedTextFields(focussedTextFields.filter((field) => field !== 'currentFiscalYearEBITDA'));
              handleOnBlurChange('currentFiscalYearEBITDA', blockData?.currentFiscalYearEBITDA, index);
            }}
            InputLabelProps={{
              shrink: focussedTextFields.includes('currentFiscalYearEBITDA') ||
                !!transactionsData[index]?.clientFinancialInfo?.currentFiscalYearEBITDA?.value,
              className: focussedTextFields.includes('currentFiscalYearEBITDA') ||
                !!transactionsData[index]?.clientFinancialInfo?.currentFiscalYearEBITDA?.value ? '' : 'inputLabelNoShrink',
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <AttachMoneyIcon />
                </InputAdornment>
              ),
            }}
            disabled={disableFields}
            helperText=" "
          />
          <TextField
            label="Total next fiscal year EBITDA"
            value={blockData?.nextFiscalYearEBITDA?.value || ''}
            onChange={(e) => { handleIntakeChange('nextFiscalYearEBITDA', { value: commaEvery3rdChar(e.target.value) }); }}
            onFocus={() => {
              setFocussedTextFields([...focussedTextFields, 'nextFiscalYearEBITDA']);
              setActiveStep(2);
            }}
            onBlur={() => {
              setFocussedTextFields(focussedTextFields.filter((field) => field !== 'nextFiscalYearEBITDA'));
              handleOnBlurChange('nextFiscalYearEBITDA', blockData?.nextFiscalYearEBITDA, index);
            }}
            InputLabelProps={{
              shrink: focussedTextFields.includes('nextFiscalYearEBITDA') ||
                !!transactionsData[index]?.clientFinancialInfo?.nextFiscalYearEBITDA?.value,
              className: focussedTextFields.includes('nextFiscalYearEBITDA') ||
                !!transactionsData[index]?.clientFinancialInfo?.nextFiscalYearEBITDA?.value ? '' : 'inputLabelNoShrink',
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <AttachMoneyIcon />
                </InputAdornment>
              ),
            }}
            disabled={disableFields}
            helperText=" "
          />
        </div>
      )}
    </>
  );
}

Step2.propTypes = {
  transactionsData: PropTypes.array.isRequired,
  setTransactionsData: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  setActiveStep: PropTypes.func.isRequired,
  setSaveTransactionData: PropTypes.func.isRequired,
  disableFields: PropTypes.bool.isRequired,
};
