import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import isEqual from 'lodash.isequal';

import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import MenuItem from '@mui/material/MenuItem';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Autocomplete } from '@mui/material';

import AttachMoneyIcon from '@mui/icons-material/AttachMoney';

import { ReactComponent as BlueCalendar } from '../../images/icons/blue_calendar.svg';

import {
  commaEvery3rdChar,
  copy,
  formatUSCurrencyWithoutDollarSign,
} from '../../utils';

import { cleanValue } from './utils';

import UploadBlock from './components/UploadBlock';

export default function Step3({
  transactionsData,
  setTransactionsData,
  index,
  setActiveStep,
  setSaveTransactionData,
  disableFields,
}) {
  const [focussedTextFields, setFocussedTextFields] = useState([]);

  const [blockData, setBlockData] = useState({});

  function handleIntakeChange(dataName, dataValue) {
    const newBlockData = copy(blockData);
    setBlockData({ ...newBlockData, [dataName]: dataValue });
  }

  function handleOnBlurChange(dataName, dataValue) {
    setActiveStep(3);
    const newData = copy(transactionsData);
    newData[index].fundingInfo = {
      ...newData[index].fundingInfo,
      [dataName]: dataValue,
    };
    setTransactionsData(newData);
    if (!isEqual(transactionsData, newData)) setSaveTransactionData(index);
  }

  function setValue(data) {
    if (data) {
      const dataCopy = copy(data);
      Object.entries(dataCopy).forEach((value) => {
        if (value[0] === 'totalAmountRaised') dataCopy[value[0]] = formatUSCurrencyWithoutDollarSign.format(parseFloat(cleanValue(value[1])));
      });
      return dataCopy;
    }
    return transactionsData[index]?.fundingInfo;
  }

  useEffect(() => setBlockData(setValue(transactionsData[index]?.fundingInfo) || {}), [transactionsData]);

  return (
    <>
      <h4>Funding & secondary transactions</h4>
      <p className="block-header-text">
        Tell us about this company&apos;s funding. All fields are optional. The more you provide the quicker we can get started.
      </p>
      <TextField
        select
        className="company-performance"
        label="Company performance vs. investor expectations since latest round of funding"
        value={transactionsData[index]?.fundingInfo?.companyPerformance || ''}
        onChange={(e) => { handleOnBlurChange('companyPerformance', e.target.value); }}
        onFocus={() => setActiveStep(3)}
        SelectProps={{ MenuProps: { disableScrollLock: true, classes: { paper: 'select-dropdown' } } }}
        onKeyDown={(e) => { e.stopPropagation(); }}
        disabled={disableFields}
      >
        {[
          '5. Far exceed expectations',
          '4. Exceed expectations',
          '3. Perform as expected',
          '2. Actual results below projections provided to investors',
          '1. Actual results far below projections provided to investors',
        ].map((menuItem) => (
          <MenuItem key={menuItem} value={menuItem}>
            {menuItem}
          </MenuItem>
        ))}
      </TextField>
      <div className="form-grid">
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DatePicker
            label="Date of latest round of funding"
            inputFormat="MM/DD/YYYY"
            onOpen={() => setActiveStep(3)}
            value={transactionsData[index]?.fundingInfo?.latestRoundDate ?
              moment(transactionsData[index].fundingInfo.latestRoundDate, 'YYYY-MM-DD') : null}
            onChange={(val) => { handleOnBlurChange('latestRoundDate', val ? val.format('YYYY-MM-DD') : ''); }}
            slotProps={{ textField: { variant: 'outlined', helperText: 'MM/DD/YYYY' } }}
            slots={{ openPickerIcon: BlueCalendar }}
            maxDate={moment().subtract(1, 'day')}
            disabled={disableFields}
          />
        </LocalizationProvider>
        <Autocomplete
          disablePortal
          freeSolo
          onOpen={() => setActiveStep(3)}
          options={[
            'SAFE Note',
            'Convertible Note',
            'Angel',
            'Pre-Seed',
            'Series Seed',
            'Series A',
            'Series B',
            'Series C',
            'Series D',
            'Series E',
            'Series F',
            'No funding',
          ]}
          groupBy={(option, i) => option[i]}
          inputValue={transactionsData[index]?.fundingInfo?.latestRoundType || ''}
          onInputChange={(e, value) => {
            handleOnBlurChange('latestRoundType', value);
          }}
          renderInput={(params) => <TextField {...params} label="Type of latest round of funding" helperText=" " />}
          onKeyDown={(e) => { e.stopPropagation(); }}
          disabled={disableFields}
        />
        <TextField
          label="Next round expected pre-money"
          disabled={disableFields}
          value={commaEvery3rdChar(blockData?.nextRoundExpectedPreMoney) || ''}
          onChange={(e) => { handleIntakeChange('nextRoundExpectedPreMoney', commaEvery3rdChar(e.target.value)); }}
          onFocus={() => {
            setFocussedTextFields([...focussedTextFields, 'nextRoundExpectedPreMoney']);
            setActiveStep(3);
          }}
          onBlur={() => {
            setFocussedTextFields(focussedTextFields.filter((field) => field !== 'nextRoundExpectedPreMoney'));
            handleOnBlurChange('nextRoundExpectedPreMoney', blockData?.nextRoundExpectedPreMoney);
          }}
          InputLabelProps={{
            shrink: focussedTextFields.includes('nextRoundExpectedPreMoney') ||
              !!transactionsData[index]?.fundingInfo?.nextRoundExpectedPreMoney,
            className: focussedTextFields.includes('nextRoundExpectedPreMoney') ||
              !!transactionsData[index]?.fundingInfo?.nextRoundExpectedPreMoney ? '' : 'inputLabelNoShrink',
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <AttachMoneyIcon />
              </InputAdornment>
            ),
          }}
        />
        <TextField
          label="Next round expected post-money"
          disabled={disableFields}
          value={commaEvery3rdChar(blockData?.nextRoundExpectedPostMoney) || ''}
          onChange={(e) => { handleIntakeChange('nextRoundExpectedPostMoney', commaEvery3rdChar(e.target.value)); }}
          onFocus={() => {
            setFocussedTextFields([...focussedTextFields, 'nextRoundExpectedPostMoney']);
            setActiveStep(3);
          }}
          onBlur={() => {
            setFocussedTextFields(focussedTextFields.filter((field) => field !== 'nextRoundExpectedPostMoney'));
            handleOnBlurChange('nextRoundExpectedPostMoney', blockData?.nextRoundExpectedPostMoney);
          }}
          InputLabelProps={{
            shrink: focussedTextFields.includes('nextRoundExpectedPostMoney') ||
              !!transactionsData[index]?.fundingInfo?.nextRoundExpectedPostMoney,
            className: focussedTextFields.includes('nextRoundExpectedPostMoney') ||
              !!transactionsData[index]?.fundingInfo?.nextRoundExpectedPostMoney ? '' : 'inputLabelNoShrink',
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <AttachMoneyIcon />
              </InputAdornment>
            ),
          }}
        />
      </div>
      <div className="funding-radio">
        <p>Have there been any secondary transactions in the company&apos;s equity in the last 12 months?</p>
        <RadioGroup
          row
          value={transactionsData[index]?.fundingInfo?.secondaryTransactions || ''}
          onChange={(e) => handleOnBlurChange('secondaryTransactions', e.target.value)}
        >
          <FormControlLabel disabled={disableFields} value={1} control={<Radio />} label="Yes" />
          <FormControlLabel disabled={disableFields} value={0} control={<Radio />} label="No" />
        </RadioGroup>
      </div>
      {parseInt(transactionsData[index]?.fundingInfo?.secondaryTransactions, 10) === 1 &&
        (
          <div>
            <div className="funding-extra-info">
              <p>
                Please upload the document that includes the share class, number of shares,
                and price per share for all secondary transactions.
              </p>
              <UploadBlock
                uploadType="secondary-transactions"
                transactionsData={transactionsData}
                setTransactionsData={setTransactionsData}
                index={index}
                setActiveStep={setActiveStep}
                setSaveTransactionData={setSaveTransactionData}
                disableFields={disableFields}
              />
              <p className="funding-upload-text">
                Maximum file size: 35 MB. File types accepted:
                <span> Microsoft Excel </span>
                (.xls, .xlsx)
                <span> Word </span>
                (.doc, .docx),
                <span> PowerPoint </span>
                (.ppt, pptx, .pps, .ppsx), and
                <span> image files </span>
                (.jpg, .jpeg, .heic, and .png).
              </p>
            </div>
          </div>
        )}
    </>
  );
}

Step3.propTypes = {
  transactionsData: PropTypes.array.isRequired,
  setTransactionsData: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  setActiveStep: PropTypes.func.isRequired,
  setSaveTransactionData: PropTypes.func.isRequired,
  disableFields: PropTypes.bool.isRequired,
};
