import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';

import {
  UserContext,
  IsSigningOutContext,
  ErrorMessageContext,
  NavWidthContext,
  SubNavStateContext,
} from '.';

export default function ContextsProvider({ children }) {
  const [userData, setUserData] = useState({});
  const [isSigningOut, setIsSigningOut] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState('');
  const [navWidth, setNavWidth] = useState(null);
  const [subNavState, setSubNavState] = useState({});

  const userDataProviderValue = useMemo(() => ({ userData, setUserData }), [userData]);
  const isSigningOutProviderValue = useMemo(() => ({ isSigningOut, setIsSigningOut }), [isSigningOut]);
  const showErrorMessageProviderValue = useMemo(() => ({ showErrorMessage, setShowErrorMessage }), [showErrorMessage]);
  const navWidthProviderValue = useMemo(() => ({ navWidth, setNavWidth }), [navWidth]);
  const subNavStateProviderValue = useMemo(() => ({ subNavState, setSubNavState }), [subNavState]);

  return (
    <UserContext.Provider value={userDataProviderValue}>
      <IsSigningOutContext.Provider value={isSigningOutProviderValue}>
        <ErrorMessageContext.Provider value={showErrorMessageProviderValue}>
          <NavWidthContext.Provider value={navWidthProviderValue}>
            <SubNavStateContext.Provider value={subNavStateProviderValue}>
              {children}
            </SubNavStateContext.Provider>
          </NavWidthContext.Provider>
        </ErrorMessageContext.Provider>
      </IsSigningOutContext.Provider>
    </UserContext.Provider>
  );
}

ContextsProvider.propTypes = { children: PropTypes.element.isRequired };
