import React from 'react';
import PropTypes from 'prop-types';

import {
  Page,
  View,
  Text,
  Image,
} from '@react-pdf/renderer';

import DraftOverlay from '../components/DraftOverlay';
import CopyrightChar from '../components/CopyrightChar';

import wholePageBackground from '../assets/images/wholePageBackground.png';
import secondaryTOCBackground from '../assets/images/secondaryTOCBackground.png';
import separatorLine from '../assets/images/line.png';


export default function TableOfContents({
  valuationDate,
  totalPageCount,
  reportData,
  hasPubCo,
  hasValueCompare,
}) {
  const tableOfContentsData = [
    { pageName: 'Company Description', pageNumber: 3 },
    { pageName: 'Fund Holdings Summary', pageNumber: 4 },
    { pageName: 'Equity Value Conclusion & Summary', pageNumber: 5 },
    { pageName: 'Capitalization Table', pageNumber: 6 },
    { pageName: 'Breakpoint Analysis', pageNumber: totalPageCount.capTable },
    { pageName: 'Backsolve OPM Allocation', pageNumber: totalPageCount.breakpoints },
    { pageName: 'Transaction Date OPM Backsolve approach', pageNumber: totalPageCount.opmBacksolve },
    ...hasPubCo ? [{ pageName: 'Guideline Public company method', pageNumber: totalPageCount.opmPubCo }] : [],
    { pageName: 'Fair value per share', pageNumber: totalPageCount.fairValue },
    // ...hasPubCo ? [{ pageName: 'Discount for lack of marketability (DLOM)', pageNumber: totalPageCount.opmPubCo + 1 }] : [],
    { pageName: 'Valuation Date Volatility, Industry', pageNumber: totalPageCount.valVolatility },
    { pageName: 'Transaction Date Volatility, Industry', pageNumber: totalPageCount.tranVolatility },
    { pageName: 'Market Equity Adjustment, Industry', pageNumber: totalPageCount.marketAdjustment },
    ...hasPubCo ? [{ pageName: 'Public Company Descriptions', pageNumber: totalPageCount.pubCoDescriptions }] : [],
    ...hasPubCo ? [{ pageName: 'Guideline Public Company Method', pageNumber: totalPageCount.pubCoMethod }] : [],
    ...hasPubCo ? [{ pageName: 'Public Company Growth & Margins', pageNumber: totalPageCount.pubCoGrowthMargins }] : [],
    // { pageName: 'Public company Growth & Margins', pageNumber: 25 + totalPageCount.pubCoDescriptions },
    // { pageName: 'Guideline Transaction Method', pageNumber: 27 + totalPageCount.pubCoDescriptions },
    // { pageName: 'Target company growth & margins', pageNumber: 29 + totalPageCount.pubCoDescriptions },
    // { pageName: 'Guideline transaction descriptions', pageNumber: 31 + totalPageCount.pubCoDescriptions },
    { pageName: 'General Appraiser\'s Notes', pageNumber: totalPageCount.generalAppraisersNotes },
    { pageName: 'Valuation Methodology Summary', pageNumber: totalPageCount.valSumWeighting },
    { pageName: 'Allocation Methodology Summary', pageNumber: totalPageCount.allocationMethodSummary },
    ...hasValueCompare ? [{ pageName: 'Value Comparison', pageNumber: totalPageCount.valueComparison }] : [],
  ];

  return (
    <Page
      size="TABLOID"
      orientation="landscape"
      style={{
        display: 'flex',
        flexDirection: 'row',
        backgroundColor: '#EAEAEB',
      }}
    >
      <View
        style={{
          width: '831px',
          height: '792px',
          backgroundColor: '#303D5C',
        }}
      >
        <Image
          src={wholePageBackground}
          style={{
            position: 'absolute',
            width: '1224px',
            height: '792px',
            top: '0px',
            left: '0px',
          }}
        />
      </View>
      <Text
        style={{
          position: 'absolute',
          top: '100px',
          left: '102px',
          width: '400px',
          fontFamily: 'ManropeBold',
          fontSize: '12px',
          color: '#EAEAEB',
          letterSpacing: '2px',
        }}
      >
        ASC 820 VALUATION |
        {` ${valuationDate.toUpperCase()}`}
      </Text>
      <View
        style={{
          width: '480px',
          padding: '32px',
          marginRight: '36px',
        }}
      >
        <Image
          src={secondaryTOCBackground}
          style={{
            position: 'absolute',
            width: '480px',
            height: '792px',
            left: '-8px',
          }}
        />
        <View
          style={{
            width: '400px',
            height: '708px',
            backgroundColor: '#FAFAFD',
            borderRadius: '24px',
            paddingLeft: '36px',
          }}
        >
          <Text
            style={{
              fontFamily: 'ManropeBold',
              fontSize: '12px',
              color: '#49454F',
              letterSpacing: '2px',
              marginTop: '40px',
            }}
          >
            TABLE OF CONTENTS
          </Text>
          <View
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginTop: '24px',
            }}
          >
            {tableOfContentsData.map((contentData) => (
              <React.Fragment key={contentData.pageNumber}>
                <View
                  key={contentData.pageNumber}
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    height: '38px',
                    width: '237px',
                    borderTop: '1px',
                    borderColor: '#CCCCCC',
                  }}
                >
                  <View
                    style={{
                      width: '40px',
                      height: '40px',
                      borderTop: '3px',
                      borderColor: '#CCCCCC',
                    }}
                  >
                    <Text
                      style={{
                        fontFamily: 'ManropeMed',
                        fontSize: '20px',
                        color: '#49454F',
                        letterSpacing: '2px',
                        marginTop: '2px',
                      }}
                    >
                      {contentData.pageNumber.toString().length === 1 ? `o${contentData.pageNumber}` : contentData.pageNumber}
                    </Text>
                  </View>
                  <Text
                    style={{
                      fontFamily: 'Roboto',
                      fontSize: '12px',
                      color: '#49454F',
                      letterSpacing: '0.4px',
                    }}
                  >
                    {contentData.pageName}
                  </Text>
                </View>
              </React.Fragment>
            ))}
          </View>
        </View>
      </View>
      <View
        style={{
          position: 'absolute',
          bottom: '0px',
          height: '36px',
          width: '100%',
          paddingLeft: '32px',
          paddingRight: '32px',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        <CopyrightChar color="#938F99" />
        <Text
          style={{
            fontFamily: 'Roboto',
            fontSize: '8px',
            letterSpacing: '0.4px',
            color: '#938F99',
          }}
        >
          {new Date().getFullYear()}
          {' '}
          initio software. All rights reserved. Valuation prepared by
          {' '}
          {reportData.isVenBioUser ? 'venBio' : 'Redwood Valuation'}
          .
        </Text>
      </View>
      <Image
        src={separatorLine}
        style={{
          position: 'absolute',
          width: '100%',
          bottom: '34px',
        }}
      />
      {reportData.isDraft && <DraftOverlay />}
    </Page>
  );
}

TableOfContents.propTypes = {
  valuationDate: PropTypes.string.isRequired,
  totalPageCount: PropTypes.object.isRequired,
  reportData: PropTypes.object.isRequired,
  hasPubCo: PropTypes.bool.isRequired,
  hasValueCompare: PropTypes.bool.isRequired,
};
