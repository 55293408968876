import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import {
  IconButton,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip,
} from '@mui/material';

import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';

import useFetch from '../../hooks/useFetch';

import determineBadgeColor from './utils';
import { SubNavStateContext } from '../../contexts';

const statuses = [
  'Client not started 820',
  'Client in-progress',
  '820 Data entry',
  '820 Analysis',
  '820 Review',
  'Draft',
  'Financial statements ready',
  '820 Done',
];

const blockerStatuses = [
  'On hold',
  'Stuck',
];

const draftStatuses = [
  'Draft review',
  'Draft delivered',
  'Draft iteration',
  'Draft audit',
];

export default function StatusBadgeDropdown({
  company,
  setInsideElementHover,
  currentStatus,
  setCurrentStatus,
  blockerStatus,
  setBlockerStatus,
  setProjectStatus,
}) {
  const { subNavState, setSubNavState } = useContext(SubNavStateContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const [subDraftMenu, setSubDraftMenu] = useState(false);
  const [updateStatus, setUpdateStatus] = useState(false);

  const [, changeTransactionStatusRequest] = useFetch();

  async function handleUpdateTransactionStatus() {
    const statusData = {
      investorCompanyId: company.investorCompanyId,
      portfolioCompanyId: company.portfolioCompanyId,
      status: currentStatus,
      blockerStatus,
      projectId: company.projectId,
      transactionId: company.transactionId,
    };
    setSubNavState({
      ...subNavState, status: {
        companyId: company.portfolioCompanyId,
        updateStatus: currentStatus,
        blockerStatus,
      },
    });
    changeTransactionStatusRequest({
      url: '/transactions/asc820/change-transaction-status',
      method: 'post',
      body: statusData,
      bodyIds: ['enterpriseCompanyId', 'requestUserId'],
      onFinally: () => setUpdateStatus(false),
      onSuccess: (res) => setProjectStatus(res.projectCard),
    });
  }

  function handleStatusChange(status) {
    if (status === blockerStatus) {
      setBlockerStatus('');
    } else if (blockerStatuses.includes(status)) {
      setBlockerStatus(status);
    } else if (currentStatus !== status) {
      setCurrentStatus(status);
      setBlockerStatus('');
    }
    setUpdateStatus(true);
    setAnchorEl(null);
  }

  useEffect(() => { if (company.blockerStatus.length) setBlockerStatus(company.blockerStatus); }, []);

  useEffect(() => { if (updateStatus) handleUpdateTransactionStatus(); }, [updateStatus]);

  return (
    <div className="status-badge-wrapper">
      {blockerStatus.length > 0 && (
        <span className={`status-badge ${determineBadgeColor(blockerStatus)} sub-status`}>
          {blockerStatus}
        </span>
      )}
      <IconButton
        className="status-badge-btn"
        edge="start"
        color="inherit"
        aria-label="menu"
        onClick={(e) => setAnchorEl(e.currentTarget)}
      >
        <span className={`status-badge ${determineBadgeColor(currentStatus)}`}>
          {currentStatus}
          <KeyboardArrowDownOutlinedIcon />
        </span>
      </IconButton>
      <Menu
        id="company-status-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => {
          setAnchorEl(null);
          setInsideElementHover(false);
          setSubDraftMenu(false);
        }}
        disableScrollLock
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {[...statuses, ...blockerStatuses].map((status) => (
          status !== 'Draft' ? (
            <MenuItem
              key={status}
              className={`cmp-status ${status === currentStatus ? 'active' : ''} ${status === blockerStatus ? 'sub-status-active' : ''}`}
              onClick={() => {
                handleStatusChange(status);
                setInsideElementHover(false);
              }}
            >
              <ListItemText primary={status} />
            </MenuItem>
          ) : (
            <Tooltip
              key={status}
              className={draftStatuses.includes(currentStatus) ? 'active' : ''}
              id="sub-menu"
              open={subDraftMenu}
              placement="right"
              arrow
              title={(
                <>
                  {draftStatuses.map((draftStatus) => (
                    <MenuItem
                      key={draftStatus}
                      className={`cmp-status ${draftStatus === currentStatus ? 'active' : ''}`}
                      onClick={() => {
                        handleStatusChange(draftStatus);
                        setInsideElementHover(false);
                        setSubDraftMenu(false);
                      }}
                    >
                      <ListItemText primary={draftStatus} />
                    </MenuItem>
                  ))}
                </>
              )}
            >
              <MenuItem
                key="draft"
                onClick={() => setSubDraftMenu(true)}
              >
                <ListItemText primary="Draft" />
                <ArrowRightIcon />
              </MenuItem>
            </Tooltip>
          )
        ))}
      </Menu>
    </div>
  );
}

StatusBadgeDropdown.propTypes = {
  company: PropTypes.object.isRequired,
  setInsideElementHover: PropTypes.func,
  currentStatus: PropTypes.string,
  setCurrentStatus: PropTypes.func.isRequired,
  blockerStatus: PropTypes.string.isRequired,
  setBlockerStatus: PropTypes.func.isRequired,
  setProjectStatus: PropTypes.func,
};

StatusBadgeDropdown.defaultProps = {
  setInsideElementHover: () => { },
  setProjectStatus: () => { },
  currentStatus: '',
};
