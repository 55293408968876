import React from 'react';

import PropTypes from 'prop-types';
import AnimateHeight from 'react-animate-height';

import {
  Button,
  TextField,
  Radio,
  RadioGroup,
  FormControlLabel,
} from '@mui/material';

import PersonAddAltOutlinedIcon from '@mui/icons-material/PersonAddAltOutlined';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ErrorIcon from '@mui/icons-material/Error';
import RemoveIcon from '@mui/icons-material/Remove';

import {
  copy,
  formatPhoneNumber,
  isAValidUSPhoneNumber,
  isAValidEmail,
} from '../../utils';

export default function AddInvestorClients({
  clients,
  setClients,
  sendEmailForm,
  setSendEmailForm,
  clientInputsHaveErrors,
  setClientInputsHaveError,
  clientListRef,
  dialogScrollRef,
  createASC820InProgress,
  createASC820,
  emailOnly,
  setCurrentStep,
  setInvestorRowAdded,
}) {
  const { companyName } = JSON.parse(localStorage.getItem('enterpriseCompanyInfo'));

  function checkForErrors() {
    if (sendEmailForm === 'true') {
      const updatedErrors = [...clientInputsHaveErrors];
      clientInputsHaveErrors.forEach((clientErrors, index) => {
        updatedErrors[index] = {
          ...clientErrors,
          firstName: clients[index].firstName === '',
          lastName: clients[index].lastName === '',
          email: clients[index].email === '' || !isAValidEmail(clients[index].email),
          phone: clients[index].phone.length ? !isAValidUSPhoneNumber(clients[index].phone) : false,
        };
      });
      setClientInputsHaveError(updatedErrors);
      if (updatedErrors.some((clientErrors) => Object.values(clientErrors).includes(true))) return;
    }
    createASC820();
  }

  function handleNewClientOnChange(value, client, valueName, clientIndex) {
    setClients((prevClients) =>
      prevClients.map((newClient, index) =>
        index === clientIndex ?
          { ...client, [valueName]: value } :
          newClient));
  }

  function handleErrorClear(clientValue, index) {
    setClientInputsHaveError((prevClients) => {
      const updatedClients = [...prevClients];
      updatedClients[index] = {
        ...updatedClients[index],
        [clientValue]: false,
      };
      return updatedClients;
    });
  }

  function handleRemoveClient(index) {
    const clientCopy = copy(clients);
    const clientErrorCopy = copy(clientInputsHaveErrors);
    clientCopy.splice(index, 1);
    clientErrorCopy.splice(index, 1);
    setClientInputsHaveError(clientErrorCopy);
    setClients(clientCopy);
  }

  function handleNewClientAddition() {
    setClients([
      ...clients,
      {
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
      },
    ]);
    setClientInputsHaveError([
      ...clientInputsHaveErrors,
      {
        firstName: false,
        lastName: false,
        email: false,
        phone: false,
      },
    ]);
    setInvestorRowAdded(true);
  }

  return (
    <>
      <h4>Email ASC 820 form to client?</h4>
      {!emailOnly ? (
        <>
          <p>
            <span>Select &apos;Email form&apos; </span>
            to email a link to a secure, password protected ASC 820 submission form to all
            client(s) you add below so they can provide documents and information that will help
            expedite your work. As your client(s) fill out their form, this project&apos;s status will
            automatically update in real-time in your ASC 820 list.
          </p>
          <p>
            <span>Do not email form </span>
            - If
            {' '}
            {companyName}
            {' '}
            plans to collect and fill in all data on behalf of this client,
            choose &apos;Do not email form&apos;. If you change your mind later, you&apos;ll be able to email
            the form to your client at a later date.
          </p>
          <p>
            Either way this project will be added to your ASC 820 list where you&apos;ll be
            able to update the project and view the project&apos;s status.
          </p>
          <RadioGroup
            row
            value={sendEmailForm}
            onChange={(e) => setSendEmailForm(e.target.value)}
          >
            <FormControlLabel
              control={<Radio />}
              value="true"
              label="Email form"
            />
            <FormControlLabel
              control={<Radio />}
              value="false"
              label="Do not email form"
            />
          </RadioGroup>
        </>
      ) : (
        <p className="single-p">
          Email a link to a secure, password protected ASC 820 submission form to all
          client(s) you add below so they can provide documents and information that will help
          expedite your work. As your client(s) fill out their form, this project&apos;s status will
          automatically update in real-time in your ASC 820 list.
        </p>
      )}
      <AnimateHeight duration={500} height={sendEmailForm === 'true' ? 'auto' : 0}>
        <div className="additional-clients">
          {clients.map((client, clientIndex) => (
            <div className="client-input" key={`${clientIndex + 1}`}>
              <TextField
                key={`${clientIndex + 1}`}
                label={`${clientIndex > 0 ? `${clientIndex + 1}. Client's first name` : "Primary client's first name*"}`}
                value={client.firstName}
                onChange={(e) => handleNewClientOnChange(e.target.value, client, 'firstName', clientIndex)}
                onFocus={() => handleErrorClear('firstName', clientIndex)}
                inputProps={{
                  onAnimationStart: (e) => {
                    if (e.animationName === 'mui-auto-fill') handleErrorClear('firstName', clientIndex);
                    if (e.animationName === 'mui-auto-fill-cancel') handleErrorClear('firstName', clientIndex);
                  },
                }}
                error={clientInputsHaveErrors[clientIndex]?.firstName}
                helperText={clientInputsHaveErrors[clientIndex]?.firstName ? (
                  <>
                    <ErrorIcon />
                    Missing input
                  </>
                ) : ' '}
              />
              <TextField
                label={`${clientIndex > 0 ? "Client's last name" : "Primary client's last name*"}`}
                value={client.lastName}
                onChange={(e) => handleNewClientOnChange(e.target.value, client, 'lastName', clientIndex)}
                inputProps={{
                  onAnimationStart: (e) => {
                    if (e.animationName === 'mui-auto-fill') handleErrorClear('lastName', clientIndex);
                    if (e.animationName === 'mui-auto-fill-cancel') handleErrorClear('lastName', clientIndex);
                  },
                }}
                onFocus={() => handleErrorClear('lastName', clientIndex)}
                error={clientInputsHaveErrors[clientIndex]?.lastName}
                helperText={clientInputsHaveErrors[clientIndex]?.lastName ? (
                  <>
                    <ErrorIcon />
                    Missing input
                  </>
                ) : ' '}
              />
              {clientIndex > 0 ? (
                <div className="remove-client">
                  <Button onClick={() => handleRemoveClient(clientIndex)}>
                    <RemoveIcon />
                    Remove
                  </Button>
                </div>
              ) : (
                ''
              )}
              <TextField
                label={`${clientIndex > 0 ? "Client's email address" : "Primary client's email address*"}`}
                value={client.email}
                onChange={(e) => handleNewClientOnChange(e.target.value, client, 'email', clientIndex)}
                onFocus={() => handleErrorClear('email', clientIndex)}
                inputProps={{
                  onAnimationStart: (e) => {
                    if (e.animationName === 'mui-auto-fill') handleErrorClear('email', clientIndex);
                    if (e.animationName === 'mui-auto-fill-cancel') handleErrorClear('email', clientIndex);
                  },
                }}
                error={clientInputsHaveErrors[clientIndex]?.email}
                helperText={clientInputsHaveErrors[clientIndex]?.email ? (
                  <>
                    <ErrorIcon />
                    Please enter a valid email address
                  </>
                ) : ' '}
              />
              <TextField
                label={`${clientIndex > 0 ? "Client's phone number" : "Primary client's phone number"}`}
                value={client.phone}
                onChange={(e) => handleNewClientOnChange(formatPhoneNumber(e.target.value), client, 'phone', clientIndex)}
                onFocus={() => handleErrorClear('phone', clientIndex)}
                error={clientInputsHaveErrors[clientIndex]?.phone}
                helperText={clientInputsHaveErrors[clientIndex]?.phone ? (
                  <>
                    <ErrorIcon />
                    Invalid US Phone number format
                  </>
                ) : ' '}
              />
              <div ref={clientListRef} />
            </div>
          ))}
        </div>
        {clients.length < 2 && (
          <hr className="dialog-spacer" />
        )}
        <div className="add-client-wrapper">
          <Button
            onClick={() => handleNewClientAddition()}
          >
            <PersonAddAltOutlinedIcon />
            Add another client
          </Button>
          <p>Everyone you add will receive an email and can access and fill out the same form.</p>
        </div>
      </AnimateHeight>
      <hr className="dialog-spacer" />
      <div className="dialog-footer" ref={dialogScrollRef}>
        {!emailOnly && (
          <Button
            className="send-email-btn"
            onClick={() => setCurrentStep('start-asc-820')}
          >
            <ArrowBackIcon />
            Back
          </Button>
        )}
        <Button onClick={() => checkForErrors()}>
          {!createASC820InProgress ? 'Done' : (
            <>
              <div className="dots-circle-spinner" />
              Creating ASC 820...
            </>
          )}
        </Button>
      </div>
    </>
  );
}

AddInvestorClients.propTypes = {
  clients: PropTypes.arrayOf(PropTypes.object).isRequired,
  setClients: PropTypes.func.isRequired,
  sendEmailForm: PropTypes.string.isRequired,
  setSendEmailForm: PropTypes.func.isRequired,
  clientInputsHaveErrors: PropTypes.arrayOf(PropTypes.object).isRequired,
  setClientInputsHaveError: PropTypes.func.isRequired,
  clientListRef: PropTypes.object.isRequired,
  dialogScrollRef: PropTypes.object.isRequired,
  createASC820InProgress: PropTypes.bool.isRequired,
  createASC820: PropTypes.func.isRequired,
  emailOnly: PropTypes.bool,
  setCurrentStep: PropTypes.func.isRequired,
  setInvestorRowAdded: PropTypes.func.isRequired,
};

AddInvestorClients.defaultProps = { emailOnly: false };
