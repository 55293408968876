import React, { useState, useEffect, useRef } from 'react';

import PropTypes from 'prop-types';

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Autocomplete from '@mui/material/Autocomplete';

import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import LightbulbOutlinedIcon from '@mui/icons-material/LightbulbOutlined';
import AddIcon from '@mui/icons-material/Add';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import EditIcon from '@mui/icons-material/Edit';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';

import { commaEvery3rdChar, copy } from '../../utils';
import useFetch from '../../hooks/useFetch';

import './Holdings.scss';

export default function Holdings({ userData, setUserData, exitingToTab, setExitingToTab, setTabToView }) {
  const [isEditing, setIsEditing] = useState(false);
  const [holdings, setHoldings] = useState([]);
  const [holdingInputId, setHoldingInputId] = useState(0);
  const [changeInProgress, setChangeInProgress] = useState(false);
  const [classNames, setClassNames] = useState([]);
  const [transactionData, setTransactionData] = useState({});
  const [showTable, setShowTable] = useState(false);
  const [pageInfo, setPageInfo] = useState(false);
  const [noTableData, setNoTableData] = useState(false);
  const [capData, setCapData] = useState(true);
  const [savingEdits, setSavingEdits] = useState(false);
  const [changeHasBeenMade, setChangeHasBeenMade] = useState(false);
  const [originalTransactionData, setOriginalTransactionData] = useState(false);
  const [funds, setFunds] = useState([]);

  const [open, setOpen] = useState(true);
  const [holdingIdx, setHoldingIdx] = useState(null);

  const changeHasBeenMadeRef = useRef(false);
  const changeInProgressRef = useRef(false);

  const [, updateTransactionRequest] = useFetch();
  const [, getFundListRequest] = useFetch();

  function holdingOnChange(value, idx, dataName) {
    const newHoldingData = [...holdings];
    const parsedValue = parseFloat(value.replaceAll(',', ''));
    if (dataName === 'sharesOwned' && newHoldingData[idx].pricePerShare) {
      const parsedPricePerShare = parseFloat(newHoldingData[idx].pricePerShare.replaceAll(',', ''));
      const newCarryingValue = commaEvery3rdChar((parsedValue * parsedPricePerShare).toFixed(4));
      newHoldingData[idx].carryingValue = newCarryingValue;
    } else if (dataName === 'pricePerShare' && newHoldingData[idx].sharesOwned) {
      const parsedSharesOwned = parseFloat(newHoldingData[idx].sharesOwned.replaceAll(',', ''));
      const pricePerShare = `$${commaEvery3rdChar(parsedValue.toFixed(4))}`;
      newHoldingData[idx].pricePerShare = pricePerShare;
      const newCarryingValue = commaEvery3rdChar((parsedValue * parsedSharesOwned).toFixed(4));
      newHoldingData[idx].carryingValue = newCarryingValue;
    } else if (dataName === 'fundName') {
      newHoldingData[idx].fundName = value;
    }
    newHoldingData[idx] = {
      ...newHoldingData[idx],
      [dataName]: ['sharesOwned', 'carryingValue', 'pricePerShare'].includes(dataName) ?
        commaEvery3rdChar(value) : value,
    };
    setHoldings(newHoldingData);
  }

  function setHoldingsData() {
    if (userData.transactionData.holdings) {
      setHoldings(userData.transactionData.holdings?.length ?
        userData.transactionData.holdings.map((v, i) => {
          const formattedHoldings = { ...v };
          formattedHoldings.sharesOwned = commaEvery3rdChar(v.sharesOwned);
          formattedHoldings.pricePerShare = commaEvery3rdChar(v.pricePerShare);
          formattedHoldings.carryingValue = commaEvery3rdChar(v.carryingValue);
          return ({ ...formattedHoldings, rowID: i });
        }) : [{ rowID: 0 }]);
      setHoldingInputId(userData.transactionData.holdings?.length || 1);
    }
  }

  useEffect(() => {
    changeInProgressRef.current = changeInProgress;
    changeHasBeenMadeRef.current = changeHasBeenMade;
  }, [changeInProgress, changeHasBeenMade]);

  function saveData(autoSave, exitingTab) {
    if (autoSave && (!changeHasBeenMadeRef.current || changeInProgressRef.current)) return;
    if (!autoSave) setSavingEdits(true);
    const holdingsToSave = copy(holdings);
    holdingsToSave.forEach((val, i) => {
      if (!val.classOfStock && !val.sharesOwned && !val.pricePerShare && !val.carryingValue) {
        holdingsToSave.splice(i, 1);
      } else {
        delete holdingsToSave[i].rowID;
        holdingsToSave[i].sharesOwned = holdingsToSave[i].sharesOwned ? parseInt(holdingsToSave[i].sharesOwned?.replaceAll(',', ''), 10) : null;
        holdingsToSave[i].carryingValue = holdingsToSave[i].carryingValue ? parseFloat(holdingsToSave[i].carryingValue?.replaceAll(',', '')) : null;
      }
    });

    const transactionDBData = {
      ...userData.transactionData,
      holdings: holdingsToSave,
      version: parseInt(userData.transactionData.version, 10),
    };
    updateTransactionRequest({
      url: '/transactions/asc820/enterprise-update-transaction',
      method: 'post',
      body: transactionDBData,
      bodyIds: ['projectId', 'requestUserId'],
      onSuccess: () => { if (!autoSave) setUserData({ ...userData, transactionData: transactionDBData }); },
      onFinally: () => {
        setSavingEdits(false);
        setChangeHasBeenMade(false);
        if (!autoSave) setIsEditing(false);
        if (exitingTab) setTabToView(exitingToTab);
      },
    });
  }

  // This is to get the class names from the cap structure tab
  const getClassNames = () => {
    const classes = [];
    if (userData.capData.safeConvertible) classes.push(...Object.keys(userData.capData.safeConvertible));
    if (userData.capData.preferred) classes.push(...Object.keys(userData.capData.preferred));
    if (userData.capData.common) classes.push(...Object.keys(userData.capData.common));
    return classes;
  };

  // This is to get the fund list from the database
  function getFundList() {
    getFundListRequest({
      url: '/companies/get-funds-list/',
      urlIds: ['enterpriseCompanyId', 'investorCompanyId', 'requestUserId'],
      onSuccess: setFunds,
    });
  }

  useEffect(() => {
    setClassNames(getClassNames());
    getFundList();
  }, [userData]);

  useEffect(() => {
    if (userData.capData) {
      if (!userData.capData.preferred && !userData.capData.common && !userData.capData.safeConvertible) setCapData(false);
      else if (holdings.length === 0) setNoTableData(false);
      else if (holdings.length > 0) {
        setNoTableData(true);
        setShowTable(true);
      }
    }
  }, [userData, holdings]);

  useEffect(() => {
    if (exitingToTab && isEditing) saveData(false, true);
    else if (exitingToTab) setTabToView(exitingToTab);
    setExitingToTab(false);
  }, [exitingToTab]);

  useEffect(() => { setHoldingsData(); }, [userData]);

  return (
    <div className="Holdings">
      <div className={`page-info ${pageInfo ? 'active' : ''}`}>
        <p>
          <LightbulbOutlinedIcon />
          Not seeing the class of stock you&apos;re looking for?
          You&apos;ll need to add it to the Cap structure tab first to be able to select it here.
        </p>
        <IconButton
          onClick={() => setPageInfo(!pageInfo)}
        >
          <CloseOutlinedIcon />
        </IconButton>
      </div>
      <div className="input-block">
        <div className="block-header">
          <h5>Holdings</h5>
        </div>
        <div className="holdings-titles">
          <span>Class of stock</span>
          <span>Fund name</span>
          <span>Shares owned</span>
          <span>Price per share</span>
          <span>Carrying Value</span>
        </div>
        {!capData ? (
          <div className="holdings-data no-cap-data">
            <p>No holdings have been added</p>
            <p>You will be able to add Holdings once Stock Classes have been entered on the Cap Structure tab.</p>
          </div>
        ) : !noTableData ? (
          <div key="something" className="holdings-data no-holdings">
            <p key="something">No holdings have been added</p>
          </div>
        ) : showTable && (
          <>
            {holdings.map((holding, holdingIndex) => (
              <React.Fragment key={holding.rowID}>
                {!isEditing ? (
                  <div className="holdings-data">
                    <span className="holding-data">{holding.classOfStock || '-'}</span>
                    {holding.fundName?.length >= 40 ? (
                      <Tooltip title={holding.fundName} placement="top">
                        <span className="holding-data">{`${holding.fundName.slice(0, 40).trim()}...`}</span>
                      </Tooltip>
                    ) : <span className="holding-data">{holding.fundName || '-'}</span>}
                    <span className="holding-data">{commaEvery3rdChar(holding.sharesOwned) || '-'}</span>
                    <span className="holding-data">
                      {
                        holding.pricePerShare ?
                          `$${commaEvery3rdChar(parseFloat(holding.pricePerShare.replaceAll('$', '').replaceAll(',', '')).toFixed(4))}` :
                          '-'
                      }
                    </span>
                    <span className="holding-data">
                      {holding.carryingValue ?
                        `$${commaEvery3rdChar(parseFloat(holding.carryingValue.replaceAll('$', '').replaceAll(',', '')).toFixed(4))}` : '-'}
                    </span>
                  </div>
                ) : (
                  <div className="holdings-data">
                    {['classOfStock', 'fundName', 'sharesOwned', 'pricePerShare', 'carryingValue'].map((dataName, columnIdx) => (
                      <React.Fragment key={dataName}>
                        {!holding.classOfStock && columnIdx !== 0 ? (
                          <span className="holding-data">-</span>
                        ) : columnIdx === 1 ? (
                          <Autocomplete
                            freeSolo
                            value={holding.fundName || ''}
                            onChange={(e, value) => holdingOnChange(value, holdingIndex, 'fundName')}
                            options={funds}
                            open={holdingIndex === holdingIdx ? open : false}
                            onOpen={() => {
                              setOpen(true);
                              setHoldingIdx(null);
                            }}
                            onClose={() => {
                              setHoldingIdx(null);
                              setOpen(false);
                            }}
                            renderInput={(params) => (
                              <div ref={params.InputProps.ref}>
                                <TextField
                                  {...params.inputProps}
                                  value={holding.fundName || ''}
                                  type="text"
                                  onChange={(e) => holdingOnChange(e.target.value, holdingIndex, 'fundName')}
                                  onClick={() => {
                                    setHoldingIdx(holdingIndex);
                                    setOpen(true);
                                  }}
                                  onBlur={(e) => {
                                    setOpen(false);
                                    return !funds.includes(e.target.value) ? setFunds([...funds, e.target.value]) : null;
                                  }}
                                />
                              </div>
                            )}
                          />
                        ) : (
                          <TextField
                            select={columnIdx === 0}
                            key={dataName}
                            value={holding[dataName] || ''}
                            onChange={(e) => holdingOnChange(e.target.value, holdingIndex, dataName)}
                            onFocus={() => setChangeInProgress(true)}
                            onBlur={() => {
                              setChangeInProgress(false);
                              setTransactionData({ ...transactionData, holdings });
                            }}
                            SelectProps={{ MenuProps: { disableScrollLock: true, classes: { paper: 'select-dropdown' } } }}
                          >
                            {classNames.map((className, idx) => (
                              <MenuItem
                                key={`${className} ${idx + 1}`}
                                value={className}
                                onClick={() => {
                                  if (className === holding.classOfStock) {
                                    const newHoldingData = copy(holdings);
                                    newHoldingData[holdingIndex].classOfStock = null;
                                    newHoldingData[holdingIndex].fundName = null;
                                    newHoldingData[holdingIndex].sharesOwned = null;
                                    newHoldingData[holdingIndex].pricePerShare = null;
                                    newHoldingData[holdingIndex].carryingValue = null;
                                    setHoldings(newHoldingData);
                                  }
                                }}
                              >
                                {className}
                              </MenuItem>
                            ))}
                          </TextField>
                        )}
                      </React.Fragment>
                    ))}
                    <IconButton
                      className="clear-icon"
                      onClick={() => {
                        if (holdings.length === 0) {
                          setHoldings([{ rowID: 0 }]);
                          setHoldingInputId(0);
                          setIsEditing(false);
                        } else {
                          const newHoldingData = copy(holdings);
                          newHoldingData.splice(holdingIndex, 1);
                          setHoldings(newHoldingData);
                          setTransactionData({ ...transactionData, holdings: newHoldingData });
                        }
                        if (holdings.length === 1) setIsEditing(false);
                      }}
                    >
                      <DeleteOutlineIcon />
                    </IconButton>
                  </div>
                )}
              </React.Fragment>
            ))}
          </>
        )}
        <div className="footer-container">
          {!isEditing ? (
            <Button
              className={`edit-btn ${(!capData || !noTableData) ? 'not-visible' : ''}`}
              onClick={() => {
                setIsEditing(true);
                setOriginalTransactionData(userData.transactionData);
              }}
            >
              <EditIcon />
              Edit
            </Button>
          ) : (
            <>
              <Button onClick={() => {
                setUserData({ ...userData, transactionData: originalTransactionData });
                setIsEditing(false);
                setChangeHasBeenMade(true);
              }}
              >
                <CancelOutlinedIcon />
                Cancel
              </Button>
              <Button onClick={() => { saveData(); }}>
                {savingEdits ? (
                  <>
                    <span className="dots-circle-spinner" />
                    Saving
                  </>
                ) : (
                  <>
                    <SaveOutlinedIcon />
                    Save
                  </>
                )}
              </Button>
            </>
          )}
          <Button
            onClick={() => {
              if (classNames.length === 0) setTabToView(3);
              setHoldings(
                !holdings.length ?
                  [{ rowID: 0 }] :
                  [...holdings, { rowID: holdingInputId + 1, fundName: holdings[holdings.length - 1].fundName || '' }],
              );
              setHoldingInputId(holdingInputId + 1);
              setShowTable(true);
              setIsEditing(true);
            }}
          >
            <AddIcon />
            {classNames.length === 0 ? 'Add class of stock' : 'Add holding'}
          </Button>
        </div>
      </div>
    </div>
  );
}

Holdings.propTypes = {
  userData: PropTypes.object.isRequired,
  setUserData: PropTypes.func.isRequired,
  setExitingToTab: PropTypes.func.isRequired,
  exitingToTab: PropTypes.bool.isRequired,
  setTabToView: PropTypes.func.isRequired,
};
