import React from 'react';
import { Route, Routes } from 'react-router-dom';

import ProfileSettings from './pages/ProfileInfo/ProfileSettings';
import EnterpriseInfo from './pages/ProfileInfo/EnterpriseInfo';
import InvestorIntake from './pages/InvestorIntake';
import FirstSignIn from './components/FirstSignIn';
import Home from './pages/Home';

import { getUserType } from './utils/auth';

export default function RouterProvider() {
  return (
    <Routes>
      {getUserType() === 'investor' ? (
        <Route path="/" element={<InvestorIntake />} />
      ) : (
        <>
          <Route path="/" element={<Home />} />
          <Route path="/profile-settings" element={<ProfileSettings />} />
          <Route path="/enterprise-info" element={<EnterpriseInfo />} />
          <Route path="/first-enterprise-sign-in" element={<FirstSignIn />} />
        </>
      )}
      <Route path="*" element={<h3 style={{ textAlign: 'center', marginTop: '24px' }}>Sorry, page not found</h3>} />
    </Routes>
  );
}
