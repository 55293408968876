import React, { useState, useEffect } from 'react';
import { Auth } from 'aws-amplify';

import { useNavigate } from 'react-router-dom';

import { TextField, Button } from '@mui/material';

import ErrorIcon from '@mui/icons-material/Error';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';

import helloAvatar from '../images/hello_avatar.svg';
import { ReactComponent as LoadingSpinner } from '../images/loading-spinner.svg';

import { isAValidUSPhoneNumber, formatPhoneNumber } from '../utils';

import useFetch from '../hooks/useFetch';

import './FirstSignIn.scss';

export default function FirstSignIn() {
  const [userInfo, setUserInfo] = useState(null);
  const [newUserInfo, setNewUserInfo] = useState(null);
  const [addingUserSuccess, setAddingUserSuccess] = useState(false);

  const [firstNameInputHasError, setFirstNameInputHasError] = useState(false);
  const [lastNameInputHasError, setLastNameInputHasError] = useState(false);
  const [phoneNumberInputHasError, setPhoneNumberInputHasError] = useState(false);

  const [finalizingUser, setFinalizingUser] = useState(false);

  const [, verifyEnterpriseAccountRequest] = useFetch();

  const nav = useNavigate();

  async function getUserData() {
    const userAuthData = await Auth.currentUserInfo();
    const accountData = {
      firstName: userAuthData.attributes['custom:firstName'],
      lastName: userAuthData.attributes['custom:lastName'],
      companyName: 'Redwood',
      phoneNumber: userAuthData.attributes['custom:phoneNumber'],
      userId: userAuthData.attributes.sub,
      email: userAuthData.attributes.email,
      enterpriseCompanyId: userAuthData.attributes['custom:companyId'],
    };
    setUserInfo(accountData);
    setNewUserInfo(accountData);
    return null;
  }

  useEffect(() => { getUserData(); }, []);

  async function finalizeUser(event) {
    event.preventDefault();
    if (newUserInfo.firstName === '' || newUserInfo.lastName === '' || newUserInfo.phoneNumber === '') {
      if (newUserInfo.firstName === '') setFirstNameInputHasError(true);
      if (newUserInfo.lastName === '') setLastNameInputHasError(true);
      if (newUserInfo.phoneNumber === '') setPhoneNumberInputHasError(true);
      return;
    }
    if (JSON.stringify(userInfo) !== JSON.stringify(newUserInfo)) {
      setFinalizingUser(true);
      const userData = {
        firstName: newUserInfo.firstName,
        lastName: newUserInfo.lastName,
        email: newUserInfo.email,
        phoneNumber: newUserInfo.phoneNumber,
      };
      verifyEnterpriseAccountRequest({
        url: '/accounts/update-enterprise-user', method: 'post',
        body: userData, bodyIds: ['enterpriseCompanyId', 'requestUserId'],
        onSuccess: async () => {
          const user = await Auth.currentAuthenticatedUser();
          await Auth.updateUserAttributes(user, {
            'custom:firstName': newUserInfo.firstName,
            'custom:lastName': newUserInfo.lastName,
            'custom:phoneNumber': newUserInfo.phoneNumber,
          });
          setAddingUserSuccess(true);
        },
        onFinally: () => setFinalizingUser(false),
      });
    } else {
      setAddingUserSuccess(true);
    }
  }

  if (addingUserSuccess) {
    return (
      <div className="success-message-container">
        <div className="success-message">
          <div className="check-icon"><CheckRoundedIcon /></div>
          <span>Successfully finalized</span>
        </div>
        <Button
          className="return-to-portal"
          onClick={() => nav('/')}
        >
          Navigate to portal
        </Button>
      </div>
    );
  }

  if (newUserInfo) {
    return (
      <div className="FirstSignIn">
        <div className="form-container">
          <div className="form-header">
            <img src={helloAvatar} alt="" />
            <div className="form-header-text">
              <h4>Thank you for choosing initio</h4>
              <p>
                Please provide a bit more information. We ask for you phone number for
                customer service and account verification, we&apos;ll never sell or use it for
                marketing purposes.
              </p>
            </div>
          </div>
          <form onSubmit={(e) => finalizeUser(e)}>
            <div className="form-grid">
              <TextField
                label="First name"
                value={newUserInfo.firstName || ''}
                onChange={(e) => setNewUserInfo({ ...newUserInfo, firstName: e.target.value })}
                onFocus={() => setFirstNameInputHasError(false)}
                error={firstNameInputHasError}
                helperText={firstNameInputHasError ? (
                  <>
                    <ErrorIcon />
                    Missing input
                  </>
                ) : ' '}
              />
              <TextField
                label="Last name"
                value={newUserInfo.lastName || ''}
                onChange={(e) => setNewUserInfo({ ...newUserInfo, lastName: e.target.value })}
                onFocus={() => setLastNameInputHasError(false)}
                error={lastNameInputHasError}
                helperText={lastNameInputHasError ? (
                  <>
                    <ErrorIcon />
                    Missing input
                  </>
                ) : ' '}
              />
              <TextField
                label="Company name"
                value={newUserInfo.companyName}
                disabled
              />
              <TextField
                label="Phone number"
                value={newUserInfo.phoneNumber || ''}
                onChange={(e) => setNewUserInfo({ ...newUserInfo, phoneNumber: formatPhoneNumber(e.target.value) })}
                onFocus={() => setPhoneNumberInputHasError(false)}
                onBlur={() => newUserInfo.phoneNumber && setPhoneNumberInputHasError(!isAValidUSPhoneNumber(newUserInfo.phoneNumber))}
                error={phoneNumberInputHasError}
                helperText={phoneNumberInputHasError ? (
                  <>
                    <ErrorIcon />
                    Invalid US Phone number format
                  </>
                ) : ' '}
              />
            </div>
            <div className="bottom-btn">
              <Button type="submit">
                {finalizingUser ? (
                  <>
                    <LoadingSpinner className="loading-spinner" />
                    Finalizing...
                  </>
                ) : 'Finalize'}
              </Button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}
