/* eslint-disable jsx-a11y/mouse-events-have-key-events */ // TODO Fix accessibility for mouse over events
import React, { useState } from 'react';

import PropTypes from 'prop-types';

import AnimateHeight from 'react-animate-height';

import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Dialog from '@mui/material/Dialog';
import Tooltip from '@mui/material/Tooltip';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';

import draftThumbnail from '../../../images/draft-report-thumbnail.png';
import reportThumbnail from '../../../images/report-thumbnail.png';

import useS3 from '../../../hooks/useS3';

import './index.scss';

export default function PDFList({
  userData,
  reportList,
  listAllPDFs,
  reportToView,
  setReportToView,
}) {
  const [viewReportList, setViewReportList] = useState(false);

  const [showThumbnailOverlay, setShowThumbnailOverlay] = useState(-1);

  const [reportToDelete, setReportToDelete] = useState(null);

  const s3Path = `820PortfolioReports/${userData.metaData.portfolioCompanyId}/${userData.metaData.transactionId}`;

  const [, s3DeleteRequest] = useS3();
  const [, s3GetRequest] = useS3();

  function deleteS3PDF() {
    s3DeleteRequest({
      key: reportToDelete.key,
      customPrefix: s3Path,
      requestType: 'remove',
      onSuccess: () => { listAllPDFs(); },
      onFinally: () => setReportToDelete(null),
    });
  }

  async function downloadPDF(reportToDownload) {
    s3GetRequest({
      key: reportToDownload.key,
      customPrefix: s3Path,
      requestType: 'get',
      contentDisposition: `attachment; filename ="${reportToDownload.displayName}.pdf"`,
      onSuccess: (s3Response) => { window.open(s3Response); },
    });
  }

  return (
    <>
      <div className="pdf-list-wrapper">
        <div className="view-toggle-btn">
          <Button onClick={() => setViewReportList(!viewReportList)}>
            View all reports
            <ExpandMoreIcon
              className={`circle-chevron${viewReportList ? ' upward' : ' downward'}`}
            />
          </Button>
        </div>
        <AnimateHeight duration={500} height={viewReportList ? 'auto' : 0}>
          <div className="pdf-list">
            {reportList.map((report, reportIndex) => (
              <div
                key={report.key}
                role="button"
                aria-label="View-report"
                className={`pdf-tile${report.key === reportToView.key ? ' report-in-view' : ''}`}
                onClick={() => setReportToView(report)}
                onKeyDown={(e) => { if (e.key === 'Enter') setReportToView(report); }}
                onMouseOver={() => { if (showThumbnailOverlay !== reportIndex) setShowThumbnailOverlay(reportIndex); }}
                onMouseLeave={() => setShowThumbnailOverlay(-1)}
                onFocus={() => { if (showThumbnailOverlay !== reportIndex) setShowThumbnailOverlay(reportIndex); }}
                tabIndex={0}
              >
                <img src={report.type === 'final' ? reportThumbnail : draftThumbnail} alt="report thumbnail" />
                {showThumbnailOverlay === reportIndex && (
                  <div className="thumbnail-overlay">
                    <Tooltip
                      disableInteractive
                      title={`Delete${report.type === 'final' ? '' : ' draft'}`}
                      PopperProps={{ className: 'bottom-arrow-tooltip' }}
                      placement="top"
                      enterDelay={500}
                      enterNextDelay={1500}
                      arrow
                    >
                      <IconButton onClick={(e) => {
                        e.stopPropagation();
                        setReportToDelete(report);
                      }}
                      >
                        <DeleteOutlinedIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip
                      disableInteractive
                      title={`Download${report.type === 'final' ? '' : ' draft'}`}
                      PopperProps={{ className: 'bottom-arrow-tooltip' }}
                      placement="top"
                      enterDelay={500}
                      enterNextDelay={1500}
                      arrow
                    >
                      <IconButton onClick={() => downloadPDF(report)}>
                        <DownloadOutlinedIcon />
                      </IconButton>
                    </Tooltip>
                  </div>
                )}
                <p>{`${report.type === 'final' ? 'Final' : 'Draft'}_${report.displayVersion}`}</p>
              </div>
            ))}
          </div>
        </AnimateHeight>
      </div>
      <Dialog className="confirm-deletion" open={reportToDelete !== null}>
        <h4>
          <WarningAmberRoundedIcon />
          {`Delete ${reportToDelete?.displayName} report?`}
        </h4>
        <p>
          {`If you delete ${reportToDelete?.displayName} you won't be able to undo this action.`}
        </p>
        <div className="bottom-btns">
          <Button onClick={() => setReportToDelete(null)}>Cancel</Button>
          <Button onClick={() => { deleteS3PDF(); }}>Delete</Button>
        </div>
      </Dialog>
    </>
  );
}

PDFList.propTypes = {
  userData: PropTypes.object.isRequired,
  reportList: PropTypes.array.isRequired,
  listAllPDFs: PropTypes.func.isRequired,
  reportToView: PropTypes.object.isRequired,
  setReportToView: PropTypes.func.isRequired,
};
