import React from 'react';
import PropTypes from 'prop-types';
import {
  Page,
  View,
  Text,
} from '@react-pdf/renderer';

import TablePageHeader from '../../components/TablePageHeader';
import BottomPagination from '../../components/BottomPagination';
import DraftOverlay from '../../components/DraftOverlay';

export default function ValueComparison({ reportData, totalPageCount, exhibitNumber }) {
  const tableData = reportData.ValueComparison;
  const { changeInValue } = tableData;
  const { changeInConcludedEquityValue, changeInHoldingsValue } = changeInValue;

  const metricRowNamesMapper = {
    concludedEquityValue: 'Concluded equity value',
    enterpriseValue: 'Enterprise value',
    cash: 'Cash',
    debt: 'Debt',
    nextFiscalYearRevenue: 'Next fiscal year revenue',
    next12MonthsRevenue: 'Next 12 months revenue',
    currentFiscalYearRevenue: 'Current fiscal year revenue',
    last12MonthsRevenue: 'Last 12 months (LTM) Revenue',
    lastFiscalYearRevenue: 'Last fiscal year revenue',
    'Implied Enterprise Value Multiple - nextFiscalYearRevenue': 'Implied Enterprise Value Multiple - Next Fiscal Year Revenue',
    'Implied Enterprise Value Multiple - next12MonthsRevenue': 'Implied Enterprise Value Multiple - Next 12 Months Revenue',
    'Implied Enterprise Value Multiple - currentFiscalYearRevenue': 'Implied Enterprise Value Multiple - Current Fiscal Year Revenue',
    'Implied Enterprise Value Multiple - last12MonthsRevenue': 'Implied Enterprise Value Multiple - LTM Revenue',
    'Implied Enterprise Value Multiple - lastFiscalYearRevenue': 'Implied Enterprise Value Multiple - Last Fiscal Year Revenue',
    holdingsValue: 'Holdings value',
  };

  const metricOrder = [
    'concludedEquityValue',
    'cash',
    'debt',
    'enterpriseValue',
    'nextFiscalYearRevenue',
    'next12MonthsRevenue',
    'currentFiscalYearRevenue',
    'last12MonthsRevenue',
    'lastFiscalYearRevenue',
    'Implied Enterprise Value Multiple - nextFiscalYearRevenue',
    'Implied Enterprise Value Multiple - next12MonthsRevenue',
    'Implied Enterprise Value Multiple - currentFiscalYearRevenue',
    'Implied Enterprise Value Multiple - last12MonthsRevenue',
    'Implied Enterprise Value Multiple - lastFiscalYearRevenue',
    'holdingsValue',
  ];

  const calibrationRowMapper = {
    equityValuePostMoney: 'Equity value post money',
    transactionDate: 'Transaction date',
    termSinceTransaction: 'Term since transaction',
    last12MonthsRevenue: 'Last 12 months (LTM) Revenue',
    last12MonthsEBITDA: 'Last 12 months (LTM) EBITDA',
    lastFiscalYearEBITDA: 'Last fiscal year EBITDA',
    impliedEquityMultipleOflast12MonthsRevenue: 'Implied equity multiple of LTM Revenue',
    impliedEquityMultipleOflastFiscalYearEBITDA: 'Implied equity multiple of last fiscal year EBITDA',
    impliedEquityMultipleOflast12MonthsEBITDA: 'Implied equity multiple of LTM EBITDA',
    impliedEquityMultipleOfcurrentFiscalYearEBITDA: 'Implied equity multiple of current fiscal year EBITDA',
    impliedAnnualRateOfReturn: 'Implied annual rate of return',
    last12MonthsRevenueCagr: 'LTM Revenue CAGR',
    last12MonthsEBITDACagr: 'LTM EBITDA CAGR',
    currentFiscalYearEBITDA: 'Current fiscal year EBITDA',
    next12MonthsRevenue: 'Next 12 months Revenue',
    impliedEquityMultipleOfnext12MonthsRevenue: 'Implied equity multiple of next 12 months revenue',
    next12MonthsRevenueCagr: 'Next 12 months revenue CAGR',
    next12MonthsEBITDA: 'Next 12 months EBITDA',
    impliedEquityMultipleOfnext12MonthsEBITDA: 'Implied equity multiple of next 12 months EBITDA',
    currentFiscalYearEBITDACagr: 'Current fiscal year EBITDA CAGR',
    lastFiscalYearRevenue: 'Last fiscal year revenue',
    impliedEquityMultipleOflastFiscalYearRevenue: 'Implied equity multiple of last fiscal year revenue',
    impliedEquityMultipleOfnextFiscalYearRevenue: 'Implied equity multiple of next fiscal year revenue',
    currentFiscalYearRevenueCagr: 'Current fiscal year revenue CAGR',
    impliedEquityMultipleOfcurrentFiscalYearRevenue: 'Implied equity multiple of current fiscal year revenue',
    currentFiscalYearRevenue: 'Current fiscal year revenue',
    nextFiscalYearRevenueCagr: 'Next fiscal year revenue CAGR',
    nextFiscalYearRevenue: 'Next fiscal year revenue',
    nextFiscalYearEBITDACagr: 'Next fiscal year EBITDA CAGR',
    impliedEquityMultipleOfnextFiscalYearEBITDA: 'Implied equity multiple of next fiscal year EBITDA',
    nextFiscalYearEBITDA: 'Next fiscal year EBITDA',
    lastFiscalYearRevenueCagr: 'Last fiscal year revenue CAGR',
    next12MonthsEBITDACagr: 'Next 12 months EBITDA CAGR',
    lastFiscalYearEBITDACagr: 'Last fiscal year EBITDA CAGR',
  };

  const calibrationOrder = [
    'equityValuePostMoney',
    'transactionDate',
    'termSinceTransaction',
    'impliedAnnualRateOfReturn',
    'nextFiscalYearRevenue',
    'next12MonthsRevenue',
    'currentFiscalYearRevenue',
    'last12MonthsRevenue',
    'lastFiscalYearRevenue',
    'nextFiscalYearEBITDA',
    'next12MonthsEBITDA',
    'currentFiscalYearEBITDA',
    'last12MonthsEBITDA',
    'lastFiscalYearEBITDA',
    'impliedEquityMultipleOfnextFiscalYearRevenue',
    'impliedEquityMultipleOfnext12MonthsRevenue',
    'impliedEquityMultipleOfcurrentFiscalYearRevenue',
    'impliedEquityMultipleOflast12MonthsRevenue',
    'impliedEquityMultipleOflastFiscalYearRevenue',
    'impliedEquityMultipleOfnextFiscalYearEBITDA',
    'impliedEquityMultipleOfnext12MonthsEBITDA',
    'impliedEquityMultipleOfcurrentFiscalYearEBITDA',
    'impliedEquityMultipleOflastFiscalYearEBITDA',
    'impliedEquityMultipleOflast12MonthsEBITDA',
    'nextFiscalYearRevenueCagr',
    'next12MonthsRevenueCagr',
    'currentFiscalYearRevenueCagr',
    'last12MonthsRevenueCagr',
    'lastFiscalYearRevenueCagr',
    'nextFiscalYearEBITDACagr',
    'next12MonthsEBITDACagr',
    'currentFiscalYearEBITDACagr',
    'last12MonthsEBITDACagr',
    'lastFiscalYearEBITDACagr',
  ];

  const fullyDilutedCalculationMapper = {
    mostRecentTransaction: 'Most recent transaction',
    issuePrice: 'Issue price',
    totalSharesOutstanding: 'Total shares outstanding (current)',
    postMoneyEquityValue: 'Post-money equity value (implied with current shares)',
    impliedCurrentFullyDilutedEquityValue: 'Implied current fully diluted equity value',
  };

  const fullyDilutedCalculationOrder = [
    'mostRecentTransaction',
    'issuePrice',
    'totalSharesOutstanding',
    'postMoneyEquityValue',
    'impliedCurrentFullyDilutedEquityValue',
  ];

  function sortRowNames(names, order) {
    const sortedNames = names.filter((name) => order.includes(name));
    sortedNames.sort((a, b) => order.indexOf(a) - order.indexOf(b));
    return sortedNames;
  }
  const { changeBreakdown, calibration } = changeInValue;

  const valChangeKeys = Object.keys(changeBreakdown.Change);
  const sortedValChangeRowNames = sortRowNames(valChangeKeys, metricOrder);

  const calibrationRowNames = Object.keys(calibration.fullyDiluted.uponTransaction);
  const sortedCalibrationRowNames = sortRowNames(calibrationRowNames, calibrationOrder);
  const fullyDilutedKeys = Object.keys(calibration.fullyDilutedEquityValueCalculation);
  const sortedFullyDilutedCalRowNames = sortRowNames(fullyDilutedKeys, fullyDilutedCalculationOrder);
  const calibrationColumns = ['rowNames', 'uponTransaction', 'currentImplied', 'uponTransaction', 'currentImplied'];

  const valChangeRowNames = ['', ...Object.keys(changeInValue.changeBreakdown)];

  const returnRowData = (data, columnIndex) => {
    let cellData = null;
    if (columnIndex === 1) cellData = calibration.fullyDiluted.uponTransaction[data];
    if (columnIndex === 2) cellData = calibration.fullyDiluted.currentImplied[data];
    if (columnIndex === 3) cellData = calibration.backsolve.uponTransaction[data];
    if (columnIndex === 4) cellData = calibration.backsolve.currentImplied[data];
    return !cellData ? '' : cellData;
  };

  const prevValuationFootnote = reportData.footnoteData.prevValuation;
  const prevTransactionFootnote = reportData.footnoteData.prevTransaction;
  const valuationDateFootnote = reportData.footnoteData.valuationDate;

  let numberOfFootnoteLines = 0;

  function determineFootnoteLines() {
    const footnotes = [prevValuationFootnote, prevTransactionFootnote, valuationDateFootnote];
    footnotes.forEach((footnote) => {
      if (footnote) numberOfFootnoteLines += Math.ceil(footnote.length / 230);
    });
  }

  determineFootnoteLines();

  const rowNamesLength = sortedCalibrationRowNames.length;

  const showOnePage = (rowNamesLength < 23 && numberOfFootnoteLines < 1) ||
    (rowNamesLength <= 19 && numberOfFootnoteLines <= 4) ||
    (rowNamesLength < 19 && numberOfFootnoteLines <= 4) ||
    (rowNamesLength < 15 && numberOfFootnoteLines < 9) ||
    (rowNamesLength <= 9 && numberOfFootnoteLines <= 12);

  const showTwoPages = rowNamesLength > 22 ||
    (rowNamesLength > 19 && numberOfFootnoteLines > 4) ||
    (rowNamesLength > 19 && numberOfFootnoteLines >= 4) ||
    (rowNamesLength >= 19 && numberOfFootnoteLines > 4) ||
    (rowNamesLength > 9 && numberOfFootnoteLines > 8);

  const showThreePages = (rowNamesLength > 33 && numberOfFootnoteLines < 1) ||
    (rowNamesLength > 29 && numberOfFootnoteLines > 0 && numberOfFootnoteLines <= 4) ||
    (rowNamesLength > 24 && numberOfFootnoteLines > 4 && numberOfFootnoteLines <= 8) ||
    (rowNamesLength > 19 && numberOfFootnoteLines > 8 && numberOfFootnoteLines <= 12);

  return (
    <>
      <Page
        size="TABLOID"
        orientation="landscape"
        style={{ position: 'relative', backgroundColor: '#EAEAEB' }}
      >
        <TablePageHeader
          companyName={reportData.companyInfo.companyName}
          pageName={`Value comparison   |   Exhibit ${exhibitNumber}`}
        />
        <View
          style={{
            marginTop: '55px',
            marginLeft: '24px',
            width: '1160px',
            minHeight: '77px',
            backgroundColor: '#FAFAFD',
            padding: '8px 17px',
            borderBottom: '0px',
            border: '1px solid #AFB1B3',
          }}
        >
          <View
            style={{
              fontFamily: 'Roboto',
              height: 'auto',
              width: 'auto',
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <View
              style={{
                fontFamily: 'Roboto',
                height: 'auto',
                width: 'auto',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Text
                style={{
                  display: 'flex',
                  fontFamily: 'Roboto',
                  alignItems: 'center',
                  fontWeight: '400',
                  fontSize: '10px',
                  color: '#49454F',
                  letterSpacing: '0.4px',
                  padding: '2px 16px 0px 0px',
                  height: '20px',
                }}
              >
                Change in Concluded equity value:
              </Text>
              <Text
                style={{
                  display: 'flex',
                  fontFamily: 'Roboto',
                  fontWeight: '400',
                  fontSize: '10px',
                  color: '#49454F',
                  letterSpacing: '0.4px',
                  padding: '2px 16px 0px 0px',
                  height: '20px',
                }}
              >
                Change in Holdings value:
              </Text>
            </View>
            <View
              style={{
                fontFamily: 'Roboto',
                height: 'auto',
                width: 'auto',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Text
                style={{
                  display: 'flex',
                  fontFamily: 'RobotoMed',
                  fontWeight: '600',
                  fontSize: '10px',
                  color: '#49454F',
                  letterSpacing: '0.4px',
                  padding: '2px 16px 0px 0px',
                  height: '20px',
                }}
              >
                {changeInConcludedEquityValue}
              </Text>
              <Text
                style={{
                  fontFamily: 'RobotoMed',
                  fontWeight: '600',
                  fontSize: '10px',
                  color: '#49454F',
                  letterSpacing: '0.4px',
                  padding: '2px 16px 0px 0px',
                  height: '20px',
                }}
              >
                {changeInHoldingsValue}
              </Text>
            </View>
          </View>
          {!!prevValuationFootnote && (
            <View
              style={{
                fontFamily: 'RobotoMed',
                height: 'auto',
                width: '1126px',
              }}
            >
              <Text
                style={{
                  fontFamily: 'Roboto',
                  fontSize: '10px',
                  color: '#49454F',
                  letterSpacing: '0.4px',
                  fontWeight: '400',
                  lineHeight: '0.55mm',
                }}
              >
                <Text
                  style={{
                    fontFamily: 'RobotoMed',
                    fontSize: '11px',
                    color: '#49454F',
                    letterSpacing: '0.4px',
                    marginRight: '8px',
                    fontWeight: '600',
                  }}
                >
                  Appraiser&apos;s commentary:&nbsp;&nbsp;
                </Text>
                {prevValuationFootnote}
              </Text>
            </View>
          )}
        </View>
        <View
          style={{
            marginLeft: '24px',
            display: 'flex',
            flexDirection: 'column',
            width: '1160px',
            border: '1px solid #AFB1B3',
            borderTop: '0px',
            height: 'auto',
          }}
        >
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              height: '20px',
              backgroundColor: '#307157',
              borderBottom: '1px',
              borderColor: '#CCCCCC',
            }}
          >
            <Text
              style={{
                fontFamily: 'RobotoMed',
                fontSize: '9px',
                color: '#E1E2EC',
                letterSpacing: '0.4px',
                paddingLeft: '16px',
                textAlign: 'left',
                fontWeight: '900',
              }}
            >
              Valuation date
            </Text>
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                marginLeft: 'auto',
                height: '20px',
                backgroundColor: '#307157',
                borderBottom: '1px',
                borderColor: '#CCCCCC',
              }}
            >
              {valChangeRowNames.map((labels, index) => (
                <Text
                  key={`${labels} - ${index + 1}`}
                  style={{
                    fontFamily: 'RobotoMed',
                    fontSize: '9px',
                    color: '#E1E2EC',
                    paddingTop: '5px',
                    letterSpacing: '0.4px',
                    textAlign: 'right',
                    paddingRight: '10px',
                    marginRight: index === valChangeRowNames?.length || 0 - 1 ? '20px' : '0px',
                    width: '204px',
                  }}
                >
                  {labels}
                </Text>
              ))}
            </View>
          </View>
          {valChangeKeys.map((_, i) => i).map((data, columnIdx) => (
            <View
              key={data}
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'flex-start',
                height: '16px',
                backgroundColor: `${columnIdx % 2 ? '#FAFAFD' : '#F0F0F3'}`,
              }}
            >
              {valChangeRowNames.map((rowNames, columnIndex) => (
                <Text
                  key={rowNames}
                  style={
                    columnIndex === 0 ? ({
                      display: 'flex',
                      justifyContent: 'flex-start',
                      fontFamily: 'Roboto',
                      fontSize: '10px',
                      color: '#49454F',
                      paddingTop: '2px',
                      paddingLeft: '16px',
                      alignItems: 'center',
                      letterSpacing: '0.4px',
                      textAlign: 'left',
                      width: '524px',
                    }) : ({
                      display: 'flex',
                      justifyContent: 'flex-end',
                      fontFamily: 'Roboto',
                      fontSize: '10px',
                      color: '#49454F',
                      padding: '2px 8px 0px 8px',
                      letterSpacing: '0.4px',
                      textAlign: 'right',
                      width: '204px',
                    })}
                >
                  {columnIndex === 0 ?
                    metricRowNamesMapper[sortedValChangeRowNames[columnIdx]] :
                    changeBreakdown[rowNames][sortedValChangeRowNames[columnIdx]]}
                </Text>
              ))}
            </View>
          ))}
          {!!valuationDateFootnote && (
            <View
              style={{
                width: '1158px',
                height: 'auto',
                backgroundColor: '#FAFAFD',
                padding: '4px 16px 0px',
                borderBottom: '4px solid #F0F0F3',
              }}
            >
              <Text
                style={{
                  fontFamily: 'Roboto',
                  fontSize: '9px',
                  color: '#49454F',
                  letterSpacing: '0.4px',
                  textAlign: 'left',
                  width: '1125px',
                  wordWrap: 'break-word',
                  lineHeight: '0.55mm',
                  paddingTop: '8px',
                  height: 'auto',
                }}
              >
                *&nbsp;
                {valuationDateFootnote}
              </Text>
            </View>
          )}
          {showOnePage && (
            <>
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  height: '24px',
                  backgroundColor: '#0C2617',
                  padding: '5px 16px 0px',
                }}
              >
                <Text
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    fontFamily: 'RobotoMed',
                    fontSize: '10px',
                    letterSpacing: '0.4px',
                    color: '#E1E2EC',
                    width: '121px',
                  }}
                >
                  Calibration
                </Text>
                <Text
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    fontFamily: 'RobotoMed',
                    fontSize: '10px',
                    letterSpacing: '0.4px',
                    color: '#98B8AA',
                  }}
                >
                  (USD $)
                </Text>
              </View>
              <View
                style={{
                  display: 'flex',
                  height: '20px',
                  flexDirection: 'row',
                  backgroundColor: '#307157',
                  borderBottom: '1px solid #307157',
                }}
              >
                <View
                  style={{
                    display: 'flex',
                    width: '416px',
                    borderBottom: '1px solid #CCCCCC',
                  }}
                />
                <View
                  style={{
                    display: 'flex',
                    width: '280px',
                    borderBottom: '1px solid #CCCCCC',
                    margin: '0px 12px',
                  }}
                >
                  <Text
                    style={{
                      display: 'flex',
                      fontFamily: 'RobotoMed',
                      fontSize: '10px',
                      letterSpacing: '0.4px',
                      paddingTop: '3px',
                      textAlign: 'center',
                      color: '#E1E2EC',
                    }}
                  >
                    Fully diluted
                  </Text>
                </View>
                <View
                  style={{
                    display: 'flex',
                    width: '280px',
                    borderBottom: '1px solid #CCCCCC',
                    margin: '0px 12px 0px 0px',
                  }}
                >
                  <Text
                    style={{
                      display: 'flex',
                      fontFamily: 'RobotoMed',
                      fontSize: '10px',
                      letterSpacing: '0.4px',
                      paddingTop: '3px',
                      textAlign: 'center',
                      color: '#E1E2EC',
                    }}
                  >
                    Backsolve
                  </Text>
                </View>
                <View
                  style={{
                    display: 'flex',
                    width: '146px',
                    borderBottom: '1px solid #CCCCCC',
                  }}
                />
              </View>
              <View
                style={{
                  display: 'flex',
                  height: '20px',
                  flexDirection: 'row',
                  backgroundColor: '#307157',
                  borderTop: '1px solid #307157',
                }}
              >
                <View
                  style={{
                    display: 'flex',
                    width: '416px',
                  }}
                />
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    gap: '12px',
                    width: '280px',
                    margin: '0px 12px',
                  }}
                >
                  <Text
                    style={{
                      display: 'flex',
                      fontFamily: 'RobotoMed',
                      fontSize: '10px',
                      letterSpacing: '0.4px',
                      paddingTop: '3px',
                      textAlign: 'center',
                      color: '#E1E2EC',
                      marginLeft: '40px',
                    }}
                  >
                    Upon transaction
                  </Text>
                  <Text
                    style={{
                      display: 'flex',
                      fontFamily: 'RobotoMed',
                      fontSize: '10px',
                      letterSpacing: '0.4px',
                      paddingTop: '3px',
                      textAlign: 'center',
                      color: '#E1E2EC',
                      marginRight: '8px',
                    }}
                  >
                    Current (implied)
                  </Text>
                </View>
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    gap: '12px',
                    width: '280px',
                    margin: '0px 12px',
                  }}
                >
                  <Text
                    style={{
                      display: 'flex',
                      fontFamily: 'RobotoMed',
                      fontSize: '10px',
                      letterSpacing: '0.4px',
                      paddingTop: '3px',
                      textAlign: 'center',
                      color: '#E1E2EC',
                      marginLeft: '35px',
                    }}
                  >
                    Upon transaction
                  </Text>
                  <Text
                    style={{
                      display: 'flex',
                      fontFamily: 'RobotoMed',
                      fontSize: '10px',
                      letterSpacing: '0.4px',
                      paddingTop: '3px',
                      textAlign: 'center',
                      color: '#E1E2EC',
                      marginRight: '8px',
                    }}
                  >
                    Current (implied)
                  </Text>
                </View>
                <View style={{ display: 'flex', width: '146px' }} />
              </View>
              {sortedCalibrationRowNames.map((data, rowIndex) => (
                <View
                  key={data}
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'flex-start',
                    height: '16px',
                    backgroundColor: `${rowIndex % 2 ? '#FAFAFD' : '#F0F0F3'}`,
                  }}
                >
                  {calibrationColumns.map((column, columnIndex) => (
                    <Text
                      key={column}
                      style={
                        columnIndex === 0 ? ({
                          display: 'flex',
                          justifyContent: 'flex-start',
                          fontFamily: 'Roboto',
                          fontSize: '10px',
                          color: '#49454F',
                          paddingTop: '2px',
                          paddingLeft: '16px',
                          alignItems: 'center',
                          letterSpacing: '0.4px',
                          textAlign: 'left',
                          width: '416px',
                          marginRight: '12px',
                        }) : ({
                          display: 'flex',
                          justifyContent: 'flex-end',
                          fontFamily: 'Roboto',
                          fontSize: '10px',
                          color: '#49454F',
                          padding: '2px 8px 0px 8px',
                          letterSpacing: '0.4px',
                          textAlign: 'right',
                          width: `${columnIndex > 0 ? `${124 + columnIndex * 9}px` : '152px'}`,
                        })}
                    >
                      {column === 'rowNames' ? calibrationRowMapper[data] : returnRowData(data, columnIndex)}
                    </Text>
                  ))}
                </View>
              ))}
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  height: '20px',
                  backgroundColor: '#9ECEBB',
                  padding: '4px 16px 0px',
                  textAlign: 'left',
                }}
              >
                <Text
                  style={{
                    fontSize: '10px',
                    letterSpacing: '0.5px',
                    fontFamily: 'RobotoMed',
                    color: '#49454F',
                  }}
                >
                  Fully diluted equity value calculation
                </Text>
              </View>
              {sortedFullyDilutedCalRowNames.map((_, i) => i).map((data, rowIndex) => (
                <View
                  key={data}
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'flex-start',
                    height: '16px',
                    backgroundColor: `${rowIndex % 2 ? '#FAFAFD' : '#F0F0F3'}`,
                  }}
                >
                  {new Array(2).fill('').map((column, columnIndex) => (
                    <Text
                      key={column}
                      style={
                        columnIndex === 0 ? ({
                          display: 'flex',
                          justifyContent: 'flex-start',
                          fontFamily: 'Roboto',
                          fontSize: '10px',
                          color: '#49454F',
                          paddingTop: '2px',
                          paddingLeft: '16px',
                          alignItems: 'center',
                          letterSpacing: '0.4px',
                          textAlign: 'left',
                          width: '416px',
                        }) : ({
                          display: 'flex',
                          justifyContent: 'flex-end',
                          fontFamily: 'Roboto',
                          fontSize: '10px',
                          color: '#49454F',
                          padding: '2px 8px 0px 8px',
                          letterSpacing: '0.4px',
                          textAlign: 'right',
                          width: '144px',
                        })}
                    >
                      {columnIndex === 0 ?
                        fullyDilutedCalculationMapper[sortedFullyDilutedCalRowNames[rowIndex]] :
                        calibration.fullyDilutedEquityValueCalculation[sortedFullyDilutedCalRowNames[rowIndex]] ?
                          calibration.fullyDilutedEquityValueCalculation[sortedFullyDilutedCalRowNames[rowIndex]] : 'N/A'}
                    </Text>
                  ))}
                </View>
              ))}
              {prevTransactionFootnote?.length && (
                <View
                  style={{
                    width: '1158px',
                    backgroundColor: '#FAFAFD',
                    padding: '4px 16px 0px',
                    borderTop: '1px solid #CCCCCC',
                  }}
                >
                  <Text
                    style={{
                      fontFamily: 'Roboto',
                      fontSize: '9px',
                      color: '#49454F',
                      letterSpacing: '0.4px',
                      textAlign: 'left',
                      width: '1125px',
                      wordWrap: 'break-word',
                      lineHeight: '0.55mm',
                      padding: '8px 0px',
                    }}
                  >
                    <Text
                      style={{
                        fontFamily: 'RobotoMed',
                        fontSize: '11px',
                        color: '#49454F',
                        letterSpacing: '0.4px',
                        marginRight: '8px',
                        fontWeight: '600',
                        lineHeight: '0.55mm',
                      }}
                    >
                      Appraiser&apos;s commentary:&nbsp;&nbsp;
                    </Text>
                    {prevTransactionFootnote}
                  </Text>
                </View>
              )}
            </>
          )}
        </View>
        <BottomPagination
          pageNumber={totalPageCount}
          reportData={reportData}
        />
        {reportData.isDraft && <DraftOverlay />}
      </Page>
      {showTwoPages && (
        <Page
          size="TABLOID"
          orientation="landscape"
          style={{ position: 'relative', backgroundColor: '#EAEAEB' }}
        >
          <TablePageHeader
            companyName={reportData.companyInfo.companyName}
            pageName={`Value comparison   |   Exhibit ${exhibitNumber}`}
          />
          <View
            style={{
              marginTop: '55px',
              marginLeft: '24px',
              width: '1177px',
              minHeight: '77px',
              backgroundColor: '#FAFAFD',
              borderBottom: '0px',
              border: '1px solid #AFB1B3',
            }}
          >
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                height: '24px',
                backgroundColor: '#0C2617',
                padding: '5px 16px 0px',
              }}
            >
              <Text
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  fontFamily: 'RobotoMed',
                  fontSize: '10px',
                  letterSpacing: '0.4px',
                  color: '#E1E2EC',
                  width: '121px',
                }}
              >
                Calibration
              </Text>
              <Text
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  fontFamily: 'RobotoMed',
                  fontSize: '10px',
                  letterSpacing: '0.4px',
                  color: '#98B8AA',
                }}
              >
                (USD $)
              </Text>
            </View>
            <View
              style={{
                display: 'flex',
                height: '20px',
                flexDirection: 'row',
                backgroundColor: '#307157',
                borderBottom: '1px solid #307157',
              }}
            >
              <View
                style={{
                  display: 'flex',
                  width: '416px',
                  borderBottom: '1px solid #CCCCCC',
                }}
              />
              <View
                style={{
                  display: 'flex',
                  width: '280px',
                  borderBottom: '1px solid #CCCCCC',
                  margin: '0px 12px',
                }}
              >
                <Text
                  style={{
                    display: 'flex',
                    fontFamily: 'RobotoMed',
                    fontSize: '10px',
                    letterSpacing: '0.4px',
                    paddingTop: '3px',
                    textAlign: 'center',
                    color: '#E1E2EC',
                  }}
                >
                  Fully diluted
                </Text>
              </View>
              <View
                style={{
                  display: 'flex',
                  width: '280px',
                  borderBottom: '1px solid #CCCCCC',
                  margin: '0px 12px 0px 0px',
                }}
              >
                <Text
                  style={{
                    display: 'flex',
                    fontFamily: 'RobotoMed',
                    fontSize: '10px',
                    letterSpacing: '0.4px',
                    paddingTop: '3px',
                    textAlign: 'center',
                    color: '#E1E2EC',
                  }}
                >
                  Backsolve
                </Text>
              </View>
              <View
                style={{
                  display: 'flex',
                  width: '146px',
                  borderBottom: '1px solid #CCCCCC',
                }}
              />
            </View>
            <View
              style={{
                display: 'flex',
                height: '20px',
                flexDirection: 'row',
                backgroundColor: '#307157',
                borderTop: '1px solid #307157',
              }}
            >
              <View
                style={{
                  display: 'flex',
                  width: '416px',
                }}
              />
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  gap: '12px',
                  width: '280px',
                  margin: '0px 12px',
                }}
              >
                <Text
                  style={{
                    display: 'flex',
                    fontFamily: 'RobotoMed',
                    fontSize: '10px',
                    letterSpacing: '0.4px',
                    paddingTop: '3px',
                    textAlign: 'center',
                    color: '#E1E2EC',
                    marginLeft: '40px',
                  }}
                >
                  Upon transaction
                </Text>
                <Text
                  style={{
                    display: 'flex',
                    fontFamily: 'RobotoMed',
                    fontSize: '10px',
                    letterSpacing: '0.4px',
                    paddingTop: '3px',
                    textAlign: 'center',
                    color: '#E1E2EC',
                    marginRight: '8px',
                  }}
                >
                  Current (implied)
                </Text>
              </View>
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  gap: '12px',
                  width: '280px',
                  margin: '0px 12px',
                }}
              >
                <Text
                  style={{
                    display: 'flex',
                    fontFamily: 'RobotoMed',
                    fontSize: '10px',
                    letterSpacing: '0.4px',
                    paddingTop: '3px',
                    textAlign: 'center',
                    color: '#E1E2EC',
                    marginLeft: '35px',
                  }}
                >
                  Upon transaction
                </Text>
                <Text
                  style={{
                    display: 'flex',
                    fontFamily: 'RobotoMed',
                    fontSize: '10px',
                    letterSpacing: '0.4px',
                    paddingTop: '3px',
                    textAlign: 'center',
                    color: '#E1E2EC',
                    marginRight: '8px',
                  }}
                >
                  Current (implied)
                </Text>
              </View>
              <View style={{ display: 'flex', width: '146px' }} />
            </View>
            {sortedCalibrationRowNames.map((data, rowIndex) => (
              <View
                key={data}
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'flex-start',
                  height: '16px',
                  backgroundColor: `${rowIndex % 2 ? '#FAFAFD' : '#F0F0F3'}`,
                }}
              >
                {calibrationColumns.map((column, columnIndex) => (
                  <Text
                    key={column}
                    style={
                      columnIndex === 0 ? ({
                        display: 'flex',
                        justifyContent: 'flex-start',
                        fontFamily: 'Roboto',
                        fontSize: '10px',
                        color: '#49454F',
                        paddingTop: '2px',
                        paddingLeft: '16px',
                        alignItems: 'center',
                        letterSpacing: '0.4px',
                        textAlign: 'left',
                        width: '416px',
                        marginRight: '12px',
                      }) : ({
                        display: 'flex',
                        justifyContent: 'flex-end',
                        fontFamily: 'Roboto',
                        fontSize: '10px',
                        color: '#49454F',
                        padding: '2px 8px 0px 8px',
                        letterSpacing: '0.4px',
                        textAlign: 'right',
                        width: `${columnIndex > 0 ? `${124 + columnIndex * 9}px` : '152px'}`,
                      })}
                  >
                    {column === 'rowNames' ? calibrationRowMapper[data] : returnRowData(data, columnIndex)}
                  </Text>
                ))}
              </View>
            ))}
            {showTwoPages && !showThreePages && (
              <>
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    height: '20px',
                    backgroundColor: '#9ECEBB',
                    padding: '4px 16px 0px',
                    textAlign: 'left',
                  }}
                >
                  <Text
                    style={{
                      fontSize: '10px',
                      letterSpacing: '0.5px',
                      fontFamily: 'RobotoMed',
                      color: '#49454F',
                    }}
                  >
                    Fully diluted equity value calculation
                  </Text>
                </View>
                {sortedFullyDilutedCalRowNames.map((_, i) => i).map((data, rowIndex) => (
                  <View
                    key={data}
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'flex-start',
                      height: '16px',
                      backgroundColor: `${rowIndex % 2 ? '#FAFAFD' : '#F0F0F3'}`,
                    }}
                  >
                    {new Array(2).fill('').map((column, columnIndex) => (
                      <Text
                        key={column}
                        style={
                          columnIndex === 0 ? ({
                            display: 'flex',
                            justifyContent: 'flex-start',
                            fontFamily: 'Roboto',
                            fontSize: '10px',
                            color: '#49454F',
                            paddingTop: '2px',
                            paddingLeft: '16px',
                            alignItems: 'center',
                            letterSpacing: '0.4px',
                            textAlign: 'left',
                            width: '416px',
                          }) : ({
                            display: 'flex',
                            justifyContent: 'flex-end',
                            fontFamily: 'Roboto',
                            fontSize: '10px',
                            color: '#49454F',
                            padding: '2px 8px 0px 8px',
                            letterSpacing: '0.4px',
                            textAlign: 'right',
                            width: '144px',
                          })}
                      >
                        {columnIndex === 0 ?
                          fullyDilutedCalculationMapper[sortedFullyDilutedCalRowNames[rowIndex]] :
                          calibration.fullyDilutedEquityValueCalculation[sortedFullyDilutedCalRowNames[rowIndex]] ?
                            calibration.fullyDilutedEquityValueCalculation[sortedFullyDilutedCalRowNames[rowIndex]] : 'N/A'}
                      </Text>
                    ))}
                  </View>
                ))}
                {!!prevTransactionFootnote && (
                  <View
                    style={{
                      width: '1175px',
                      backgroundColor: '#FAFAFD',
                      padding: '4px 16px 0px',
                      borderTop: '1px solid #CCCCCC',
                    }}
                  >
                    <Text
                      style={{
                        fontFamily: 'Roboto',
                        fontSize: '9px',
                        color: '#49454F',
                        letterSpacing: '0.4px',
                        textAlign: 'left',
                        width: 'auto',
                        wordWrap: 'break-word',
                        lineHeight: '0.55mm',
                        padding: '8px 0px',
                      }}
                    >
                      <Text
                        style={{
                          fontFamily: 'RobotoMed',
                          fontSize: '11px',
                          color: '#49454F',
                          letterSpacing: '0.4px',
                          marginRight: '8px',
                          fontWeight: '600',
                          lineHeight: '0.55mm',
                        }}
                      >
                        Appraiser&apos;s commentary:&nbsp;&nbsp;
                      </Text>
                      {prevTransactionFootnote}
                    </Text>
                  </View>
                )}
              </>
            )}
          </View>
          <BottomPagination
            pageNumber={totalPageCount + 1}
            reportData={reportData}
          />
          {reportData.isDraft && <DraftOverlay />}
        </Page>
      )}
      {showThreePages && (
        <Page
          size="TABLOID"
          orientation="landscape"
          style={{ position: 'relative', backgroundColor: '#EAEAEB' }}
        >
          <TablePageHeader
            companyName={reportData.companyInfo.companyName}
            pageName={`Value comparison   |   Exhibit ${exhibitNumber}`}
          />
          <View
            style={{
              marginTop: '55px',
              marginLeft: '24px',
              width: '1177px',
              minHeight: '77px',
              backgroundColor: '#FAFAFD',
              borderBottom: '0px',
              border: '1px solid #AFB1B3',
            }}
          >
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                height: '24px',
                backgroundColor: '#0C2617',
                padding: '5px 16px 0px',
              }}
            >
              <Text
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  fontFamily: 'RobotoMed',
                  fontSize: '10px',
                  letterSpacing: '0.4px',
                  color: '#E1E2EC',
                  width: '200px',
                }}
              >
                Calibration
              </Text>
              <Text
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  fontFamily: 'RobotoMed',
                  fontSize: '10px',
                  letterSpacing: '0.4px',
                  color: '#98B8AA',
                }}
              >
                (USD $)
              </Text>
            </View>
            <View
              style={{
                display: 'flex',
                height: '20px',
                flexDirection: 'row',
                backgroundColor: '#307157',
                borderBottom: '1px solid #307157',
              }}
            >
              <View
                style={{
                  display: 'flex',
                  width: '416px',
                  borderBottom: '1px solid #CCCCCC',
                }}
              />
              <View
                style={{
                  display: 'flex',
                  width: '280px',
                  borderBottom: '1px solid #CCCCCC',
                  margin: '0px 12px',
                }}
              >
                <Text
                  style={{
                    display: 'flex',
                    fontFamily: 'RobotoMed',
                    fontSize: '10px',
                    letterSpacing: '0.4px',
                    paddingTop: '3px',
                    textAlign: 'center',
                    color: '#E1E2EC',
                  }}
                >
                  Fully diluted
                </Text>
              </View>
              <View
                style={{
                  display: 'flex',
                  width: '280px',
                  borderBottom: '1px solid #CCCCCC',
                  margin: '0px 12px 0px 0px',
                }}
              >
                <Text
                  style={{
                    display: 'flex',
                    fontFamily: 'RobotoMed',
                    fontSize: '10px',
                    letterSpacing: '0.4px',
                    paddingTop: '3px',
                    textAlign: 'center',
                    color: '#E1E2EC',
                  }}
                >
                  Backsolve
                </Text>
              </View>
              <View
                style={{
                  display: 'flex',
                  width: '146px',
                  borderBottom: '1px solid #CCCCCC',
                }}
              />
            </View>
            <View
              style={{
                display: 'flex',
                height: '20px',
                flexDirection: 'row',
                backgroundColor: '#307157',
                borderTop: '1px solid #307157',
              }}
            >
              <View
                style={{
                  display: 'flex',
                  width: '416px',
                }}
              />
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  gap: '12px',
                  width: '280px',
                  margin: '0px 12px',
                }}
              >
                <Text
                  style={{
                    display: 'flex',
                    fontFamily: 'RobotoMed',
                    fontSize: '10px',
                    letterSpacing: '0.4px',
                    paddingTop: '3px',
                    textAlign: 'center',
                    color: '#E1E2EC',
                    marginLeft: '40px',
                  }}
                >
                  Upon transaction
                </Text>
                <Text
                  style={{
                    display: 'flex',
                    fontFamily: 'RobotoMed',
                    fontSize: '10px',
                    letterSpacing: '0.4px',
                    paddingTop: '3px',
                    textAlign: 'center',
                    color: '#E1E2EC',
                    marginRight: '8px',
                  }}
                >
                  Current (implied)
                </Text>
              </View>
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  gap: '12px',
                  width: '280px',
                  margin: '0px 12px',
                }}
              >
                <Text
                  style={{
                    display: 'flex',
                    fontFamily: 'RobotoMed',
                    fontSize: '10px',
                    letterSpacing: '0.4px',
                    paddingTop: '3px',
                    textAlign: 'center',
                    color: '#E1E2EC',
                    marginLeft: '35px',
                  }}
                >
                  Upon transaction
                </Text>
                <Text
                  style={{
                    display: 'flex',
                    fontFamily: 'RobotoMed',
                    fontSize: '10px',
                    letterSpacing: '0.4px',
                    paddingTop: '3px',
                    textAlign: 'center',
                    color: '#E1E2EC',
                    marginRight: '8px',
                  }}
                >
                  Current (implied)
                </Text>
              </View>
              <View style={{ display: 'flex', width: '146px' }} />
            </View>
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                height: '20px',
                backgroundColor: '#9ECEBB',
                padding: '4px 16px 0px',
                textAlign: 'left',
              }}
            >
              <Text
                style={{
                  fontSize: '10px',
                  letterSpacing: '0.5px',
                  fontFamily: 'RobotoMed',
                  color: '#49454F',
                }}
              >
                Fully diluted equity value calculation
              </Text>
            </View>
            {sortedFullyDilutedCalRowNames.map((_, i) => i).map((data, rowIndex) => (
              <View
                key={data}
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'flex-start',
                  height: '16px',
                  backgroundColor: `${rowIndex % 2 ? '#FAFAFD' : '#F0F0F3'}`,
                }}
              >
                {new Array(2).fill('').map((column, columnIndex) => (
                  <Text
                    key={column}
                    style={
                      columnIndex === 0 ? ({
                        display: 'flex',
                        justifyContent: 'flex-start',
                        fontFamily: 'Roboto',
                        fontSize: '10px',
                        color: '#49454F',
                        paddingTop: '2px',
                        paddingLeft: '16px',
                        alignItems: 'center',
                        letterSpacing: '0.4px',
                        textAlign: 'left',
                        width: '416px',
                      }) : ({
                        display: 'flex',
                        justifyContent: 'flex-end',
                        fontFamily: 'Roboto',
                        fontSize: '10px',
                        color: '#49454F',
                        padding: '2px 8px 0px 8px',
                        letterSpacing: '0.4px',
                        textAlign: 'right',
                        width: '144px',
                      })}
                  >
                    {columnIndex === 0 ?
                      fullyDilutedCalculationMapper[sortedFullyDilutedCalRowNames[rowIndex]] :
                      calibration.fullyDilutedEquityValueCalculation[sortedFullyDilutedCalRowNames[rowIndex]] ?
                        calibration.fullyDilutedEquityValueCalculation[sortedFullyDilutedCalRowNames[rowIndex]] : 'N/A'}
                  </Text>
                ))}
              </View>
            ))}
            {prevTransactionFootnote?.length && (
              <View
                style={{
                  width: '1175px',
                  backgroundColor: '#FAFAFD',
                  padding: '4px 16px 0px',
                  borderTop: '1px solid #CCCCCC',
                }}
              >
                <Text
                  style={{
                    fontFamily: 'Roboto',
                    fontSize: '9px',
                    color: '#49454F',
                    letterSpacing: '0.4px',
                    textAlign: 'left',
                    width: 'auto',
                    wordWrap: 'break-word',
                    lineHeight: '0.55mm',
                    padding: '8px 0px',
                  }}
                >
                  <Text
                    style={{
                      fontFamily: 'RobotoMed',
                      fontSize: '11px',
                      color: '#49454F',
                      letterSpacing: '0.4px',
                      marginRight: '8px',
                      fontWeight: '600',
                      lineHeight: '0.55mm',
                    }}
                  >
                    Appraiser&apos;s commentary:&nbsp;&nbsp;
                  </Text>
                  {prevTransactionFootnote}
                </Text>
              </View>
            )}
          </View>
          <BottomPagination
            pageNumber={totalPageCount + 2}
            reportData={reportData}
          />
          {reportData.isDraft && <DraftOverlay />}
        </Page>
      )}
    </>
  );
}

ValueComparison.propTypes = {
  reportData: PropTypes.object.isRequired,
  totalPageCount: PropTypes.number.isRequired,
  exhibitNumber: PropTypes.number.isRequired,
};
