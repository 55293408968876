import React from 'react';
import PropTypes from 'prop-types';

import successCheckmark from '../../images/checkmark-art.svg';

export default function Success({ sendEmailForm, primaryClientInfo, emailOnly }) {
  return (
    <>
      <div className="box-header">
        <h4>Success!</h4>
      </div>
      <div className="img-wrapper">
        <img src={successCheckmark} alt="success" />
      </div>
      <div className="success-msg-container">
        {sendEmailForm === 'true' ? (
          <p>
            An email has been sent to&nbsp;
            {primaryClientInfo.firstName}
            &nbsp;
            {primaryClientInfo.lastName}
            &nbsp;at&nbsp;
            {primaryClientInfo.email}
            {!emailOnly && (
              <>
                &nbsp;and we&apos;ll add this project to
                your homepage
              </>
            )}
            .
            <br />
            <br />
            We&apos;ll update this ASC 820 project on a company by company basis once they&apos;ve
            successfully submitted all required information.
          </p>
        ) : <p>This project has been added to your ASC 820 list.</p>}
      </div>
    </>
  );
}

Success.propTypes = {
  sendEmailForm: PropTypes.string.isRequired,
  primaryClientInfo: PropTypes.object.isRequired,
  emailOnly: PropTypes.bool.isRequired,
};
