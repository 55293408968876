import React from 'react';
import PropTypes from 'prop-types';

import {
  View,
  Text,
} from '@react-pdf/renderer';

export default function TablePageHeader({
  companyName,
  pageName,
}) {
  return (
    <View
      style={{
        position: 'absolute',
        top: '0px',
        backgroundColor: '#0C2617',
        height: '48px',
        width: '100%',
      }}
    >
      <Text
        style={{
          fontFamily: 'ManropeMed',
          fontSize: '16px',
          color: '#EAEAEB',
          position: 'absolute',
          top: '14px',
          left: '28px',
          letterSpacing: '0.1px',
        }}
      >
        {companyName}
      </Text>
      <Text
        style={{
          fontFamily: 'ManropeSemi',
          fontSize: '12px',
          color: '#B1CABF',
          position: 'absolute',
          top: '16px',
          right: '48px',
          letterSpacing: '2px',
        }}
      >
        {pageName.toUpperCase()}
      </Text>
    </View>
  );
}


TablePageHeader.propTypes = {
  companyName: PropTypes.string.isRequired,
  pageName: PropTypes.string.isRequired,
};
