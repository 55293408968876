import React from 'react';
import PropTypes from 'prop-types';

import {
  Page,
  Text,
  Image,
} from '@react-pdf/renderer';

import DraftOverlay from '../components/DraftOverlay';

import redwoodLogo from '../assets/images/redwood-logo.png';
import separatorLine from '../assets/images/line.png';
import titleLine from '../assets/images/title-line.png';
import coverPageBackground from '../assets/images/cover-page.png';
import initioLogo from '../../../../../images/initio-logo.png';

export default function CoverPage({
  companyName,
  valuationDate,
  isLastPage,
  isDraft,
  isVenBioUser,
}) {
  return (
    <Page
      size="TABLOID"
      orientation="landscape"
    >
      <Image
        src={coverPageBackground}
        style={{
          position: 'absolute',
          width: '1224px',
          height: '792px',
          top: '0px',
          left: '0px',
        }}
      />
      {!isLastPage && (
        <>
          <Image
            src={isVenBioUser ? initioLogo : redwoodLogo}
            style={{
              width: '107px',
              height: '37px',
              marginTop: '170px',
              marginLeft: '704px',
            }}
          />
          <Text
            style={{
              fontFamily: 'ManropeSemi',
              fontSize: '28px',
              marginLeft: '700px',
              color: '#FFFFFF',
            }}
          >
            {companyName}
          </Text>
          <Text
            style={{
              fontFamily: 'ManropeBold',
              fontSize: '12px',
              position: 'absolute',
              bottom: '43px',
              right: '140px',
              color: '#C0C6DC',
              letterSpacing: '2px',
            }}
          >
            ASC 820 VALUATION |
            {` ${valuationDate.toUpperCase()}`}
          </Text>
          <Image
            src={titleLine}
            style={{
              width: '50%',
              marginLeft: '50%',
              marginTop: '16px',
              height: '3px',
            }}
          />
        </>
      )}
      <Image
        src={separatorLine}
        style={{
          position: 'absolute',
          width: '100%',
          bottom: '34px',
        }}
      />
      {isDraft && <DraftOverlay />}
    </Page>
  );
}


CoverPage.propTypes = {
  companyName: PropTypes.string.isRequired,
  valuationDate: PropTypes.string.isRequired,
  isDraft: PropTypes.bool.isRequired,
  isLastPage: PropTypes.bool,
  isVenBioUser: PropTypes.bool.isRequired,
};

CoverPage.defaultProps = {
  isLastPage: false,
};
