import React, { useState } from 'react';

import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';

import {
  Autocomplete,
  Button,
  MenuItem,
  TextField,
} from '@mui/material';

import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';

import AddBusinessOutlinedIcon from '@mui/icons-material/AddBusinessOutlined';
import TipsAndUpdatesOutlinedIcon from '@mui/icons-material/TipsAndUpdatesOutlined';
import ErrorIcon from '@mui/icons-material/Error';

import { ReactComponent as BlueCalendar } from '../../images/icons/blue_calendar.svg';
import rocketFrame from '../../images/rocket-frame.svg';

import AddCompanyBlock from '../AddCompanyBlock';

import { commaEvery3rdChar } from '../../utils';

import { getUserName } from '../../utils/auth';

export default function StartASC820({
  ASC820Info,
  setASC820Info,
  handleDataClear,
  inputsHaveErrors,
  setInputsHaveErrors,
  investorOptions,
  setSelectedInvestorName,
  enterpriseUsers,
  projectCompanies,
  setProjectCompanies,
  companyNameErrorList,
  setCompanyNameErrorList,
  portfolioCompanies,
  portfolioCompaniesNames,
  companyListRef,
  dialogScrollRef,
  setCurrentStep,
  setCompanyRowAdded,
}) {
  const [showRolesDescription, setShowRolesDescription] = useState(false);

  function checkForErrors() {
    const errorArray = [];
    projectCompanies.forEach((company) => {
      if (company.companyName.length < 1) errorArray.push(true);
      else errorArray.push(false);
    });
    setCompanyNameErrorList(errorArray);
    if (
      errorArray.includes(true) ||
      !ASC820Info.investorFirmName ||
      !ASC820Info.projectName ||
      !ASC820Info.valuationDate
    ) {
      setInputsHaveErrors((prevErrors) => ({
        ...prevErrors,
        investorFirmName: !ASC820Info.investorFirmName,
        projectName: !ASC820Info.projectName,
        valuationDate: !ASC820Info.valuationDate,
      }));
      return;
    }
    setCurrentStep('add-investor');
  }

  return (
    <>
      <div className="dialog-header-wrapper">
        <img src={rocketFrame} alt="rocket icon" />
        <div className="header-intro-text">
          <h4>Start an ASC 820 valuation</h4>
          <p className="header-instructions">
            Fill out this form to add a new project to your ASC 820 list. Required fields have an asterisk.
          </p>
        </div>

      </div>
      <div className="input-grid">
        <TextField
          label="Project name*"
          value={ASC820Info.projectName || ''}
          onChange={(e) => setASC820Info({ ...ASC820Info, projectName: e.target.value })}
          onFocus={() => setInputsHaveErrors({ ...inputsHaveErrors, projectName: false })}
          error={inputsHaveErrors.projectName}
          helperText={inputsHaveErrors.projectName ? (
            <>
              <ErrorIcon />
              Missing input
            </>
          ) : ' '}
        />
        <Autocomplete
          freeSolo
          options={investorOptions.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter))}
          onInputChange={(_, value) => {
            setASC820Info((prevAsc820Info) => {
              const newAsc820Info = {
                ...prevAsc820Info,
                investorFirmName: value,
              };
              return newAsc820Info;
            });
          }}
          groupBy={(option) => option.firstLetter}
          getOptionLabel={(option) => option.option}
          inputValue={ASC820Info.investorFirmName || ''}
          renderInput={(params) => (
            <TextField
              // eslint-disable-next-line
              {...params}
              label="Investor firm name*"
              value={ASC820Info.investorFirmName || ''}
              onChange={(e) => {
                setSelectedInvestorName(e.target.value);
                setASC820Info((prevAsc820Info) => {
                  const newAsc820Info = {
                    ...prevAsc820Info,
                    investorFirmName: e.target.value,
                  };
                  return newAsc820Info;
                });
              }}
              onClick={(e) => {
                setSelectedInvestorName(e.target.value);
                setASC820Info((prevAsc820Info) => {
                  const newAsc820Info = {
                    ...prevAsc820Info,
                    investorFirmName: e.target.value,
                  };
                  return newAsc820Info;
                });
              }}
              onFocus={() => setInputsHaveErrors({ ...inputsHaveErrors, investorFirmName: false })}
              onBlur={(e) => {
                setSelectedInvestorName(e.target.value);
                setASC820Info((prevAsc820Info) => {
                  const newAsc820Info = {
                    ...prevAsc820Info,
                    investorFirmName: e.target.value,
                  };
                  return newAsc820Info;
                });
              }}
              error={inputsHaveErrors.investorFirmName}
              helperText={inputsHaveErrors.investorFirmName ? (
                <>
                  <ErrorIcon />
                  Missing input
                </>
              ) : ' '}
            />
          )}
        />
        {/* Removed for now. Field is not filling the way that meets design */}
        {/* <TextField
          label="CapIQ ID"
          InputLabelProps={{ shrink: ASC820Info.investorCapIqIdentifier ? ASC820Info.investorCapIqIdentifier.length > 0 : false }}
          disabled
          value={ASC820Info.investorCapIqIdentifier || ''}
          onChange={(e) => setASC820Info({ ...ASC820Info, investorCapIqIdentifier: e.target.value })}
        /> */}
        <TextField
          label="Total fee"
          value={ASC820Info.totalFee || ''}
          onChange={(e) => setASC820Info({
            ...ASC820Info,
            totalFee: `${ASC820Info?.totalFee && ASC820Info.totalFee.length > 1 ? '$' : ''}${commaEvery3rdChar(e.target.value)}`,
          })}
        />
      </div>
      <hr className="dialog-spacer" />
      <div className="input-grid assignments-row">
        <div className="assignments">
          <TextField
            label="Assign 'Data entry'"
            value={ASC820Info?.assignees?.dataEntry || ''}
            onChange={(e) => setASC820Info({ ...ASC820Info, assignees: { ...ASC820Info?.assignees, dataEntry: e.target.value } })}
            select
            SelectProps={{ MenuProps: { disableScrollLock: true, classes: { paper: 'select-dropdown' } } }}
          >
            {enterpriseUsers?.map((user) => (
              <MenuItem key={user?.accountId} value={user}>
                {user.firstName}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            label="Assign 'Preparer'"
            value={ASC820Info?.assignees?.preparer || ''}
            onChange={(e) => setASC820Info({ ...ASC820Info, assignees: { ...ASC820Info?.assignees, preparer: e.target.value } })}
            select
            SelectProps={{ MenuProps: { disableScrollLock: true, classes: { paper: 'select-dropdown' } } }}
          >
            {enterpriseUsers?.map((user) => (
              <MenuItem key={user?.accountId} value={user}>
                {user.firstName}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            label="Assign 'Reviewer'"
            value={ASC820Info?.assignees?.reviewer || ''}
            onChange={(e) => setASC820Info({ ...ASC820Info, assignees: { ...ASC820Info?.assignees, reviewer: e.target.value } })}
            select
            SelectProps={{ MenuProps: { disableScrollLock: true, classes: { paper: 'select-dropdown' } } }}
          >
            {enterpriseUsers?.map((user) => (
              <MenuItem key={user?.accountId} value={user}>
                {user.firstName}
              </MenuItem>
            ))}
          </TextField>
        </div>
      </div>
      <div className="roles-tip">
        <TipsAndUpdatesOutlinedIcon />
        <Button onClick={() => setShowRolesDescription(!showRolesDescription)}>
          Tell me about these roles
        </Button>
        {showRolesDescription && (
          <p className="roles-description">
            &apos;Data entry&apos; is assigned to the person who inputs and checks accuracy of data.
            &apos;Preparer&apos; makes selections to come to initial values.
            &apos;Reviewer&apos; reviews the analysis to determine whether assumptions and conclusions are reasonable.
          </p>
        )}
      </div>
      <div className="ancillary-info">
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DatePicker
            clearable
            className="date-picker"
            label="Valuation date*"
            value={ASC820Info.valuationDate ? moment(ASC820Info.valuationDate, 'YYYY-MM-DD') : null}
            onChange={(val) => setASC820Info({ ...ASC820Info, valuationDate: val ? val.format('YYYY-MM-DD') : '' })}
            onOpen={() => setInputsHaveErrors({ ...inputsHaveErrors, valuationDate: false })}
            slots={{ openPickerIcon: BlueCalendar }}
            slotProps={{
              textField: {
                variant: 'outlined',
                error: inputsHaveErrors.valuationDate,
                helperText: inputsHaveErrors.valuationDate ? (
                  <>
                    <ErrorIcon />
                    Missing input
                  </>
                ) : (' '),
              },
            }}
          />
        </LocalizationProvider>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DatePicker
            clearable
            className="date-picker"
            label="Client info due"
            value={ASC820Info.clientInfoDueDate ? moment(ASC820Info.clientInfoDueDate, 'YYYY-MM-DD') : null}
            onChange={(val) => setASC820Info({ ...ASC820Info, clientInfoDueDate: val ? val.format('YYYY-MM-DD') : '' })}
            onOpen={() => setInputsHaveErrors({ ...inputsHaveErrors, clientInfoDueDate: false })}
            slots={{ openPickerIcon: BlueCalendar }}
            slotProps={{ textField: { variant: 'outlined' } }}
          />
        </LocalizationProvider>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DatePicker
            clearable
            className="date-picker"
            label="Draft due"
            value={ASC820Info.draftDueDate ? moment(ASC820Info.draftDueDate, 'YYYY-MM-DD') : null}
            onChange={(val) => setASC820Info({ ...ASC820Info, draftDueDate: val ? val.format('YYYY-MM-DD') : '' })}
            onOpen={() => setInputsHaveErrors({ ...inputsHaveErrors, draftDueDate: false })}
            slots={{ openPickerIcon: BlueCalendar }}
            slotProps={{ textField: { variant: 'outlined' } }}
          />
        </LocalizationProvider>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DatePicker
            clearable
            className="date-picker"
            label="Audit start"
            value={ASC820Info.auditDate ? moment(ASC820Info.auditDate, 'YYYY-MM-DD') : null}
            onChange={(val) => setASC820Info({ ...ASC820Info, auditDate: val ? val.format('YYYY-MM-DD') : '' })}
            slotProps={{ textField: { variant: 'outlined' } }}
            slots={{ openPickerIcon: BlueCalendar }}
          />
        </LocalizationProvider>
      </div>
      <p className="selections-copy">
        Selections made here will be applied to all companies in the is project.
        If you need to edit the information for any reason, you&apos;ll be able to make edits for the entire
        project from the project&apos;s company list or
        edit them for a single company on that company&apos;s &apos;General info&apos; tab.
      </p>
      <hr className="dialog-spacer" />
      <div className="add-companies-block">
        <h4>Add company list (optional)</h4>
        <p className={projectCompanies.length > 0 ? 'added' : 'no-companies'}>
          You&apos;ll be able to add companies to this project at any time.
          If you choose to send a form to your client they will see the companies you&apos;ve
          added here and be able to add additional companies.
        </p>
        <div className="company-block-wrapper">
          {projectCompanies.map((company, index) => (
            <AddCompanyBlock
              key={company.companyId}
              projectCompanies={projectCompanies}
              setProjectCompanies={setProjectCompanies}
              company={company}
              index={index}
              companyNameErrorList={companyNameErrorList}
              setCompanyNameErrorList={setCompanyNameErrorList}
              portfolioCompanies={portfolioCompanies}
              portfolioCompaniesNames={portfolioCompaniesNames}
              setCompanyRowAdded={setCompanyRowAdded}
              companyListRef={companyListRef}
            />
          ))}
        </div>
        <div className="add-company-btn-info-wrapper">
          <Button
            className="add-company-btn"
            onClick={() => {
              const newCompany = {
                companyId: uuidv4(),
                companyName: '',
                capIqIdentifier: '',
                note: '',
                noteCreatedDatetime: moment().format('YYYY-MM-DD HH:mm:ss'),
                noteAuthor: getUserName(),
              };
              setProjectCompanies([...projectCompanies, newCompany]);
              setCompanyRowAdded(true);
            }}
          >
            <AddBusinessOutlinedIcon />
            {`${projectCompanies.length > 0 ? 'Add another company' : 'Add a company'}`}
          </Button>
        </div>
      </div>
      <hr className="dialog-spacer" />
      <div className="dialog-footer" ref={dialogScrollRef}>
        <Button onClick={() => handleDataClear()}>
          Cancel
        </Button>
        <Button onClick={() => checkForErrors()}>
          Continue
        </Button>
      </div>
    </>
  );
}

StartASC820.propTypes = {
  ASC820Info: PropTypes.object.isRequired,
  setASC820Info: PropTypes.func.isRequired,
  handleDataClear: PropTypes.func.isRequired,
  inputsHaveErrors: PropTypes.object.isRequired,
  setInputsHaveErrors: PropTypes.func.isRequired,
  investorOptions: PropTypes.array.isRequired,
  setSelectedInvestorName: PropTypes.func.isRequired,
  projectCompanies: PropTypes.array.isRequired,
  setProjectCompanies: PropTypes.func.isRequired,
  companyNameErrorList: PropTypes.array.isRequired,
  setCompanyNameErrorList: PropTypes.func.isRequired,
  companyListRef: PropTypes.object.isRequired,
  dialogScrollRef: PropTypes.object.isRequired,
  setEnterpriseProjects: PropTypes.func,
  enterpriseProjects: PropTypes.arrayOf(PropTypes.object),
  enterpriseUsers: PropTypes.array,
  investorCompanies: PropTypes.object,
  portfolioCompanies: PropTypes.object,
  investorCompaniesNames: PropTypes.array,
  portfolioCompaniesNames: PropTypes.array,
  setCurrentStep: PropTypes.func.isRequired,
  setCompanyRowAdded: PropTypes.func.isRequired,
};

StartASC820.defaultProps = {
  setEnterpriseProjects: () => { },
  enterpriseProjects: [],
  enterpriseUsers: [],
  investorCompanies: {},
  investorCompaniesNames: [],
  portfolioCompanies: {},
  portfolioCompaniesNames: [],
};
