import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  Page,
  View,
  Text,
} from '@react-pdf/renderer';

import TablePageHeader from '../../components/TablePageHeader';
import BottomPagination from '../../components/BottomPagination';
import DraftOverlay from '../../components/DraftOverlay';

export default function PubCoGrowth({ reportData, totalPageCount, exhibitNumber }) {
  const gridData = reportData.PublicCompanyMethod.grids;
  const table1Headers = Object.keys(gridData.growth);
  const tableData = gridData.growth['Company Name'];

  const columnOrder = [
    'Company Name',
    'Market Capitalization',
    'Enterprise Value',
    'Revenue | LTM',
    'Revenue Growth Rate | LFY',
    'Revenue Growth Rate | LTM',
    'Revenue Growth Rate | CFY',
    'EBITDA Growth Rate | LFY',
    'EBITDA Growth Rate | LTM',
    'EBITDA Growth Rate | CFY',
  ];

  const valuationDate = moment(reportData.companyInfo.valuationDate, 'YYYY-MM-DD').format('MM/DD/YYYY');
  const valuationDatePlusOne = moment(reportData.companyInfo.valuationDate, 'YYYY-MM-DD').add(1, 'year').format('MM/DD/YYYY');
  const valuationDatePlusTwo = moment(reportData.companyInfo.valuationDate, 'YYYY-MM-DD').add(2, 'year').format('MM/DD/YYYY');
  const valuationDatePlusThree = moment(reportData.companyInfo.valuationDate, 'YYYY-MM-DD').add(3, 'year').format('MM/DD/YYYY');
  const financialStatementDate = moment(reportData.companyInfo.financialStatementDate).format('MM/DD/YYYY');
  const financialStatementDatePlusOne = moment(reportData.companyInfo.financialStatementDate).add(1, 'year').format('MM/DD/YYYY');
  const financialStatementDatePlusTwo = moment(reportData.companyInfo.financialStatementDate).add(1, 'year').format('MM/DD/YYYY');
  const financialStatementDatePlusThree = moment(reportData.companyInfo.financialStatementDate).add(1, 'year').format('MM/DD/YYYY');

  const fiscalYear = moment(valuationDate).subtract(1, 'years').format('MM/DD/YYYY');

  const kpiMapper = {
    'Company Name': 'Company name',
    'Market Capitalization': 'Market\ncapitalization',
    'Enterprise Value': 'Enterprise\nvalue',
    'Revenue | LTM': 'LTM\nrevenue',
    'Revenue Growth Rate | LFY': `LFY\n${fiscalYear}`,
    'Revenue Growth Rate | LTM': `LTM\n${financialStatementDate}`,
    'Revenue Growth Rate | CFY': `CFY\n${valuationDate}`,
    'Revenue Growth Rate | NTM': `NTM\n${financialStatementDatePlusOne}`,
    'Revenue Growth Rate | NTM+1': `NTM+1\n${financialStatementDatePlusTwo}`,
    'Revenue Growth Rate | NTM+2': `NTM+2M\n${financialStatementDatePlusThree}`,
    'Revenue Growth Rate | NFY': `NFY\n${valuationDate}`,
    'Revenue Growth Rate | NFY+1': `NFY+1\n${valuationDate}`,
    'Revenue Growth Rate | NFY+2': `NFY+2\n${valuationDate}`,
    'EBITDA Growth Rate | LFY': `LFY\n${fiscalYear}`,
    'EBITDA Growth Rate | LTM': `LTM\n${financialStatementDate}`,
    'EBITDA Growth Rate | CFY': `CFY\n${valuationDate}`,
    'EBITDA Growth Rate | NTM': `NTM\n${financialStatementDatePlusOne}`,
    'EBITDA Growth Rate | NTM+1': `NTM+1\n${financialStatementDatePlusTwo}`,
    'EBITDA Growth Rate | NTM+2': `NTM+2M\n${financialStatementDatePlusThree}`,
    'EBITDA Growth Rate | NFY': `NFY\n${valuationDatePlusOne}`,
    'EBITDA Growth Rate | NFY+1': `NFY+1\n${valuationDatePlusTwo}`,
    'EBITDA Growth Rate | NFY+2': `NFY+2\n${valuationDatePlusThree}`,
  };

  function sortColumnNames(names, order) {
    const sortedNames = names.filter((name) => order.includes(name));
    sortedNames.sort((a, b) => order.indexOf(a) - order.indexOf(b));
    return sortedNames;
  }

  const sortedHeaders = sortColumnNames(table1Headers, columnOrder);

  function returnCellData(cellData, dataName, rowIndex, columnIndex, table) {
    let grid;
    if (table === 'placement') {
      grid = gridData.placement;
      return grid[dataName][rowIndex] || 'N/A';
    }
    if (table === 'firstTable') grid = gridData.growth;
    if (table === 'summaryFirstTable') grid = gridData.growthSummary;
    if (table === 'thirdTable') grid = gridData.selection;
    if (cellData === 'selectedMultiple' || cellData === 'relativeSelection') grid = gridData.selection;
    return grid[dataName][rowIndex] || 'N/A';
  }

  function columnWidth(index, type) {
    if (type === 'columnHeaders') {
      if (index === 0) return '240px';
      if ([1, 2, 3].includes(index)) return '135px';
      if (index === 2) return '100px';
      if (index > 3) return '130px';
    }
    return '240px';
  }

  const numberOfPages = (Math.ceil((tableData.length + 10) / 37)) || 1;

  function customMap(array, callback, startIndex = 0) {
    return array.map((element, index) => callback(element, index + startIndex));
  }

  return (
    <>
      {[...Array(numberOfPages)].map((_, i) => i).map((pageNumber, pageIndex) => (
        <Page
          key={pageNumber}
          size="TABLOID"
          orientation="landscape"
          style={{ position: 'relative', backgroundColor: '#EAEAEB' }}
        >
          <TablePageHeader
            companyName={reportData.companyInfo.companyName}
            pageName={`Public company Growth & Margins   |   Exhibit ${exhibitNumber}`}
          />
          <View
            style={{
              marginTop: '64px',
              marginLeft: '32px',
              display: 'flex',
              flexDirection: 'column',
              width: '1160px',
              border: '1px',
              borderColor: '#AFB1B3',
            }}
          >
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                height: '24px',
                backgroundColor: '#0C2617',
              }}
            >
              <Text
                style={{
                  fontFamily: 'RobotoMed',
                  fontSize: '10px',
                  color: '#8AB9A4',
                  paddingLeft: '16px',
                  letterSpacing: '0.08px',
                  marginRight: '8px',
                  width: '652px',
                  paddingTop: '6px',
                  borderBottom: '1px',
                  borderColor: '#CCCCCC',
                  height: '24px',
                }}
              >
                (USD $Millions)
                {pageNumber}
              </Text>
              <Text
                style={{
                  fontFamily: 'RobotoMed',
                  fontSize: '10px',
                  color: '#E1E2EC',
                  paddingLeft: '16px',
                  letterSpacing: '0.08px',
                  marginRight: '8px',
                  width: '250px',
                  paddingTop: '6px',
                  borderBottom: '1px',
                  borderColor: '#CCCCCC',
                  height: '24px',
                }}
              >
                Sales growth
              </Text>
              <Text
                style={{
                  fontFamily: 'RobotoMed',
                  fontSize: '10px',
                  color: '#E1E2EC',
                  paddingLeft: '16px',
                  letterSpacing: '0.08px',
                  width: '240px',
                  paddingTop: '6px',
                  borderBottom: '1px',
                  borderColor: '#CCCCCC',
                  height: '24px',
                }}
              >
                EBITDA growth
              </Text>
            </View>
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                height: '40px',
                backgroundColor: '#307157',
                borderBottom: '1px',
                borderColor: '#CCCCCC',
              }}
            >
              {sortedHeaders.map((dataName, index) => (
                <Text
                  key={dataName}
                  style={{
                    fontFamily: 'RobotoMed',
                    fontSize: '10px',
                    color: '#E1E2EC',
                    width: columnWidth(index, 'columnHeaders'),
                    marginTop: 'auto',
                    paddingLeft: '8px',
                    paddingRight: '8px',
                    letterSpacing: '0.4px',
                    lineHeight: '0.55mm',
                    ...index === 1 && { marginLeft: '200px' },
                    ...index === 4 && { marginLeft: '50px' },
                    textAlign: index !== 0 && 'right',
                    ...index !== 0 && { left: 'auto' },
                  }}
                >
                  {kpiMapper[dataName]}
                </Text>
              ))}
            </View>
            {customMap(tableData.slice(pageIndex * 37, (pageIndex + 1) * 37), (cellData, rowIndex) => (
              <View
                key={cellData}
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  height: '16px',
                  backgroundColor: `${rowIndex % 2 ? '#F0F0F3' : '#FAFAFD'}`,
                }}
              >
                {table1Headers.map((dataName, columnIndex) => (
                  <Text
                    key={dataName}
                    style={{
                      fontFamily: [1, 3].includes(rowIndex) ? 'RobotoMed' : 'Roboto',
                      fontSize: '10px',
                      color: '#49454F',
                      width: columnWidth(columnIndex, 'columnHeaders'),
                      paddingLeft: '8px',
                      paddingRight: '8px',
                      letterSpacing: '0.4px',
                      ...columnIndex !== 0 && { textAlign: 'right' },
                      ...columnIndex !== 0 && { marginRight: 'auto' },
                      ...columnIndex === 1 && { marginLeft: '200px' },
                      ...columnIndex === 4 && { marginLeft: '50px' },
                    }}
                  >
                    {returnCellData(cellData, dataName, rowIndex, columnIndex, 'firstTable') || 'N/A'}
                  </Text>
                ))}
              </View>
            ), pageIndex * 37)}
            {[...Array(numberOfPages)].length - 1 === pageIndex && (
              <>
                {gridData.placement.headers.map((cellData, rowIndex) => (

                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      height: '16px',
                      backgroundColor: '#9ECEBB',
                      borderTop: '1px',
                      borderColor: '#EAE9EC',
                    }}
                  >
                    {table1Headers.map((dataName, cellIndex) => (
                      <Text
                        key={dataName}
                        style={{
                          fontFamily: 'RobotoMed',
                          fontSize: '10px',
                          color: '#49454F',
                          width: columnWidth(cellIndex, 'columnHeaders'),
                          paddingLeft: '8px',
                          paddingRight: '8px',
                          letterSpacing: '0.4px',
                          textAlign: cellIndex !== 0 && 'right',
                          ...cellIndex !== 0 && { textAlign: 'right' },
                          ...cellIndex !== 0 && { marginRight: 'auto' },
                          ...cellIndex === 1 && { marginLeft: '200px' },
                          ...cellIndex === 4 && { marginLeft: '50px' },
                        }}
                      >
                        {cellIndex === 0 && rowIndex === 0 ? reportData.companyInfo.companyName :
                          cellIndex === 0 && rowIndex === 1 ? 'Rank' :
                            cellIndex === 0 && rowIndex === 2 ? 'Percentile Rank' :
                              cellIndex > 0 ? returnCellData('', dataName, rowIndex, 0, 'placement') :
                                'N/A'}
                      </Text>
                    ))}
                  </View>
                ))}
              </>
            )}
          </View>
          {tableData.slice(pageIndex * 37, (pageIndex + 1) * 37).length < 18 && (
            <View
              style={{
                marginTop: '16px',
                marginLeft: '32px',
                display: 'flex',
                flexDirection: 'column',
                width: '1160px',
                border: '1px',
                borderColor: '#AFB1B3',
              }}
            >
              {[
                ['Maximum', 'max'], ['95th percentile', '95%'],
                ['90th percentile', '90%'], ['75th percentile', '75%'],
                ['Median', 'median'], ['25th percentile', '25%'],
                ['10th percentile', '10%'], ['5th percentile', '5%'],
                ['Minimum', 'min'], ['Average', 'mean'],
              ].map((rowName, rowIndex) => (
                <View
                  key={rowName}
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    height: '16px',
                    backgroundColor: `${rowIndex % 2 ? '#F0F0F3' : '#FAFAFD'}`,
                  }}
                >
                  {sortedHeaders.map((dataName, cellIndex) => (
                    <Text
                      key={dataName}
                      style={{
                        fontFamily: cellIndex === 0 ? 'RobotoMed' : 'Roboto',
                        fontSize: '10px',
                        color: '#49454F',
                        width: columnWidth(cellIndex, 'columnHeaders'),
                        paddingLeft: '16px',
                        paddingRight: '4px',
                        letterSpacing: '0.4px',
                        textAlign: cellIndex !== 0 && 'right',
                        ...cellIndex !== 0 && { textAlign: 'right' },
                        ...cellIndex !== 0 && { marginRight: 'auto' },
                        ...cellIndex === 1 && { marginLeft: '200px' },
                        ...cellIndex === 4 && { marginLeft: '50px' },
                      }}
                    >
                      {cellIndex === 0 ? rowName[0] :
                        returnCellData(rowName, dataName, rowIndex, cellIndex, 'summaryFirstTable') || 'N/A'}
                    </Text>
                  ))}
                </View>
              ))}
            </View>
          )}
          <BottomPagination
            pageNumber={totalPageCount + pageIndex}
            reportData={reportData}
          />
          {reportData.isDraft && <DraftOverlay />}
        </Page>
      ))}
      {tableData.slice((numberOfPages - 1) * 37, numberOfPages * 37).length > 18 && (
        <Page
          size="TABLOID"
          orientation="landscape"
          style={{ position: 'relative', backgroundColor: '#EAEAEB' }}
        >
          <TablePageHeader companyName={reportData.companyInfo.companyName} pageName="Guideline Public Company Method   |   Exhibit 12" />
          <View
            style={{
              marginTop: '16px',
              marginLeft: '32px',
              display: 'flex',
              flexDirection: 'column',
              width: '1160px',
              border: '1px',
              borderColor: '#AFB1B3',
            }}
          >
            {['Maximum', '95th percentile', '90th percentile',
              '75th percentile', 'Median', '25th percentile',
              '10th percentile', '5th percentile', 'Minimum', 'Average',
            ].map((rowName, rowIndex) => (
              <View
                key={rowName}
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  height: '16px',
                  backgroundColor: `${rowIndex % 2 ? '#F0F0F3' : '#FAFAFD'}`,
                }}
              >
                {table1Headers.map((dataName, cellIndex) => (
                  <Text
                    key={dataName}
                    style={{
                      fontFamily: cellIndex === 0 ? 'RobotoMed' : 'Roboto',
                      fontSize: '10px',
                      color: '#49454F',
                      width: columnWidth(cellIndex),
                      paddingLeft: '16px',
                      paddingRight: '16px',
                      letterSpacing: '0.4px',
                      textAlign: cellIndex !== 0 && 'right',
                    }}
                  >
                    {cellIndex === 0 ? rowName : cellIndex === 1 ? '' : 'N/A'}
                  </Text>
                ))}
              </View>
            ))}
          </View>
          <BottomPagination
            pageNumber={totalPageCount}
            reportData={reportData}
          />
          {reportData.isDraft && <DraftOverlay />}
        </Page>
      )}
    </>
  );
}

PubCoGrowth.propTypes = {
  reportData: PropTypes.object.isRequired,
  totalPageCount: PropTypes.number.isRequired,
  exhibitNumber: PropTypes.number.isRequired,
};
