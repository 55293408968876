import React from 'react';
import PropTypes from 'prop-types';
import {
  Page,
  View,
  Text,
} from '@react-pdf/renderer';

import TablePageHeader from '../../components/TablePageHeader';
import BottomPagination from '../../components/BottomPagination';
import DraftOverlay from '../../components/DraftOverlay';

export default function ValSumWeighting({ reportData, totalPageCount, exhibitNumber }) {
  return (
    <Page
      size="TABLOID"
      orientation="landscape"
      style={{ position: 'relative', backgroundColor: '#EAEAEB' }}
    >
      <TablePageHeader
        companyName={reportData.companyInfo.companyName}
        pageName={`Valuation methodology summary - Weighting   |   Exhibit ${exhibitNumber}`}
      />
      <View
        style={{
          display: 'flex',
          flexDirection: 'row',
        }}
      >
        <View
          style={{
            width: '548px',
            height: '580px',
            marginTop: '84px',
            marginLeft: '32px',
            padding: '24px',
            backgroundColor: '#FAFAFD',
          }}
        >
          <Text
            style={{
              fontFamily: 'ManropeSemi',
              fontSize: '16px',
              color: '#49454F',
              letterSpacing: '0.08px',
            }}
          >
            Valuation approach and weighting
          </Text>
          <View
            style={{
              marginTop: '16px',
              width: '500px',
              borderBottom: '1px',
              borderColor: '#CCCCCC',
            }}
          />
          <Text
            style={{
              fontFamily: 'RobotoMed',
              fontSize: '14px',
              color: '#49454F',
              marginTop: '16px',
            }}
          >
            Backsolve method - Option pricing model (OPM)
          </Text>
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              marginTop: '24px',
            }}
          >
            <View style={{ minWidth: '68px', marginRight: '16px' }}>
              <Text
                style={{
                  fontFamily: 'ManropeMed',
                  fontSize: '28px',
                  color: '#008862',
                }}
              >
                {reportData.footnoteData.backsolveWeightingApproach.weight}
              </Text>
              <Text
                style={{
                  fontFamily: 'ManropeMed',
                  fontSize: '14px',
                  color: '#008862',
                  marginTop: '-4px',
                }}
              >
                weight
              </Text>
            </View>
            <View>
              <Text
                style={{
                  fontFamily: 'RobotoMed',
                  fontSize: '12px',
                  color: '#49454F',
                  lineHeight: '0.55mm',
                }}
              >
                Appraiser&apos;s commentary
              </Text>
              <Text
                style={{
                  fontFamily: 'Roboto',
                  fontSize: '12px',
                  color: '#49454F',
                  lineHeight: '0.55mm',
                  width: '412px',
                }}
              >
                {reportData.footnoteData.backsolveWeightingApproach.approach}
              </Text>
            </View>
          </View>
          <Text
            style={{
              fontFamily: 'RobotoMed',
              fontSize: '14px',
              color: '#49454F',
              marginTop: '56px',
            }}
          >
            Guideline public company method (GPC)
          </Text>
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              marginTop: '24px',
            }}
          >
            <View style={{ minWidth: '68px', marginRight: '16px' }}>
              <Text
                style={{
                  fontFamily: 'ManropeMed',
                  fontSize: '28px',
                  color: '#008862',
                }}
              >
                {reportData.footnoteData.gpcWeightingApproach.weight}
              </Text>
              <Text
                style={{
                  fontFamily: 'ManropeMed',
                  fontSize: '14px',
                  color: '#008862',
                  marginTop: '-4px',
                }}
              >
                weight
              </Text>
            </View>
            <View>
              <Text
                style={{
                  fontFamily: 'RobotoMed',
                  fontSize: '12px',
                  color: '#49454F',
                  lineHeight: '0.55mm',
                }}
              >
                Appraiser&apos;s commentary
              </Text>
              <Text
                style={{
                  fontFamily: 'Roboto',
                  fontSize: '12px',
                  color: '#49454F',
                  lineHeight: '0.55mm',
                  width: '412px',
                }}
              >
                {reportData.footnoteData.gpcWeightingApproach.approach}
              </Text>
            </View>
          </View>
          <Text
            style={{
              fontFamily: 'RobotoMed',
              fontSize: '14px',
              color: '#49454F',
              marginTop: '56px',
            }}
          >
            Mergers & Acquisitions transaction method (M&A)
          </Text>
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              marginTop: '24px',
            }}
          >
            <View style={{ minWidth: '68px', marginRight: '16px' }}>
              <Text
                style={{
                  fontFamily: 'ManropeMed',
                  fontSize: '28px',
                  color: '#008862',
                }}
              >
                {reportData.footnoteData.mnaWeightingApproach.weight}
              </Text>
              <Text
                style={{
                  fontFamily: 'ManropeMed',
                  fontSize: '14px',
                  color: '#008862',
                  marginTop: '-4px',
                }}
              >
                weight
              </Text>
            </View>
            <View>
              <Text
                style={{
                  fontFamily: 'RobotoMed',
                  fontSize: '12px',
                  color: '#49454F',
                  lineHeight: '0.55mm',
                }}
              >
                Appraiser&apos;s commentary
              </Text>
              <Text
                style={{
                  fontFamily: 'Roboto',
                  fontSize: '12px',
                  color: '#49454F',
                  lineHeight: '0.55mm',
                  width: '412px',
                }}
              >
                {reportData.footnoteData.mnaWeightingApproach.approach}
              </Text>
            </View>
          </View>
        </View>
        <View
          style={{
            width: '548px',
            height: '580px',
            marginTop: '84px',
            marginLeft: '64px',
            padding: '24px',
            backgroundColor: '#FAFAFD',
          }}
        >
          <View
            style={{
              marginTop: '37px',
              width: '500px',
              borderBottom: '1px',
              borderColor: '#CCCCCC',
            }}
          />
          <Text
            style={{
              fontFamily: 'RobotoMed',
              fontSize: '14px',
              color: '#49454F',
              marginTop: '16px',
            }}
          >
            Preferred transaction method
          </Text>
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              marginTop: '24px',
            }}
          >
            <View style={{ minWidth: '68px', marginRight: '16px' }}>
              <Text
                style={{
                  fontFamily: 'ManropeMed',
                  fontSize: '28px',
                  color: '#008862',
                }}
              >
                {reportData.footnoteData.preferredTranWeightingApproach.weight}
              </Text>
              <Text
                style={{
                  fontFamily: 'ManropeMed',
                  fontSize: '14px',
                  color: '#008862',
                  marginTop: '-4px',
                }}
              >
                weight
              </Text>
            </View>
            <View>
              <Text
                style={{
                  fontFamily: 'RobotoMed',
                  fontSize: '12px',
                  color: '#49454F',
                  lineHeight: '0.55mm',
                }}
              >
                Appraiser&apos;s commentary
              </Text>
              <Text
                style={{
                  fontFamily: 'Roboto',
                  fontSize: '12px',
                  color: '#49454F',
                  lineHeight: '0.55mm',
                  width: '412px',
                }}
              >
                {reportData.footnoteData.preferredTranWeightingApproach.approach}
              </Text>
            </View>
          </View>
          <Text
            style={{
              fontFamily: 'RobotoMed',
              fontSize: '14px',
              color: '#49454F',
              marginTop: '56px',
            }}
          >
            Income approach - Gordon growth
          </Text>
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              marginTop: '24px',
            }}
          >
            <View style={{ minWidth: '68px', marginRight: '16px' }}>
              <Text
                style={{
                  fontFamily: 'ManropeMed',
                  fontSize: '28px',
                  color: '#008862',
                }}
              >
                {reportData.footnoteData.gordonGrowthWeightingApproach.weight}
              </Text>
              <Text
                style={{
                  fontFamily: 'ManropeMed',
                  fontSize: '14px',
                  color: '#008862',
                  marginTop: '-4px',
                }}
              >
                weight
              </Text>
            </View>
            <View>
              <Text
                style={{
                  fontFamily: 'RobotoMed',
                  fontSize: '12px',
                  color: '#49454F',
                  lineHeight: '0.55mm',
                }}
              >
                Appraiser&apos;s commentary
              </Text>
              <Text
                style={{
                  fontFamily: 'Roboto',
                  fontSize: '12px',
                  color: '#49454F',
                  lineHeight: '0.55mm',
                  width: '412px',
                }}
              >
                {reportData.footnoteData.gordonGrowthWeightingApproach.approach}
              </Text>
            </View>
          </View>
          <Text
            style={{
              fontFamily: 'RobotoMed',
              fontSize: '14px',
              color: '#49454F',
              marginTop: '56px',
            }}
          >
            Post-money method
          </Text>
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              marginTop: '24px',
            }}
          >
            <View style={{ minWidth: '68px', marginRight: '16px' }}>
              <Text
                style={{
                  fontFamily: 'ManropeMed',
                  fontSize: '28px',
                  color: '#008862',
                }}
              >
                {reportData.footnoteData.postMoneyWeightingApproach.weight}
              </Text>
              <Text
                style={{
                  fontFamily: 'ManropeMed',
                  fontSize: '14px',
                  color: '#008862',
                  marginTop: '-4px',
                }}
              >
                weight
              </Text>
            </View>
            <View>
              <Text
                style={{
                  fontFamily: 'RobotoMed',
                  fontSize: '12px',
                  color: '#49454F',
                  lineHeight: '0.55mm',
                }}
              >
                Appraiser&apos;s commentary
              </Text>
              <Text
                style={{
                  fontFamily: 'Roboto',
                  fontSize: '12px',
                  color: '#49454F',
                  lineHeight: '0.55mm',
                  width: '412px',
                }}
              >
                {reportData.footnoteData.postMoneyWeightingApproach.approach}
              </Text>
            </View>
          </View>
        </View>
      </View>
      <BottomPagination
        pageNumber={totalPageCount}
        reportData={reportData}
      />
      {reportData.isDraft && <DraftOverlay />}
    </Page>
  );
}

ValSumWeighting.propTypes = {
  reportData: PropTypes.object.isRequired,
  totalPageCount: PropTypes.number.isRequired,
  exhibitNumber: PropTypes.number.isRequired,
};
