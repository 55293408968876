import React from 'react';
import PropTypes from 'prop-types';

import { TextField } from '@mui/material';

import { copy } from '../../../utils';

export default function InfoTile({
  label,
  dataName,
  isEditing,
  values,
  setNewValues,
}) {
  if (!isEditing) {
    return (
      <div className="data-tile">
        <span className="data-name">{label}</span>
        <span className="data">{values[dataName]}</span>
      </div>
    );
  }
  return (
    <TextField
      label={label}
      value={values[dataName]}
      onChange={(e) => {
        const newData = copy(values);
        newData[dataName] = e.target.value;
        setNewValues(newData);
      }}
    />
  );
}

InfoTile.propTypes = {
  label: PropTypes.string.isRequired,
  dataName: PropTypes.string.isRequired,
  isEditing: PropTypes.bool.isRequired,
  values: PropTypes.object.isRequired,
  setNewValues: PropTypes.func.isRequired,
};
