import React, { useState, useEffect } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';

import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import InputAdornment from '@mui/material/InputAdornment';

import EditIcon from '@mui/icons-material/Edit';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';

import { copy } from '../../../utils';
import { getUserName } from '../../../utils/auth';

import useFetch from '../../../hooks/useFetch';

import './Notes.scss';

export default function Notes({ notesList, setNotesList }) {
  const [noteList, setNoteList] = useState([]);
  const [noteContent, setNoteContent] = useState('');
  const [editNoteContent, setEditNoteContent] = useState('');

  const [noteMenuAnchorEl, setNoteMenuAnchorEl] = useState(null);
  const [noteMenuIsOpen, setNoteMenuIsOpen] = useState(false);
  const [editNoteIndex, setEditNoteIndex] = useState(-1);

  const [, writeNoteRequest] = useFetch();
  const [, deleteNoteRequest] = useFetch();

  function postNote(isEdit, noteIndex) {
    const noteAuthor = getUserName();
    const createdDatetime = moment().format('YYYY-MM-DD HH:mm:ss');
    const noteToSend = {
      note: isEdit ? editNoteContent : noteContent,
      noteAuthor,
      createdDatetime,
    };
    writeNoteRequest({
      url: '/notes/transaction-note/write-transaction-note',
      method: 'post',
      body: noteToSend,
      bodyIds: ['enterpriseCompanyId', 'transactionId', 'portfolioCompanyId', 'requestUserId'],
      onSuccess: (responseData) => {
        if (isEdit) {
          setNoteList(() => {
            const newNoteList = [...notesList];
            newNoteList[noteIndex].note = noteToSend.note;
            newNoteList[noteIndex].editNote = false;
            return newNoteList;
          });
        } else if (responseData) {
          setNotesList((prevList) => {
            const newNoteList = prevList.length ? [...prevList] : [];
            newNoteList.push(noteToSend);
            if (newNoteList[noteIndex]?.editNote) {
              newNoteList[noteIndex].editNote = false;
            }
            return newNoteList;
          });
        }
      },
    });
  }

  useEffect(() => { if (notesList) setNoteList(notesList); }, [notesList]);

  function handleNoteMenuClose() {
    setNoteMenuIsOpen(false);
    setNoteMenuAnchorEl(null);
  }

  function handleNoteMenuOpen(e, noteIndex) {
    setNoteMenuAnchorEl(e.currentTarget);
    setNoteMenuIsOpen(true);
    setEditNoteIndex(noteIndex);
  }

  function deleteNote(noteIndex, andEdit) {
    const deleteNoteData = {
      companyId: noteList[noteIndex].companyId,
      transactionId: noteList[noteIndex].transactionId,
      userId: noteList[noteIndex].insertUserId,
      noteId: noteList[noteIndex].noteId,
      createdDatetime: noteList[noteIndex].createdDatetime,
      noteAuthor: noteList[noteIndex].currentAdminName,
    };
    const newNoteList = copy(noteList);
    newNoteList[noteIndex].isBeingEdited = andEdit;
    newNoteList[noteIndex].editNote = false;
    setNoteList(newNoteList);
    deleteNoteRequest({
      url: '/notes/transaction-note/delete-transaction-note/',
      urlIds: ['enterpriseCompanyId', deleteNoteData.companyId, deleteNoteData.transactionId, deleteNoteData.noteId, 'requestUserId'],
      method: 'delete',
      body: deleteNoteData,
      onSuccess: () => {
        if (andEdit) {
          postNote(true, noteIndex);
        } else {
          noteList.splice(noteIndex, 1);
          setNoteList(copy(noteList));
        }
      },
    });
  }

  function editNote() { deleteNote(editNoteIndex, true); }

  function switchNoteToEdit(reset) {
    const newNoteArr = [];
    noteList.forEach((note) => {
      newNoteArr.push({
        ...note,
        editNote: false,
      });
    });
    if (!reset) newNoteArr[editNoteIndex].editNote = true;
    setNoteList(newNoteArr);
  }

  return (
    <main className="Notes">
      <div className="note-list">
        {noteList.length > 0 && (noteList.map((note, noteIndex) => {
          if (note.editNote) {
            return (
              <React.Fragment key={`${note.createdDatetime}`}>
                <div className="edit-note">
                  <TextField
                    multiline
                    maxRows={4}
                    placeholder="Type note"
                    value={editNoteContent}
                    onChange={(e) => setEditNoteContent(e.target.value)}
                    inputProps={{ maxLength: 500 }}
                  />
                  <div className="edit-note-btns">
                    <Button
                      className="edit-note-cancel-btn"
                      onClick={(e) => {
                        e.stopPropagation();
                        switchNoteToEdit(true);
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      className="edit-note-save-btn"
                      onClick={(e) => {
                        e.stopPropagation();
                        editNote(noteIndex);
                      }}
                    >
                      Save
                    </Button>
                  </div>
                </div>
                <div className="note-length">
                  {editNoteContent.length}
                  / 500
                </div>
              </React.Fragment>
            );
          }
          return (
            <div
              key={`${note.createdDatetime}`}
              className={`note${note.isBeingEdited ? ' isEditing' : ''}`}
            >
              <span className="note-date-time">
                <span className="note-author">{note.noteAuthor}</span>
                <span className="timestamp">
                  {' '}
                  {moment(note.createdDatetime, 'YYYY-MM-DD HH:mm:ss').format('MM/DD/YY')}
                  {' '}
                  at
                  {' '}
                  {moment(note.createdDatetime, 'YYYY-MM-DD HH:mm:ss').format('hh:mma')}
                </span>
              </span>
              <div className="note-content">
                <span>
                  {note.note}
                </span>
              </div>
              <IconButton
                className="note-vert-icon"
                onClick={(e) => {
                  e.stopPropagation();
                  handleNoteMenuOpen(e, noteIndex);
                }}
              >
                <MoreVertIcon />
              </IconButton>
              <Menu
                className="note-menu"
                anchorEl={noteMenuAnchorEl}
                open={noteMenuIsOpen}
                onClose={() => handleNoteMenuClose()}
                disableScrollLock
              >
                <MenuItem
                  onClick={(e) => {
                    e.stopPropagation();
                    switchNoteToEdit();
                    setEditNoteContent(noteList[editNoteIndex].note);
                    handleNoteMenuClose();
                  }}
                >
                  <EditIcon />
                  Edit
                </MenuItem>
                <MenuItem
                  onClick={(e) => {
                    e.stopPropagation();
                    deleteNote(editNoteIndex);
                    handleNoteMenuClose();
                  }}
                >
                  <DeleteOutlineIcon />
                  Delete
                </MenuItem>
              </Menu>
            </div>
          );
        }))}
      </div>
      <TextField
        multiline
        maxRows={4}
        placeholder="Type note"
        className="note-field"
        value={noteContent}
        onChange={(e) => setNoteContent(e.target.value)}
        onClick={(e) => { e.stopPropagation(); }}
        onKeyDown={(e) => { if (e.key === 'Enter') e.stopPropagation(); }}
        InputProps={{
          endAdornment: (
            <InputAdornment
              position="end"
              onClick={(e) => {
                e.stopPropagation();
                postNote();
                setNoteContent('');
              }}
            >
              <SendOutlinedIcon />
            </InputAdornment>
          ),
        }}
        // eslint-disable-next-line react/jsx-no-duplicate-props
        inputProps={{ maxLength: 500 }}
      />
      <div className="note-length">
        {noteContent.length}
        / 500
      </div>
    </main>
  );
}

Notes.propTypes = {
  notesList: PropTypes.any,
  setNotesList: PropTypes.func.isRequired,
};

Notes.defaultProps = {
  notesList: [],
};
