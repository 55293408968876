import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material';

import ErrorOutlinedIcon from '@mui/icons-material/ErrorOutlined';

import { commaEvery3rdChar } from '../../../utils';

import './BreakpointTable.scss';

export default function BreakpointTable({ breakpointsData, breakpointErrors }) {
  const [columns, setColumns] = useState([]);
  const [data, setData] = useState([]);
  const [rowError, setRowError] = useState(false);
  const [dynamicError, setDynamicError] = useState('');
  const [errorLocation, setErrorLocation] = useState('');

  function setValues(keys) {
    const incrementValue = {};
    const cumulativeValue = {};
    cumulativeValue['Total Shares Outstanding'] = '';
    cumulativeValue['Original Issue Price'] = '';
    let cumulativeVal = 0;
    keys.forEach((key) => {
      breakpointsData.forEach((breakpoint, idx) => {
        const breakpointCopy = { ...breakpoint };
        if ((key === 'Total Shares Outstanding' || key.includes('$')) && (!incrementValue[key] || !cumulativeValue[key])) {
          const parsedNumber = !breakpoint[key].includes('-') ? parseFloat(breakpointCopy[key].replace(/[$,]/g, '')) : 0;
          if (incrementValue[key]) {
            incrementValue[key] += parsedNumber;
            if (idx === breakpointsData.length - 1) {
              if (key !== 'Total Shares Outstanding') {
                cumulativeVal += incrementValue[key];
                cumulativeValue[key] = `$${commaEvery3rdChar(cumulativeVal.toFixed(2))}`;
              }
              incrementValue[key] = key !== 'Total Shares Outstanding' ?
                `$${commaEvery3rdChar(incrementValue[key].toFixed(2))}` :
                `${commaEvery3rdChar(incrementValue[key])}`;
            }
          } else {
            incrementValue[key] = parsedNumber;
          }
        }
        if (key === 'Ownership Class') {
          incrementValue[key] = 'Incremental';
          cumulativeValue[key] = 'Cumulative';
        }
      });
    });
    setData([...breakpointsData, incrementValue, cumulativeValue]);
  }

  function getColumnNames() {
    if (!breakpointsData.length) return;
    let priceColumns = Object.keys(breakpointsData[0]);
    priceColumns = priceColumns.filter((val) => val.includes('$'));
    priceColumns = priceColumns.sort((a, b) => parseFloat(a.split('$')[1]) - parseFloat(b.split('$')[1]));
    const namedColumns = ['Ownership Class', 'Total Shares Outstanding', 'Original Issue Price'];
    const columnNames = namedColumns.concat(priceColumns);
    setValues(columnNames);
    setColumns(columnNames);
  }

  function determineRowWarning() {
    let errors = '';
    if (breakpointsData) {
      breakpointsData.forEach((breakpoint, idx) => {
        const emptyRow = Object.values(breakpoint).includes('-');
        const ownershipClass = breakpoint['Ownership Class'];
        if (emptyRow) {
          setRowError(true);
          errors += idx === breakpointsData.length - 1 ? `'${ownershipClass}'` : `'${ownershipClass}', `;
        } else setRowError(false);
      });
      setErrorLocation(' above ');
    }
    if (breakpointErrors.length) {
      setRowError(true);
      setColumns(['', '', '', '']);
      errors += breakpointErrors.join(' and ');
      setErrorLocation(' in the "820 inputs" tab ');
    }
    if (!breakpointsData.length && !breakpointErrors.length) {
      setRowError(true);
      setColumns(['', '', '', '']);
      errors += 'the Cap structure';
    }
    setDynamicError(errors);
  }

  useEffect(() => {
    getColumnNames();
    determineRowWarning();
  }, [breakpointErrors, breakpointsData]);

  return (
    <TableContainer className="BreakpointsContainer" component={Paper}>
      <Table size="small">
        <TableHead>
          <TableRow className="title-row">
            {['(USD$)', 'Capitalization details', 'Breakpoint analysis'].map((title, index) => (
              <TableCell
                colSpan={[1, 2, columns.length - 2][index]}
                className={`table-title-cell ${index === 2 ? 'breakpoint' : ''}`}
                key={title}
              >
                {title}
              </TableCell>
            ))}
          </TableRow>
          <TableRow>
            {columns.map((column, index) => (
              <TableCell key={`${column}-${index + 1}`}>{column}</TableCell>
            ))}
          </TableRow>
          {rowError && (
            <TableRow className="warn-row">
              <TableCell colSpan={columns.length} className="warn-cell">
                <p>
                  <ErrorOutlinedIcon />
                  Looks like you&apos;re missing information for&nbsp;
                  {dynamicError}
                  .
                  For an accurate analysis you&apos;ll need to edit the information
                  {errorLocation}
                  then refresh this breakpoint analysis table.
                </p>
              </TableCell>
            </TableRow>
          )}
        </TableHead>
        <TableBody>
          {data.map((row, idx) => (
            <TableRow key={row['Ownership Class']} className={`${idx % 2 === 0 ? 'even' : 'odd'}`}>
              {columns.map((column) => (
                <TableCell key={column}>{row[column]}</TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

BreakpointTable.propTypes = {
  breakpointsData: PropTypes.arrayOf(PropTypes.object),
  breakpointErrors: PropTypes.arrayOf(PropTypes.string).isRequired,
};

BreakpointTable.defaultProps = {
  breakpointsData: [],
};
