import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

import StorefrontIcon from '@mui/icons-material/Storefront';
import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined';
import TableChartOutlinedIcon from '@mui/icons-material/TableChartOutlined';

import useFetch from '../../../hooks/useFetch';

export default function EditNotes({ userData, setUserData }) {
  const [oldDataState, setOldDataState] = useState(userData);

  const [revertFootnoteState, setRevertFootnoteState] = useState('');

  const [, updateCompanyDataRequest] = useFetch();
  const [, revertFootnoteRequest] = useFetch();
  const [, saveFootnotesRequest] = useFetch();

  function saveCompanyData(dataName) {
    if (userData.companyData[dataName] === oldDataState.companyData[dataName]) return;
    updateCompanyDataRequest({
      url: '/companies/portfolio/update-company-data',
      method: 'post',
      body: userData.companyData,
      onSuccess: () => setOldDataState(userData),
    });
  }

  function revertFootnote(data, dataName, secondaryDataName) {
    if (revertFootnoteState === dataName || revertFootnoteState === secondaryDataName) return;
    if (dataName === 'valuationMethodologySummary') setRevertFootnoteState(secondaryDataName);
    else if (dataName === 'allocationMethodologySummary') setRevertFootnoteState(secondaryDataName);
    else setRevertFootnoteState(dataName);
    const footnoteData = {
      methodsFootnotes: [],
      payload: data,
      dataName,
      ...userData.gridData.defaultScenarios.term && userData.gridData.defaultScenarios.volatility ? {
        term: new Intl.NumberFormat('en-US', {
          minimumIntegerDigits: 1,
          minimumFractionDigits: 1,
        }).format(userData.gridData.defaultScenarios.term),
        volatility: userData.gridData.defaultScenarios.volatility,
      } : {
        term: new Intl.NumberFormat('en-US', {
          minimumIntegerDigits: 1,
          minimumFractionDigits: 1,
        }).format(userData.gridData.defaultScenarios.term),
        volatility: userData.gridData.defaultScenarios.volatility.toFixed(1).toString(),
      },
    };
    revertFootnoteRequest({
      url: '/footnotes/dynamic-footnotes',
      method: 'post',
      body: footnoteData,
      bodyIds: ['enterpriseCompanyId', 'portfolioCompanyId', 'transactionId', 'requestUserId'],
      onSuccess: (responseData) => {
        if (dataName === 'valuationMethodologySummary') {
          setUserData({
            ...userData,
            footnoteData: {
              ...userData.footnoteData,
              valuationMethodologySummary: {
                backsolve: {
                  backsolveKeyInsights: responseData.valuationMethodologySummary.backsolve.backsolveKeyInsights ? {
                    ...userData.footnoteData.valuationMethodologySummary.backsolve.backsolveKeyInsights,
                    ...responseData.valuationMethodologySummary.backsolve.backsolveKeyInsights,
                  } : {
                    ...userData.footnoteData.valuationMethodologySummary.backsolve.backsolveKeyInsights,
                  },
                  genTransactionDetails: responseData.valuationMethodologySummary.backsolve.genTransactionDetails ? {
                    ...userData.footnoteData.valuationMethodologySummary.backsolve.genTransactionDetails,
                    ...responseData.valuationMethodologySummary.backsolve.genTransactionDetails,
                  } : {
                    ...userData.footnoteData.valuationMethodologySummary.backsolve.genTransactionDetails,
                  },
                },
              },
            },
          });
        } else if (dataName === 'allocationMethodologySummary') {
          setUserData({
            ...userData,
            footnoteData: {
              ...userData.footnoteData,
              allocationMethodologySummary: {
                opmDetails: responseData.allocationMethodologySummary.opmDetails ? {
                  ...userData.footnoteData.allocationMethodologySummary.opmDetails,
                  ...responseData.allocationMethodologySummary.opmDetails,
                } : {
                  ...userData.footnoteData.allocationMethodologySummary.opmDetails,
                },
                selectedAllocationMethodology: responseData.allocationMethodologySummary.selectedAllocationMethodology ? {
                  ...userData.footnoteData.allocationMethodologySummary.selectedAllocationMethodology,
                  ...responseData.allocationMethodologySummary.selectedAllocationMethodology,
                } : {
                  ...userData.footnoteData.allocationMethodologySummary.selectedAllocationMethodology,
                },
              },
            },
          });
        } else {
          setUserData({ ...userData, footnoteData: { ...userData.footnoteData, [dataName]: responseData[dataName] } });
        }
      },
      onFinally: () => setRevertFootnoteState(''),
    });
  }

  function saveFootnotes(data, dataName) {
    const footnoteData = {
      methodsFootnotes: [],
      payload: data,
      dataName,
    };
    saveFootnotesRequest({
      url: '/footnotes/dynamic-footnotes',
      method: 'post',
      body: footnoteData,
      bodyIds: ['enterpriseCompanyId', 'portfolioCompanyId', 'transactionId', 'requestUserId'],
      onSuccess: () => {
        setUserData(userData);
        setOldDataState(userData);
      },
    });
  }

  function characterCount(footnoteData, dataName) {
    if (footnoteData[dataName]) {
      return footnoteData[dataName]?.length;
    }
    return 0;
  }

  return (
    <>
      <div className="spacer" />
      <p className="edit-notes-header">Add, edit, and review notes and footnotes, then generate a new draft or final report</p>
      <div className="footnotes-wrapper">
        <div className="edit-footnotes">
          <div className="footnote">
            <p>
              <StorefrontIcon />
              Company description
            </p>
            <TextField
              multiline
              value={userData.companyData.customDescription}
              onChange={(e) => setUserData({ ...userData, companyData: { ...userData.companyData, customDescription: e.target.value } })}
              inputProps={{ maxLength: 900 }}
              onBlur={() => saveCompanyData('customDescription')}
            >
              {userData.companyData.customDescription}
            </TextField>
            {/* <Button
              className="revert-btn"
              onClick={() => setUserData({
                ...userData, companyData: { ...userData.companyData, customDescription: userData.companyData.description },
              })}
            >
              Revert to default note
              <RefreshOutlinedIcon />
            </Button> */}
          </div>
          <hr />
          {[
            { title: 'Fund holdings summary', dataName: 'fundHoldingSummary' },
            { title: 'Equity value conclusion & summary', dataName: 'eqValueConclusion' },
            { title: 'Capitalization table', dataName: 'capTable' },
            { title: 'Breakpoint analysis', dataName: 'bpAnalysis' },
            { title: 'Option pricing model allocation (OPM) allocation', dataName: 'opmAllocation' },
            { title: 'Guideline public company model allocation (GPC) allocation', dataName: 'gpcAllocation' },
            { title: 'Fair value per share', dataName: 'fairValuePerShare' },
            { title: 'Discount for lack of marketability (DLOM)', dataName: 'dlom' },
          ].map((footnoteField) => (
            <div key={footnoteField.dataName} className="footnote">
              <p>
                <TableChartOutlinedIcon />
                {`Exhibit: ${footnoteField.title}`}
              </p>
              <span className="sub-header">Footnote(s)</span>
              <TextField
                multiline
                value={userData.footnoteData?.[footnoteField.dataName]}
                onChange={(e) => setUserData({ ...userData, footnoteData: { ...userData.footnoteData, [footnoteField.dataName]: e.target.value } })}
                onBlur={() => saveFootnotes({ [footnoteField.dataName]: userData.footnoteData[footnoteField.dataName] }, footnoteField.dataName)}
                inputProps={{ maxLength: 900 }}
              />
              <div className="footnote-characters-left">{`${characterCount(userData?.footnoteData, footnoteField.dataName)}/900`}</div>
              <Button
                className="revert-btn"
                onClick={() => revertFootnote({ [footnoteField.dataName]: null }, footnoteField.dataName)}
              >
                {revertFootnoteState === footnoteField.dataName ? (
                  <>
                    Reverting to default note
                    &nbsp;
                    <div className="dots-circle-spinner" />
                  </>
                ) : (
                  <>
                    Revert to default note
                    <RefreshOutlinedIcon />
                  </>
                )
                }
              </Button>
            </div>
          ))}
          <hr />
          <div className="footnote span-two">
            <p>
              <TableChartOutlinedIcon />
              Exhibit: Valuation date Volatility - Industry
            </p>
            {[
              { title: 'Volatility percentile - footnote', dataName: 'volPercentFn' },
              { title: 'Transaction date volatility for backsolve - footnote', dataName: 'tdVolatilityBacksolve' },
            ].map((footnoteField) => (
              <div key={footnoteField.dataName}>
                <span className="sub-header">{footnoteField.title}</span>
                <TextField
                  multiline
                  value={userData.footnoteData[footnoteField.dataName]}
                  onChange={(e) => setUserData({ ...userData, footnoteData: { ...userData.footnoteData, [footnoteField.dataName]: e.target.value } })}
                  onBlur={() => saveFootnotes({ [footnoteField.dataName]: userData.footnoteData[footnoteField.dataName] }, footnoteField.dataName)}
                  inputProps={{ maxLength: 900 }}
                />
                <div className="footnote-characters-left">{`${characterCount(userData?.footnoteData, footnoteField.dataName)}/900`}</div>
                <Button
                  className="revert-btn"
                  onClick={() => revertFootnote({ [footnoteField.dataName]: null }, footnoteField.dataName)}
                >
                  {revertFootnoteState === footnoteField.dataName ? (
                    <>
                      Reverting to default note
                      &nbsp;
                      <div className="dots-circle-spinner" />
                    </>
                  ) : (
                    <>
                      Revert to default note
                      <RefreshOutlinedIcon />
                    </>
                  )
                  }
                </Button>
              </div>
            ))}
          </div>
          <hr />
          {[
            { title: 'Public company descriptions', dataName: 'gpcDescription' },
            { title: 'Guideline public company method', dataName: 'gpcMethodology' },
          ].map((footnoteField) => (
            <div key={footnoteField.dataName} className="footnote">
              <p>
                <TableChartOutlinedIcon />
                {`Exhibit: ${footnoteField.title}`}
              </p>
              <span className="sub-header">Footnote(s)</span>
              <TextField
                multiline
                value={userData.footnoteData?.[footnoteField.dataName]}
                onChange={(e) => setUserData({ ...userData, footnoteData: { ...userData.footnoteData, [footnoteField.dataName]: e.target.value } })}
                onBlur={() => saveFootnotes({ [footnoteField.dataName]: userData.footnoteData[footnoteField.dataName] }, footnoteField.dataName)}
                inputProps={{ maxLength: 900 }}
              />
              <div className="footnote-characters-left">{`${characterCount(userData?.footnoteData, footnoteField.dataName)}/900`}</div>
              <Button
                className="revert-btn"
                onClick={() => revertFootnote({ [footnoteField.dataName]: null }, footnoteField.dataName)}
              >
                {revertFootnoteState === footnoteField.dataName ? (
                  <>
                    Reverting to default note
                    &nbsp;
                    <div className="dots-circle-spinner" />
                  </>
                ) : (
                  <>
                    Revert to default note
                    <RefreshOutlinedIcon />
                  </>
                )
                }
              </Button>
            </div>
          ))}
          <hr />
          {[
            { title: 'Public company growth and margins', dataName: 'gpcGrowthMargins' },
            { title: 'Guideline transaction method', dataName: 'guidelineTranMethodology' },
          ].map((footnoteField) => (
            <div key={footnoteField.dataName} className="footnote">
              <p>
                <TableChartOutlinedIcon />
                {`Exhibit: ${footnoteField.title}`}
              </p>
              <span className="sub-header">Footnote(s)</span>
              <TextField
                multiline
                value={userData.footnoteData?.[footnoteField.dataName]}
                onChange={(e) => setUserData({ ...userData, footnoteData: { ...userData.footnoteData, [footnoteField.dataName]: e.target.value } })}
                onBlur={() => saveFootnotes({ [footnoteField.dataName]: userData.footnoteData[footnoteField.dataName] }, footnoteField.dataName)}
                inputProps={{ maxLength: 900 }}
              />
              <div className="footnote-characters-left">{`${characterCount(userData?.footnoteData, footnoteField.dataName)}/900`}</div>
              <Button
                className="revert-btn"
                onClick={() => revertFootnote({ [footnoteField.dataName]: null }, footnoteField.dataName)}
              >
                {revertFootnoteState === footnoteField.dataName ? (
                  <>
                    Reverting to default note
                    &nbsp;
                    <div className="dots-circle-spinner" />
                  </>
                ) : (
                  <>
                    Revert to default note
                    <RefreshOutlinedIcon />
                  </>
                )}
              </Button>
            </div>
          ))}
          <hr />
          {[
            { title: 'Target company growth and margins', dataName: 'targetCompanyGrowthMargins' },
            { title: 'Guideline transaction descriptions', dataName: 'guidelineTranDescription' },
          ].map((footnoteField) => (
            <div key={footnoteField.dataName} className="footnote">
              <p>
                <TableChartOutlinedIcon />
                {`Exhibit: ${footnoteField.title}`}
              </p>
              <span className="sub-header">Footnote(s)</span>
              <TextField
                multiline
                value={userData.footnoteData?.[footnoteField.dataName]}
                onChange={(e) => setUserData({ ...userData, footnoteData: { ...userData.footnoteData, [footnoteField.dataName]: e.target.value } })}
                onBlur={() => saveFootnotes({ [footnoteField.dataName]: userData.footnoteData[footnoteField.dataName] }, footnoteField.dataName)}
                inputProps={{ maxLength: 900 }}
              />
              <div className="footnote-characters-left">{`${characterCount(userData?.footnoteData, footnoteField.dataName)}/900`}</div>
              <Button
                className="revert-btn"
                onClick={() => revertFootnote({ [footnoteField.dataName]: null }, footnoteField.dataName)}
              >
                {revertFootnoteState === footnoteField.dataName ? (
                  <>
                    Reverting to default note
                    &nbsp;
                    <div className="dots-circle-spinner" />
                  </>
                ) : (
                  <>
                    Revert to default note
                    <RefreshOutlinedIcon />
                  </>
                )}
              </Button>
            </div>
          ))}
          <hr />
          <div className="footnote general-ac">
            <p>
              <TableChartOutlinedIcon />
              Exhibit: General appraisers notes
              &nbsp;
              <span>
                Edit company name and valuation date on the General info tab and
                <br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;820 inputs tab. Run the calc engine to see them update in the exhibit.
              </span>
            </p>
            {[
              { title: 'Client name', dataName: 'companyName' },
              { title: 'Headquarters', dataName: 'headquarters' },
              { title: 'Year founded', dataName: 'yearFounded' },
              { title: 'Primary Industry', dataName: 'companyIndustry' },
              { title: 'Primary Sector', dataName: 'primarySector' },
              { title: 'Website', dataName: 'website' },
            ].map((footnoteField) => (
              <div key={footnoteField.dataName}>
                <p>{footnoteField.title}</p>
                <TextField
                  multiline
                  maxRows={4}
                  value={
                    footnoteField.dataName === 'companyIndustry' ?
                      userData.companyData.companyIndustry[0] :
                      userData.companyData[footnoteField.dataName]}
                  onChange={(e) => setUserData({ ...userData, companyData: { ...userData.companyData, [footnoteField.dataName]: e.target.value } })}
                  onBlur={() => saveCompanyData(footnoteField.dataName)}
                />
              </div>
            ))}
          </div>
          <hr />
          <div className="weighting-blocks">
            <p className="block-header">
              <span>
                <TableChartOutlinedIcon />
                Exhibit: Valuation methodology summary - Weighting (valuation approaches)
              </span>
            </p>
            {[
              { title: 'Backsolve method - Option pricing model (OPM)', dataName: 'backsolveWeightingApproach' },
              { title: 'Guideline public company method (GPC)', dataName: 'gpcWeightingApproach' },
              { title: 'Mergers and Acquisitions transaction method (M&A)', dataName: 'mnaWeightingApproach' },
              { title: 'Preferred transaction method', dataName: 'preferredTranWeightingApproach' },
              { title: 'Income approach - Gordon growth', dataName: 'gordonGrowthWeightingApproach' },
              { title: 'Post-money method', dataName: 'postMoneyWeightingApproach' },
            ].map((footnoteField) => (
              <div key={footnoteField.dataName} className="weight-block">
                <div className="fn-input">
                  <p>{`${footnoteField.title} appraisers commentary`}</p>
                  <TextField
                    multiline
                    value={userData.footnoteData[footnoteField.dataName]?.approach}
                    onChange={(e) => setUserData({
                      ...userData, footnoteData: {
                        ...userData.footnoteData,
                        [footnoteField.dataName]: { ...userData.footnoteData[footnoteField.dataName], approach: e.target.value },
                      },
                    })}
                    onBlur={() => saveFootnotes({
                      [footnoteField.dataName]: {
                        approach: userData.footnoteData[footnoteField.dataName].approach,
                      },
                    }, footnoteField.dataName)}
                    inputProps={{ maxLength: 900 }}
                  />
                  <div className="footnote-characters-left">{`${userData?.footnoteData?.[footnoteField.dataName]?.approach?.length}/500`}</div>
                  <Button
                    className="revert-btn"
                    onClick={() => revertFootnote({ [footnoteField.dataName]: null }, footnoteField.dataName)}
                  >
                    {revertFootnoteState === footnoteField.dataName ? (
                      <>
                        Reverting to default note
                        &nbsp;
                        <div className="dots-circle-spinner" />
                      </>
                    ) : (
                      <>
                        Revert to default note
                        <RefreshOutlinedIcon />
                      </>
                    )}
                  </Button>
                </div>
                <div className="weight-container">
                  <p>Weight</p>
                  <div>
                    <span>{userData.footnoteData[footnoteField.dataName]?.weight}</span>
                    <h6>Weight</h6>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <hr />
          <div className="methodology-summary val">
            <h6>
              <TableChartOutlinedIcon />
              Exhibit: Valuation methodology summary - Backsolve
            </h6>
            <div className="column left">
              <h5>General transaction details with appraiser&apos;s commentary</h5>
              <p>
                Most recent round type
                <span>
                  &nbsp;-&nbsp;
                  <i>
                    Not looking right? Edit this from the Cap Structure tab.
                  </i>
                </span>
              </p>
              <div className="info-card">
                <span>
                  {userData.footnoteData?.valuationMethodologySummary?.backsolve?.genTransactionDetails?.mostRecentRound.round}
                </span>
              </div>
              <p>Most recent round - appraiser&apos;s commentary</p>
              <TextField
                multiline
                value={userData.footnoteData?.valuationMethodologySummary?.backsolve?.genTransactionDetails?.mostRecentRound?.commentary}
                onChange={(e) => setUserData((prevState) => {
                  const newState = { ...prevState };
                  newState.footnoteData.valuationMethodologySummary.backsolve.genTransactionDetails.mostRecentRound.commentary = e.target.value;
                  return newState;
                })}
                onBlur={() => saveFootnotes({
                  valuationMethodologySummary: {
                    backsolve: {
                      genTransactionDetails: {
                        mostRecentRound: {
                          commentary: userData.footnoteData.valuationMethodologySummary.backsolve.genTransactionDetails.mostRecentRound.commentary,
                        },
                      },
                    },
                  },
                }, 'valuationMethodologySummary')}
                inputProps={{ maxLength: 900 }}
              />
              <Button
                className="revert-btn"
                onClick={() => revertFootnote({
                  valuationMethodologySummary: {
                    backsolve: {
                      genTransactionDetails: {
                        mostRecentRound: {
                          commentary: null,
                        },
                      },
                    },
                  },
                }, 'valuationMethodologySummary', 'mostRecentRound')}
              >
                {revertFootnoteState === 'mostRecentRound' ? (
                  <>
                    Reverting to default note
                    &nbsp;
                    <div className="dots-circle-spinner" />
                  </>
                ) : (
                  <>
                    Revert to default note
                    <RefreshOutlinedIcon />
                  </>
                )}
              </Button>
              <p>Transaction date - appraiser&apos;s commentary</p>
              <TextField
                multiline
                value={userData.footnoteData?.valuationMethodologySummary?.backsolve?.genTransactionDetails?.tranDateAC}
                onChange={(e) => setUserData((prevState) => {
                  const newState = { ...prevState };
                  newState.footnoteData.valuationMethodologySummary.backsolve.genTransactionDetails.tranDateAC = e.target.value;
                  return newState;
                })}
                onBlur={() => saveFootnotes({
                  valuationMethodologySummary: {
                    backsolve: {
                      genTransactionDetails: {
                        tranDateAC: userData.footnoteData.valuationMethodologySummary.backsolve.genTransactionDetails.tranDateAC,
                      },
                    },
                  },
                }, 'valuationMethodologySummary')}
                inputProps={{ maxLength: 900 }}
              />
              <Button
                className="revert-btn"
                onClick={() =>
                  revertFootnote({
                    valuationMethodologySummary: {
                      backsolve: {
                        genTransactionDetails: {
                          tranDateAC: null,
                        },
                      },
                    },
                  }, 'valuationMethodologySummary', 'tranDateAC')
                }
              >
                {revertFootnoteState === 'tranDateAC' ? (
                  <>
                    Reverting to default note
                    &nbsp;
                    <div className="dots-circle-spinner" />
                  </>
                ) : (
                  <>
                    Revert to default note
                    <RefreshOutlinedIcon />
                  </>
                )}
              </Button>
              <p>Nature of transaction - appraiser&apos;s commentary</p>
              <TextField
                multiline
                value={userData.footnoteData?.valuationMethodologySummary?.backsolve?.genTransactionDetails?.natureOfTranAC}
                onChange={(e) => setUserData((prevState) => {
                  const newState = { ...prevState };
                  newState.footnoteData.valuationMethodologySummary.backsolve.genTransactionDetails.natureOfTranAC = e.target.value;
                  return newState;
                })}
                onBlur={() => saveFootnotes({
                  valuationMethodologySummary: {
                    backsolve: {
                      genTransactionDetails: {
                        natureOfTranAC: userData.footnoteData.valuationMethodologySummary.backsolve.genTransactionDetails.natureOfTranAC,
                      },
                    },
                  },
                }, 'valuationMethodologySummary')}
                inputProps={{ maxLength: 900 }}
              />
              <Button
                className="revert-btn"
                onClick={() =>
                  revertFootnote({
                    valuationMethodologySummary: {
                      backsolve: {
                        genTransactionDetails: {
                          natureOfTranAC: null,
                        },
                      },
                    },
                  }, 'valuationMethodologySummary', 'natureOfTranAC')}
              >
                {revertFootnoteState === 'natureOfTranAC' ? (
                  <>
                    Reverting to default note
                    &nbsp;
                    <div className="dots-circle-spinner" />
                  </>
                ) : (
                  <>
                    Revert to default note
                    <RefreshOutlinedIcon />
                  </>
                )}
              </Button>
            </div>
            <div className="column right">
              <h5>Backsolve methodology inputs</h5>
              <div className="value-and-ac">
                <p>
                  S = implied value of equity
                  <span className="far-right-label valuation">
                    Appraiser&apos;s commentary
                  </span>
                </p>
                <div className="card-input">
                  <div className="info-card right">
                    {userData.footnoteData?.valuationMethodologySummary?.backsolve?.backsolveKeyInsights?.sImpliedEquity?.value}
                  </div>
                  <TextField
                    multiline
                    value={userData.footnoteData?.valuationMethodologySummary?.backsolve?.backsolveKeyInsights?.sImpliedEquity?.ac}
                    onChange={(e) => setUserData((prevState) => {
                      const newState = { ...prevState };
                      newState.footnoteData.valuationMethodologySummary.backsolve.backsolveKeyInsights.sImpliedEquity.ac = e.target.value;
                      return newState;
                    })}
                    onBlur={() => saveFootnotes({
                      valuationMethodologySummary: {
                        backsolve: {
                          backsolveKeyInsights: {
                            sImpliedEquity: {
                              ac: userData.footnoteData.valuationMethodologySummary.backsolve.backsolveKeyInsights.sImpliedEquity.ac,
                            },
                          },
                        },
                      },
                    }, 'valuationMethodologySummary')}
                    inputProps={{ maxLength: 900 }}
                  />
                </div>
                <Button
                  className="revert-btn"
                  onClick={() => revertFootnote({
                    valuationMethodologySummary: {
                      backsolve: {
                        backsolveKeyInsights: {
                          sImpliedEquity: {
                            ac: null,
                          },
                        },
                      },
                    },
                  }, 'valuationMethodologySummary', 'valSImpliedEquity')}
                >
                  {revertFootnoteState === 'valSImpliedEquity' ? (
                    <>
                      Reverting to default note
                      &nbsp;
                      <div className="dots-circle-spinner" />
                    </>
                  ) : (
                    <>
                      Revert to default note
                      <RefreshOutlinedIcon />
                    </>
                  )}
                </Button>
              </div>
              <div className="value-and-ac">
                <p>t = time to expiration</p>
                <div className="card-input">
                  <div className="info-card right">
                    {userData.footnoteData?.valuationMethodologySummary?.backsolve?.backsolveKeyInsights?.tTimeExpiration?.value}
                  </div>
                  <TextField
                    multiline
                    value={userData.footnoteData?.valuationMethodologySummary?.backsolve?.backsolveKeyInsights?.tTimeExpiration?.ac}
                    onChange={(e) => setUserData((prevState) => {
                      const newState = { ...prevState };
                      newState.footnoteData.valuationMethodologySummary.backsolve.backsolveKeyInsights.tTimeExpiration.ac = e.target.value;
                      return newState;
                    })}
                    onBlur={() => saveFootnotes({
                      valuationMethodologySummary: {
                        backsolve: {
                          backsolveKeyInsights: {
                            tTimeExpiration: {
                              ac: userData.footnoteData.valuationMethodologySummary.backsolve.backsolveKeyInsights.tTimeExpiration.ac,
                            },
                          },
                        },
                      },
                    }, 'valuationMethodologySummary')}
                    inputProps={{ maxLength: 900 }}
                  />
                </div>
                <Button
                  className="revert-btn"
                  onClick={() =>
                    revertFootnote({
                      valuationMethodologySummary: {
                        backsolve: {
                          backsolveKeyInsights: {
                            tTimeExpiration: {
                              ac: null,
                            },
                          },
                        },
                      },
                    }, 'valuationMethodologySummary', 'valTTimeExpiration')}
                >
                  {revertFootnoteState === 'valTTimeExpiration' ? (
                    <>
                      Reverting to default note
                      &nbsp;
                      <div className="dots-circle-spinner" />
                    </>
                  ) : (
                    <>
                      Revert to default note
                      <RefreshOutlinedIcon />
                    </>
                  )}
                </Button>
              </div>
              <div className="value-and-ac">
                <p>rf = Risk free rate</p>
                <div className="card-input">
                  <div className="info-card right">
                    {userData.footnoteData?.valuationMethodologySummary?.backsolve?.backsolveKeyInsights?.rfRiskFreeRate?.value}
                  </div>
                  <TextField
                    multiline
                    value={userData.footnoteData?.valuationMethodologySummary?.backsolve?.backsolveKeyInsights?.rfRiskFreeRate?.ac}
                    onChange={(e) => setUserData((prevState) => {
                      const newState = { ...prevState };
                      newState.footnoteData.valuationMethodologySummary.backsolve.backsolveKeyInsights.rfRiskFreeRate.ac = e.target.value;
                      return newState;
                    })}
                    onBlur={() => saveFootnotes({
                      valuationMethodologySummary: {
                        backsolve: {
                          backsolveKeyInsights: {
                            rfRiskFreeRate: {
                              ac: userData.footnoteData.valuationMethodologySummary.backsolve.backsolveKeyInsights.rfRiskFreeRate.ac,
                            },
                          },
                        },
                      },
                    }, 'valuationMethodologySummary')}
                    inputProps={{ maxLength: 900 }}
                  />
                </div>
                <Button
                  className="revert-btn"
                  onClick={() =>
                    revertFootnote({
                      valuationMethodologySummary: {
                        backsolve: {
                          backsolveKeyInsights: {
                            rfRiskFreeRate: {
                              ac: null,
                            },
                          },
                        },
                      },
                    }, 'valuationMethodologySummary', 'valRfRiskFreeRate')}
                >
                  {revertFootnoteState === 'valRfRiskFreeRate' ? (
                    <>
                      Reverting to default note
                      &nbsp;
                      <div className="dots-circle-spinner" />
                    </>
                  ) : (
                    <>
                      Revert to default note
                      <RefreshOutlinedIcon />
                    </>
                  )}
                </Button>
              </div>
              <div className="value-and-ac">
                <p>sd = volatility</p>
                <div className="card-input">
                  <div className="info-card right">
                    {userData?.footnoteData?.valuationMethodologySummary?.backsolve?.backsolveKeyInsights?.sdVolatility?.value}
                  </div>
                  <TextField
                    multiline
                    value={userData?.footnoteData?.valuationMethodologySummary?.backsolve?.backsolveKeyInsights?.sdVolatility?.ac}
                    onChange={(e) => setUserData((prevState) => {
                      const newState = { ...prevState };
                      newState.footnoteData.valuationMethodologySummary.backsolve.backsolveKeyInsights.sdVolatility.ac = e.target.value;
                      return newState;
                    })}
                    onBlur={() => saveFootnotes({
                      valuationMethodologySummary: {
                        backsolve: {
                          backsolveKeyInsights: {
                            sdVolatility: {
                              ac: userData.footnoteData.valuationMethodologySummary.backsolve.backsolveKeyInsights.sdVolatility.ac,
                            },
                          },
                        },
                      },
                    }, 'valuationMethodologySummary')}
                    inputProps={{ maxLength: 900 }}
                  />
                </div>
                <Button
                  className="revert-btn"
                  onClick={() =>
                    revertFootnote({
                      valuationMethodologySummary: {
                        backsolve: {
                          backsolveKeyInsights: {
                            sdVolatility: {
                              ac: null,
                            },
                          },
                        },
                      },
                    }, 'valuationMethodologySummary', 'valSdVolatility')}
                >
                  {revertFootnoteState === 'valSdVolatility' ? (
                    <>
                      Reverting to default note
                      &nbsp;
                      <div className="dots-circle-spinner" />
                    </>
                  ) : (
                    <>
                      Revert to default note
                      <RefreshOutlinedIcon />
                    </>
                  )}
                </Button>
              </div>
              <div className="value-and-ac">
                <p>Based on</p>
                <div className="card-input">
                  <div className="info-card right">
                    {userData?.footnoteData?.valuationMethodologySummary?.backsolve?.backsolveKeyInsights?.basedOn?.value}
                  </div>
                  <TextField
                    multiline
                    value={userData?.footnoteData?.valuationMethodologySummary?.backsolve?.backsolveKeyInsights?.basedOn?.ac}
                    onChange={(e) => setUserData((prevState) => {
                      const newState = { ...prevState };
                      newState.footnoteData.valuationMethodologySummary.backsolve.backsolveKeyInsights.basedOn.ac = e.target.value;
                      return newState;
                    })}
                    onBlur={() => saveFootnotes({
                      valuationMethodologySummary: {
                        backsolve: {
                          backsolveKeyInsights: {
                            basedOn: {
                              ac: userData.footnoteData.valuationMethodologySummary.backsolve.backsolveKeyInsights.basedOn.ac,
                            },
                          },
                        },
                      },
                    }, 'valuationMethodologySummary')
                    }
                    inputProps={{ maxLength: 900 }}
                  />
                </div>
                <Button
                  className="revert-btn"
                  onClick={() => revertFootnote({
                    valuationMethodologySummary: {
                      backsolve: {
                        backsolveKeyInsights: {
                          basedOn: {
                            ac: null,
                          },
                        },
                      },
                    },
                  }, 'valuationMethodologySummary', 'valBasedOn')}
                >
                  {revertFootnoteState === 'valBasedOn' ? (
                    <>
                      Reverting to default note
                      &nbsp;
                      <div className="dots-circle-spinner" />
                    </>
                  ) : (
                    <>
                      Revert to default note
                      <RefreshOutlinedIcon />
                    </>
                  )}
                </Button>
              </div>
              <div className="value-and-ac">
                <p>
                  Number of companies
                  <span>
                    &nbsp;-&nbsp;
                    <i>
                      Not looking right? You can edit this from the industry tab
                    </i>
                  </span>
                </p>
                <div className="card-input">
                  <div className="info-card right">
                    {userData?.footnoteData?.valuationMethodologySummary?.backsolve?.backsolveKeyInsights?.numOfCompanies?.value}
                  </div>
                  <TextField
                    multiline
                    value={userData?.footnoteData?.valuationMethodologySummary?.backsolve?.backsolveKeyInsights?.numOfCompanies?.ac}
                    onChange={(e) => setUserData((prevState) => {
                      const newState = { ...prevState };
                      newState.footnoteData.valuationMethodologySummary.backsolve.backsolveKeyInsights.numOfCompanies.ac = e.target.value;
                      return newState;
                    })}
                    onBlur={() => saveFootnotes({
                      valuationMethodologySummary: {
                        backsolve: {
                          backsolveKeyInsights: {
                            numOfCompanies: {
                              ac: userData.footnoteData.valuationMethodologySummary.backsolve.backsolveKeyInsights.numOfCompanies.ac,
                            },
                          },
                        },
                      },
                    }, 'valuationMethodologySummary')}
                    inputProps={{ maxLength: 900 }}
                  />
                </div>
                <Button
                  className="revert-btn"
                  onClick={() => revertFootnote({
                    valuationMethodologySummary: {
                      backsolve: {
                        backsolveKeyInsights: {
                          numOfCompanies: {
                            ac: null,
                          },
                        },
                      },
                    },
                  }, 'valuationMethodologySummary', 'numOfCompanies')}
                >
                  {revertFootnoteState === 'numOfCompanies' ? (
                    <>
                      Reverting to default note
                      &nbsp;
                      <div className="dots-circle-spinner" />
                    </>
                  ) : (
                    <>
                      Revert to default note
                      <RefreshOutlinedIcon />
                    </>
                  )}
                </Button>
              </div>
              <div className="value-and-ac">
                <p>
                  Primary industry
                  <span>
                    &nbsp;-&nbsp;
                    <i>
                      Not looking right? You can edit this from the General info or 820 inputs tab.
                    </i>
                  </span>
                </p>
                <div className="card-input">
                  <div className="info-card right">
                    {userData?.footnoteData?.valuationMethodologySummary?.backsolve?.backsolveKeyInsights?.primaryIndustry?.value}
                  </div>
                  <TextField
                    multiline
                    value={userData?.footnoteData?.valuationMethodologySummary?.backsolve?.backsolveKeyInsights?.primaryIndustry?.ac}
                    onChange={(e) => setUserData((prevState) => {
                      const newState = { ...prevState };
                      newState.footnoteData.valuationMethodologySummary.backsolve.backsolveKeyInsights.primaryIndustry.ac = e.target.value;
                      return newState;
                    })}
                    onBlur={() => saveFootnotes({
                      valuationMethodologySummary: {
                        backsolve: {
                          backsolveKeyInsights: {
                            primaryIndustry: {
                              ac: userData.footnoteData.valuationMethodologySummary.backsolve.backsolveKeyInsights.primaryIndustry.ac,
                            },
                          },
                        },
                      },
                    }, 'valuationMethodologySummary')}
                    inputProps={{ maxLength: 900 }}
                  />
                </div>
                <Button
                  className="revert-btn"
                  onClick={() => revertFootnote({
                    valuationMethodologySummary: {
                      backsolve: {
                        backsolveKeyInsights: {
                          primaryIndustry: {
                            ac: null,
                          },
                        },
                      },
                    },
                  }, 'valuationMethodologySummary', 'primaryIndustry')}
                >
                  {revertFootnoteState === 'primaryIndustry' ? (
                    <>
                      Reverting to default note
                      &nbsp;
                      <div className="dots-circle-spinner" />
                    </>
                  ) : (
                    <>
                      Revert to default note
                      <RefreshOutlinedIcon />
                    </>
                  )}
                </Button>
              </div>
              <div className="ac">
                <p>Volatility selection methodology - commentary</p>
                <div className="card-input">
                  <TextField
                    multiline
                    name="volatilitySelectionMethodology"
                    value={userData?.footnoteData?.valuationMethodologySummary?.backsolve?.backsolveKeyInsights?.volatilitySelectionMethodology?.ac}
                    onChange={(e) => setUserData((prevState) => {
                      const newState = { ...prevState };
                      newState.footnoteData.valuationMethodologySummary.backsolve.backsolveKeyInsights.volatilitySelectionMethodology.ac =
                        e.target.value;
                      return newState;
                    })}
                    onBlur={() => saveFootnotes({
                      valuationMethodologySummary: {
                        backsolve: {
                          backsolveKeyInsights: {
                            volatilitySelectionMethodology: {
                              ac: userData.footnoteData.valuationMethodologySummary.backsolve.backsolveKeyInsights.volatilitySelectionMethodology.ac,
                            },
                          },
                        },
                      },
                    }, 'valuationMethodologySummary')}
                    inputProps={{ maxLength: 900 }}
                  />
                </div>
                <Button
                  className="revert-btn"
                  onClick={() => revertFootnote({
                    valuationMethodologySummary: {
                      backsolve: {
                        backsolveKeyInsights: {
                          volatilitySelectionMethodology: {
                            ac: null,
                          },
                        },
                      },
                    },
                  }, 'valuationMethodologySummary', 'volatilitySelectionAc')}
                >
                  {revertFootnoteState === 'volatilitySelectionAc' ? (
                    <>
                      Reverting to default note
                      &nbsp;
                      <div className="dots-circle-spinner" />
                    </>
                  ) : (
                    <>
                      Revert to default note
                      <RefreshOutlinedIcon />
                    </>
                  )}
                </Button>
              </div>
              <div className="ac">
                <p>X = Exercise price (breakpoints)</p>
                <div className="card-input">
                  <TextField
                    multiline
                    value={userData?.footnoteData?.valuationMethodologySummary?.backsolve?.backsolveKeyInsights?.xExercisePrice?.ac}
                    onChange={(e) => setUserData((prevState) => {
                      const newState = { ...prevState };
                      newState.footnoteData.valuationMethodologySummary.backsolve.backsolveKeyInsights.xExercisePrice.ac = e.target.value;
                      return newState;
                    })}
                    onBlur={() => saveFootnotes({
                      valuationMethodologySummary: {
                        backsolve: {
                          backsolveKeyInsights: {
                            xExercisePrice: {
                              ac: userData.footnoteData.valuationMethodologySummary.backsolve.backsolveKeyInsights.xExercisePrice.ac,
                            },
                          },
                        },
                      },
                    }, 'valuationMethodologySummary')}
                    inputProps={{ maxLength: 900 }}
                  />
                </div>
                <Button
                  className="revert-btn"
                  onClick={() => revertFootnote({
                    valuationMethodologySummary: {
                      backsolve: {
                        backsolveKeyInsights: {
                          xExercisePrice: {
                            ac: null,
                          },
                        },
                      },
                    },
                  }, 'valuationMethodologySummary', 'valXExercisePrice')}
                >
                  {revertFootnoteState === 'valXExercisePrice' ? (
                    <>
                      Reverting to default note
                      &nbsp;
                      <div className="dots-circle-spinner" />
                    </>
                  ) : (
                    <>
                      Revert to default note
                      <RefreshOutlinedIcon />
                    </>
                  )}
                </Button>
              </div>
              <div className="ac">
                <p>
                  Equity adjustment&nbsp;
                  {userData?.footnoteData?.valuationMethodologySummary?.backsolve?.backsolveKeyInsights?.eqAdjustment?.value}
                </p>
                <div className="card-input">
                  <TextField
                    multiline
                    value={userData?.footnoteData?.valuationMethodologySummary?.backsolve?.backsolveKeyInsights?.eqAdjustment?.ac}
                    onChange={(e) => setUserData((prevState) => {
                      const newState = { ...prevState };
                      newState.footnoteData.valuationMethodologySummary.backsolve.backsolveKeyInsights.eqAdjustment.ac = e.target.value;
                      return newState;
                    })}
                    onBlur={() => saveFootnotes({
                      valuationMethodologySummary: {
                        backsolve: {
                          backsolveKeyInsights: {
                            eqAdjustment: {
                              ac: userData.footnoteData.valuationMethodologySummary.backsolve.backsolveKeyInsights.eqAdjustment.ac,
                            },
                          },
                        },
                      },
                    }, 'valuationMethodologySummary')}
                    inputProps={{ maxLength: 900 }}
                  />
                </div>
                <Button
                  className="revert-btn"
                  onClick={() => revertFootnote({
                    valuationMethodologySummary: {
                      backsolve: {
                        backsolveKeyInsights: {
                          eqAdjustment: {
                            ac: null,
                          },
                        },
                      },
                    },
                  }, 'valuationMethodologySummary', 'eqAdjustment')}
                >
                  {revertFootnoteState === 'eqAdjustment' ? (
                    <>
                      Reverting to default note
                      &nbsp;
                      <div className="dots-circle-spinner" />
                    </>
                  ) : (
                    <>
                      Revert to default note
                      <RefreshOutlinedIcon />
                    </>
                  )}
                </Button>
              </div>
            </div>
          </div>
          <hr />
          <div className="methodology-summary gpc">
            <h6>
              <TableChartOutlinedIcon />
              Exhibit: Valuation methodology summary - Guideline public company (GPC)
            </h6>
            <div className="gpc-criteria">
              <div>
                <p>Public company selection criteria</p>
                <TextField
                  multiline
                  value={userData?.footnoteData?.gpcSelectionCriteria}
                  onChange={(e) => setUserData({ ...userData, footnoteData: { ...userData.footnoteData, gpcSelectionCriteria: e.target.value } })}
                  onBlur={() => saveFootnotes({ gpcSelectionCriteria: userData.footnoteData.gpcSelectionCriteria })}
                  inputProps={{ maxLength: 900 }}
                />
                <div className="footnote-characters-left">{`${0}/900`}</div>
                <Button
                  className="revert-btn"
                  onClick={() =>
                    revertFootnote({ gpcSelectionCriteria: null }, 'gpcSelectionCriteria')}
                >
                  {revertFootnoteState === 'gpcSelectionCriteria' ? (
                    <>
                      Reverting to default note
                      &nbsp;
                      <div className="dots-circle-spinner" />
                    </>
                  ) : (
                    <>
                      Revert to default note
                      <RefreshOutlinedIcon />
                    </>
                  )}
                </Button>
              </div>
              <div>
                <p>Guideline company selection criteria</p>
                <TextField
                  multiline
                  name="guidelineSelectionCriteria"
                  value={userData?.footnoteData?.guidelineSelectionCriteria}
                  onChange={(e) => setUserData({
                    ...userData, footnoteData: {
                      ...userData.footnoteData, guidelineSelectionCriteria: e.target.value,
                    },
                  })}
                  onBlur={() => saveFootnotes({ guidelineSelectionCriteria: userData.footnoteData.guidelineSelectionCriteria })}
                  inputProps={{ maxLength: 900 }}
                />
                <div className="footnote-characters-left">{`${0}/900`}</div>
                <Button
                  className="revert-btn"
                  onClick={() => revertFootnote({ guidelineSelectionCriteria: null }, 'guidelineSelectionCriteria')}
                >
                  {revertFootnoteState === 'guidelineSelectionCriteria' ? (
                    <>
                      Reverting to default note
                      &nbsp;
                      <div className="dots-circle-spinner" />
                    </>
                  ) : (
                    <>
                      Revert to default note
                      <RefreshOutlinedIcon />
                    </>
                  )}
                </Button>
              </div>
            </div>
            {userData?.footnoteData?.methodsFootnotes?.length > 0 && (
              <div className="column left gpc">
                <h6>Selected multiple weighting</h6>
                {userData?.footnoteData?.methodsFootnotes.map((method, methodIndex) => (
                  <div className="gpc-two-card" key={method.period}>
                    <div>
                      <p>{method.kpi}</p>
                      <div className="info-card"><span>{method.weight}</span></div>
                    </div>
                    <div>
                      <p>Appraiser&apos;s commentary</p>
                      <TextField
                        multiline
                        maxRows={4}
                        value={method.footnote}
                        onChange={(e) => setUserData((prevState) => {
                          const newState = { ...prevState };
                          newState.footnoteData.methodsFootnotes[methodIndex].footnote = e.target.value;
                          return newState;
                        })}
                        onBlur={() => saveFootnotes({
                          methodsFootnotes: [
                            userData.footnoteData.methodsFootnotes[methodIndex],
                          ],
                        }, method.period)}
                      />
                      <Button
                        className="revert-btn"
                        onClick={() => revertFootnote({ methodsFootnotes: [{ ...method, footnote: null }] })}
                      >
                        {revertFootnoteState === method.period ? (
                          <>
                            Reverting to default note
                            &nbsp;
                            <div className="dots-circle-spinner" />
                          </>
                        ) : (
                          <>
                            Revert to default note
                            <RefreshOutlinedIcon />
                          </>
                        )}
                      </Button>
                    </div>
                  </div>
                ))}
              </div>
            )}
            {false && (
              <div className="column right gpc">
                <h6>Added or Removed companies</h6>
                <div className="value-and-ac">
                  <p>
                    Removed Company name
                    <span className="far-right-label">
                      Reason for removal
                    </span>
                  </p>
                  <div className="card-input">
                    <div className="info-card right">(Most recent round)</div>
                    <TextField
                      multiline
                      maxRows={4}
                    />
                  </div>
                  <Button className="revert-btn">
                    Revert to default note
                    <RefreshOutlinedIcon />
                  </Button>
                </div>
                <div className="value-and-ac">
                  <p>
                    Removed Company name
                    <span className="far-right-label">
                      Reason for removal
                    </span>
                  </p>
                  <div className="card-input">
                    <div className="info-card right">(Most recent round)</div>
                    <TextField
                      multiline
                      maxRows={4}
                    />
                  </div>
                  <Button className="revert-btn">
                    Revert to default note
                    <RefreshOutlinedIcon />
                  </Button>
                </div>
              </div>
            )}
          </div>
          <hr />

          <div className="methodology-summary opm">
            <h6>
              <TableChartOutlinedIcon />
              Exhibit: Valuation methodology summary - OPM
            </h6>
            <div className="column left">
              <p>Selected allocation methodology</p>
              {userData?.footnoteData?.allocationMethodologySummary?.selectedAllocationMethodology?.types.map((methodology) => (
                <div key={methodology} className="info-card">
                  <span>
                    {methodology}
                  </span>
                </div>
              ))}
              <p>Appraiser&apos;s commentary</p>
              {userData?.footnoteData?.allocationMethodologySummary?.selectedAllocationMethodology?.ac.map((comm, commIndex) => (
                // eslint-disable-next-line
                <React.Fragment key={commIndex}>
                  <TextField
                    multiline
                    value={comm}
                    onChange={(e) => setUserData((prevState) => {
                      const newState = { ...prevState };
                      newState.footnoteData.allocationMethodologySummary.selectedAllocationMethodology.ac[commIndex] = e.target.value;
                      return newState;
                    })}
                    onBlur={() => saveFootnotes({
                      allocationMethodologySummary: {
                        selectedAllocationMethodology: {
                          ac: [
                            userData.footnoteData.allocationMethodologySummary.selectedAllocationMethodology.ac[commIndex],
                          ],
                        },
                      },
                    }, 'allocationMethodologySummary')}
                    inputProps={{ maxLength: 900 }}
                  />
                  <Button
                    className="revert-btn"
                    onClick={() => revertFootnote({
                      allocationMethodologySummary: {
                        selectedAllocationMethodology: {
                          ac: null,
                        },
                      },
                    }, 'allocationMethodologySummary', 'selectedAllocationAc')}
                  >
                    {revertFootnoteState === 'selectedAllocationAc' ? (
                      <>
                        Reverting to default note
                        &nbsp;
                        <div className="dots-circle-spinner" />
                      </>
                    ) : (
                      <>
                        Revert to default note
                        <RefreshOutlinedIcon />
                      </>
                    )}
                  </Button>
                </React.Fragment>
              ))}
            </div>
            <div className="column right">
              <h5>Option pricing model details</h5>
              <div className="ac">
                <p>Appraiser&apos;s commentary</p>
                <TextField
                  multiline
                  maxRows={4}
                  value={userData?.footnoteData?.allocationMethodologySummary?.opmDetails?.generalAppComm?.ac}
                  onChange={(e) => setUserData((prevState) => {
                    const newState = { ...prevState };
                    newState.footnoteData.allocationMethodologySummary.opmDetails.generalAppComm.ac = e.target.value;
                    return newState;
                  })}
                  onBlur={() => saveFootnotes({
                    allocationMethodologySummary: {
                      opmDetails: {
                        generalAppComm: {
                          ac: userData.footnoteData.allocationMethodologySummary.opmDetails.generalAppComm.ac,
                        },
                      },
                    },
                  }, 'allocationMethodologySummary')}
                />
              </div>
              <Button
                className="revert-btn"
                onClick={() => revertFootnote({
                  allocationMethodologySummary: {
                    opmDetails: {
                      generalAppComm: {
                        ac: null,
                      },
                    },
                  },
                }, 'allocationMethodologySummary', 'generalAppComm')}
              >
                {revertFootnoteState === 'generalAppComm' ? (
                  <>
                    Reverting to default note
                    &nbsp;
                    <div className="dots-circle-spinner" />
                  </>
                ) : (
                  <>
                    Revert to default note
                    <RefreshOutlinedIcon />
                  </>
                )}
              </Button>
              <div className="value-and-ac">
                <p>
                  S = Total value
                  <span className="far-right-label allocation">
                    Appraiser&apos;s commentary
                  </span>
                </p>
                <div className="card-input">
                  <div>
                    {userData?.footnoteData?.allocationMethodologySummary?.opmDetails?.sTotalValue?.value.map((value) => (
                      <div className="info-card right" key={value}>
                        {value}
                      </div>
                    ))}
                    {/* <div className="info-card right">
                      {userData?.footnoteData?.allocationMethodologySummary?.opmDetails?.sTotalValue?.value}
                    </div> */}
                  </div>
                  <TextField
                    multiline
                    value={userData?.footnoteData?.allocationMethodologySummary?.opmDetails?.sTotalValue.ac}
                    onChange={(e) => setUserData((prevState) => {
                      const newState = { ...prevState };
                      newState.footnoteData.allocationMethodologySummary.opmDetails.sTotalValue.ac = e.target.value;
                      return newState;
                    })}
                    onBlur={() => saveFootnotes({
                      allocationMethodologySummary: {
                        opmDetails: {
                          sTotalValue: {
                            ac: userData.footnoteData.allocationMethodologySummary.opmDetails.sTotalValue.ac,
                          },
                        },
                      },
                    }, 'allocationMethodologySummary')}
                    inputProps={{ maxLength: 900 }}
                  />
                </div>
                <Button
                  className="revert-btn"
                  onClick={() => revertFootnote({
                    allocationMethodologySummary: {
                      opmDetails: {
                        sTotalValue: {
                          ac: null,
                        },
                      },
                    },
                  }, 'allocationMethodologySummary', 'sTotalValue')}
                >
                  {revertFootnoteState === 'sTotalValue' ? (
                    <>
                      Reverting to default note
                      &nbsp;
                      <div className="dots-circle-spinner" />
                    </>
                  ) : (
                    <>
                      Revert to default note
                      <RefreshOutlinedIcon />
                    </>
                  )}
                </Button>
              </div>
              <div className="value-and-ac">
                <p>
                  T = time to expiration
                  <span>
                    &nbsp;-&nbsp;
                    <i>
                      Not looking right? You can edit this from the Calculations tab
                    </i>
                  </span>
                </p>
                <div className="card-input">
                  <div className="info-card right">
                    {userData?.footnoteData?.allocationMethodologySummary?.opmDetails?.tTimeExpiration?.value}
                  </div>
                  <TextField
                    multiline
                    value={userData?.footnoteData?.allocationMethodologySummary?.opmDetails?.tTimeExpiration?.ac}
                    onChange={(e) => setUserData((prevState) => {
                      const newState = { ...prevState };
                      newState.footnoteData.allocationMethodologySummary.opmDetails.tTimeExpiration.ac = e.target.value;
                      return newState;
                    })}
                    onBlur={() => saveFootnotes({
                      allocationMethodologySummary: {
                        opmDetails: {
                          tTimeExpiration: {
                            ac: userData.footnoteData.allocationMethodologySummary.opmDetails.tTimeExpiration.ac,
                          },
                        },
                      },
                    })}
                    inputProps={{ maxLength: 900 }}
                  />
                </div>
                <Button
                  className="revert-btn"
                  onClick={() => revertFootnote({
                    allocationMethodologySummary: {
                      opmDetails: {
                        tTimeExpiration: {
                          ac: null,
                        },
                      },
                    },
                  }, 'allocationMethodologySummary', 'tTimeExpiration')}
                >
                  {revertFootnoteState === 'tTimeExpiration' ? (
                    <>
                      Reverting to default note
                      &nbsp;
                      <div className="dots-circle-spinner" />
                    </>
                  ) : (
                    <>
                      Revert to default note
                      <RefreshOutlinedIcon />
                    </>
                  )}
                </Button>
              </div>
              <div className="value-and-ac">
                <p>rf = Risk free rate</p>
                <div className="card-input">
                  <div className="info-card right">
                    {userData?.footnoteData?.allocationMethodologySummary?.opmDetails?.rfRiskFreeRate?.value}
                  </div>
                  <TextField
                    multiline
                    value={userData?.footnoteData?.allocationMethodologySummary?.opmDetails?.rfRiskFreeRate?.ac}
                    onChange={(e) => setUserData((prevState) => {
                      const newState = { ...prevState };
                      newState.footnoteData.allocationMethodologySummary.opmDetails.rfRiskFreeRate.ac = e.target.value;
                      return newState;
                    })}
                    onBlur={() => saveFootnotes({
                      allocationMethodologySummary: {
                        opmDetails: {
                          rfRiskFreeRate: {
                            ac: userData.footnoteData.allocationMethodologySummary.opmDetails.rfRiskFreeRate.ac,
                          },
                        },
                      },
                    }, 'allocationMethodologySummary')}
                    inputProps={{ maxLength: 900 }}
                  />
                </div>
                <Button
                  className="revert-btn"
                  onClick={() => revertFootnote({
                    allocationMethodologySummary: {
                      opmDetails: {
                        rfRiskFreeRate: {
                          ac: null,
                        },
                      },
                    },
                  }, 'allocationMethodologySummary', 'rfRiskFreeRate')}
                >
                  {revertFootnoteState === 'rfRiskFreeRate' ? (
                    <>
                      Reverting to default note
                      &nbsp;
                      <div className="dots-circle-spinner" />
                    </>
                  ) : (
                    <>
                      Revert to default note
                      <RefreshOutlinedIcon />
                    </>
                  )}
                </Button>
              </div>
              <div className="value-and-ac">
                <p>
                  sd = volatility
                  <span>
                    &nbsp;-&nbsp;
                    <i>
                      Not looking right? You can edit this from the Industry or Calculations tab
                    </i>
                  </span>
                </p>
                <div className="card-input">
                  <div className="info-card right">
                    {userData?.footnoteData?.allocationMethodologySummary?.opmDetails?.sdVolatility?.value}
                  </div>
                  <TextField
                    multiline
                    value={userData?.footnoteData?.allocationMethodologySummary?.opmDetails?.sdVolatility?.ac}
                    onChange={(e) => setUserData((prevState) => {
                      const newState = { ...prevState };
                      newState.footnoteData.allocationMethodologySummary.opmDetails.sdVolatility.ac = e.target.value;
                      return newState;
                    })}
                    onBlur={() => saveFootnotes({
                      allocationMethodologySummary: {
                        opmDetails: {
                          sdVolatility: {
                            ac: userData.footnoteData.allocationMethodologySummary.opmDetails.sdVolatility.ac,
                          },
                        },
                      },
                    }, 'allocationMethodologySummary')}
                    inputProps={{ maxLength: 900 }}
                  />
                </div>
                <Button
                  className="revert-btn"
                  onClick={() => revertFootnote({
                    allocationMethodologySummary: {
                      opmDetails: {
                        sdVolatility: {
                          ac: null,
                        },
                      },
                    },
                  }, 'allocationMethodologySummary', 'sdVolatility')}
                >
                  {revertFootnoteState === 'sdVolatility' ? (
                    <>
                      Reverting to default note
                      &nbsp;
                      <div className="dots-circle-spinner" />
                    </>
                  ) : (
                    <>
                      Revert to default note
                      <RefreshOutlinedIcon />
                    </>
                  )}
                </Button>
              </div>
              <div className="value-and-ac">
                <p>Based on</p>
                <div className="card-input">
                  <div className="info-card right">
                    {userData?.footnoteData?.allocationMethodologySummary?.opmDetails?.basedOn?.value}
                  </div>
                  <TextField
                    multiline
                    value={userData?.footnoteData?.allocationMethodologySummary?.opmDetails?.basedOn?.ac}
                    onChange={(e) => setUserData((prevState) => {
                      const newState = { ...prevState };
                      newState.footnoteData.allocationMethodologySummary.opmDetails.basedOn.ac = e.target.value;
                      return newState;
                    })}
                    onBlur={() => saveFootnotes({
                      allocationMethodologySummary: {
                        opmDetails: {
                          basedOn: {
                            ac: userData.footnoteData.allocationMethodologySummary.opmDetails.basedOn.ac,
                          },
                        },
                      },
                    })}
                    inputProps={{ maxLength: 900 }}
                  />
                </div>
                <Button
                  className="revert-btn"
                  onClick={() => revertFootnote({
                    allocationMethodologySummary: {
                      opmDetails: {
                        basedOn: {
                          ac: null,
                        },
                      },
                    },
                  }, 'allocationMethodologySummary', 'basedOn')}
                >
                  {revertFootnoteState === 'basedOn' ? (
                    <>
                      Reverting to default note
                      &nbsp;
                      <div className="dots-circle-spinner" />
                    </>
                  ) : (
                    <>
                      Revert to default note
                      <RefreshOutlinedIcon />
                    </>
                  )}
                </Button>
              </div>
              <div className="value-and-ac">
                <p>
                  Number of companies
                  <span>
                    &nbsp;-&nbsp;
                    <i>
                      Not looking right? You can edit this from the industry tab
                    </i>
                  </span>
                </p>
                <div className="card-input">
                  <div className="info-card right">
                    {userData?.footnoteData?.allocationMethodologySummary?.opmDetails?.numOfCompanies?.value}
                  </div>
                  <TextField
                    multiline
                    value={userData?.footnoteData?.allocationMethodologySummary?.opmDetails?.numOfCompanies?.ac}
                    onChange={(e) => setUserData((prevState) => {
                      const newState = { ...prevState };
                      newState.footnoteData.allocationMethodologySummary.opmDetails.numOfCompanies.ac = e.target.value;
                      return newState;
                    })}
                    onBlur={() => saveFootnotes({
                      allocationMethodologySummary: {
                        opmDetails: {
                          numOfCompanies: {
                            ac: userData.footnoteData.allocationMethodologySummary.opmDetails.numOfCompanies.ac,
                          },
                        },
                      },
                    })}
                    inputProps={{ maxLength: 900 }}
                  />
                </div>
                <Button
                  className="revert-btn"
                  onClick={() => revertFootnote({
                    allocationMethodologySummary: {
                      opmDetails: {
                        numOfCompanies: {
                          ac: null,
                        },
                      },
                    },
                  }, 'allocationMethodologySummary', 'numOfCompanies')}
                >
                  {revertFootnoteState === 'numOfCompanies' ? (
                    <>
                      Reverting to default note
                      &nbsp;
                      <div className="dots-circle-spinner" />
                    </>
                  ) : (
                    <>
                      Revert to default note
                      <RefreshOutlinedIcon />
                    </>
                  )}
                </Button>
              </div>
              <div className="value-and-ac">
                <p>
                  Primary industry
                  <span>
                    &nbsp;-&nbsp;
                    <i>
                      Not looking right? You can edit this from the General info or 820 inputs tab.
                    </i>
                  </span>
                </p>
                <div className="card-input">
                  <div className="info-card right">
                    {userData?.footnoteData?.allocationMethodologySummary?.opmDetails?.primaryIndustry?.value}
                  </div>
                  <TextField
                    multiline
                    value={userData?.footnoteData?.allocationMethodologySummary?.opmDetails?.primaryIndustry?.ac}
                    onChange={(e) => setUserData((prevState) => {
                      const newState = { ...prevState };
                      newState.footnoteData.allocationMethodologySummary.opmDetails.primaryIndustry.ac = e.target.value;
                      return newState;
                    })}
                    onBlur={() => saveFootnotes({
                      allocationMethodologySummary: {
                        opmDetails: {
                          primaryIndustry: {
                            ac: userData.footnoteData.allocationMethodologySummary.opmDetails.primaryIndustry.ac,
                          },
                        },
                      },
                    })}
                    inputProps={{ maxLength: 900 }}
                  />
                </div>
                <Button
                  className="revert-btn"
                  onClick={() => revertFootnote({
                    allocationMethodologySummary: {
                      opmDetails: {
                        primaryIndustry: {
                          ac: null,
                        },
                      },
                    },
                  }, 'allocationMethodologySummary', 'primaryIndustry')}
                >
                  {revertFootnoteState === 'primaryIndustry' ? (
                    <>
                      Reverting to default note
                      &nbsp;
                      <div className="dots-circle-spinner" />
                    </>
                  ) : (
                    <>
                      Revert to default note
                      <RefreshOutlinedIcon />
                    </>
                  )}
                </Button>
              </div>
              <div className="ac">
                <p>Volatility selection methodology - commentary</p>
                <div className="card-input">
                  <TextField
                    multiline
                    value={userData?.footnoteData?.allocationMethodologySummary?.opmDetails?.volatilitySelectionMethodology?.ac}
                    onChange={(e) => setUserData((prevState) => {
                      const newState = { ...prevState };
                      newState.footnoteData.allocationMethodologySummary.opmDetails.volatilitySelectionMethodology.ac = e.target.value;
                      return newState;
                    })}
                    onBlur={() => saveFootnotes({
                      allocationMethodologySummary: {
                        opmDetails: {
                          volatilitySelectionMethodology: {
                            ac: userData.footnoteData.allocationMethodologySummary.opmDetails.volatilitySelectionMethodology.ac,
                          },
                        },
                      },
                    }, 'allocationMethodologySummary')}
                    inputProps={{ maxLength: 900 }}
                  />
                </div>
                <Button
                  className="revert-btn"
                  onClick={() => revertFootnote({
                    allocationMethodologySummary: {
                      opmDetails: {
                        volatilitySelectionMethodology: {
                          ac: null,
                        },
                      },
                    },
                  }, 'allocationMethodologySummary', 'volatilitySelectionMethod')}
                >
                  {revertFootnoteState === 'volatilitySelectionMethod' ? (
                    <>
                      Reverting to default note
                      &nbsp;
                      <div className="dots-circle-spinner" />
                    </>
                  ) : (
                    <>
                      Revert to default note
                      <RefreshOutlinedIcon />
                    </>
                  )}
                </Button>
              </div>
              <div className="ac">
                <p>X = Exercise price (breakpoints)</p>
                <div className="card-input">
                  <TextField
                    multiline
                    value={userData?.footnoteData?.allocationMethodologySummary?.opmDetails?.xExercisePrice?.ac}
                    onChange={(e) => setUserData((prevState) => {
                      const newState = { ...prevState };
                      newState.footnoteData.allocationMethodologySummary.opmDetails.xExercisePrice.ac = e.target.value;
                      return newState;
                    })}
                    onBlur={() => saveFootnotes({
                      allocationMethodologySummary: {
                        opmDetails: {
                          xExercisePrice: {
                            ac: userData.footnoteData.allocationMethodologySummary.opmDetails.xExercisePrice.ac,
                          },
                        },
                      },
                    })}
                    inputProps={{ maxLength: 900 }}
                  />
                </div>
                <Button
                  className="revert-btn"
                  onClick={() => revertFootnote({
                    allocationMethodologySummary: {
                      opmDetails: {
                        xExercisePrice: {
                          ac: null,
                        },
                      },
                    },
                  }, 'allocationMethodologySummary', 'xExercisePrice')}
                >
                  {revertFootnoteState === 'xExercisePrice' ? (
                    <>
                      Reverting to default note
                      &nbsp;
                      <div className="dots-circle-spinner" />
                    </>
                  ) : (
                    <>
                      Revert to default note
                      <RefreshOutlinedIcon />
                    </>
                  )}
                </Button>
              </div>
            </div>
          </div>
          <hr />
          <div className="methodology-summary gpc">
            <h6>
              <TableChartOutlinedIcon />
              Exhibit: Value comparison
            </h6>
            <div className="gpc-criteria">
              <div>
                <p>Previous valuation - appraiser&apos;s commentary</p>
                <TextField
                  multiline
                  value={userData?.footnoteData?.prevValuation}
                  onChange={(e) => setUserData((prevState) => {
                    const newState = { ...prevState };
                    newState.footnoteData.prevValuation = e.target.value;
                    return newState;
                  })}
                  onBlur={() => saveFootnotes({ prevValuation: userData.footnoteData.prevValuation }, 'prevValuation')}
                  inputProps={{ maxLength: 900 }}
                  maxRows={4}
                />
                <div className="footnote-characters-left">
                  {`${userData?.footnoteData?.prevValuation ? userData?.footnoteData?.prevValuation?.length : 0}/900`}
                </div>
                <Button
                  className="revert-btn"
                  onClick={() => revertFootnote({ prevValuation: null }, 'prevValuation')}
                >
                  {revertFootnoteState === 'prevValuation' ? (
                    <>
                      Reverting to default note
                      &nbsp;
                      <div className="dots-circle-spinner" />
                    </>
                  ) : (
                    <>
                      Revert to default note
                      <RefreshOutlinedIcon />
                    </>
                  )}
                </Button>
              </div>
              <div>
                <p>Previous transaction - appraiser&apos;s commentary</p>
                <TextField
                  multiline
                  value={userData?.footnoteData?.prevTransaction}
                  onChange={(e) => setUserData((prevState) => {
                    const newState = { ...prevState };
                    newState.footnoteData.prevTransaction = e.target.value;
                    return newState;
                  })}
                  onBlur={() => saveFootnotes({ prevTransaction: userData.footnoteData.prevTransaction }, 'prevTransaction')}
                  inputProps={{ maxLength: 900 }}
                  maxRows={4}
                />
                <div className="footnote-characters-left">
                  {`${userData?.footnoteData?.prevTransaction ? userData?.footnoteData?.prevTransaction?.length : 0}/900`}
                </div>
                <Button
                  className="revert-btn"
                  onClick={() => revertFootnote({ prevTransaction: null }, 'prevTransaction')}
                >
                  {revertFootnoteState === 'prevTransaction' ? (
                    <>
                      Reverting to default note
                      &nbsp;
                      <div className="dots-circle-spinner" />
                    </>
                  ) : (
                    <>
                      Revert to default note
                      <RefreshOutlinedIcon />
                    </>
                  )}
                </Button>
              </div>
              <div>
                <p>Valuation date footnote</p>
                <TextField
                  multiline
                  value={userData?.footnoteData?.valuationDate}
                  onChange={(e) => setUserData((prevState) => {
                    const newState = { ...prevState };
                    newState.footnoteData.valuationDate = e.target.value;
                    return newState;
                  })}
                  onBlur={() => saveFootnotes({ valuationDate: userData.footnoteData.valuationDate }, 'valuationDate')}
                  inputProps={{ maxLength: 900 }}
                  maxRows={4}
                />
                <div className="footnote-characters-left">
                  {`${userData?.footnoteData?.valuationDate ? userData?.footnoteData?.valuationDate?.length : 0}/900`}
                </div>
                <Button
                  className="revert-btn"
                  onClick={() => revertFootnote({ valuationDate: null }, 'valuationDate')}
                >
                  {revertFootnoteState === 'valuationDate' ? (
                    <>
                      Reverting to default note
                      &nbsp;
                      <div className="dots-circle-spinner" />
                    </>
                  ) : (
                    <>
                      Revert to default note
                      <RefreshOutlinedIcon />
                    </>
                  )}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

EditNotes.propTypes = {
  userData: PropTypes.object.isRequired,
  setUserData: PropTypes.func.isRequired,
};
