import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import isEqual from 'lodash.isequal';

import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Dialog from '@mui/material/Dialog';

import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import CloseIcon from '@mui/icons-material/Close';
import TipsAndUpdatesOutlinedIcon from '@mui/icons-material/TipsAndUpdatesOutlined';
// import ContactPhoneOutlinedIcon from '@mui/icons-material/ContactPhoneOutlined';

import { ReactComponent as BlueCheckmark } from '../../images/icons/blue_checkmark.svg';
import { ReactComponent as GreenCheckmark } from '../../images/icons/green_checkmark.svg';

import Step1 from './Step1_BasicInfo';
import Step2 from './Step2_Financials';
import Step3 from './Step3_Funding';
import Step4 from './Step4_Uploads';

import { cleanSingleObject } from './utils';

import { copy } from '../../utils';

import { getUserId } from '../../utils/auth';

import useFetch from '../../hooks/useFetch';

import './IntakeBlock.scss';

export default function IntakeBlock({
  companiesData,
  setCompaniesData,
  transactionsData,
  setTransactionsData,
  enterpriseCompanyData,
  dropdownHeights,
  setDropdownHeights,
  setSaveTransactionData,
  index,
}) {
  const [activeStep, setActiveStep] = useState(0);
  const [stepsVisited, setStepsVisited] = useState([]);
  const [newActiveStep, setNewActiveStep] = useState(-1);

  const [confirmSubmitInfo, setConfirmSubmitInfo] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [userHasAccess, setUserHasAccess] = useState(true);
  const [submittingTransaction, setSubmittingTransaction] = useState(false);

  const [blockHeight, setBlockHeight] = useState(650);

  const intakeBlockRef = useRef(null);
  const boxScrollRef = useRef(null);
  const firstStepRef = useRef(null);
  const secondStepRef = useRef(null);
  const thirdStepRef = useRef(null);
  const fourthStepRef = useRef(null);

  function handleIntakeChange(dataName, dataValue) {
    if (dataName === 'userHasAccess' && dataValue === '0') setUserHasAccess(false);
    else {
      setNewActiveStep(1);
      setUserHasAccess(true);
    }
    const newData = copy(transactionsData);
    newData[index] = { ...newData[index], [dataName]: parseInt(dataValue, 10) };
    setTransactionsData(newData);
    if (!isEqual(transactionsData, newData)) setSaveTransactionData(index);
  }

  function determineStatus(stepIndex, blockIndex) {
    let status = 'not-started';
    if (activeStep === stepIndex && transactionsData[blockIndex]?.userHasAccess) status = 'is-next';
    else if (stepsVisited.includes(stepIndex)) status = 'has-started';
    return status;
  }

  const [, submitASC820Request] = useFetch();

  async function submitTransaction(blockIndex, companyIndex) {
    setSubmittingTransaction(true);
    let transactionData = {
      ...transactionsData[blockIndex],
      insertUserId: getUserId(),
      userHasAccess: parseInt(transactionsData[blockIndex].userHasAccess, 10),
      version: parseInt(transactionsData[blockIndex].version, 10),
      basicInfo: {
        ...transactionsData[index].basicInfo,
        transactionDate: transactionsData[index].fundingInfo?.latestRoundDate,
      },
    };
    delete transactionData.companyIndex;
    delete transactionData.companyName;
    delete transactionData.lastModified;
    transactionData = cleanSingleObject(transactionData);
    submitASC820Request({
      url: '/transactions/asc820/submit-asc820-info',
      method: 'post',
      body: transactionData,
      bodyIds: ['requestUserId'],
      onFinally: () => {
        const newCompaniesData = copy(companiesData);
        newCompaniesData[blockIndex].submittedByUser = '1';
        setCompaniesData(newCompaniesData);
        setConfirmSubmitInfo(false);
        setSubmitSuccess(true);
        setUserHasAccess(true);
        const newDropdownHeights = copy(dropdownHeights);
        if (newDropdownHeights.includes(companyIndex)) {
          newDropdownHeights.splice(newDropdownHeights.indexOf(companyIndex), 1);
        }
        else newDropdownHeights.push(companyIndex);
        setDropdownHeights(newDropdownHeights);
        setSubmittingTransaction(false);
      },
    });
  }

  const steps = ['Has Permission', 'Basic information', 'Financials', 'Funding & secondary transactions', 'Document upload'];

  useEffect(() => {
    if (parseInt(transactionsData[index]?.userHasAccess, 10) === 0) setUserHasAccess(false);
    if (transactionsData[index]?.userHasAccess === null) {
      const transactionCopy = copy(transactionsData[index]);
      transactionCopy.userHasAccess = 1;
      setTransactionsData([...transactionsData.slice(0, index), transactionCopy, ...transactionsData.slice(index + 1)]);
    }
  }, [transactionsData, companiesData]);

  useEffect(() => {
    if (newActiveStep >= 0) {
      if (newActiveStep === 1) boxScrollRef.current.scrollTo({ top: firstStepRef.current.offsetTop - 50, behavior: 'smooth' });
      else if (newActiveStep === 2) boxScrollRef.current.scrollTo({ top: secondStepRef.current.offsetTop - 50, behavior: 'smooth' });
      else if (newActiveStep === 3) boxScrollRef.current.scrollTo({ top: thirdStepRef.current.offsetTop - 50, behavior: 'smooth' });
      else if (newActiveStep === 4) boxScrollRef.current.scrollTo({ top: fourthStepRef.current.offsetTop - 50, behavior: 'smooth' });
      setActiveStep(newActiveStep);
      setNewActiveStep(-1);
    }
  }, [newActiveStep]);

  useEffect(() => {
    if (!stepsVisited.includes(activeStep)) {
      const newStepsVisitedArray = copy(stepsVisited);
      newStepsVisitedArray.push(activeStep);
      setStepsVisited(newStepsVisitedArray);
    }
  }, [activeStep]);

  const resizeHandler = (e) => {
    const y = e.clientY;
    const sbHeight = window.getComputedStyle(intakeBlockRef.current).height;
    const initialHeight = parseInt(sbHeight, 10);

    const mouseMoveHandler = (moveEvent) => {
      const dy = moveEvent.clientY - y;
      const newHeight = initialHeight + dy;

      if (newHeight >= 650) setBlockHeight(`${newHeight}px`);
    };

    const mouseUpHandler = () => {
      document.removeEventListener('mouseup', mouseUpHandler);
      document.removeEventListener('mousemove', mouseMoveHandler);
    };

    document.addEventListener('mousemove', mouseMoveHandler);
    document.addEventListener('mouseup', mouseUpHandler);
  };

  return (
    <div className="IntakeBlock" role="button" onClick={(e) => { e.stopPropagation(); }} onKeyDown={() => { }} tabIndex={-1} ref={intakeBlockRef}>
      {companiesData[index].submittedByUser === '1' && (
        <div className="submitted-data-tooltip">
          <BlueCheckmark />
          <p>
            You&apos;ve successfully submitted
            {` ${companiesData[index].companyName}'s `}
            information. While you can&apos;t edit it, you can still upload documents for
            {` ${companiesData[index].companyName}.`}
            <br />
            <Button onClick={() => setNewActiveStep(3)}>Jump to the &apos;Document upload&apos; section</Button>
          </p>
        </div>
      )}
      <div className="intake-block-content-wrapper">
        <div className="left-stepper-container" style={{ height: blockHeight }}>
          <Stepper activeStep={activeStep} orientation="vertical">
            {steps.map((stepName, i) => {
              if (i !== 0) {
                const status = determineStatus(i, index);
                return (
                  <Step
                    key={stepName}
                    disabled={false}
                    className="individual-step"
                  >
                    <StepLabel onClick={() => setNewActiveStep(i)} className={status} StepIconComponent={() => i}>
                      {stepName}
                      {status === 'completed' && companiesData[index].submittedByUser !== '1' && (
                        <IconButton onClick={() => setNewActiveStep(i)}>
                          <EditOutlinedIcon />
                        </IconButton>
                      )}
                    </StepLabel>
                  </Step>
                );
              }
              return null;
            })}
          </Stepper>
          <Button
            className="left-submit-btn"
            disabled={companiesData[index].submittedByUser === '1'}
            onClick={() => setConfirmSubmitInfo(true)}
          >
            {companiesData[index].submittedByUser === '1' ? 'Submitted' : 'Submit company info'}
          </Button>
        </div>
        <div className="intake-blocks" ref={boxScrollRef} style={{ height: blockHeight }}>
          <div className={`intake-block ${activeStep === 0 ? ' active-step' : ''}`}>
            <h4>Before you get started</h4>
            <p>First, a quick question to help us determine the optimal way to move this company&apos;s valuation forward.</p>
            <p>{`Do you have information rights with ${companiesData[index]?.companyName}?`}</p>
            <RadioGroup
              row
              value={userHasAccess ? 1 : 0}
              onChange={(e) => handleIntakeChange('userHasAccess', e.target.value)}
            >
              <FormControlLabel disabled={companiesData[index].submittedByUser === '1'} value={1} control={<Radio />} label="Yes" />
              <FormControlLabel disabled={companiesData[index].submittedByUser === '1'} value={0} control={<Radio />} label="No" />
            </RadioGroup>
            {userHasAccess === false && (
              <>
                <div className="no-info-right-tooltip">
                  <TipsAndUpdatesOutlinedIcon />
                  <p>
                    It&apos;s okay if you don&apos;t. Go ahead and provide what you can and then click submit.
                    If you don&apos;t provide enough information
                    we&apos;ll contact you so we can discuss any potential costs and/or delays related to
                    {` ${companiesData[index]?.companyName} `}
                    collecting information and documents on your behalf.
                  </p>
                </div>
                {/* TODO Add back when contact information is defined */}
                {/* <div className="contact-us">
                  <h4>
                    <ContactPhoneOutlinedIcon />
                    Want to contact us?
                  </h4>
                  <p>If you&apos;d like to talk sooner, here&apos;s our contact information:</p>
                  <p className="contact-info">Phone: (415) 472-9175</p>
                  <p>If we aren&apos;t available when you call, we&apos;ll call you back by the next business day.</p>
                </div> */}
              </>
            )}
          </div>
          <div className={`intake-block${activeStep === 1 ? ' active-step' : ''}`} ref={firstStepRef}>
            <Step1
              transactionsData={transactionsData}
              setTransactionsData={setTransactionsData}
              setActiveStep={setActiveStep}
              setSaveTransactionData={setSaveTransactionData}
              disableFields={companiesData[index].submittedByUser === '1' || companiesData[index].isLocked === '1'}
              index={index}
            />
          </div>
          <div className={`intake-block${activeStep === 2 ? ' active-step' : ''}`} ref={secondStepRef}>
            <Step2
              transactionsData={transactionsData}
              setTransactionsData={setTransactionsData}
              setActiveStep={setActiveStep}
              setSaveTransactionData={setSaveTransactionData}
              disableFields={companiesData[index].submittedByUser === '1' || companiesData[index].isLocked === '1'}
              index={index}
            />
          </div>
          <div className={`intake-block ${activeStep === 3 ? ' active-step' : ''}`} ref={thirdStepRef}>
            <Step3
              transactionsData={transactionsData}
              setTransactionsData={setTransactionsData}
              setActiveStep={setActiveStep}
              setSaveTransactionData={setSaveTransactionData}
              disableFields={companiesData[index].submittedByUser === '1' || companiesData[index].isLocked === '1'}
              index={index}
            />
          </div>
          <div className={`intake-block ${activeStep === 4 ? ' active-step' : ''}`} ref={thirdStepRef}>
            <Step4
              transactionsData={transactionsData}
              setTransactionsData={setTransactionsData}
              setActiveStep={setActiveStep}
              setSaveTransactionData={setSaveTransactionData}
              disableFields={companiesData[index].submittedByUser === '1' || companiesData[index].isLocked === '1'}
              index={index}
            />
          </div>
          <Button
            className="submit-btn"
            disabled={companiesData[index].submittedByUser === '1'}
            onClick={() => setConfirmSubmitInfo(true) && setUserHasAccess(false)}
          >
            {companiesData[index].submittedByUser === '1' ? 'Submitted' : 'Submit company info'}
          </Button>
        </div>
        <button
          type="button"
          aria-label="resize"
          className="resizer-btn"
          onMouseDown={resizeHandler}
        />
      </div>
      <Dialog
        open={confirmSubmitInfo}
        className="confirm-submit-dialog"
        disableScrollLock
      >
        <div className="box-header">
          <WarningAmberOutlinedIcon />
          <h4>{`Submit ${companiesData[index]?.companyName}'s info now?`}</h4>
        </div>
        <p>When you click &apos;Submit&apos;:</p>
        <ul>
          <li>
            You acknowledge all information and documents in this submission form,
            whether provided by you or Redwood Valuation,
            is accurate and is the most current that&apos;s available.
          </li>
          <li>
            All input fields will be locked, you will not be able to make edits, you will only be able to upload additional documents.
          </li>
          <li>
            {`${enterpriseCompanyData.companyName} `}
            will reach out to you for any missing information.
          </li>
        </ul>
        <div className="box-buttons">
          <Button
            className="cancel-btn"
            onClick={() => {
              setConfirmSubmitInfo(false);
              setUserHasAccess(true);
            }}
          >
            Cancel
          </Button>
          <Button
            className="confirm-btn"
            onClick={() => submitTransaction(index, companiesData[index].companyIndex)}
          >
            {submittingTransaction ? (
              <>
                <div className="dots-circle-spinner" />
                Submitting info...
              </>
            ) : 'Submit'}
          </Button>
        </div>
      </Dialog>
      <Dialog
        open={submitSuccess}
        className="submit-success-dialog"
        disableScrollLock
      >
        <IconButton
          className="close-icon"
          onClick={() => setSubmitSuccess(false)}
        >
          <CloseIcon />
        </IconButton>
        <div className="box-header submit-success">
          <GreenCheckmark />
          <h4>Successfully submitted</h4>
        </div>
        <p>
          If
          {` ${enterpriseCompanyData.companyName} `}
          needs more information to complete this ASC 820 valuation they will contact you shortly to discuss next-steps.
        </p>
      </Dialog>
    </div>
  );
}

IntakeBlock.propTypes = {
  companiesData: PropTypes.array.isRequired,
  setCompaniesData: PropTypes.func.isRequired,
  transactionsData: PropTypes.array.isRequired,
  setTransactionsData: PropTypes.func.isRequired,
  enterpriseCompanyData: PropTypes.object.isRequired,
  dropdownHeights: PropTypes.array.isRequired,
  setDropdownHeights: PropTypes.func.isRequired,
  setSaveTransactionData: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
};
