import cloneDeep from 'lodash.clonedeep';
import { numericFormatter } from 'react-number-format';

export const copy = (obj) => cloneDeep(obj);

// anystring@anystring.anystring
export const isAValidEmail = (str) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(str);
// check for valid (xxx) xxx-xxxx phone US phone number format
export const isAValidUSPhoneNumber = (str) => /^\(?([0-9]{3})\)?[ ]?([0-9]{3})[-]?([0-9]{4})$/.test(str);

export function checkForEmailErrors(email) {
  let errorStatus = true;
  let errorMessage = '';
  if ((!email.includes('@') || email.split('@').length > 2)) errorMessage = 'An email address must contain a single @';
  else if (!email.split('@')[1].includes('.')) errorMessage = 'The domain portion of the email address is invalid (the portion after @)';
  else if (!isAValidEmail(email)) errorMessage = 'Invalid email entered...';
  else errorStatus = false;
  return { errorStatus, errorMessage };
}

export function formatPhoneNumber(value) {
  // allow only digit values
  const phoneNumber = value.replace(/[^\d]/g, '');

  // return the value with no formatting if it is less than four digits
  // avoid confusing behavior when functions formats the area code too early
  if (phoneNumber.length < 4) return phoneNumber;

  // if phone number length is greater than 4 and less the 7 return first formatting
  if (phoneNumber.length < 7) return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;

  // finally, if the phone number length is greater then 7, add the rest of the formatting
  return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
}

export function timeOfDayGreeting() {
  const today = new Date();
  const curHr = today.getHours();

  if (curHr < 12) return 'morning';
  if (curHr < 18) return 'afternoon';

  return 'evening';
}

export function truncateStr(string = '', maxLength = 50) {
  if (string.length > maxLength) {
    return `${string.substring(0, maxLength)}…`;
  }
  return string;
}

export const onlyNums = (str) => str.replace(/[^\d.]/g, '');

export const onlyDates = (str) => str.replace(/[^\d./-]/g, '');

export const commaEvery3rdChar = (val) => numericFormatter((val || '').toString().replace(/[^\d.-]/g, ''), { thousandSeparator: true });

export const removeCommas = (str) => str.replaceAll(',', '');

export const stripDenomination = (str) => str.replace(/[$,]/g, '');

export function getInitials(name) {
  if (!name) return name;
  const splitName = name.split(' ');
  const firstInitial = Array.from(splitName[0])[0];
  const lastInitial = splitName[1] ? Array.from(splitName[1])[0] : '';
  return firstInitial + lastInitial;
}

export const concatCharacter = (input, char) => (input && (!input.toString().includes(char) ? input.toString() + char : input));

export const formatSeriesName = (seriesName) => seriesName.includes(' @ $') ? `${seriesName.split(' @ $')[0]}` +
  ` @ $${new Intl.NumberFormat('en-US', { minimumFractionDigits: 2 })
    .format((seriesName.split(' @ $')[seriesName.split(' @ $').length - 1]))}` : seriesName;

// limit the number of characters and numerical value of a given string
export function maxCharsAndValue(str, charLimit, valLimit) {
  let newStr = str;
  if (parseInt(newStr, 10) > valLimit) newStr = valLimit.toString();
  if (newStr.length > charLimit) newStr.substring(0, charLimit);
  return newStr;
}

export const formatUSCurrency = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumSignificantDigits: 2 });

export const formatUSCurrencyWithoutDollarSign = new Intl.NumberFormat('en-US', {
  style: 'decimal',
  maximumFractionDigits: 2,
  minimumFractionDigits: 2,
});

export const formatUSCurrencyWithTwoDecimals = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  maximumFractionDigits: 2,
  minimumFractionDigits: 2,
});

export const formatUSCurrencyWithFourDecimals = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  maximumFractionDigits: 4,
  minimumFractionDigits: 4,
});

export const formatUSCurrencyWithSixDecimals = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  maximumFractionDigits: 6,
  minimumFractionDigits: 6,
});

export function toTitleCase(str) {
  return str
    .toLowerCase() // Convert the whole string to lowercase
    .replace(/(^|\s)\w/g, (match) => match.toUpperCase()); // Capitalize the first letter of each word
}

export function toCamelCase(inputString) {
  return inputString
    .toLowerCase()
    .replace(/[^a-zA-Z0-9]+(.)/g, (_, chr) => chr.toUpperCase());
}

export default {
  copy,
  isAValidEmail,
  isAValidUSPhoneNumber,
  checkForEmailErrors,
  formatPhoneNumber,
  timeOfDayGreeting,
  onlyNums,
  onlyDates,
  commaEvery3rdChar,
  formatSeriesName,
  removeCommas,
  stripDenomination,
  getInitials,
  concatCharacter,
  maxCharsAndValue,
  formatUSCurrency,
  formatUSCurrencyWithTwoDecimals,
  formatUSCurrencyWithFourDecimals,
  toTitleCase,
  toCamelCase,
  truncateStr,
};
