import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import isEqual from 'lodash.isequal';

import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import MenuItem from '@mui/material/MenuItem';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { ReactComponent as BlueCalendar } from '../../images/icons/blue_calendar.svg';

import { copy } from '../../utils';

import { industryVerticals } from '../../utils/globals';

export default function Step1({
  transactionsData,
  setTransactionsData,
  index,
  setActiveStep,
  setSaveTransactionData,
  disableFields,
}) {
  const [searchIndustryInput, setSearchIndustryInput] = useState('');

  function handleIntakeChange(dataName, dataValue) {
    setActiveStep(1);
    const newData = copy(transactionsData);
    newData[index].basicInfo = { ...newData[index].basicInfo, [dataName]: dataValue };
    setTransactionsData(newData);
    if (!isEqual(transactionsData, newData)) setSaveTransactionData(index);
  }

  useEffect(() => setSearchIndustryInput(transactionsData[index]?.basicInfo?.companyIndustry?.[0] || ''), [transactionsData]);

  return (
    <>
      <h4>Basic information</h4>
      <p className="block-header-text">
        We understand that you may not have all of this information, so all fields are optional.
        The more you provide the quicker we can get started on this valuation.
      </p>
      <div className="form-grid">
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DatePicker
            label="Valuation Date"
            inputFormat="MM/DD/YYYY"
            value={transactionsData[index]?.basicInfo?.valuationDate ? moment(transactionsData[index]?.basicInfo?.valuationDate, 'YYYY-MM-DD') : null}
            onOpen={() => setActiveStep(1)}
            onChange={(val) => { handleIntakeChange('valuationDate', val ? val.format('YYYY-MM-DD') : ''); }}
            slotProps={{ textField: { variant: 'outlined', helperText: 'MM/DD/YYYY' } }}
            slots={{ openPickerIcon: BlueCalendar }}
            disabled={disableFields}
          />
        </LocalizationProvider>
        <Autocomplete
          disablePortal
          freeSolo
          onOpen={() => setActiveStep(1)}
          options={industryVerticals}
          groupBy={(option) => option.category}
          getOptionLabel={(option) => option.title}
          inputValue={searchIndustryInput}
          disabled={disableFields}
          onInputChange={(e, value) => {
            const newIndustryArr = [...transactionsData[index]?.basicInfo?.companyIndustry || []];
            if (industryVerticals.find((val) => val.title === value)) {
              newIndustryArr[0] = value;
              handleIntakeChange('companyIndustry', newIndustryArr);
              setSearchIndustryInput(value);
            } else if (!value) {
              newIndustryArr[0] = '';
              handleIntakeChange('companyIndustry', newIndustryArr);
              setSearchIndustryInput('');
            } else {
              setSearchIndustryInput(value);
            }
          }}
          renderInput={(params) => <TextField {...params} label="Company industry" helperText=" " />}
          onKeyDown={(e) => { e.stopPropagation(); }}
        />
        <TextField
          select
          label="Most likely type of exit event"
          value={transactionsData[index]?.basicInfo?.typeOfExit === 'Merger and acquisition MA' ?
            'Merger and acquisition (M&A)' :
            transactionsData[index]?.basicInfo?.typeOfExit === 'Initial public offering IPO' ?
              'Initial public offering (IPO)' :
              transactionsData[index]?.basicInfo?.typeOfExit === 'Dont know' ?
                'Don\'t know' : transactionsData[index]?.basicInfo?.typeOfExit ||
                ''}
          onChange={(e) => { handleIntakeChange('typeOfExit', e.target.value); }}
          onFocus={() => setActiveStep(1)}
          SelectProps={{ MenuProps: { disableScrollLock: true, classes: { paper: 'select-dropdown' } } }}
          onKeyDown={(e) => { e.stopPropagation(); }}
          disabled={disableFields}
          helperText=" "
        >
          {[
            'Merger and acquisition (M&A)',
            'Initial public offering (IPO)',
            'Don\'t know',
            'Other',
          ].map((menuItem) => (
            <MenuItem key={menuItem} value={menuItem}>
              {menuItem}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          select
          label="When is exit event most likely to occur"
          value={transactionsData[index]?.basicInfo?.exitTiming || ''}
          onChange={(e) => { handleIntakeChange('exitTiming', e.target.value); }}
          onFocus={() => setActiveStep(1)}
          SelectProps={{ MenuProps: { disableScrollLock: true, classes: { paper: 'select-dropdown' } } }}
          onKeyDown={(e) => { e.stopPropagation(); }}
          disabled={disableFields}
          helperText="Estimate in years from valuation date"
        >
          {[
            'Less than 1 year',
            '1 - 2 year(s)',
            '2 - 3 years',
            '3 - 4 years',
            '4 - 5 years',
            '5 years or more',
          ].map((menuItem) => (
            <MenuItem key={menuItem} value={menuItem}>
              {menuItem}
            </MenuItem>
          ))}
        </TextField>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DatePicker
            label="Capitalization table, date last updated"
            inputFormat="MM/DD/YYYY"
            value={transactionsData[index]?.basicInfo?.capTableDate ?
              moment(transactionsData[index]?.basicInfo?.capTableDate, 'YYYY-MM-DD') : null}
            onChange={(val) => { handleIntakeChange('capTableDate', val ? val.format('YYYY-MM-DD') : ''); }}
            onOpen={() => setActiveStep(1)}
            slotProps={{ textField: { variant: 'outlined', helperText: 'MM/DD/YYYY' } }}
            slots={{ openPickerIcon: BlueCalendar }}
            disabled={disableFields}
          />
        </LocalizationProvider>
        <TextField
          select
          label="Capitalization table provider"
          value={transactionsData[index]?.basicInfo?.capTableProvider || ''}
          onChange={(e) => { handleIntakeChange('capTableProvider', e.target.value); }}
          onFocus={() => setActiveStep(1)}
          SelectProps={{ MenuProps: { disableScrollLock: true, classes: { paper: 'select-dropdown' } } }}
          onKeyDown={(e) => { e.stopPropagation(); }}
          disabled={disableFields}
        >
          {[
            'Carta',
            'Pulley',
            'Shareworks',
            'Other',
          ].map((menuItem) => (
            <MenuItem key={menuItem} value={menuItem}>
              {menuItem}
            </MenuItem>
          ))}
        </TextField>
      </div>
    </>
  );
}

Step1.propTypes = {
  transactionsData: PropTypes.array.isRequired,
  setTransactionsData: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  setActiveStep: PropTypes.func.isRequired,
  setSaveTransactionData: PropTypes.func.isRequired,
  disableFields: PropTypes.bool.isRequired,
};
