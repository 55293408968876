import React from 'react';
import PropTypes from 'prop-types';
import {
  Page,
  View,
  Text,
} from '@react-pdf/renderer';

import TablePageHeader from '../../components/TablePageHeader';
import BottomPagination from '../../components/BottomPagination';
import DraftOverlay from '../../components/DraftOverlay';


export default function ValSumBacksolve({ reportData, totalPageCount, exhibitNumber }) {
  const commentaryData = reportData.footnoteData.valuationMethodologySummary.backsolve;
  return (
    <Page
      size="TABLOID"
      orientation="landscape"
      style={{ position: 'relative', backgroundColor: '#EAEAEB' }}
    >
      <TablePageHeader
        companyName={reportData.companyInfo.companyName}
        pageName={`Valuation methodology summary - Backsolve   |   Exhibit ${exhibitNumber}`}
      />
      <View
        style={{
          display: 'flex',
          flexDirection: 'row',
        }}
      >
        <View
          style={{
            width: '548px',
            height: '580px',
            marginTop: '84px',
            marginLeft: '32px',
            padding: '24px',
            backgroundColor: '#FAFAFD',
          }}
        >
          <Text
            style={{
              fontFamily: 'ManropeSemi',
              fontSize: '16px',
              color: '#49454F',
              letterSpacing: '0.08px',
            }}
          >
            Market approach - Backsolve method
          </Text>
          <View
            style={{
              marginTop: '16px',
              width: '500px',
              borderBottom: '1px',
              borderColor: '#CCCCCC',
            }}
          />
          <Text
            style={{
              fontFamily: 'RobotoMed',
              fontSize: '14px',
              color: '#49454F',
              marginTop: '16px',
              marginBottom: '16px',
            }}
          >
            General transaction details with appraisers commentary
          </Text>
          <View style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'flex-start',
            marginBottom: '16px',
          }}
          >
            <Text style={{
              width: '12px',
              fontSize: '12px',
              color: '#49454F',
            }}
            >
              {'\u2022 '}
            </Text>
            <View>
              <Text
                style={{
                  fontFamily: 'RobotoMed',
                  color: '#49454F',
                  fontSize: '12px',
                  lineHeight: '0.55mm',
                  letterSpacing: '0.25px',
                }}
              >
                Most recent round:&nbsp;
                {commentaryData.genTransactionDetails.mostRecentRound.round}
              </Text>
              <Text
                style={{
                  fontFamily: 'Roboto',
                  color: '#49454F',
                  fontSize: '12px',
                  lineHeight: '0.55mm',
                  letterSpacing: '0.25px',
                  marginTop: '4px',
                }}
              >
                {commentaryData.genTransactionDetails.mostRecentRound.commentary}
              </Text>
            </View>
          </View>
          <View style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'flex-start',
            marginBottom: '16px',
          }}
          >
            <Text style={{
              width: '12px',
              fontSize: '12px',
              color: '#49454F',
            }}
            >
              {'\u2022 '}
            </Text>
            <View>
              <Text
                style={{
                  fontFamily: 'RobotoMed',
                  color: '#49454F',
                  fontSize: '12px',
                  lineHeight: '0.55mm',
                  letterSpacing: '0.25px',
                }}
              >
                Transaction date
              </Text>
              <Text
                style={{
                  fontFamily: 'Roboto',
                  color: '#49454F',
                  fontSize: '12px',
                  lineHeight: '0.55mm',
                  letterSpacing: '0.25px',
                  marginTop: '4px',
                }}
              >
                {commentaryData.genTransactionDetails.tranDateAC}
              </Text>
            </View>
          </View>
          <View style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'flex-start',
            marginBottom: '16px',
          }}
          >
            <Text style={{
              width: '12px',
              fontSize: '12px',
              color: '#49454F',
            }}
            >
              {'\u2022 '}
            </Text>
            <View>
              <Text
                style={{
                  fontFamily: 'RobotoMed',
                  color: '#49454F',
                  fontSize: '12px',
                  lineHeight: '0.55mm',
                  letterSpacing: '0.25px',
                }}
              >
                Nature of&nbsp;
                {commentaryData.genTransactionDetails.mostRecentRound.round}
                &nbsp;transaction
              </Text>
              <Text
                style={{
                  fontFamily: 'Roboto',
                  color: '#49454F',
                  fontSize: '12px',
                  lineHeight: '0.55mm',
                  letterSpacing: '0.25px',
                  marginTop: '4px',
                }}
              >
                {commentaryData.genTransactionDetails.natureOfTranAC}
              </Text>
            </View>
          </View>
        </View>
        <View
          style={{
            width: '548px',
            height: '580px',
            marginTop: '84px',
            marginLeft: '64px',
            padding: '24px',
            backgroundColor: '#FAFAFD',
          }}
        >
          <Text
            style={{
              fontFamily: 'RobotoMed',
              fontSize: '14px',
              color: '#49454F',
              marginBottom: '16px',
            }}
          >
            Backsolve methodology inputs
          </Text>
          {commentaryData.backsolveKeyInsights.sImpliedEquity && (
            <View style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'flex-start',
              marginBottom: '8px',
            }}
            >
              <Text style={{
                width: '12px',
                fontSize: '12px',
                color: '#49454F',
              }}
              >
                {'\u2022 '}
              </Text>
              <View>
                <Text
                  style={{
                    fontFamily: 'RobotoMed',
                    color: '#49454F',
                    fontSize: '12px',
                    lineHeight: '0.55mm',
                    letterSpacing: '0.25px',
                  }}
                >
                  S = Implied value of equity:&nbsp;
                  {commentaryData.backsolveKeyInsights.sImpliedEquity.value}
                </Text>
                <Text
                  style={{
                    fontFamily: 'Roboto',
                    color: '#49454F',
                    fontSize: '12px',
                    lineHeight: '0.55mm',
                    letterSpacing: '0.25px',
                    marginTop: '4px',
                  }}
                >
                  {commentaryData.backsolveKeyInsights.sImpliedEquity.ac}
                </Text>
              </View>
            </View>
          )}
          {commentaryData.backsolveKeyInsights.tTimeExpiration && (
            <View style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'flex-start',
              marginBottom: '8px',
            }}
            >
              <Text style={{
                width: '12px',
                fontSize: '12px',
                color: '#49454F',
              }}
              >
                {'\u2022 '}
              </Text>
              <View>
                <Text
                  style={{
                    fontFamily: 'RobotoMed',
                    color: '#49454F',
                    fontSize: '12px',
                    lineHeight: '0.55mm',
                    letterSpacing: '0.25px',
                  }}
                >
                  {`t = Time to expiration: ${commentaryData.backsolveKeyInsights.tTimeExpiration.value}` +
                    `${parseFloat(commentaryData.backsolveKeyInsights.tTimeExpiration.value) > 1 ? ' years' : ' year'}`}
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <Text style={{ fontFamily: 'Roboto' }}>
                    {commentaryData.backsolveKeyInsights.tTimeExpiration.ac}
                  </Text>
                </Text>
              </View>
            </View>
          )}
          {commentaryData.backsolveKeyInsights.rfRiskFreeRate && (
            <View style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'flex-start',
              marginBottom: '8px',
            }}
            >
              <Text style={{
                width: '12px',
                fontSize: '12px',
                color: '#49454F',
              }}
              >
                {'\u2022 '}
              </Text>
              <View>
                <Text
                  style={{
                    fontFamily: 'RobotoMed',
                    color: '#49454F',
                    fontSize: '12px',
                    lineHeight: '0.55mm',
                    letterSpacing: '0.25px',
                  }}
                >
                  rf = Risk-free rate:&nbsp;
                  {commentaryData.backsolveKeyInsights.rfRiskFreeRate.value}
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <Text style={{ fontFamily: 'Roboto' }}>
                    {commentaryData.backsolveKeyInsights.rfRiskFreeRate.ac}
                  </Text>
                </Text>
              </View>
            </View>
          )}
          {commentaryData.backsolveKeyInsights.sdVolatility && (
            <View style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'flex-start',
              marginBottom: '8px',
            }}
            >
              <Text style={{
                width: '12px',
                fontSize: '12px',
                color: '#49454F',
              }}
              >
                {'\u2022 '}
              </Text>
              <View>
                <Text
                  style={{
                    fontFamily: 'RobotoMed',
                    color: '#49454F',
                    fontSize: '12px',
                    lineHeight: '0.55mm',
                    letterSpacing: '0.25px',
                  }}
                >
                  sd = Volatility:&nbsp;
                  {commentaryData.backsolveKeyInsights.sdVolatility.value}
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <Text style={{ fontFamily: 'Roboto' }}>
                    {commentaryData.backsolveKeyInsights.sdVolatility.ac}
                  </Text>
                </Text>
              </View>
            </View>
          )}
          {commentaryData.backsolveKeyInsights.basedOnIndustry && (
            <View style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'flex-start',
              marginBottom: '8px',
            }}
            >
              <Text style={{
                width: '12px',
                fontSize: '12px',
                color: '#49454F',
              }}
              >
                {'\u2022 '}
              </Text>
              <View>
                <Text
                  style={{
                    fontFamily: 'RobotoMed',
                    color: '#49454F',
                    fontSize: '12px',
                    lineHeight: '0.55mm',
                    letterSpacing: '0.25px',
                  }}
                >
                  Based on&nbsp;
                  {commentaryData.backsolveKeyInsights.basedOnIndustry.value.toLowerCase()}
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <Text style={{ fontFamily: 'Roboto' }}>
                    {commentaryData.backsolveKeyInsights.basedOnIndustry.ac}
                  </Text>
                </Text>
              </View>
            </View>
          )}
          {commentaryData.backsolveKeyInsights.numOfCompanies && (
            <View style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'flex-start',
              marginBottom: '8px',
            }}
            >
              <Text style={{
                width: '12px',
                fontSize: '12px',
                color: '#49454F',
              }}
              >
                {'\u2022 '}
              </Text>
              <View>
                <Text
                  style={{
                    fontFamily: 'RobotoMed',
                    color: '#49454F',
                    fontSize: '12px',
                    lineHeight: '0.55mm',
                    letterSpacing: '0.25px',
                  }}
                >
                  Number of companies:&nbsp;
                  {commentaryData.backsolveKeyInsights.numOfCompanies.value}
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <Text style={{ fontFamily: 'Roboto' }}>
                    {commentaryData.backsolveKeyInsights.numOfCompanies.ac}
                  </Text>
                </Text>
              </View>
            </View>
          )}
          {!!commentaryData.backsolveKeyInsights.primaryIndustry?.value && (
            <View style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'flex-start',
              marginBottom: '8px',
            }}
            >
              <Text style={{
                width: '12px',
                fontSize: '12px',
                color: '#49454F',
              }}
              >
                {'\u2022 '}
              </Text>
              <View>
                <Text
                  style={{
                    fontFamily: 'RobotoMed',
                    color: '#49454F',
                    fontSize: '12px',
                    lineHeight: '0.55mm',
                    letterSpacing: '0.25px',
                  }}
                >
                  Primary industry:&nbsp;
                  {commentaryData.backsolveKeyInsights.primaryIndustry.value}
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <Text style={{ fontFamily: 'Roboto' }}>
                    {commentaryData.backsolveKeyInsights.primaryIndustry.ac}
                  </Text>
                </Text>
              </View>
            </View>
          )}
          {commentaryData.backsolveKeyInsights.volatilitySelectionMethodology && (
            <View style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'flex-start',
              marginBottom: '8px',
            }}
            >
              <Text style={{
                width: '12px',
                fontSize: '12px',
                color: '#49454F',
              }}
              >
                {'\u2022 '}
              </Text>
              <View>
                <Text
                  style={{
                    fontFamily: 'RobotoMed',
                    color: '#49454F',
                    fontSize: '12px',
                    lineHeight: '0.55mm',
                    letterSpacing: '0.25px',
                  }}
                >
                  {commentaryData.backsolveKeyInsights.volatilitySelectionMethodology.value}
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <Text style={{ fontFamily: 'Roboto' }}>
                    {commentaryData.backsolveKeyInsights.volatilitySelectionMethodology.ac}
                  </Text>
                </Text>
              </View>
            </View>
          )}
          {commentaryData.backsolveKeyInsights.xExercisePrice && (
            <View style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'flex-start',
              marginBottom: '8px',
            }}
            >
              <Text style={{
                width: '12px',
                fontSize: '12px',
                color: '#49454F',
              }}
              >
                {'\u2022 '}
              </Text>
              <View>
                <Text
                  style={{
                    fontFamily: 'RobotoMed',
                    color: '#49454F',
                    fontSize: '12px',
                    lineHeight: '0.55mm',
                    letterSpacing: '0.25px',
                  }}
                >
                  X = Exercise price
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <Text style={{ fontFamily: 'Roboto' }}>
                    {commentaryData.backsolveKeyInsights.xExercisePrice.ac}
                  </Text>
                </Text>
              </View>
            </View>
          )}
          {commentaryData.backsolveKeyInsights.eqAdjustment && (
            <View style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'flex-start',
              marginBottom: '8px',
            }}
            >
              <Text style={{
                width: '12px',
                fontSize: '12px',
                color: '#49454F',
              }}
              >
                {'\u2022 '}
              </Text>
              <View>
                <Text
                  style={{
                    fontFamily: 'RobotoMed',
                    color: '#49454F',
                    fontSize: '12px',
                    lineHeight: '0.55mm',
                    letterSpacing: '0.25px',
                  }}
                >
                  Market equity adjustment selection:&nbsp;
                  {commentaryData.backsolveKeyInsights.eqAdjustment.value}
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <Text style={{ fontFamily: 'Roboto' }}>
                    {commentaryData.backsolveKeyInsights.eqAdjustment.ac}
                  </Text>
                </Text>
              </View>
            </View>
          )}
        </View>
      </View>
      <BottomPagination
        pageNumber={totalPageCount}
        reportData={reportData}
      />
      {reportData.isDraft && <DraftOverlay />}
    </Page>
  );
}

ValSumBacksolve.propTypes = {
  reportData: PropTypes.object.isRequired,
  totalPageCount: PropTypes.number.isRequired,
  exhibitNumber: PropTypes.number.isRequired,
};
