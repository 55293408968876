/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  Text,
  View,
  Image,
} from '@react-pdf/renderer';

import separatorLine from '../assets/images/line.png';

import CopyrightChar from './CopyrightChar';

export default function BottomPagination({
  pageNumber,
  reportData,
}) {
  return (
    <>
      <View
        style={{
          position: 'absolute',
          bottom: '0px',
          height: '36px',
          width: '100%',
          paddingLeft: '32px',
          paddingRight: '32px',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        <Text
          style={{
            fontFamily: 'Roboto',
            fontSize: '8px',
            letterSpacing: '0.4px',
            color: '#79747E',
          }}
        >
          PROPRIETARY AND CONFIDENTIAL
        </Text>
        <CopyrightChar color="#79747E" />
        <Text
          style={{
            fontFamily: 'Roboto',
            fontSize: '8px',
            letterSpacing: '0.4px',
            color: '#79747E',
          }}
        >
          {new Date().getFullYear()}
          {' '}
          initio software. All rights reserved. Valuation prepared by
          {' '}
          {reportData.isVenBioUser ? 'venBio' : 'Redwood Valuation'}
          ™.
        </Text>
        <Text
          style={{
            fontFamily: 'Roboto',
            fontSize: '8px',
            color: '#79747E',
            marginLeft: 'auto',
            letterSpacing: '0.4px',
          }}
        >
          Valuation date:
          {` ${moment(reportData.companyInfo.valuationDate, 'YYYY-MM-DD').format('MMMM DD, YYYY')}    |   Prepared for: ${reportData.companyInfo.investorFirmName}   |    `}
        </Text>
        <Text
          style={{
            fontFamily: 'Roboto',
            fontSize: '9px',
            color: '#49454F',
            marginRight: '14px',
            letterSpacing: '0.4px',
          }}
        >
          {reportData.isVenBioUser ? 'venBio' : 'Redwood Valuation'}
        </Text>
        <Text
          style={{
            fontFamily: 'Roboto',
            fontSize: '12px',
            color: '#79747E',
            letterSpacing: '0.4px',
          }}
        >
          {pageNumber}
        </Text>
      </View>
      <Image
        src={separatorLine}
        style={{
          position: 'absolute',
          width: '100%',
          bottom: '34px',
        }}
      />
    </>
  );
}

BottomPagination.propTypes = {
  pageNumber: PropTypes.number.isRequired,
  reportData: PropTypes.object.isRequired,
};
