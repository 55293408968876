import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import AddIcon from '@mui/icons-material/Add';
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';

import CompTableHeader from './CompTableHeader';
import CompTableRow from './CompTableRow';

import './CompTable.scss';

export default function CompTable({
  tabToView,
  filterSelected,
  compData,
  setCompData,
  fiscalYears,
  filters,
  filterValues,
  setShowAddCompModal,
  headerScroll,
  subCompScroll,
  overlayScrollbarRef,
  rightCompXScrollRef,
  rightCompYScroll,
  rightCompYScrollRef,
  valuationDate,
  transactionDate,
  financialStatementDate,
  reSummarize,
  runningComps,
  descriptionsToShow,
  setDescriptionsToShow,
  descriptionsRef,
  checkboxesRef,
}) {
  const [showConfirmationModal, setShowConfirmationModal] = useState(-1);

  return (
    <div className="CompTable">
      <CompTableHeader
        compData={compData}
        setCompData={setCompData}
        tabToView={tabToView}
        filterSelected={filterSelected}
        fiscalYears={fiscalYears}
        filters={filters}
        filterValues={filterValues}
        headerScroll={headerScroll}
        valuationDate={valuationDate}
        transactionDate={transactionDate}
        financialStatementDate={financialStatementDate}
        reSummarize={reSummarize}
      />
      <CompTableRow
        compData={compData}
        rowIndex={0}
        tabToView={tabToView}
        filterSelected={filterSelected}
        fiscalYears={fiscalYears}
        filters={filters}
        filterValues={filterValues}
        valuationDate={valuationDate}
        transactionDate={transactionDate}
        financialStatementDate={financialStatementDate}
        subCompScroll={subCompScroll}
      />
      <div className="comp-table-wrapper" ref={rightCompYScrollRef}>
        <div className="comp-table-rows-wrapper" ref={rightCompXScrollRef}>
          {compData.Identifier?.length && compData.Identifier.map((compID, index) => index !== 0 && (
            <CompTableRow
              key={compID}
              compData={compData}
              rowIndex={index}
              tabToView={tabToView}
              filterSelected={filterSelected}
              setShowConfirmationModal={setShowConfirmationModal}
              fiscalYears={fiscalYears}
              filters={filters}
              filterValues={filterValues}
              valuationDate={valuationDate}
              transactionDate={transactionDate}
              financialStatementDate={financialStatementDate}
              reSummarize={reSummarize}
              descriptionsToShow={descriptionsToShow}
              setDescriptionsToShow={setDescriptionsToShow}
              descriptionsRef={descriptionsRef}
              checkboxesRef={checkboxesRef}
            />
          ))}
        </div>
      </div>
      {rightCompYScroll?.current && rightCompYScroll.current.scrollHeight - rightCompYScroll.current.clientHeight > 0 && (
        <CompTableRow
          compData={compData}
          rowIndex={0}
          tabToView={tabToView}
          filterSelected={filterSelected}
          fiscalYears={fiscalYears}
          filters={filters}
          filterValues={filterValues}
          valuationDate={valuationDate}
          transactionDate={transactionDate}
          financialStatementDate={financialStatementDate}
          overlayScrollbarRef={overlayScrollbarRef}
          overlayScroll
        />
      )}
      <div className="table-footer">
        <Button disabled={runningComps} onClick={() => setShowAddCompModal(true)}>
          <AddIcon />
          Add Companies
        </Button>
      </div>
      <Dialog open={showConfirmationModal >= 0 || showConfirmationModal === 'all'} className="active-comp-confirmation-modal">
        <div className="dialog-header">
          <WarningAmberRoundedIcon />
          <span>Are you sure?</span>
        </div>
        {showConfirmationModal === 'all' ? (
          <>
            <p>Some companies were originally removed from the Volatility tab.</p>
            <p>If you add them back here, you&apos;ll also automatically add them back to the Volatility tab&apos;s Company list.</p>
          </>
        ) : (
          <>
            <p>This company was originally removed from the Volatility tab.</p>
            <p>If you add it back here, you&apos;ll also automatically add it back to the Volatility tab&apos;s Company list.</p>
          </>
        )}
        <div className="bottom-btns">
          <Button onClick={() => setShowConfirmationModal(-1)}>Cancel</Button>
          <Button
            onClick={() => {
              setShowConfirmationModal(-1);
              reSummarize(showConfirmationModal, 'add-both');
            }}
          >
            Add to both tab
          </Button>
        </div>
      </Dialog>
    </div>
  );
}

CompTable.propTypes = {
  tabToView: PropTypes.number.isRequired,
  filterSelected: PropTypes.bool.isRequired,
  fiscalYears: PropTypes.array.isRequired,
  filters: PropTypes.object.isRequired,
  filterValues: PropTypes.object.isRequired,
  setShowAddCompModal: PropTypes.func.isRequired,
  compData: PropTypes.object.isRequired,
  setCompData: PropTypes.func.isRequired,
  headerScroll: PropTypes.object.isRequired,
  subCompScroll: PropTypes.object.isRequired,
  rightCompXScrollRef: PropTypes.func.isRequired,
  rightCompYScroll: PropTypes.object.isRequired,
  rightCompYScrollRef: PropTypes.func.isRequired,
  overlayScrollbarRef: PropTypes.func.isRequired,
  valuationDate: PropTypes.string.isRequired,
  transactionDate: PropTypes.string.isRequired,
  reSummarize: PropTypes.func.isRequired,
  runningComps: PropTypes.bool.isRequired,
  descriptionsToShow: PropTypes.array.isRequired,
  setDescriptionsToShow: PropTypes.func.isRequired,
  descriptionsRef: PropTypes.object.isRequired,
  checkboxesRef: PropTypes.object.isRequired,
  financialStatementDate: PropTypes.string,
};

CompTable.defaultProps = {
  financialStatementDate: '',
};
