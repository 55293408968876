import React, { useState, useEffect, useContext, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import AnimateHeight from 'react-animate-height';

import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Dialog from '@mui/material/Dialog';

import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import DoneIcon from '@mui/icons-material/Done';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import CloseIcon from '@mui/icons-material/Close';
import UploadOutlinedIcon from '@mui/icons-material/UploadOutlined';

import IntakeBlock from './IntakeBlock';
import SuccessfulIntake from './SuccessfulIntake';

import AddCompanyToProject from '../../components/AddCompanyToProject';
import UploadBlock from './components/UploadBlock';

import { getUserId, getUserName } from '../../utils/auth';

import { NavWidthContext } from '../../contexts';

import { copy } from '../../utils';

import { cleanObjectValues } from './utils';

import useFetch from '../../hooks/useFetch';

import './index.scss';


function CustomDroppable({ children, ...props }) {
  const [enabled, setEnabled] = useState(false);

  useEffect(() => {
    const animation = requestAnimationFrame(() => setEnabled(true));

    return () => {
      cancelAnimationFrame(animation);
      setEnabled(false);
    };
  }, []);

  if (!enabled) return null;

  return <Droppable {...props}>{children}</Droppable>;
}

CustomDroppable.propTypes = { children: PropTypes.func.isRequired };

export default function InvestorIntake() {
  const [dropdownHeights, setDropdownHeights] = useState([]);

  const [companiesData, setCompaniesData] = useState([]);

  const [fetchingTransactionList, setFetchingTransactionsList] = useState(false);
  const [transactionsSavingData, setTransactionsSavingData] = useState(false);
  const [fetchingTransactionData, setFetchingTransactionData] = useState(-1);

  const [transactionsData, setTransactionsData] = useState([]);

  const [transactionDataIsSaving, setTransactionDataIsSaving] = useState(false);

  const [successMessage, setSuccessMessage] = useState(false);

  const { setNavWidth } = useContext(NavWidthContext);

  const [enterpriseCompanyData, setEnterpriseCompanyData] = useState({});
  const [projectData, setProjectData] = useState([]);

  const [addCompany, setAddCompany] = useState(false);

  const [showScheduleOfInvestmentsUploadDialog, setShowScheduleOfInvestmentsUploadDialog] = useState(false);

  const [companiesDataForAddition, setCompaniesDataForAddition] = useState({
    portfolioCompanyId: '',
    enterpriseCompanyId: '',
    projectId: '',
    requestUserId: getUserId(),
  });

  const [saveTransactionData, setSaveTransactionData] = useState(false);

  const appWidth = useRef(null);

  const appWidthRef = useCallback((node) => {
    if (appWidth?.current) window.removeEventListener('resize', () => setNavWidth(appWidth?.current.scrollWidth));
    if (node) {
      appWidth.current = node;
      window.addEventListener('resize', () => setNavWidth(appWidth?.current.scrollWidth));
    }
  }, []);

  function getCookie(name) {
    const cookieName = `${name}=`;
    const decodedCookie = decodeURIComponent(document.cookie);
    const cookieArray = decodedCookie.split(';');

    for (let i = 0; i < cookieArray.length; i += 1) {
      let cookie = cookieArray[i];
      while (cookie.charAt(0) === ' ') {
        cookie = cookie.substring(1);
      }
      if (cookie.indexOf(cookieName) === 0) {
        return JSON.parse(cookie.substring(cookieName.length, cookie.length));
      }
    }
    return null;
  }

  const [{ callEnded: getTransactionListCallEnded, status: transactionListStatus }, getTransactionsListRequest] = useFetch();
  const [{ callEnded: getEnterpriseCompanyInfoCallEnded }, getEnterpriseCompanyInfoRequest] = useFetch();
  const [{ callEnded: getProjectDetailsCallEnded }, getProjectDetailsRequest] = useFetch();
  const [, investorUpdateTransactionRequest] = useFetch();
  const [, getInvestorTransactionData] = useFetch();

  async function getTransactionsList() {
    setFetchingTransactionsList(true);
    getTransactionsListRequest({
      url: '/homepages/investor/',
      urlIds: ['companyId', 'userId'],
      onSuccess: (transactionList, getTransactionListStatus) => {
        if (getTransactionListStatus === 200) {
          const { enterpriseCompanyId, investorCompanyId, projectId } = transactionList[0];
          let newCompanyData = [];
          transactionList.forEach((company, i) => {
            newCompanyData.push({
              companyIndex: i,
              companyName: company.portfolioCompanyName,
              portfolioCompanyId: company.portfolioCompanyId,
              transactionId: company.transactionId,
              status: company.status,
              submittedByUser: company.submittedByUser,
              isLocked: company.isLocked,
            });
          });
          setTransactionsData([...Array(newCompanyData.length).fill(null)]);
          getEnterpriseCompanyInfoRequest({
            url: '/companies/enterprise/get-enterprise-company-info/',
            urlIds: [enterpriseCompanyId, 'userId'],
            onSuccess: setEnterpriseCompanyData,
          });
          setCompaniesDataForAddition({
            ...companiesDataForAddition,
            investorCompanyId,
            enterpriseCompanyId,
            projectId,
          });
          getProjectDetailsRequest({
            url: '/projects/asc820/get-project-details/',
            urlIds: [enterpriseCompanyId, investorCompanyId, projectId, 'userId'],
            onSuccess: (projectDataResponse) => setProjectData([projectDataResponse]),
          });
          const savedOrder = getCookie('transactionOrder');
          if (savedOrder) {
            newCompanyData = newCompanyData.sort((a, b) => {
              const orderA = savedOrder[a.transactionId];
              const orderB = savedOrder[b.transactionId];
              return orderA - orderB;
            });
          }
          setCompaniesData(newCompanyData);
        } else if (getTransactionListStatus === 206) {
          const projectId = transactionList.resource.split('_');
          setCompaniesDataForAddition({
            ...companiesDataForAddition,
            enterpriseCompanyId: transactionList.companyId,
            investorCompanyId: transactionList.investorCompanyId,
            projectId: projectId[projectId.length - 1],
          });
        }
      },
    });
  }

  useEffect(() => {
    setFetchingTransactionsList(!((getTransactionListCallEnded && getEnterpriseCompanyInfoCallEnded && getProjectDetailsCallEnded) ||
      transactionListStatus === 206));
  }, [getTransactionListCallEnded, getEnterpriseCompanyInfoCallEnded, getProjectDetailsCallEnded, transactionListStatus]);

  async function saveData(transactionToSaveIndex) {
    setTransactionDataIsSaving(true);
    const cleanedTransactions = cleanObjectValues(transactionsData);
    setTransactionsSavingData(cleanedTransactions
      .filter((transaction, i) => transaction && (transactionToSaveIndex !== 'all' ? i === transactionToSaveIndex : true))
      .map((transaction) => ({
        ...transaction,
        companyId: transaction.portfolioCompanyId,
        version: parseInt(transaction.version || 1, 10),
        holdings: transaction.holdings || [],
      })));
  }

  useEffect(() => {
    if (transactionsSavingData.length) {
      investorUpdateTransactionRequest({
        url: '/transactions/asc820/investor-update-transaction', method: 'post',
        body: transactionsSavingData[transactionsSavingData.length - 1],
        bodyIds: ['requestUserId'],
        multipleCalls: transactionsSavingData.length,
        onFinally: () => {
          setTransactionsSavingData(transactionsSavingData.slice(0, -1));
          if (transactionsSavingData.length === 1) {
            setTransactionDataIsSaving(false);
            setSaveTransactionData(false);
          }
        },
      });
    } else {
      setTransactionDataIsSaving(false);
      setSaveTransactionData(false);
    }
  }, [transactionsSavingData]);

  function setCookie(name, value, daysToExpire) {
    const date = new Date();
    date.setTime(date.getTime() + (daysToExpire * 24 * 60 * 60 * 1000));
    const expires = `expires=${date.toUTCString()}`;
    const stringValue = JSON.stringify(value);
    document.cookie = `${name}=${stringValue};${expires};path=/`;
  }

  function handleTransactionOrderSave(transactionList) {
    setTransactionDataIsSaving(true);
    const transactionOrderMap = {};
    for (let i = 0; i < transactionList.length; i += 1) {
      const transaction = transactionList[i];
      transactionOrderMap[transaction.transactionId] = i;
    }
    setCookie('transactionOrder', transactionOrderMap, 7);
    setTimeout(() => {
      setTransactionDataIsSaving(false);
    }, 1500);
  }

  function handleOnDragEnd(result) {
    if (!result.destination) return;

    const newCompanyList = copy(companiesData);
    const newTransactionList = copy(transactionsData);

    const [reorderedCompany] = newCompanyList.splice(result.source.index, 1);
    const [reorderedTransaction] = newTransactionList.splice(result.source.index, 1);
    newCompanyList.splice(result.destination.index, 0, reorderedCompany);
    newTransactionList.splice(result.destination.index, 0, reorderedTransaction);

    setCompaniesData(newCompanyList);
    setTransactionsData(newTransactionList);
    handleTransactionOrderSave(newCompanyList);
  }

  async function handleDropdownToggle(index, companyIndex) {
    const newDropdownHeights = copy(dropdownHeights);
    if (!transactionsData[index]) {
      setFetchingTransactionData(index);
      getInvestorTransactionData({
        url: '/transactions/asc820/get-investor-transaction-data/',
        urlIds: ['companyId', companiesData[index].portfolioCompanyId, companiesData[index].transactionId, 'userId'],
        onSuccess: (responseData) => {
          const newTransactionsData = copy(transactionsData);
          newTransactionsData[index] = responseData;
          setTransactionsData(newTransactionsData);
          newDropdownHeights.push(companyIndex);
          setDropdownHeights(newDropdownHeights);
        },
        onFinally: () => setFetchingTransactionData(-1),
      });
    } else {
      if (newDropdownHeights.includes(companyIndex)) newDropdownHeights.splice(newDropdownHeights.indexOf(companyIndex), 1);
      else newDropdownHeights.push(companyIndex);
      setDropdownHeights(newDropdownHeights);
    }
  }

  useEffect(() => { if (saveTransactionData !== false) saveData(saveTransactionData); }, [saveTransactionData]);

  useEffect(() => { getTransactionsList(); }, []);

  if (successMessage) {
    return <SuccessfulIntake projectData={projectData[0]} setSuccessMessage={setSuccessMessage} fetchingTransactionList={fetchingTransactionList} />;
  }

  return (
    <main className="InvestorIntake">
      <AddCompanyToProject
        addCompany={addCompany}
        setAddCompany={setAddCompany}
        projectId={companiesDataForAddition?.projectId}
        investorCompanyId={companiesDataForAddition?.investorCompanyId}
        setCompanies={setCompaniesData}
        companies={companiesData}
        enterpriseCompanyIdent={companiesDataForAddition?.enterpriseCompanyId}
        investor
        portfolioCompaniesNames={[]}
      />
      <div className="page-header" ref={appWidthRef}>
        <div className="header-nav">
          <p>ASC 820 information</p>
          <p>{getUserName()}</p>
          {!companiesData.length && (
            <Button
              className="add-company-btn"
              onClick={() => setAddCompany(true)}
            >
              <AddOutlinedIcon />
              Add company
            </Button>
          )}
        </div>
        {!!companiesData.length && (
          <div className="copy-btn-wrapper">
            <div className="header-text">
              <p>Click on a company below to get started. Save your progress as you go.</p>
              <p>Submit information for every company listed below, then click &apos;Done!&apos;.</p>
            </div>
            <Button
              className="add-company-btn"
              onClick={() => setAddCompany(true)}
            >
              <AddOutlinedIcon />
              Add company
            </Button>
            <Button
              className="schedule-upload-btn"
              onClick={() => setShowScheduleOfInvestmentsUploadDialog(true)}
            >
              <UploadOutlinedIcon />
              Upload schedule of investments
            </Button>
            <Button
              className="save-btn"
              onClick={() => { saveData('all'); }}
            >
              {transactionDataIsSaving && saveTransactionData === false ? (
                <>
                  <span className="dots-circle-spinner" />
                  Saving
                </>
              ) : (
                <>
                  <SaveOutlinedIcon />
                  Save
                </>
              )}
            </Button>
            <Button
              className="done-btn"
              disabled={!companiesData.every((company) => company.submittedByUser === '1')}
              onClick={() => {
                if (companiesData.every((company) => company.submittedByUser === '1')) {
                  setSuccessMessage(true);
                }
              }}
            >
              Done!
            </Button>
          </div>
        )}
      </div>
      {fetchingTransactionList ? <div className="fetching-investor-companies"><div className="dots-circle-spinner" /></div> : (
        <DragDropContext onDragEnd={(result) => { handleOnDragEnd(result); }}>
          <CustomDroppable droppableId="company-list" type="group">
            {(dropProvided) => (
              <div className="company-list" {...dropProvided.droppableProps} ref={dropProvided.innerRef}>
                {companiesData.map((company, index) => {
                  if (company.companyName) {
                    return (
                      <React.Fragment key={company.companyIndex}>
                        <Draggable draggableId={company.companyIndex.toString()} index={index}>
                          {(dragProvided) => (
                            <div
                              role="button"
                              className="company-btn"
                              ref={dragProvided.innerRef}
                              {...dragProvided.draggableProps}
                              onClick={() => { handleDropdownToggle(index, company.companyIndex); }}
                              onKeyDown={(e) => { if (e.key === 'Enter') handleDropdownToggle(index, company.companyIndex); }}
                              tabIndex={0}
                            >
                              <div className={`company-btn-head-content${dropdownHeights.includes(company.companyIndex) ? ' is-open' : ''}`}>
                                <div className="left-btn-side">
                                  {company.submittedByUser === '1' ?
                                    <div className="done-icon"><DoneIcon /></div> :
                                    <div className="number-icon">{index + 1}</div>}
                                  <span>{company.companyName}</span>
                                </div>
                                <div className="right-btn-side">
                                  <span>
                                    {company.submittedByUser === '1' ? 'Information submitted' :
                                      `${dropdownHeights.includes(company.companyIndex) ? 'Provide all' :
                                        'Expand to provide all'} info for this company`}
                                  </span>
                                  {fetchingTransactionData === index ? <div className="dots-circle-spinner fetching-tr-data" /> : (
                                    <IconButton
                                      className="right-chevron-btn"
                                      onClick={() => { handleDropdownToggle(index, company.companyIndex); }}
                                    >
                                      <ExpandMoreIcon
                                        className={`rotating-chevron${dropdownHeights.includes(company.companyIndex) ? ' upward' : ' downward'}`}
                                      />
                                    </IconButton>
                                  )}
                                  <IconButton
                                    className="right-handle-btn"
                                    {...dragProvided.dragHandleProps}
                                  >
                                    <DragHandleIcon />
                                  </IconButton>
                                </div>
                              </div>
                              <AnimateHeight duration={750} height={dropdownHeights.includes(company.companyIndex) ? 'auto' : 0}>
                                <IntakeBlock
                                  companiesData={companiesData}
                                  setCompaniesData={setCompaniesData}
                                  transactionsData={transactionsData}
                                  setTransactionsData={setTransactionsData}
                                  enterpriseCompanyData={enterpriseCompanyData}
                                  dropdownHeights={dropdownHeights}
                                  setDropdownHeights={setDropdownHeights}
                                  setSuccessMessage={setSuccessMessage}
                                  setSaveTransactionData={setSaveTransactionData}
                                  index={index}
                                />
                              </AnimateHeight>
                            </div>
                          )}
                        </Draggable>
                      </React.Fragment>
                    );
                  }
                  return null;
                })}
                {dropProvided.placeholder}
              </div>
            )}
          </CustomDroppable>
        </DragDropContext>
      )}
      <Dialog className="schedule-upload-dialog" open={showScheduleOfInvestmentsUploadDialog}>
        <IconButton
          className="close-icon"
          onClick={() => setShowScheduleOfInvestmentsUploadDialog(false)}
        >
          <CloseIcon />
        </IconButton>
        <div className="text-and-icon">
          <UploadOutlinedIcon />
          <p>
            Maximum file size: 35 MB. File types accepted:
            <span> Microsoft Excel </span>
            (.xls, .xlsx)
            <span> Word </span>
            (.doc, .docx),
            <span> PowerPoint </span>
            (.ppt, pptx, .pps, .ppsx), and
            <span> image files </span>
            (.jpg, .jpeg, .heic, and .png).
          </p>
        </div>
        <UploadBlock
          uploadType="schedule-of-investments"
          transactionsData={projectData}
          setTransactionsData={setProjectData}
          setSaveTransactionData={setSaveTransactionData}
          disableFields={companiesData.every((company) => company.submittedByUser === '1')}
        />
      </Dialog>
    </main>
  );
}
